import registrationRoutes from 'routes';

export const menuRefs = {
  // TRAINING_OFFER: 'offertaformativa',
  // NEWS_EVENTS: 'newsedeventi',
  // EVENTS: 'events',
  // FILE_ARTICLE: 'filearticle',
  // CAROUSEL_SDS: 'storiedisuccesso',
  // INITIATIVES: 'slidersp',
  // CONTACTS: 'contacts',
};

const menu = [
  {
    label: 'CORSI ASSOCIATI',
    link: registrationRoutes.allSubsriptions.path,
  },
  {
    label: 'Download Report ECM',
    link: registrationRoutes.downloadXml.path,
    provisioner: true
  },
  // {
  //   label: 'Catalogo',
  //   link: registrationRoutes.s4bCatalog.path,
  // }
];

export default menu;
