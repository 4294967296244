import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui/atoms';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { connect } from 'react-redux';
import { menuRefs } from 'utils/menu';

import {
  SliderSettingsCentered,
  SliderSettingsAside,
} from '../../../utils/slider-settings';
import CustomCarousel from '../CustomCarousel';
import {
  SliderContainer,
  CustomContainer,
  Card,
  Image,
  TextWrapper,
  Title,
  Subtitle,
  ButtonWrapper,
  TextBoxWrapper,
  TextBoxImg,
  CtasWrapper,
} from './style';
import { HtmlWrapper } from '../RenderLandingParagraph/style';
import ProDisplay from '../../typography/proDisplay';
import { HtmlRaw } from '../index';
import DigitedMedButton from "../../atoms/DigitedMedButton";

function Slider({
  cards,
  onClick,
  mediaIsPhone,
  boxCentered = true,
  scrollToRef,
  scrollCount,
}) {
  const myRef = useRef(null);
  useEffect(() => {
    if (scrollToRef && scrollToRef === menuRefs.INITIATIVES) {
      myRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollToRef, scrollCount]);
  const htmlDecode = (input) => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  };
  return (
    <SliderContainer boxCentered={boxCentered} ref={myRef}>
      <CustomCarousel
        config={boxCentered ? SliderSettingsCentered : SliderSettingsAside}
        alt
      >
        {cards.map((c, index) => (
          <Card boxCentered={boxCentered} key={`card-${index}`} img={c.image}>
            <CustomContainer boxCentered={boxCentered} pullUp={c.logo && c.logo.length > 0} mediaIsPhone={mediaIsPhone}>
              {boxCentered ? (
                <>
                  {c.logo && c.logo.length > 0 ? (
                    <Image
                      src={c.logo}
                      mediaIsPhone={mediaIsPhone}
                      pushDown={mediaIsPhone ? null : (c.cta_1_url && c.cta_1_url.length) ? 3 : 5}
                    />
                  ) : null}
                  <TextWrapper hasCTA={Boolean(c.cta_1_url)} mediaIsPhone={mediaIsPhone}>
                    <Title boxCentered={boxCentered}>
                      <ProDisplay
                        type="sliderTitle"
                        configuration={mediaIsPhone ? { fontSize: 20 } : {}}
                      >
                        {htmlDecode(c.title)}
                      </ProDisplay>
                    </Title>
                    <Subtitle>
                      <ProDisplay
                        type="sliderSubtitle"
                        configuration={mediaIsPhone ? { fontSize: 14 } : {}}
                      >
                        <HtmlRaw html={htmlDecode(c.subtitle)} />
                      </ProDisplay>
                    </Subtitle>
                  </TextWrapper>
                  {c.cta_1_url && (
                    <ButtonWrapper boxCentered={boxCentered} className="show-more">
                      <DigitedMedButton isMobile={mediaIsPhone} onClickHandler={() => onClick(c.cta_1_url)} text="Vedi"/>
                    </ButtonWrapper>
                  )}
                </>

              ) : (
                <TextBoxWrapper>
                  <TextBoxImg>
                    <Title boxCentered={boxCentered}>
                      <ProDisplay
                        type="landingtitle"
                        configuration={
                          mediaIsPhone ? { fontSize: 25 } : {}
                        }
                      >
                        {c.title.replace(/&quot;/g, '"')}
                      </ProDisplay>
                    </Title>
                    <ProDisplay type="bodyLanding" configuration={mediaIsPhone ? { fontSize: 16 } : { fontSize: 16 }}>
                      <HtmlWrapper
                        dangerouslySetInnerHTML={{
                          __html: c.subtitle,
                        }}
                      />
                    </ProDisplay>
                    {c?.cta_1_url && (
                      <CtasWrapper>
                        <ButtonWrapper boxCentered={boxCentered}>
                          {c.cta_1_type === 'apple' ? (
                            <Button
                              name="download"
                              text="Scarica"
                              onClick={() => onClick(c?.cta_1_url)}
                              target="_blank"
                              type="STORE"
                              isDownload
                              mediaIsPhone={mediaIsPhone}
                            />
                          ) : (
                            <Button
                              name={c?.cta_1_title}
                              text={'Scopri di più'.toUpperCase()}
                              onClick={() => onClick(c?.cta_1_url)}
                              height={mediaIsPhone ? 35 : 55}
                              type="PRIMARY"
                              mediaIsPhone={mediaIsPhone}
                            />
                          )}
                        </ButtonWrapper>
                      </CtasWrapper>
                    )}
                  </TextBoxImg>
                </TextBoxWrapper>
              )}
            </CustomContainer>
          </Card>
        ))}
      </CustomCarousel>
    </SliderContainer>
  );
}

Slider.propTypes = {
  cards: PropTypes.array,
  onClick: PropTypes.func,
  mediaIsPhone: PropTypes.bool,
  boxCentered: PropTypes.bool,
  scrollToRef: PropTypes.string,
  scrollCount: PropTypes.number,
};

export default connect(state => {
  const { scrollToRef, scrollCount } = state.landingPage;
  return { scrollToRef, scrollCount };
})(withMediaQueries(Slider));
