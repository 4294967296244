import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'ui/gridSystem';
import ProText from 'ui/typography/proText';
import MainContainer from 'pages/style';
import { connect } from 'react-redux';
import {
  ALL_PROSPECT_GET,
  CATALOG_PRODUCT_GET,
  TEAMS_INTERESTS_GET,
} from 'redux/actions/subscriptions';
import { PROFILE_DATA_COMPANY_GET } from 'redux/actions/profile';
import { Spinner } from 'ui/components';
import { Tab, TabContent, TabsContaner } from './style';
import ActiveSubscriptions from './ActiveSubscriptions';
import EditSubscriptions from './EditSubscriptions';

const tabs = {
  ACTIVE: 'ACTIVES',
  NOT_ACTIVE: 'NOT_ACTIVES',
};

const Subscriptions = ({
  presetTab = tabs.ACTIVE,
  all_prospect,
  assoc_subscriptions,
  totalItemsToAssociate,
  getAllProspect,
  getCompany,
  userId,
  organizationId,
  getTeamsInterests,
  getProductCatalog,
  dataLoaded,
}) => {
  const [activeTab, setActiveTab] = useState(presetTab);
  const [actives, setActives] = useState(0);
  const [toActivate, setToActivate] = useState(0);

  useEffect(() => {
    const n_actives = Object.keys(all_prospect || {}).reduce(
      (acc, k) => acc + all_prospect[k].length,
      0
    ) ?? 0;
    setActives(n_actives);
  }, [all_prospect]);

  useEffect(() => {
    const n_toActivate = totalItemsToAssociate ||
      (assoc_subscriptions?.orders?.reduce(
        (acc, item) => acc + item.quantity,
        0
      ) ?? 0);
    setToActivate(n_toActivate);
  }, [assoc_subscriptions, totalItemsToAssociate]);

  useEffect(() => {
    if (toActivate > 0) {
      setActiveTab(tabs.NOT_ACTIVE);
    } else {
      setActiveTab(tabs.ACTIVE);
    }
  }, [toActivate]);

  useEffect(() => {
    if (!(all_prospect && Object.keys(all_prospect).length > 0)) getAllProspect();
    getCompany();
    getProductCatalog();
    getTeamsInterests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, organizationId]);

  if (!dataLoaded?.orders || !dataLoaded?.prospects) {
    return (
      <MainContainer>
        <Container>
          <Spinner />
        </Container>
      </MainContainer>
    );
  }

  return (
    <MainContainer whiteBg>
      <Container>
        <TabsContaner className='mb-0'>
          <Tab
            onClick={() => setActiveTab(tabs.ACTIVE)}
            active={activeTab === tabs.ACTIVE}
          >
            <ProText configuration={{ fontSize: 12 }}>Utenti attivi ({actives})</ProText>
          </Tab>
          <Tab
            onClick={() => setActiveTab(tabs.NOT_ACTIVE)}
            active={activeTab === tabs.NOT_ACTIVE}
          >
            <ProText configuration={{ fontSize: 12 }}>Utenze da attivare ({toActivate})</ProText>
          </Tab>
        </TabsContaner>
        <TabContent>
          {activeTab === tabs.ACTIVE && <ActiveSubscriptions />}
          {activeTab === tabs.NOT_ACTIVE && (
            <EditSubscriptions
              callbackProspect={() => setActiveTab(tabs.ACTIVE)}
            />
          )}
        </TabContent>
      </Container>
    </MainContainer>
  );
};

Subscriptions.propTypes = {
  presetTab: PropTypes.string,

  all_prospect: PropTypes.object,
  assoc_subscriptions: PropTypes.object,
  dataLoaded: PropTypes.object,
  totalItemsToAssociate: PropTypes.number,

  getAllProspect: PropTypes.func.isRequired,
  getCompany: PropTypes.func.isRequired,
  userId: PropTypes.string,
  organizationId: PropTypes.string,
  getTeamsInterests: PropTypes.func,
  getProductCatalog: PropTypes.func,
};

export default connect(
  ({
    subscriptions: { all_prospect, assoc_subscriptions, dataLoaded, totalItemsToAssociate },
    authentication: { user: { userId, organizationId } = {} } = {},
  }) => ({
    all_prospect,
    assoc_subscriptions,
    userId,
    organizationId,
    dataLoaded,
    totalItemsToAssociate,
  }),
  dispatch => ({
    getAllProspect: () => dispatch({ type: ALL_PROSPECT_GET._REQUEST }),
    getCompany: () => dispatch({ type: PROFILE_DATA_COMPANY_GET._REQUEST }),
    getTeamsInterests: () =>
      dispatch({
        type: TEAMS_INTERESTS_GET._REQUEST,
      }),
    getProductCatalog: () =>
      dispatch({
        type: CATALOG_PRODUCT_GET._REQUEST,
      }),
  })
)(Subscriptions);
