import styled from "styled-components/macro";

export const Link = styled.a`
padding: 0 16px 0 0;
margin-left: auto;
  cursor: pointer;
`;

export const Text = styled.div`
  padding: 0 16px 0 0;

  &.align-right {
    margin-left: auto;
    cursor: pointer;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;

  div:nth-child(2) {
    order: 3;
    width: 100%;
  }

  @media ${props => props.theme.device.mobile} {
    div:nth-child(1) {
      max-width: 70%;

      span {
        width: 100%;
        white-space: nowrap;
        display: block !important;
      }
    }

    div:nth-child(2) {
      order: 3;
    }
  }
`;

export const ShowCalendar = styled.button`
  margin-left: auto;
  border: none;
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.transparent};
`
