import styled from "styled-components/macro";

export const SectionDevicesContainer = styled.div`
  font-family: 'prodisplay';
  margin-bottom: 50px;
  .contact-info{
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    &.light{
      font-size: 20px;
    }
    a, a:hover{
      color: #000;
      font-weight: 700;
    }
  }
`

export const Title = styled.h3`
    color: #f2284f;
  font-weight: 600;
  text-align: center;
  font-size: 40px;
  margin-bottom: 20px;
  padding: 0 20px;
`

export const Description = styled.div`
  text-align: center;
    font-size: 24px;
  margin-bottom: 50px;
  padding: 0 20px;
  font-weight: 400;
  strong{
    font-weight: 600;
  }
`

export const Cards = styled.div`
    display: flex;
  
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  margin-bottom: 40px;

  @media (max-width: 576px) {
    width: 100%;
    display: block;
    text-align: center;
  }
`
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;
  @media (max-width: 576px) {
    margin: 0 auto;
  }
  .img{
        width: 140px;
        height: 140px;
    background-repeat: no-repeat;
    background-size: 100px;
    margin-bottom: -20px;
  }
  .label{
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    font-size: 20px;
  }
  .icon-container{
    background-color: black;
    padding: 20px 0 0 22px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
`