import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import routes from 'routes';
import ProDisplay from 'ui/typography/proDisplay';
import modalStyles from './style';
import ProText from '../../../typography/proText';

const Modal = ({ isOpen, closeModal, defaultEcm }) => {
  const [selectedOption, setSelectedOption] = useState();
  const [isStudent, setIsStudent] = useState(false);
  const [isStudentRadioButtons, setIsStudentRadioButtons] = useState(false);
  const history = useHistory();
  const handleOptionChange = (e) => {
    setIsStudentRadioButtons(e.target.value === 'persona_fisica');
    if (e.target.value === 'business') setIsStudent(false);
    setSelectedOption(e.target.value);
  };

  const handleOptionStudentChange = (e) => {
    setIsStudent(e.target.value);
  };

  const handleProceed = (defaultEcm) => {
    if ((isStudent === 'true' || isStudent == 'false') || selectedOption === 'business') {
      if (isStudent) {
        history.push(`${routes.signup.path}?userType=${selectedOption}&student=${isStudent}${defaultEcm ? '&defaultEcm=true' : ''}`);
      } else {
        history.push(`${routes.signup.path}?userType=${selectedOption}${defaultEcm ? '&defaultEcm=true' : ''}`);
      }
    } else {
      alert('Per favore, seleziona una delle opzioni prima di procedere.');
    }
  };

  return (
    <div
      className="modal"
      style={{
        ...modalStyles.modal,
        ...(isOpen ? modalStyles.open : {})
      }}
    >
      <div className="row" style={modalStyles.modalContent}>
        <ProDisplay
            type="title"
            configuration={{ color: 'primary', colorType: 'base' }}
        >
          Seleziona la tipologia persona:
        </ProDisplay>
        <div style={modalStyles.radioGroup}>
          <div style={modalStyles.radioItem}>
            <input
              type="radio"
              value="persona_fisica"
              checked={selectedOption === 'persona_fisica'}
              onChange={handleOptionChange}
            />
            <ProText className="mx-2">Persona fisica</ProText>
          </div>
          <div className={isStudentRadioButtons ? 'm-2 px-5' : 'd-none'} style={modalStyles.radioGroup}>
            <div style={modalStyles.radioItem}>
              <input
                type="radio"
                value
                checked={isStudent === 'true'}
                onChange={handleOptionStudentChange}
              />
              <ProText className="mx-2">Sono uno studente</ProText>
            </div>
            <div style={modalStyles.radioItem}>
              <input
                type="radio"
                value={false}
                checked={isStudent === 'false'}
                onChange={handleOptionStudentChange}
              />
              <ProText className="mx-2">Non sono uno studente</ProText>
            </div>
          </div>

          <div style={modalStyles.radioItem}>
            <input
              type="radio"
              value="business"
              checked={selectedOption === 'business'}
              onChange={handleOptionChange}
            />
            <ProText className="mx-2">Persona giuridica/professionista</ProText>
          </div>
        </div>

        <div style={modalStyles.footer}>
          <div className="mx-1">
            <button style={modalStyles.subscribeButton} className="btn me-1" onClick={()=>handleProceed(defaultEcm)}>Prosegui</button>
          </div>
          <div>
            <button style={modalStyles.closeButton} className="btn me-1" onClick={closeModal}>Chiudi</button>
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

export default Modal;
