import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'ui/gridSystem';
import ProText from 'ui/typography/proText';
import { CookiePolicyURL, PrivacyPolicyURL } from 'const';
import { withMediaQueries } from 'hoc/withMediaQueries';
import {
  FooterContainer,
  Copyright,
  CopyrightWrapper,
  Link,
  FooterWrapper,
  LegalLinks,
} from './style';

const Footer = ({ mediaIsPhone }) => (
  <FooterContainer>
    <Container>
      <FooterWrapper>
        <CopyrightWrapper>
          <LegalLinks>
            <Link href={CookiePolicyURL} target="_blank">
              <ProText type="1" configuration={{ fontSize: '12' }}>
                COOKIE POLICY
              </ProText>
            </Link>
            <Link href={PrivacyPolicyURL} target="_blank" className="spacer">
              <ProText type="1" configuration={{ fontSize: '12' }}>
                PRIVACY POLICY
              </ProText>
            </Link>
          </LegalLinks>
          <Copyright>
            <ProText type="1" configuration={{ fontSize: mediaIsPhone ? '10' : '12' }}>
              ® Copyright Digit’Ed - P.IVA 07490560633
            </ProText>
          </Copyright>
        </CopyrightWrapper>
      </FooterWrapper>
    </Container>
  </FooterContainer>
);

Footer.propTypes = {
  mediaIsPhone: PropTypes.bool.isRequired,
};

export default withMediaQueries(Footer);
