import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withMediaQueries } from 'hoc/withMediaQueries';

import { connect } from 'react-redux';
import { MODAL_OPEN } from 'redux/actions';
import { SET_PASSWORD_MANAGER_FROM_BANK } from 'redux/actions/landingPage';
import { USERTYPE_UPDATE } from 'redux/actions/signup';

import getQueryParams from 'utils/getQueryParams';
import { errorPwd } from 'utils/password';
import validator from 'utils/regex-validator';
import billings from 'api/billings';

import { Button, CustomInput, CustomSelect } from 'ui/atoms';
import { styles } from 'utils/multi-select';
import PasswordStrength from 'ui/atoms/PasswordStrenght';
import AgreementCheck from 'ui/components/AgreementCheck';
import EmailModal from 'ui/components/Modals/EmailModal';
import ModalErrors from 'ui/components/Modals/ModalErrors';
import { Col, Container, Row } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';

import {
  ONBOARDING_GET_PROFESSIONS,
} from 'redux/actions/onBoarding';

import {
  Logo,
  FlowHeaderWrapper,
  FlowHeader
} from 'pages/Signup/style';
import {
  ButtonWrapper,
  FormGroup,
  Form,
  PasswordLegend,
  SubTitle,
  TitleBackWrapper,
  TitleContainer,
  Title,
  InputWrapper,
} from './style';

const newField = () => ({
  value: undefined,
  valid: true,
});

const initialForm = {
  confirmPassword: newField(),
  email: newField(),
  firstName: newField(),
  lastName: newField(),
  password: newField()
};

const SetFirstPasswordFromBank = ({
  agreements,
  brand,
  // mediaIsPhone,
  openErrorModal,
  setPassword,
  updateUserType,
  userType,
  getProfessions,
  professions = [],
  domainId
}) => {
  const [form, setForm] = useState(initialForm);
  const [atecoDescription, setAtecoDescription] = useState(null);

  const email = useRef('');
  const firstName = useRef('');
  const lastName = useRef('');
  const atecoNumber = useRef(null);
  const orgType = useRef('');
  const organizationId = useRef('');
  const managerType = useRef('');

    const [professionsList, setProfessionsList] = useState([]);
    const [specializationList, setSpecializationList] = useState([]);
    const [professionOptions, setProfessionOptions] = useState();
    const [specializationOptions, setSpecializationOptions] = useState();
    const [specializationOptionsToSelect, setSpecializationOptionsToSelect] = useState();
    const [specializationOptionsSelected, setSpecializationOptionsSelected] = useState();
    const [professionOptionsSelected, setProfessionOptionsSelected] = useState();
    const [typeOptionSelected, setTypeOptionSelected] = useState();
    const [specializationError, setSpecializationError] = useState(false);
    const [payloadProfessions, setPayloadProfessions] = useState();

  useEffect(() => {
    try {
      const bodyParam = getQueryParams('body');
      const bodyParamString = window.atob(bodyParam);
      const body = JSON.parse(bodyParamString);
      email.current = body?.email;
      firstName.current = decodeURIComponent(escape(body?.name));
      lastName.current = decodeURIComponent(escape(body?.lastName));
      atecoNumber.current = body?.atecoCode;
      orgType.current = body?.orgType;
      organizationId.current = body?.organizationId;
      managerType.current = body?.managerType;
      checkQueryParams();
    } catch {
      checkQueryParams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* TODO: merge the use effects on a single one which listens for the encrypted payload param */
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setForm(prev => {
      prev.email = email.current ? { value: email.current, valid: validator.email(email.current) } : prev.email;
      prev.firstName = firstName.current ? { value: firstName.current, valid: validator.string(firstName.current) } : prev.firstName;
      prev.lastName = lastName.current ? { value: lastName.current, valid: validator.string(lastName.current) } : prev.lastName;
      return { ...prev };
    });
  }, [email.current, firstName.current, lastName.current]);

  useEffect(() => {
    if (!atecoNumber.current || typeof atecoNumber.current !== 'string') return;
    const atecoMajor = atecoNumber.current.split('.').at(0);
    if (isNaN(parseInt(atecoMajor))) return;
    billings.getAtecoCodes({ type: 'Codice_ateco', search: atecoMajor, callback: handleAtecoResponse });
  }, [atecoNumber.current]);

  useEffect(() => {
    if (!orgType.current) return;
    updateUserType(orgType.current);
  }, [orgType.current]);
  /* eslint-enable react-hooks/exhaustive-deps */
  /* --- */

  const handleAtecoResponse = (atecoResponse) => {
    const newAtecoDescription = atecoResponse.find(aR => aR.Codice_ateco === atecoNumber)?.Descrizione_ateco;
    newAtecoDescription && setAtecoDescription(newAtecoDescription.split(' - ')[1]);//trust that billings.getAtecoCodes will return a string
  };

  const handleOnChange = (field, newValue) =>
    setForm(s => {
      const newState = { ...s };
      newState[field].value = newValue;
      return newState;
    });

  const validatePassword = () => {
    setForm(prevState => {
      const newState = { ...prevState };
      if (newState.confirmPassword.value !== undefined) {
        newState.confirmPassword.valid =
          newState.password.value === newState.confirmPassword.value;
      }
      return newState;
    });
  };

  const handleOnBlur = (type, field, callback) => {
    setForm(s => {
      const newState = { ...s };
      newState[field].valid = validator[type](form[field].value);
      return newState;
    });
    if (callback) callback();
  };

  const formConfirmed = () => {
    const valid = !!Object.entries(agreements?.user_clauses)
      .find(([key, value]) => value?.valid === false);
    return valid;// && !!userType;
  };

  useEffect(() => {
    if(!professionOptionsSelected?.length) {
      setSpecializationError(false);
    }

    const prof = professionsList.filter(function(p) {
      return professionOptionsSelected.find(function(pfSel) {
          return p.id === pfSel.value
      })
    })

    var profPayload = [];
    prof.forEach((w) => {
      const el = {
          professionId: w.id,
          professionCode: w.code,
          professionTitle: w.title,
          discipline: []
        }

        professionsList.forEach((p) => {
            if (p.id === w.id) {
              const disc = !!specializationOptionsSelected ? p.children.filter((c) => {
                  return specializationOptionsSelected?.find((sp) => {
                      return sp.value === c.id
                  })
              }) : [];

              if(!disc.length) {
                setSpecializationError(true);
              } else {
                setSpecializationError(false);
              }
              if (disc) {
                let disciplinePayload = [];
                disc.forEach((d) => {
                    disciplinePayload.push({
                        disciplineId: d.id,
                        disciplineCode: d.code,
                        disciplineTitle: d.title
                    })
                })
                el.discipline = disciplinePayload;
              } 
            }
        })
        profPayload.push(el);
    });

    setPayloadProfessions(profPayload);

  }, [professionOptionsSelected, specializationOptionsSelected]);

  const handleUpdatePassword = () => {
    const payloadJobInfo = {
      jobType: typeOptionSelected?.value,
      professions: payloadProfessions
    }
    const payload = {
      atecoDescription,
      email: form.email.value,
      organizationId: organizationId.current,
      password: form.password.value,
      jobInfo: payloadJobInfo,
      domainId: domainId,
      persDataAcceptance: Object.entries(agreements?.user_clauses)
        .reduce((acc, [key, value]) => { acc.push(value); return acc; }, []),
      managerType: managerType.current || null
    };
    setPassword(payload);
  };

  const buttonDisabled =
    form.password.value === undefined ||
    form.confirmPassword.value === undefined ||
    form.password.value !== form.confirmPassword.value ||
    form.password.valid !== true ||
    formConfirmed();

  const checkQueryParams = () => {
    const queryParams = {
      atecoCode: atecoNumber.current,
      email: email.current,
      lastName: lastName.current,
      name: firstName.current,
      orgType: orgType.current,
      organizationId: organizationId.current
    };

    const missingKeys = Object.entries(queryParams)
      // .filter(([key, value]) => !value)
      // .map(([key, value]) => key);
      .flatMap(([key, val]) => (!val ? [key] : []));

    if (missingKeys?.length < 1) return;

    const errorPayload = {
      errorTitle:
        // 'Missing Data',
        'Il link inserito non è valido',
      errorText:
        // `The provided link is missing some parameters: ${missingKeys.join(', ')}`
        'Devi usare il link di attivazione che hai ricevuto dopo la registrazione'
    };

    openErrorModal(errorPayload);
  };

  useEffect(() => {
    getProfessions();
  }, [getProfessions]);

  useEffect(() => {
    const professionsArray = professions.data;
    if(professionsArray){
        setProfessionsList(professionsArray);

        let specializations = [];

        if (Boolean(professionsArray)) {
          professionsArray.forEach(profession => {
            profession.children.forEach(children => {
              specializations = [...specializations, children];
              setSpecializationList(specializations);
            })
          });
        }

    }
  }, [professions]);

  useEffect(() => {
    if (Boolean(professionsList && Boolean(specializationList))) {
      let professionOptionsArray = [];

      professionsList.forEach(profession => {
        professionOptionsArray = [
          ...professionOptionsArray,
          {
            label: profession.title,
            value: profession.id
          }
        ];
        setProfessionOptions(professionOptionsArray);
      });

      let specializationsArray = [];

      specializationList.forEach(specialization => {
        specializationsArray = [
          ...specializationsArray,
          {
            label: specialization.title,
            value: specialization.id
          }
        ];
        setSpecializationOptions(specializationsArray);
      });
    }
  }, [professionsList, specializationList]);


  function onChangeProfession(values) {
    setProfessionOptionsSelected(values);

    let professionSelectedIds = [];
    let professionSelectedList = [];
    let professionChildrenAvailable = [];
    let professionChildrenAvailableIds = [];

    values.forEach(value => {
      professionSelectedIds = [
        ...professionSelectedIds, {
          id: value.value
      }]
    });

    professionSelectedList = professionsList.filter(profession => professionSelectedIds.map(item => item.id).includes(profession.id));
    professionChildrenAvailable = professionSelectedList.map(professionSelected => professionSelected.children).flat();
    professionChildrenAvailableIds = professionChildrenAvailable.map(children => children.id);

    const filteredSpecializationOptions = specializationOptions.filter(specializationOption => professionChildrenAvailableIds.includes(specializationOption.value));
    
    let remappedSpecializationOptions = [];

    filteredSpecializationOptions.forEach(option => {
      const relatedProfession = professionsList?.find(profession => profession.children.find(children => children.id === option.value)).title;

      remappedSpecializationOptions = [...remappedSpecializationOptions, {
        label: `${option.label} (${relatedProfession})`,
        value: option.value
      }]
    });

    setSpecializationOptionsToSelect(remappedSpecializationOptions);

    if(!!specializationOptionsSelected) {
      let filteredSpecializationOptionsSelected = specializationOptionsSelected.filter(specializationSelected => professionChildrenAvailableIds.includes(specializationSelected.value));
      setSpecializationOptionsSelected(filteredSpecializationOptionsSelected);
    }
  }


  function onChangeSpecialization(values) {
    setSpecializationOptionsSelected(values);
  }

  function onChangeType(value) {
    setTypeOptionSelected(value);
  }

  return (
    <>
      <FlowHeaderWrapper>
        <Container>
          <FlowHeader>
            <Logo
              disabled
              img={brand?.logo_white}
            />
          </FlowHeader>
        </Container>
      </FlowHeaderWrapper>
      <Container>
        <Row>
          <Col md={{ size: 10, offset: 1 }} sm={12}>
            <div>
              <TitleContainer>
                <TitleBackWrapper>
                  <Title>
                    <ProDisplay type="title" configuration={{ fontWeight: 300 }}>
                      Conferma il tuo account
                    </ProDisplay>
                  </Title>
                </TitleBackWrapper>

                <SubTitle>
                  <ProText type="description">
                    Crea il tuo account per accedere alla dashboard di amministrazione
                  </ProText>
                </SubTitle>
              </TitleContainer>
              <FormGroup>
                <Form>
                  <Row>
                    <Col md={6} sm={12} className="mb-3">
                      <CustomInput
                        type="text"
                        placeholder="Nome *"
                        value={form.firstName.value}
                        onChange={val => handleOnChange('firstName', val)}
                        onBlur={() => handleOnBlur('string', 'firstName')}
                        autoFocus
                        error={!form.firstName.valid}
                        disabled={form.firstName.value}
                      />
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                      <CustomInput
                        type="text"
                        placeholder="Cognome *"
                        value={form.lastName.value}
                        onChange={val => handleOnChange('lastName', val)}
                        onBlur={() => handleOnBlur('string', 'lastName')}
                        error={!form.lastName.valid}
                        disabled={form.lastName.value}
                      />
                    </Col>
                    {
                      (managerType.current === 'PROVISIONER') ? '' : <>
                    <Col md={6} sm={12} className="mb-3">
                          <InputWrapper className="select">
                     <CustomSelect
                           placeholder="Professione *"
                           options={professionOptions}
                           isMulti={true}
                           hideSelectedOptions={false}
                           closeOnSelect={false}
                           onChange={values => onChangeProfession(values)}
                           styles={styles}
                           selected={professionOptionsSelected}
                                id="profession-select"
                           isSearchType={false}
                           hasError={true}
                         />
                       </InputWrapper>
                       </Col>
                        <Col md={6} sm={12} className="mb-3">
                       <InputWrapper className='select'>
                         <CustomSelect
                           placeholder="Specializzazione *"
                           options={specializationOptionsToSelect}
                           isMulti={true}
                           hideSelectedOptions={false}
                           closeOnSelect={false}
                           onChange={values => onChangeSpecialization(values)}
                           styles={styles}
                           selected={specializationOptionsSelected}
                           id='specialization-select'
                           isSearchType={false}
                           hasError={true}
                           isError={specializationError}
                           customErrorMessage={'Selezionare almeno una specializzazione per ogni professione selezionata'}
                         />
                       </InputWrapper>
                        </Col></>
                    }
                        <Col md={6} sm={12} className="mb-3">
                        <InputWrapper className='select'>
                         <CustomSelect
                           placeholder="Tipologia *"
                           options={[
                            {
                              label: 'Privo di occupazione',
                              value: 'P'
                            },
                            {
                              label: 'Dipendente',
                              value: 'D'
                            },
                            {
                              label: 'Libero Professionista',
                              value: 'L'
                            },
                            {
                              label: 'Convenzionato',
                              value: 'C'
                            }
                           ]}
                           hideSelectedOptions={true}
                           onChange={value => onChangeType(value)}
                           styles={styles}
                           selected={typeOptionSelected}
                           isSearchType={false}
                           id='type-select'
                         />
                       </InputWrapper>
                       </Col>
                    <Col sm={12} className="mb-3">
                      <CustomInput
                        type="email"
                        placeholder="Indirizzo email *"
                        value={form.email.value}
                        onChange={val => handleOnChange('email', val)}
                        onBlur={() => handleOnBlur('email', 'email')}
                        error={!form.email.valid}
                        disabled={form.email.value}
                      />
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                      <CustomInput
                        autocomplete="new-password"
                        type="password"
                        placeholder="Password *"
                        value={form.password.value}
                        onChange={val => handleOnChange('password', val)}
                        onBlur={() =>
                          handleOnBlur('password', 'password', validatePassword)
                        }
                        error={!form.password.valid}
                        displayError={!form.password.error}
                      />
                      <PasswordLegend
                        error={!!form.password.error}
                      >
                        <ProDisplay type="2" configuration={{ fontSize: 12 }}>
                          {errorPwd}
                        </ProDisplay>
                      </PasswordLegend>
                      <PasswordStrength password={form.password.value} />
                    </Col>
                    <Col md={6} sm={12} className="mb-3">
                      <CustomInput
                        autocomplete="new-password"
                        type="password"
                        placeholder="Conferma password *"
                        value={form.confirmPassword.value}
                        onChange={val => handleOnChange('confirmPassword', val)}
                        onBlur={validatePassword}
                        error={!form.confirmPassword.valid}
                      />
                    </Col>
                    {(brand && userType) && ( // hasDomainLoaded
                      <AgreementCheck validator={formConfirmed} />
                    )}
                    {userType && (
                      <ButtonWrapper>
                        <Button
                          type={Button.TYPE.PRIMARY}
                          text="Conferma"
                          disabled={buttonDisabled || specializationError}
                          onClick={(e) => {
                            e.preventDefault();
                            handleUpdatePassword();
                          }}
                        />
                      </ButtonWrapper>
                    )}
                  </Row>
                </Form>
              </FormGroup>
            </div>
          </Col>
        </Row>
        <EmailModal />
      </Container>
    </>
  );
};

SetFirstPasswordFromBank.propTypes = {
  // HOC withMediaQueries
  // mediaIsPhone: PropTypes.bool.isRequired,

  // HOC (connect, state)
  agreements: PropTypes.object,
  brand: PropTypes.object,
  userType: PropTypes.string,

  // HOC (connect, dispatch)
  openErrorModal: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  updateUserType: PropTypes.func.isRequired,
  getProfessions: PropTypes.func.isRequired
};

export default connect(
  state => {
    const { brand, id: domainId } = state.domain;
    const { agreements } = state.signup;
    const { professions } = state.onBoarding;

    return {
      agreements,
      brand,
      userType: agreements?.userType,
      domain: state.domain,
      professions,
      domainId
    };
  },
  dispatch => ({
    openErrorModal: payload =>
      dispatch({ type: MODAL_OPEN, id: ModalErrors.id, payload }),
    setPassword: payload =>
      dispatch({ type: SET_PASSWORD_MANAGER_FROM_BANK._REQUEST, payload }),
    updateUserType: userType =>
      dispatch({ type: USERTYPE_UPDATE, userType }),
    getProfessions: () => dispatch({ type: ONBOARDING_GET_PROFESSIONS._REQUEST })
  })

)(withMediaQueries(SetFirstPasswordFromBank));
