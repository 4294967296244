import React from "react";
import {Card, Cards, Description, SectionDevicesContainer, Title} from "./style";
import SectionIscriviti from "../SectionIscriviti";

const SectionDevices = ({
                            props
                        })=>{

    return (
        <>
            <SectionDevicesContainer>
                <Title>{props.title}</Title>
                <Description dangerouslySetInnerHTML={{__html: props.description}}></Description>
                <Cards>
                    {
                        props.cards.map(card => <Card>
                            <div className="icon-container">
                                <div className="img" style={{backgroundImage: `url(${card.image})`}}></div>
                            </div>
                            <p className="label">{card.title}</p>
                        </Card>)
                    }
                </Cards>

                <SectionIscriviti/>
                <p className={"contact-info"}>Per maggiori informazioni contattaci a <a href="mailto:info@accuratesolutions.it">info@accuratesolutions.it</a></p>
                <p className={"contact-info light"}>Per supporto tecnico contattare <a href="mailto:support.digitedmed@digited.it">support.digitedmed@digited.it</a></p>
            </SectionDevicesContainer>
        </>
    )
}

export default SectionDevices;