import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Col, Container, Row } from 'ui/gridSystem';
import { Checkbox, CustomInput, CustomSelect, RadioButton } from 'ui/atoms';

import EmailModal from 'ui/components/Modals/EmailModal';
import ProDisplay from 'ui/typography/proDisplay';
import validator from 'utils/regex-validator';

import { SIGNUP_UPDATE_ACCOUNT } from 'redux/actions/signup';
import { AgreementsContentsInterface } from 'interface/signup';
import AgreementCheck from 'ui/components/AgreementCheck';
import {
  CheckboxWrapper,
  CheckboxWrapperText,
} from 'ui/components/AgreementCheck/style';
import { push } from 'redux-first-history';
import routes from 'routes';
import ProText from 'ui/typography/proText';
import PasswordStrength from 'ui/atoms/PasswordStrenght';
import { errorPwd } from 'utils/password';
import {
  ONBOARDING_GET_PROFESSIONS,
} from 'redux/actions/onBoarding';
import { useLocation } from 'react-router-dom';
import {
  FormGroup,
  TitleContainer,
  Title,
  SubTitle,
  Form,
  TitleBackWrapper,
  IconBack,
  PasswordLegend,
} from './style';
import SummaryTableSimpler from '../../../../ui/components/SummaryTableSimpler';
import { styles } from '../../../../utils/multi-select';
import { InputWrapper } from '../../../OnBoarding/CreateUser/style';



const newField = (deafultValue = undefined) => ({
  value: deafultValue,
  valid: true,
});

const allFields = {
  firstName: newField(),
  lastName: newField(),
  email: newField(),
  pwd: newField(),
  confirmpwd: newField(),
  superManagerCode: newField(),
  section: newField(),
  profession: newField(),
  specialization: newField(),
  profession_type: newField(),
  isStudent: newField(false),
};

const initialForm = {
  firstName: newField(),
  lastName: newField(),
  email: newField(),
  pwd: newField(),
  confirmpwd: newField(),
  profession: newField(),
  specialization: newField(),
  profession_type: newField(),
  isStudent: newField(false),
};

const initialFormBank = {
  firstName: newField(),
  lastName: newField(),
  email: newField(),
  superManagerCode: newField(),
  section: newField(),
  profession: newField(),
  specialization: newField(),
  profession_type: newField(),
  isStudent: newField(false),
};

const UserAccount = ({
  onChange,
  userBuyer,
  toSendPassword,
  prospectId,
  updateAccount,
  agreements,
  isFromBank,
  domain,
  pushUrl,
  stepOnBoarding,
  professions = [],
  getProfessions
}) => {
  const [form, setForm] = useState(allFields);
  const [professionsList, setProfessionsList] = useState([]);
  const [specializationList, setSpecializationList] = useState([]);
  const [professionOptions, setProfessionOptions] = useState();
  const [specializationOptions, setSpecializationOptions] = useState();
  const [specializationOptionsToSelect, setSpecializationOptionsToSelect] = useState();
  const [specializationOptionsSelected, setSpecializationOptionsSelected] = useState();
  const [professionOptionsSelected, setProfessionOptionsSelected] = useState();
  const [typeOptionSelected, setTypeOptionSelected] = useState();
  const [specializationError, setSpecializationError] = useState(false);
  const [payloadProfessions, setPayloadProfessions] = useState();
  const [checkConds, setCheckConds] = useState(!isFromBank);
  const [isTypeTouched, setIsTypeTouched] = useState(false);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const userType = searchParams.get('userType');

  useEffect(() => {
    getProfessions();
  }, [getProfessions]);

  useEffect(() => {
    const professionsArray = professions.data;
    setProfessionsList(professionsArray);

    let specializations = [];

    if (professionsArray) {
      professionsArray.forEach(profession => {
        profession.children.forEach(children => {
          specializations = [...specializations, children];
          setSpecializationList(specializations);
        });
      });
    }
  }, [professions]);

  useEffect(() => {
    if ((professionsList && Boolean(specializationList))) {
      let professionOptionsArray = [];

      professionsList.forEach(profession => {
        professionOptionsArray = [
          ...professionOptionsArray,
          {
            label: profession.title,
            value: profession.id,
            code: profession.code
          }
        ];
        setProfessionOptions(professionOptionsArray);
      });

      let specializationsArray = [];

      specializationList.forEach(specialization => {
        specializationsArray = [
          ...specializationsArray,
          {
            label: specialization.title,
            value: specialization.id,
            code: specialization.code
          }
        ];
        setSpecializationOptions(specializationsArray);
      });
    }
  }, [professionsList, specializationList]);

  useEffect(() => {
    if (!professionOptionsSelected?.length) {
      setSpecializationError(false);
    }

    const prof = professionsList.filter((p) => professionOptionsSelected.find((pfSel) => p.id === pfSel.value));

    const profPayload = [];
    prof.forEach((w) => {
      const el = {
        professionId: w.id,
        professionCode: w.code,
        professionTitle: w.title,
        discipline: []
      };

      professionsList.forEach((p) => {
        if (p.id === w.id) {
          const disc = specializationOptionsSelected ? p.children.filter((c) => specializationOptionsSelected?.find((sp) => sp.value === c.id)) : [];

          if (!disc.length) {
            setSpecializationError(true);
          } else {
            setSpecializationError(false);
          }
          if (disc) {
            const disciplinePayload = [];
            disc.forEach((d) => {
              disciplinePayload.push({
                disciplineId: d.id,
                disciplineCode: d.code,
                disciplineTitle: d.title
              });
            });
            el.discipline = disciplinePayload;
          }
        }
      });
      profPayload.push(el);
    });

    setPayloadProfessions(profPayload);
  }, [professionOptionsSelected, specializationOptionsSelected]);

  function onChangeProfession(values) {
    setProfessionOptionsSelected(values);

    let professionSelectedIds = [];
    let professionSelectedList = [];
    let professionChildrenAvailable = [];
    let professionChildrenAvailableIds = [];

    values.forEach(value => {
      professionSelectedIds = [
        ...professionSelectedIds, {
          id: value.value
        }];
    });

    professionSelectedList = professionsList.filter(profession => professionSelectedIds.map(item => item.id).includes(profession.id));
    professionChildrenAvailable = professionSelectedList.map(professionSelected => professionSelected.children).flat();
    professionChildrenAvailableIds = professionChildrenAvailable.map(children => children.id);

    const filteredSpecializationOptions = specializationOptions.filter(specializationOption => professionChildrenAvailableIds.includes(specializationOption.value));

    let remappedSpecializationOptions = [];

    filteredSpecializationOptions.forEach(option => {
      const relatedProfession = professionsList.find(profession => profession.children.find(children => children.id === option.value)).title;

      remappedSpecializationOptions = [...remappedSpecializationOptions, {
        label: `${option.label} (${relatedProfession})`,
        value: option.value,
        code: option.code
      }];
    });

    setSpecializationOptionsToSelect(remappedSpecializationOptions);

    if (specializationOptionsSelected) {
      const filteredSpecializationOptionsSelected = specializationOptionsSelected.filter(specializationSelected => professionChildrenAvailableIds.includes(specializationSelected.value));
      setSpecializationOptionsSelected(filteredSpecializationOptionsSelected);
    }
    handleOnChange('profession', values);
  }

  function onChangeSpecialization(values) {
    setSpecializationOptionsSelected(values);
    handleOnChange('specialization', values);
  }

  function onChangeType(value) {
    setTypeOptionSelected(value);
    setIsTypeTouched(true);
    handleOnChange('profession_type', value);
  }

  useEffect(() => {
    isFromBank ? setForm(initialFormBank) : setForm(initialForm);
  }, [isFromBank]);

  const handleOnChange = (field, newValue) =>
    setForm(s => {
      const newState = { ...s };
      newState[field].value = newValue;
      newState.isStudent.value = searchParams.get('student') == 'true' ?? false;
      return newState;
    });

  const validatePassword = () => {
    setForm(s => {
      const newState = { ...s };
      if (newState.pwd?.value !== undefined) {
        newState.pwd.valid = validator.password(newState.pwd.value);
        newState.pwd.error = !validator.password(newState.pwd.value) ? 'La password deve essere composta da almeno 8 caratteri, contenere almeno una lettera maiuscola, una lettera minuscola, un numero ed un carattare speciale tra cui = + - ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < \' : ; | _ ~ `' : '';
      }
      if (newState.confirmpwd?.value !== undefined) {
        newState.confirmpwd.valid =
          newState.pwd?.value === newState.confirmpwd.value;
      }
      return newState;
    });
  };

  const handleOnBlur = (type, field, callback) => {
    setForm(s => {
      const newState = { ...s };
      newState[field].valid = validator[type](form[field].value);
      return newState;
    });

    if (callback) callback();
  };

  const excludeFields = ['profession', 'specialization', 'profession_type'];

  const formValid = (Object.keys(form) || []).map(key => {
    if (key === 'isStudent') return true;
    if (excludeFields.includes(key) && (searchParams.get('student') === 'true')) {
      delete form[key].value;
      return true;
    }
    if ((searchParams.get('student') !== 'true') && key === 'profession_type') {
      return Boolean(form[key].value && form[key].value.value.length > 0 && form[key].valid);
    }
    return Boolean(form[key].value && form[key].value.length > 0 && form[key].valid);
  });

  const formConfirmed = () => {
    let valid = true;
    const policyConsentIds = userType === 'persona_fisica'
        ? domain.policy.consents.map(c => c.id)
        : [];
    if (agreements?.user_clauses) {
      Object.keys(agreements.user_clauses).forEach(key => {
        if (policyConsentIds.includes(key)) {
          return;
        }
        valid = valid && agreements.user_clauses[key]?.valid;
      });
    } else {
      valid = false;
    }
    return valid && !!userType;
  };

  useEffect(() => {
    if (onChange) {
      let isValid;
      isFromBank
        ? (isValid = formValid.filter(bool => bool === false).length <= 0)
        : (isValid =
            formValid.filter(bool => bool === false).length <= 0 &&
            formConfirmed() === true);

      isValid = isValid && (isFromBank ? checkConds : true);
      onChange(isValid);
      if (isValid) updateAccount({ ...form, professionsSpecializations: {value: payloadProfessions, valid: true} });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, checkConds, agreements, payloadProfessions]);

  useEffect(() => {
    const isValid =
      formValid.filter(bool => bool === false).length <= 0 &&
      formConfirmed() === true;
    onChange(isValid);
    if (isValid) updateAccount({ ...form, domainId: { value: domain.id }, professionsSpecializations: {value: payloadProfessions, valid: true} });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreements.user_clauses, payloadProfessions]);

  /*   useEffect(() => {
    if (!cart.total_price) {
      pushUrl(routes.subscription.path);
    }
  }, [cart.total_price, pushUrl]); */

  return (
    <Container>
      <Row>
        <Col md={{ size: 10, offset: 1 }} sm={12}>
          <div>
            <TitleContainer>
              <TitleBackWrapper>
                {isFromBank && (
                  <IconBack
                    className="icon-arrow-right"
                    onClick={() => pushUrl(routes.subscription.path)}
                  />
                )}
                <Title>
                  <ProDisplay type="title" configuration={{ fontWeight: 300 }}>
                    {isFromBank
                      ? "Crea l'account del tuo Cliente"
                      : 'Crea il tuo account'}
                  </ProDisplay>
                </Title>
              </TitleBackWrapper>

              <SubTitle>
                <ProText type="description">
                  {isFromBank
                    ? "Crea l'account del tuo Cliente per accedere alla dashboard di amministrazione"
                    : 'Crea il tuo account per accedere alla dashboard di amministrazione'}
                </ProText>
              </SubTitle>
            </TitleContainer>
            <FormGroup>
              <Form>
                <Row>
                  <Col md={6} sm={12} className="mb-3">
                    <CustomInput
                      type="text"
                      placeholder="Nome *"
                      value={form.firstName.value}
                      onChange={val => handleOnChange('firstName', val)}
                      onBlur={() => handleOnBlur('string', 'firstName')}
                      autoFocus
                      error={!form.firstName.valid}
                    />
                  </Col>
                  <Col md={6} sm={12} className="mb-3">
                    <CustomInput
                      type="text"
                      placeholder="Cognome *"
                      value={form.lastName.value}
                      onChange={val => handleOnChange('lastName', val)}
                      onBlur={() => handleOnBlur('string', 'lastName')}
                      error={!form.lastName.valid}
                    />
                  </Col>
                  <Col sm={12} className="mb-3">
                    <CustomInput
                      type="email"
                      placeholder="Indirizzo email *"
                      value={form.email.value}
                      onChange={val => handleOnChange('email', val)}
                      onBlur={() => handleOnBlur('email', 'email')}
                      error={!form.email.valid}
                    />
                  </Col>
                  {(searchParams.get('student') != 'true') && <Col sm={12} className="mb-3">
                    <InputWrapper className="select">
                      <CustomSelect
                        placeholder={(searchParams.get('student') != 'true') ? 'Professione *' : 'Professione'}
                        options={professionOptions}
                        isMulti
                        hideSelectedOptions={false}
                        closeOnSelect={false}
                        onChange={values => onChangeProfession(values)}
                        styles={styles}
                        selected={professionOptionsSelected}
                        id="profession-select"
                        isSearchType={false}
                        hasError={(searchParams.get('student') != 'true')}
                      />
                    </InputWrapper>
                  </Col>}
                  {(searchParams.get('student') != 'true') && <Col sm={12} className="mb-3">
                    <InputWrapper className="select">
                      <CustomSelect
                        placeholder="Specializzazione *"
                        options={specializationOptionsToSelect}
                        isMulti
                        hideSelectedOptions={false}
                        closeOnSelect={false}
                        onChange={values => onChangeSpecialization(values)}
                        styles={styles}
                        selected={specializationOptionsSelected}
                        id="specialization-select"
                        isSearchType={false}
                        hasError={(searchParams.get('student') !== 'true')}
                        isError={specializationError}
                        customErrorMessage="Selezionare almeno una specializzazione per ogni professione selezionata"
                      />
                    </InputWrapper>
                  </Col>}
                  {(searchParams.get('student') != 'true') && <Col sm={12} className="mb-3">
                    <InputWrapper className="select">
                      <CustomSelect
                        placeholder="Tipologia *"
                        options={[
                          { label: 'Privo di occupazione', value: 'P' },
                          { label: 'Dipendente', value: 'D' },
                          { label: 'Libero Professionista', value: 'L' },
                          { label: 'Convenzionato', value: 'C' }
                        ]}
                        hideSelectedOptions
                        onChange={value => onChangeType(value)}
                        styles={styles}
                        selected={typeOptionSelected}
                        isSearchType={false}
                        hasError={(searchParams.get('student') !== 'true') && !isTypeTouched}
                        id="type-select"
                      />
                    </InputWrapper>
                  </Col>}
                  {isFromBank ? (
                    <>
                      <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          type="text"
                          placeholder="Codice gestore *"
                          value={form.superManagerCode.value}
                          onChange={val =>
                            handleOnChange('superManagerCode', val)
                          }
                          onBlur={() => handleOnBlur('sdi', 'superManagerCode')}
                          error={!form.superManagerCode.valid}
                        />
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          type="text"
                          placeholder="Filiale di riferimento *"
                          value={form.section.value}
                          onChange={val => handleOnChange('section', val)}
                          onBlur={() => handleOnBlur('string', 'section')}
                          error={!form.section.valid}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          autocomplete="new-password"
                          type="password"
                          placeholder="Password *"
                          value={form.pwd.value}
                          onChange={val => handleOnChange('pwd', val)}
                          onBlur={() =>
                            handleOnBlur('password', 'pwd', validatePassword)
                          }
                          error={
                            typeof form.pwd.valid === 'string'
                              ? form.pwd.valid
                              : !form.pwd.valid
                          }
                          displayError={!form.pwd.error}
                        />
                        <PasswordLegend
                          error={!!form.pwd.error}
                        >
                          <ProDisplay type="2" configuration={{ fontSize: 12 }}>
                            {errorPwd}
                          </ProDisplay>
                        </PasswordLegend>
                        <PasswordStrength password={form.pwd.value} />
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          autocomplete="new-password"
                          type="password"
                          placeholder="Conferma password *"
                          value={form.confirmpwd.value}
                          onChange={val => handleOnChange('confirmpwd', val)}
                          onBlur={validatePassword}
                          error={!form.confirmpwd.valid}
                        />
                      </Col>
                    </>
                  )}
                  {!isFromBank && (
                    <>
                      {/* <PasswordSuggestion password={form.pwd} padding={20} /> */}
                      <AgreementCheck validator={formConfirmed} checkUserType />
                    </>
                  )}
                  {isFromBank && (
                    <Col md={12} className="mb-3">
                      <CheckboxWrapper
                        onClick={() => setCheckConds(!checkConds)}
                      >
                        <Checkbox
                          checked={checkConds}
                          disabled
                          onChange={() => null}
                        />
                        <CheckboxWrapperText>
                          <ProDisplay type="label">
                            Confermo che sono stati rilasciati i consensi
                            necessari all'attivazione degli abbonamenti
                          </ProDisplay>
                        </CheckboxWrapperText>
                      </CheckboxWrapper>
                    </Col>
                  )}
                </Row>
              </Form>
            </FormGroup>
          </div>
        </Col>
        {isFromBank && (
          <Col md={{ size: 10, offset: 1 }} sm={12} className="mb-5">
            <SummaryTableSimpler />
          </Col>
        )}
      </Row>
      <EmailModal />
    </Container>
  );
};

UserAccount.propTypes = {
  onChange: PropTypes.func.isRequired,
  isFromBank: PropTypes.bool,
  domain: PropTypes.object,
  stepOnBoarding: PropTypes.string,
  userBuyer: PropTypes.func,
  professions: PropTypes.object,

  // HOC (connect, dispatch)
  updateAccount: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  agreements: AgreementsContentsInterface,
  getProfessions: PropTypes.func
};

export default connect(
  state => {
    const { stepOnBoarding, toSendPassword, prospectId, professions } = state.onBoarding;
    const { agreements, isFromBank } = state.signup;

    return {
      stepOnBoarding,
      toSendPassword,
      prospectId,
      agreements,
      isFromBank,
      domain: state.domain,
      professions
    };
  },
  dispatch => ({
    updateAccount: account =>
      dispatch({ type: SIGNUP_UPDATE_ACCOUNT, account }),
    // pushUrl: url => dispatch(push(url)),
    pushUrl: (url, userType) => {
      const hasUserType = url.includes('?userType=');
      const newUrl = hasUserType ? url : `${url}?userType=${userType}`;
      dispatch(push(newUrl));
    },
    getProfessions: () => dispatch({ type: ONBOARDING_GET_PROFESSIONS._REQUEST })
  })
)(UserAccount);
