import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';

import { withMediaQueries } from 'hoc/withMediaQueries';
import { Col, Container, Row } from 'ui/gridSystem';
import { CompanyDataInterface } from 'interface/profile';
import { CustomInput, CustomSelect } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import validator from 'utils/regex-validator';
import Button from 'ui/atoms/Button';
import routes from 'routes';
import { Spinner } from 'ui/components';
import { SECTOR_GET } from 'redux/actions/signup';
import states from 'utils/states';
import { PROFILE_DATA_COMPANY_UPDATE } from 'redux/actions/profile';
import { styles } from 'utils/multi-select';
import { SelectWrapper } from 'ui/components/TableCard/style';
import billings from 'api/billings';

import {
  EditDataContainer,
  FormGroup,
  Title,
  SubTitle,
  HeadWrapper,
  Head,
  Logo,
  ButtonWrapper,
  FooterContainer,
  Foot,
} from './style';

const newField = (types = ['string'], value = undefined, valid = true) => ({
  value,
  valid,
  types,
});
const fields = [
  { name: 'rsociale', types: ['string'] },
  { name: 'piva', types: ['piva'] },
  { name: 'cf_piva', types: ['fiscalCode'] },
  { name: 'country', types: ['string'] },
  { name: 'state', types: ['string'] },
  { name: 'postalcode', types: ['postalCode'] },
  { name: 'city', types: ['string'] },
  { name: 'address', types: ['address'] },
  { name: 'atecocode', types: ['string'] },
  { name: 'atecodescription', types: ['string'] },
  { name: 'sdi', types: ['sdi'] },
  { name: 'section', types: ['string'] },
];
const initialForm = {};
fields.forEach(f => (initialForm[f.name] = newField(f.types)));
initialForm.country = { ...initialForm.country, value: 'it' };

const emptyAteco = {
  code: undefined,
  description: undefined,
};

const countryOpt = [{ label: 'Italia', value: 'IT' }];

const EditData = ({
  mediaIsPhone,
  pushUrl,
  company,
  getSector,
  sector,
  updateProfileCompany,
  domain,
  organizationType,
}) => {
  const myAteco = {
    code: { label: company.atecocode },
    description: { label: company.atecodescription },
  };

  const [atecoCodes, setAtecoCodes] = useState([]);
  const [form, setForm] = useState(initialForm);
  const [atecoOptions, setAtecoOptions] = useState();
  const [atecoDescOptions, setAtecoDescOptions] = useState();
  const [atecoPair, setAtecoPair] = useState(myAteco);
  const [timeoutId, setTimeoutId] = useState();
  const [showAltro, setShowAltro] = useState(false);

  useEffect(() => {
    setAtecoOptions(atecoCodes);
    setAtecoDescOptions(atecoCodes);
  }, [atecoCodes]);

  useEffect(() => {
    getSector();
  }, [getSector]);

  useEffect(() => {
    let acceptedTypes = ['fiscalCode'];
    if (organizationType === 'persona_giuridica') acceptedTypes = ['piva'];

    setForm(s => {
      const newState = { ...s };
      newState.cf_piva.types = acceptedTypes;
      newState.cf_piva.valid =
        Boolean(company?.orgfiscalcode) ||
        'Attenzione: inserire il campo mancante';
      return newState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (field, newValue) => {
    const { types } = form[field];
    setForm(s => {
      const newState = { ...s };

      newState[field].value = newValue;
      newState[field].valid = types.reduce((acc, t) => {
        const valid = validator[t](newValue);
        return typeof acc === 'boolean' ? acc || valid : valid;
      }, false);

      return newState;
    });
  };

  const handleSectorChange = (field, newValue) => {
    setShowAltro(
      newValue?.toString() === '34' || newValue?.toString() === '35'
    );
    handleOnChange(
      field,
      newValue?.toString() !== '34' && newValue?.toString() !== '35'
        ? newValue
        : undefined
    );
  };

  const changeLocation = url => {
    pushUrl(url);
  };

  const isFormValid = () => {
    const validityValues = (Object.keys(form) || []).map(key =>
      (typeof form[key].valid === 'boolean' ? form[key].valid : false));
    const fieldValues = (Object.keys(form) || []).map(key => form[key].value);
    const isValid =
      validityValues.indexOf(false) < 0 &&
      fieldValues.filter(item => item !== undefined).length > 0;
    return !isValid;
  };

  const formattedSector = (sector || []).map(({ name: label, id: value }) => ({
    label,
    value,
  }));

  const handleOnSubmit = () => {
    const body = (Object.keys(form) || []).reduce((acc, key) => {
      const obj = { ...acc };
      if (form[key]?.value) {
        switch (key) {
          case 'rsociale':
            obj.title = form[key]?.value;
            break;
          case 'piva':
            obj.vatNumber = form[key]?.value;
            break;
          case 'cf_piva':
            obj.orgFiscalCode = form[key]?.value;
            break;
          case 'sdi':
            obj.sdiNumber = form[key]?.value;
            break;
          case 'address':
            obj.street = form[key]?.value;
            break;
          case 'city':
            obj.city = form[key]?.value;
            break;
          case 'postalcode':
            obj.zipCode = form[key]?.value;
            break;
          case 'country':
            obj.country = form[key]?.value;
            break;
          case 'section':
            obj.industryId = form[key]?.value;
            break;
          case 'state':
            obj.district = form[key]?.value;
            break;
          case 'atecocode':
            obj.atecoCode = form[key]?.value;
            break;
          case 'atecodescription':
            obj.atecoDescription = form[key]?.value;
            break;
          default:
            console.log(`Form dati di fatturazione non ha gestito: ${key}`);
            break;
        }
      }
      return obj;
    }, {});
    updateProfileCompany(body);
  };

  const searchAtecoCodes = (search, type) => {
    if (timeoutId) clearTimeout(timeoutId);
    const currentTimeout = setTimeout(() => {
      if (search && search.length > 1) {
        billings.getAtecoCodes({ type, search, callback: setAtecoCodes });
      }
    }, 500);
    setTimeoutId(currentTimeout);
  };

  useEffect(() => {
    setAtecoOptions(
      atecoCodes.map(ac => ({ value: ac._id, label: ac.Codice_ateco }))
    );
    setAtecoDescOptions(
      atecoCodes.map(ac => ({
        value: ac._id,
        label: ac.Descrizione_ateco.split('-')[1].trim(),
      }))
    );
  }, [atecoCodes]);

  const onChangeAteco = val => {
    if (val) {
      const selectedAteco = atecoCodes.find(ac => ac._id === val.value);
      handleOnChange('atecocode', selectedAteco.Codice_ateco);
      handleOnChange(
        'atecodescription',
        selectedAteco.Descrizione_ateco.split('-')[1].trim()
      );
      setAtecoPair({
        code: { value: selectedAteco._id, label: selectedAteco.Codice_ateco },
        description: {
          value: selectedAteco._id,
          label: selectedAteco.Descrizione_ateco.split('-')[1].trim(),
        },
      });
    } else {
      handleOnChange('atecocode', '');
      handleOnChange('atecodescription', '');
      setAtecoPair(emptyAteco);
    }
  };

  return (
    <>
      <HeadWrapper>
        <Container>
          <Head>
            <Logo
              onClick={() => changeLocation(routes.landingPage.path)}
              img={domain?.brand?.logo_white}
            />
          </Head>
        </Container>
      </HeadWrapper>
      <Container>
        <EditDataContainer>
          <Row>
            <Col md={1} sm={12}>
              &nbsp;
            </Col>
            <Col md={10} sm={12}>
              <div>
                <Title>
                  <ProDisplay
                    type="title"
                    configuration={
                      mediaIsPhone
                        ? {
                          fontSize: '24',
                          lineHeight: 1.22,
                          color: 'primary',
                          colorType: 'base',
                        }
                        : {
                          color: 'primary',
                          colorType: 'base',
                        }
                    }
                  >
                    Dati fatturazione
                  </ProDisplay>
                </Title>
                <SubTitle>
                  <ProText type="description">
                    Modifica i tuoi dati di fatturazione
                  </ProText>
                </SubTitle>
                {company ? (
                  <FormGroup>
                    <Row>
                      <Col
                        md={6 /* showAltro ? 6 : 12 */}
                        sm={12}
                        className="mb-3"
                      >
                        <CustomInput
                          type="select"
                          disabled
                          placeholder="Settore"
                          value={company?.industryid}
                          options={formattedSector}
                          onChange={val => handleSectorChange('section', val)}
                          searchable={false}
                          autoFocus
                        />
                      </Col>
                      {showAltro ? (
                        <Col md={6} sm={12} className="mb-3">
                          <CustomInput
                            type="text"
                            placeholder="Altro"
                            value={company.industryid}
                            onChange={val => handleOnChange('section', val)}
                            error={!form.section.valid}
                          />
                        </Col>
                      ) : (
                        ''
                      )}
                      {company.organizationtype !== 'persona_fisica' && <Col sm={12} md={6} className="mb-3">
                        <CustomInput
                          type="text"
                          placeholder="Regione Sociale"
                          value={company?.title}
                          onChange={val => handleOnChange('rsociale', val)}
                          error={!form.rsociale.valid}
                        />
                      </Col>}
                      {company.organizationtype !== 'persona_fisica' && <Col sm={12} md={6} className="mb-3">
                        <CustomInput
                          type="text"
                          placeholder="Partita IVA"
                          value={company?.vatnumber} //{form.piva.value}
                          forceUppercase
                          onChange={val => {
                            handleOnChange('piva', val.toUpperCase());
                          }}
                          error={
                            typeof form.piva.valid === 'string'
                              ? form.piva.valid
                              : !form.piva.valid
                          }
                          disabled={!!company?.vatnumber}
                        />
                      </Col>}
                      <Col sm={12} md={6} className="mb-3">
                        <CustomInput
                          type="text"
                          placeholder="Codice Fiscale"
                          value={company?.orgfiscalcode} //{form.cf_piva.value}
                          forceUppercase
                          onChange={val => {
                            handleOnChange('cf_piva', val.toUpperCase());
                          }}
                          error={
                            typeof form.cf_piva.valid === 'string'
                              ? form.cf_piva.valid
                              : !form.cf_piva.valid
                          }
                          disabled={!!company?.orgfiscalcode}
                        />
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          type="select"
                          disabled
                          options={countryOpt}
                          value={countryOpt[0]?.value}
                          placeholder="Nazione"
                          searchable={false}
                          onChange={val => handleOnChange('country', val)}
                        />
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          type="select"
                          placeholder="Provincia"
                          value={company?.district}
                          options={states}
                          onChange={val => handleOnChange('state', val)}
                          searchable={false}
                        />
                      </Col>
                      <Col sm={12} md={6} className="mb-3">
                        <CustomInput
                          type="text"
                          placeholder="CAP"
                          value={company?.zipcode}
                          onChange={val => handleOnChange('postalcode', val)}
                          error={!form.postalcode.valid}
                        />
                      </Col>
                      <Col sm={12} md={6} className="mb-3">
                        <CustomInput
                          type="text"
                          placeholder="Città"
                          value={company?.city}
                          onChange={val => handleOnChange('city', val)}
                          error={!form.city.valid}
                        />
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          type="text"
                          placeholder="Indirizzo"
                          value={company?.street}
                          onChange={val => handleOnChange('address', val)}
                          error={!form.address.valid}
                        />
                      </Col>
                      {company.organizationtype !== 'persona_fisica' && <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          type="text"
                          placeholder="SDI"
                          value={company?.sdinumber}
                          onChange={val => handleOnChange('sdi', val)}
                          error={!form.sdi.valid}
                        />
                      </Col>}

                      <Col md={6} sm={12} className="mb-3">
                        <SelectWrapper>
                          <CustomSelect
                            placeholder="Codice ATECO *"
                            isSearchType
                            options={atecoOptions}
                            onInputChange={val =>
                              searchAtecoCodes(val, 'Codice_ateco')
                            }
                            onChange={onChangeAteco}
                            styles={styles}
                            selected={atecoPair.code}
                          />
                        </SelectWrapper>
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <SelectWrapper>
                          <CustomSelect
                            placeholder="Descrizione codice ATECO *"
                            isSearchType
                            options={atecoDescOptions}
                            onInputChange={val =>
                              searchAtecoCodes(val, 'Descrizione_ateco')
                            }
                            onChange={onChangeAteco}
                            styles={styles}
                            selected={atecoPair.description}
                          />
                        </SelectWrapper>
                      </Col>
                    </Row>
                  </FormGroup>
                ) : (
                  <Spinner />
                )}
              </div>
            </Col>
            <Col md={1} sm={12}>
              &nbsp;
            </Col>
          </Row>
        </EditDataContainer>
      </Container>
      <FooterContainer>
        <Container>
          <Foot>
            <ButtonWrapper>
              <Button
                text="Annulla"
                type={Button.TYPE.SECONDARY}
                onClick={() => changeLocation(`${routes.managerProfile.path}`)}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                text="Conferma"
                type={Button.TYPE.PRIMARY}
                onClick={() => handleOnSubmit()}
                disabled={isFormValid()}
              />
            </ButtonWrapper>
          </Foot>
        </Container>
      </FooterContainer>
    </>
  );
};

EditData.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
  company: CompanyDataInterface,
  getSector: PropTypes.func,
  sector: PropTypes.array.isRequired,
  updateProfileCompany: PropTypes.func.isRequired,
  pushUrl: PropTypes.func.isRequired,
  domain: PropTypes.object,
  organizationType: PropTypes.string,
};

export default connect(
  state => {
    const {
      company,
      privacy: { organizationType },
    } = state.profile;
    const { sector } = state.signup;
    return {
      company,
      sector,
      domain: state.domain,
      organizationType,
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    getSector: () => dispatch({ type: SECTOR_GET._REQUEST }),
    updateProfileCompany: form =>
      dispatch({ type: PROFILE_DATA_COMPANY_UPDATE._REQUEST, form }),
  })
)(withMediaQueries(EditData));
