const modalStyles = {
    modal: {
        display: 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        overflowY: 'hidden',
        zIndex: 10
    },
    open: {
        display: 'flex',
    },
    modalButton: {
        padding: '8px 15px',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        marginTop: '10px',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '10px',
        marginRight: '10px',
        overflowY: 'auto',
    },
    radioGroup: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '20px',
        width: '100%',
        maxWidth: '400px'
    },
    radioItem: {
        marginBottom: '10px',
        textAlign: 'left',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontSize: '20px',
    },
    footer: {
        marginTop: '20px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'end',
        width: '100%',
        maxWidth: '400px',
    },
    subscribeButton: {
        padding: '5px 15px',
        fontSize: '15px',
        backgroundColor: 'rgb(242, 40, 79)',
        color: 'white',
        borderRadius: '5px',
        border: '0',
        // marginTop: '250px',
    },
    closeButton: {
        padding: '5px 15px',
        fontSize: '15px',
        backgroundColor: 'rgb(0,0,0)',
        color: 'white',
        borderRadius: '5px',
        border: '0',
        // marginTop: '250px',
    },


};

export default modalStyles;