import styled from 'styled-components/macro';

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
`;

export const Title = styled.h1``;

export const BtnWrapper = styled.div`
  width: 140px;
  height: 52px;
`;

export const Main = styled.main`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 62px;
  border: solid 1px ${props => props.theme.colors.antiFlashWhite};
  background-image: linear-gradient(183deg, ${props => props.theme.colors.white} 40%, ${props => props.theme.colors.cultured2} 3%);

  @media ${props => props.theme.device.tablet} {
    border: none;
    margin: 0;
  }
`;

export const NotesContainer = styled.div`
  background-image: linear-gradient(183deg, white 40%, ${props => props.theme.colors.cultured2} 3%);
`;

export const NotePreviewContainer = styled.div`
  height: 90px;
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px ${props => props.theme.colors.antiFlashWhite};
  padding: 16px 13px 16px 20px;
  cursor: pointer;
  z-index: -1;

  &.selected {
    position: relative;
    background-color: ${props => props.theme.colors.white};
    box-shadow: 0px 20px 20px 0 rgba(209, 209, 214, 0.3);
    z-index: 0;
  }

  @media ${props => props.theme.device.tablet} {
    border-radius: 6px;
    background-color: ${props => props.theme.colors.white};
    border: solid 1px ${props => props.theme.colors.antiFlashWhite};

    &.selected {
      box-shadow: none;
    }
  }
`;

export const Text = styled.div`
  text-align: center;
`;

export const NoteDescription = styled.div`
  min-width: 70%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const SharedWith = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  & div {
    margin-left: 5px;
  }
`;

export const NoteDetailContainer = styled.div`
  min-height: 80vh;
  border-left: solid 1px ${props => props.theme.colors.antiFlashWhite};
  background-color: ${props => props.theme.colors.white};

  @media ${props => props.theme.device.tablet} {
    display: ${props => (props.isVisible ? 'block' : 'none')};
    min-width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 37px 10px;
`;

export const IconBack = styled.div`
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
  transform: rotate(180deg);
  cursor: pointer;
`;

export const IconsWrapper = styled.div`
  display: flex;
  &.align-left {
    margin-right: auto;
  }
`;

export const IconEdit = styled(IconBack)`
  transform: rotate(0);
`;

export const IconDelete = styled(IconBack)`
  transform: rotate(0);
  margin-left: 6px;
`;

export const DetailHead = styled.div`
  margin: 29px 29px 23px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > div:not(:first-child):not(:last-child) {
    margin-right: 10px;
  }

  @media ${props => props.theme.device.tablet} {
    justify-content: flex-start;
    margin: 29px 37px 23px;

    & > div:first-child {
      margin-right: 10px;
    }
  }
`;

export const IconUser = styled.div`
  width: 45px;
  height: 45px;
  font-size: 45px;
  color: ${props => props.theme.colors.primary.base};
  margin-left: 4px;
  cursor: pointer;
`;

export const IconPlus = styled.div`
  width: 45px;
  height: 45px;
  font-size: 45px;
  color: ${props => props.theme.colors.primary.base};
  margin-left: 4px;
  cursor: pointer;
`;

export const Owner = styled.div`
  margin: 0 10% 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & span {
    display: block;
  }

  @media ${props => props.theme.device.tablet} {
    margin: 0 37px 10px;
    padding: 0;
  }
`;

export const DetailTitle = styled.h2`
  margin: 0 10% 22px;

  @media ${props => props.theme.device.tablet} {
    margin: 0 37px 22px;
    padding: 0;
  }
`;

export const DetailText = styled.p`
  margin: 0 10% 22px;
  @media ${props => props.theme.device.tablet} {
    margin: 0 37px 22px;
  }
  @media ${props => props.theme.device.mobile} {
    margin-bottom: 2rem;
  }
`;

export const Image = styled.img`
  display: inline-block;
  margin: 0 10% 22px;
  max-width: 80%;
  height: auto;

  @media ${props => props.theme.device.tablet} {
    margin: 0 37px 22px;
    max-width: calc(100% - 74px);
    height: auto;
  }
`;

export const Comment = styled.div`
  margin: 0 10% 22px;

  @media ${props => props.theme.device.tablet} {
    margin: 0 37px 10px;
    padding: 0;
  }
  & > div {
    margin: 0;
    margin-bottom: 6px;
  }
`;
