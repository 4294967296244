import React, { useState } from 'react';
import { getStatusLabelByStatus } from 'redux/reducers/subscriptions';

import { withMediaQueries } from 'hoc/withMediaQueries';

import { Row, Col } from 'ui/gridSystem';
import { CustomInput } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
import {
  IconAccordion,
  AccordionWrapper
} from 'ui/components/TableCard/style';
import {
  IconEdit,
  IconStatus,
  StatusLabel,
  WrapperIcon
} from '../TableCardDesktop/style';
import {
  AccordionRow,
  TableCardMobileContainer,
  // Icon,
} from './style';
import RowCreditiEcmEdit from "../RowCreditiEcmEdit";

const TableCardMobile = ({
  // state
  changeEmail,
  countBasicPkgs,
  countPremiumPkgs,
  email,
  newEmail,
  emailError,
  offerts,
  ecmMaxCreditiArray,
  ecmRoleOptionsState,
  isProvisioner,

  // actions
  checkActives,
  handleOnBlur,
  handleOnChangeEmail,
  handleUpdateEmailProspect,
  handleUpdateCreditiEcmProspect,
  setChangeEmail,
  handleSelectRole,
  
  // HOC, state
  // productNames,
  subscriptionOnEdit,

  // HOC, dispatch
  // openDisabledSubscriptionModal,
  // openConfirmModal,
  setSubscriptionOnEdit,

  // HOC, media queries
  currentViewport,
  viewportTypes,
}) => {
  const [basicAccordionOpen, setBasicAccordionOpen] = useState(false);
  const [premiumAccordionOpen, setPremiumAccordionOpen] = useState(false);

  const handleAccordion = (packageType) => {
    if (packageType === 'premium') {
      setPremiumAccordionOpen(prev => !prev);
    } else {
      setBasicAccordionOpen(prev => !prev);
    }
    if (subscriptionOnEdit) {
      setSubscriptionOnEdit(null);
    }
  };

  const basicOfferts = offerts.filter(offert => offert.packageType === 'base');
  const premiumOfferts = offerts.filter(offert => offert.packageType === 'premium');

  const getAccordion = ({
    accordionOpen,
    handleAccordion,
    numberOfPackages,
    offerts,
    title,
  }) => (
    <AccordionWrapper>
      <Row className='align-items-center'>
        <Col xs={6} sm={5}>
          <ProDisplay>
            {title}
          </ProDisplay>
        </Col>
        <Col xs={4} sm={6}>
          <ProDisplay
            className={'text-brand'}
            configuration={{
              fontWeight: 700
            }}
          >
            {numberOfPackages}
          </ProDisplay>
        </Col>
        <Col xs={1} sm={1}>
          {numberOfPackages > 0 ? (
            <IconAccordion
              className="icon-btn-arrow"
              open={accordionOpen}
              onClick={handleAccordion}
              grayBg
            />
          ) : null}
        </Col>
      </Row>
      {accordionOpen &&
        offerts.map(off => {
          const variationTitle = off.title;

          // const is_active = ['active'].includes(off.status?.toLowerCase());
          // const will_be_deleted = ['inactiveready'].includes(off.status?.toLowerCase() );
          const is_deleted = ['inactive', 'inactiveready'].includes(off.status?.toLowerCase());
          //const is_inactive = ['inactive'].includes(off.status?.toLowerCase());

          // const onEdit =
          //   subscriptionOnEdit?.variationId === off.variationId &&
          //   subscriptionOnEdit?.email === email;
          return (
            // <AccordionRow key={off.variationId}>
            //   <Labels>
            //     <Field>
            //       <Label>
            //         <ProDisplay type="tableLabel">Nome</ProDisplay>
            //       </Label>
            //       <Text>
            //         <ProDisplay
            //           type="label"
            //           configuration={{ lineHeight: 1.57 }}
            //         >
            //           {variationTitle}
            //         </ProDisplay>
            //       </Text>
            //     </Field>
            //     <Field>
            //       <Label>
            //         <ProDisplay type="tableLabel">Stato</ProDisplay>
            //       </Label>
            //       <Text>
            //         <ProDisplay type="label">
            //           {getStatusLabelByStatus(off.status.toLowerCase())}
            //         </ProDisplay>
            //       </Text>
            //     </Field>
            //   </Labels>
            //   <Field className="p-0">
            //     {onEdit && (is_active || will_be_deleted) && (
            //       <Text>
            //         {!will_be_deleted && (
            //           <Delete
            //             onClick={() => {
            //               openConfirmModal({
            //                 isFingerLearning: off.chargeType.toLowerCase() !== 'recurring',
            //               });
            //             }}
            //           >
            //             <ProDisplay
            //               type="deleteBtn"
            //               configuration={
            //                 viewportTypes[currentViewport] < currentViewport.mediaIsDesktop
            //                   ? { fontSize: '10', lineHeight: 1.07 }
            //                   : null
            //               }
            //             >
            //               Elimina <br />
            //               abbonamento
            //             </ProDisplay>
            //           </Delete>
            //         )}
            //         {off.chargeType.toLowerCase() === 'recurring' && off.renewal && (
            //           <Delete
            //             onClick={() => {
            //               openDisabledSubscriptionModal(off);
            //             }}
            //           >
            //             <ProDisplay
            //               type="deleteBtn"
            //               configuration={
            //                 viewportTypes[currentViewport] < currentViewport.mediaIsDesktop
            //                   ? { fontSize: '10', lineHeight: 1.07 }
            //                   : null
            //               }
            //             >
            //               Disabilita <br />
            //               rinnovo
            //             </ProDisplay>
            //           </Delete>
            //         )}
            //       </Text>
            //     )}
            //     {!onEdit && is_active && (
            //       <Icon
            //         className="icon-modifica"
            //         onClick={() => handleEditSubscription({ email, ...off })}
            //       />
            //     )}
            //   </Field>
            // </AccordionRow>
            <AccordionRow key={off.variationId} className={is_deleted ? 'deleted' : ''}>
              <Row className='mx-1 mb-1 align-items-center'>
                <Col xs={9} sm={9} className='p-0'>
                  {!(off.prospectId && changeEmail[off.prospectId]) ? (
                    <ProDisplay
                      configuration={{
                        fontSize: 15,
                        lineHeight: 1.2
                      }}
                    >
                      {variationTitle}
                    </ProDisplay>
                  ) : (
                    <CustomInput
                      type="text"
                      autocomplete="new-password"
                      placeholder="Email"
                      value={newEmail}
                      onChange={val => handleOnChangeEmail(val)}
                      onBlur={() => handleOnBlur(off)}
                      error={emailError}
                      noMargin={true}
                    />
                  )}
                </Col>
                <Col xs={2} sm={2} className='p-0'>
                  <WrapperIcon>
                    {!checkActives(off) ? (
                      !(off.prospectId && changeEmail[off.prospectId]) ? (
                        <IconEdit
                          className="icon-modifica"
                          onClick={() => {
                            setChangeEmail(prev => {
                              prev[off.prospectId] = true;
                              return { ...prev };
                            })
                          }}
                        />
                      ) : (
                        <IconStatus
                          className="icon-check"
                          onClick={() => {
                            handleUpdateEmailProspect(off.prospectId)
                            setChangeEmail(prev => {
                              prev[off.prospectId] = false;
                              return { ...prev };
                            })
                          }}
                        />
                      )
                    ) : null} 
                    { viewportTypes[currentViewport] > viewportTypes.mediaIsPhone ? (
                      <StatusLabel className={off.status.toLowerCase()}>
                        <ProDisplay type="label">
                          {getStatusLabelByStatus(off.status.toLowerCase())}
                        </ProDisplay>
                        </StatusLabel>
                    ) : (
                      !(off.prospectId && changeEmail[off.prospectId]) ? (
                        <IconStatus
                          className={off.status.toLowerCase() === 'active'
                            ? 'icon-icona-check-rounded'
                            : 'icon-icona-rimuovi-copy-1'
                          }
                          status={off.status.toLowerCase()}
                        />
                      ) : (
                        <IconStatus
                          className="icon-close-x"
                          onClick={() => {
                            setChangeEmail(prev => {
                              prev[off.prospectId] = false;
                              return { ...prev };
                            })
                          }}
                        />
                      )
                    )}
                  </WrapperIcon>
                </Col>
              </Row>
              {
                /* mostrare campo ecm credits solo se ruolo è diverso da partecipante (e se user è provisioner) */ !off.bundleCode && off.ecmFlag && isProvisioner && <Row className={'mt-2'}>
                <RowCreditiEcmEdit prospectId={off.prospectId}
                                   ecmCreditsNotP={off.ecmCreditsNotP}
                                   ecmMaxCrediti={ecmMaxCreditiArray?.find(product => product.productId === off.productId)?.productEcmCredits}
                                   participationRole={off.participationRole}
                                   handleUpdateCreditiEcmProspect={handleUpdateCreditiEcmProspect}
                                   ecmRoleOptionsState={ecmRoleOptionsState}
                                   handleSelectRole={handleSelectRole}/>
              </Row>
              }
            </AccordionRow>
          );
        })
      }
    </AccordionWrapper>
  )

  return (
    <TableCardMobileContainer
      className='mb-4 mb-md-0'
    >
      <Row className='mx-n2 pt-3'>
        <ProDisplay
          className={'mb-3'}
        >
          {email}
        </ProDisplay>
      </Row>
      <ProDisplay
        className={'mb-3 text-brand'}
      >
        Pacchetti
      </ProDisplay>
      {getAccordion({
        accordionOpen: basicAccordionOpen,
        handleAccordion: () => handleAccordion('base'),
        numberOfPackages: countBasicPkgs,
        offerts: basicOfferts,
        title: 'Base',
      })}
      {getAccordion({
        accordionOpen: premiumAccordionOpen,
        handleAccordion: () => handleAccordion('premium'),
        numberOfPackages: countPremiumPkgs,
        offerts: premiumOfferts,
        title: 'Premium',
      }
      )}
    </TableCardMobileContainer>
  );
};

export default withMediaQueries(TableCardMobile);
