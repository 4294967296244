import { ToggleSwitchContainer } from './style';
import React from 'react';

const ToggleSwitch = ({active, toggleActive}) => {
    return <ToggleSwitchContainer>
        <div className={`slider ${active ? 'left' : 'right'}`}></div>
        <span onClick={()=>toggleActive()} className={active ? 'active' : ''}>ACADEMY PLUS</span>
        <span onClick={()=>toggleActive()} className={!active ? 'active' : ''}>CORSI ECM</span>
    </ToggleSwitchContainer>
}

export default ToggleSwitch;