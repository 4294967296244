import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'ui/atoms/index';
import {useLocation} from 'react-router-dom';

import ProText from 'ui/typography/proText';
import { CustomInputNumber2, Tooltip } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
// import { useAnalytics } from 'providers/analyticsProvider';
import { priceFormat, calcPeriod } from 'utils/price-format';
// import { mapCartItem } from 'utils/analytics';
import { ProductVariation } from 'interface/signup';
import { connect } from 'react-redux';
import { MODAL_OPEN, MODAL_CLOSE } from 'redux/actions';
import { SET_CURRENT_AGGREGATED_PRODUCTS } from 'redux/actions/signup';
import {
  DescriptionContainer,
  QuantityContainer,
  ProductContainer,
  Title,
  Description,
  QuantityLabel,
  PriceContainer,
  Span,
  IVA,
  Question,
  Period,
  PriceRow,
  VatRow,
  IconInfo,
  TextSection,
  DetailButton
} from './style';
import AggregatedProductsModal from '../Modals/AggregatedProductsModal';
import signup from 'api/signup';
import {Link} from "../../../pages/EventDetails/style";

const currencyCodes = {
  EUR: '€',
};

const Product = ({ product, isChild, onChange, disabled, cart, isCatalog, isAggregatedState, aggregatedSubtitle, aggregatedTitle, products, openModal, setCurrentAggregatedProducts, user }) => {
  // const { ga4IsInitialized, sendGA4Event } = useAnalytics();
  const [totalPriceAggregated, setTotalPriceAggregated] = useState();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [physicalPerson] = useState(searchParams.get('userType') === 'persona_fisica' || !!user.userDomainAttributes?.personaFisica);

  useEffect(() => {
    if (disabled) {
      if (onChange) onChange(0, product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useEffect(() => {
    if(Boolean(products)) {
      let totalPrice = 0;
      products.map(p => totalPrice += Number(p.price_number?.replace(",","."))* 100);
      setTotalPriceAggregated(Number(totalPrice)/100);
    }
  }, [products]);

  const handleChange = num => {
    if(isAggregatedState) {
      products.forEach(product => {
        if (onChange) onChange(num, product);
      });
    } else {
      if (onChange) onChange(num, product);
    }
  };

  const handleOpenModal = (modalId, products) => {
    openModal(modalId);
    setCurrentAggregatedProducts(products);
  }

  // const trackingCallback = (action) => {
  //   if (!product || !ga4IsInitialized) return;

  //   const items = Object.values(cart.products).map((item, index) => mapCartItem(item, index));
  //   /*
  //     this operation is necessary because the cart hasn't yet been updated at this point,
  //     careful moving this part of the code to other components
  //   */
  //   const existingItem = items.find(item => item.item_id === product?.sku);
  //   if (existingItem) existingItem.quantity += 1;
  //   else items.push(mapCartItem(product));

  //   sendGA4Event({
  //     eventCategory: 'signup',
  //     eventAction: action,
  //     currency: product?.price_code,
  //     value: cart.total_price
  //       ? parseFloat(cart.total_price) + parseFloat(product?.price_number) /* same as above, necessary when cart hasn't yet been updated*/
  //       : parseFloat(product?.price_number),
  //     items,
  //   });
  // };

  const downloadPdfHandler = (pdf)=>{
    window.open(pdf, '_blank');
  }

  return (
    <ProductContainer isChild={isChild}>
      {!isAggregatedState && <DescriptionContainer isCatalog={isCatalog}>
        {Boolean(product?.question) && (
          <ProText>
            <Question>{product?.question}</Question>
          </ProText>
        )}

        <ProText type="title">
          <TextSection>
            <Title>{product?.title}</Title>
            {Boolean(product?.information) && (
              <Tooltip text={product?.information}>
                <IconInfo className="icon-icona-info-active" />
              </Tooltip>
            )}
          </TextSection>
        </ProText>

        <Description>
          {Boolean(product?.body) && (
            <ProText type="label" configuration={{ fontWeight: 300 }}>
              <div
                dangerouslySetInnerHTML={{ __html: product?.body }}
                style={{ color: '#5c5c5c' }}
              />
            </ProText>
          )}
        </Description>
        {(!isAggregatedState && !!product?.pdf_content) && <DetailButton>
          <Button type={Button.TYPE.PRIMARY} onClick={() => { downloadPdfHandler(product?.pdf_content) } }>
              Descrizione corso
          </Button>
        </DetailButton>}
      </DescriptionContainer>}

      {/*--- AGGREGATI ---*/}
      {isAggregatedState && <DescriptionContainer isCatalog={isCatalog}>
      <ProText type="title">
          <TextSection>
            <Title>{aggregatedTitle}</Title>
          </TextSection>
          <Description>
          <ProText type="label" configuration={{ fontWeight: 300 }}>
            <div
              dangerouslySetInnerHTML={{ __html: aggregatedSubtitle }}
              style={{ color: '#5c5c5c' }}
            />
          </ProText>
        </Description>
        </ProText>
        {!!isAggregatedState && <DetailButton>
          <Button
            text={'Descrizione corso'}
            type={Button.TYPE.PRIMARY}
            onClick={() => {handleOpenModal(AggregatedProductsModal.id, products)}}
          />
        </DetailButton>}
      </DescriptionContainer>}

      {!isAggregatedState && <PriceContainer>
        <PriceRow>
          <ProDisplay type="productPrice">
            {Math.trunc(priceFormat(product?.price_number))}{' '}
          </ProDisplay>
          <Span>
            <ProDisplay type="productPriceCurrency">
              {currencyCodes[product?.price_code]
                ? currencyCodes[product?.price_code]
                : product?.price_code}
            </ProDisplay>
          </Span>
          {Boolean(product?.cards) && Boolean(calcPeriod(product?.cards)) && (
            <ProDisplay>
              <Period>{` / ${calcPeriod(product?.cards)}`}</Period>
            </ProDisplay>
          )}
        </PriceRow>
        <VatRow>
          <IVA>
            <ProDisplay type="IVA">(IVA esclusa)</ProDisplay>
          </IVA>
        </VatRow>
      </PriceContainer>}

      {!!isAggregatedState && <PriceContainer>
        <PriceRow>
          <ProDisplay type="productPrice">
            {Math.trunc(priceFormat(product?.price_number || totalPriceAggregated))}{' '}
          </ProDisplay>
          <Span>
            <ProDisplay type="productPriceCurrency">
            {currencyCodes[products[0]?.price_code]
                ? currencyCodes[products[0]?.price_code]
                : products[0]?.price_code}
            </ProDisplay>
          </Span>
            <ProDisplay>
              <Period>{products[0]?.cards && !!calcPeriod(products[0]?.cards) ? `/ ${calcPeriod(products[0]?.cards)}` : ' / anno'}</Period>
            </ProDisplay>
        </PriceRow>
        <VatRow>
          <IVA>
            <ProDisplay type="IVA">(IVA esclusa)</ProDisplay>
          </IVA>
        </VatRow>
      </PriceContainer>}

      {!isCatalog && (
        <QuantityContainer>
          <ProDisplay>
            <QuantityLabel>
              Seleziona il numero di utenze da attivare
            </QuantityLabel>
          </ProDisplay>

          <CustomInputNumber2
            max={physicalPerson ? 1 : undefined}
            value={
              cart?.products[
                Object.keys(cart?.products).find(k => k === product?.sku)
              ]?.num || undefined
            }
            disabled={disabled}
            onChange={handleChange}
            // trackingCallback={trackingCallback}
          />
        </QuantityContainer>
      )}
      <AggregatedProductsModal products={products}/>
      </ProductContainer>
  );
};

Product.propTypes = {
  product: ProductVariation,
  isChild: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  cart: PropTypes.object,
  isCatalog: PropTypes.bool,
  aggregatedSubtitle: PropTypes.string,
  aggregatedTitle: PropTypes.string,
  products: PropTypes.array,
  setCurrentAggregatedProducts: PropTypes.func,
  user: PropTypes.object
};


export default connect(
  ({ signup: { cart }, authentication: { user } }) => ({ cart, user }),
    dispatch => ({
      openModal: id =>
        dispatch({ type: MODAL_OPEN, id }),

      setCurrentAggregatedProducts: products =>
        dispatch({ type: SET_CURRENT_AGGREGATED_PRODUCTS._SUCCESS, products })
    }),
)(Product)

