import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ProductVariation } from 'interface/signup';
import { ProductSubscriptionContainer } from './style';
import Product from './Product';

const ProductSubscription = ({
  product,
  onChangeQuantity,
  cart,
  isCatalog,
  isAggregated,
  aggregatedTitle,
  aggregatedSubtitle,
  products
}) => {
  const [parentVal, setParentVal] = useState(
    cart?.products[Object.keys(cart?.products).find(k => k === product?.sku)]
      ?.num || 0
  );

  const [isAggregatedState, setIsAggregated] = useState();

  useEffect(() => {
    if(isAggregated) {
      setIsAggregated(isAggregated)
    }
  }, [!!isAggregated]);

  const onChange = (num, selProd) => {
    setParentVal(num);
    if (onChangeQuantity) onChangeQuantity(num, selProd);
  };

  const onChangeChild = (num, selProd) => {
    if (onChangeQuantity) onChangeQuantity(num, selProd);
  };

  return (
    <ProductSubscriptionContainer>
      {!isAggregatedState && Boolean(product) && <Product product={product} onChange={onChange} isCatalog={isCatalog} />}
      {!isAggregatedState && product?.child_products?.length > 0 &&
        product?.child_products.map(child_product => (
          <Product
            isChild
            key={child_product?.product_id + child_product?.variation_id}
            product={child_product}
            onChange={onChangeChild}
            disabled={!parentVal}
            isCatalog={isCatalog}
            cart={cart}
            isAggregatedState={isAggregatedState}
          />
        ))}

        {!!isAggregated && <Product
            isAggregatedState={isAggregatedState}
            aggregatedSubtitle = { aggregatedSubtitle }
            aggregatedTitle = { aggregatedTitle }
            products = { products}
            onChange={onChange}
            isCatalog={isCatalog}
            cart={cart}
        />}
    </ProductSubscriptionContainer>
  );
};

ProductSubscription.propTypes = {
  product: ProductVariation,
  products: PropTypes.array,
  onChangeQuantity: PropTypes.func.isRequired,
  cart: PropTypes.object,
  isCatalog: PropTypes.bool,
  isAggregated: PropTypes.bool,
  aggregatedSubtitle: PropTypes.string,
  aggregatedTitle: PropTypes.string
};

export default connect(({ signup: { cart } }) => ({ cart }))(
  ProductSubscription
);
