import colors from 'ui/theme/partials/colors';
import { mixin } from 'ui/theme/partials/mixin';

const shadowColor = mixin.rgba(colors().azureDark, 0.2);

export const styles = {
  container: styles => {
    // console.log('container', styles);
    return {
      ...styles,
      minHeight: 55
      //   outline: '3px solid red'
    };
  },
  control: (styles, state) => {
    // console.log('control', state);
    return {
      ...styles,
      minHeight: 55,
      boxShadow: state.isFocused ? `0 0 0 0.17647rem ${shadowColor}` : 0,
      '&:hover': {
        boxShadow: state.isFocused ? `0 0 0 0.17647rem ${shadowColor}` : 0
      },
      border: state.isFocused ? '1px solid black' : ''
      //   outline: '3px solid red'
    };
  },
  placeholder: (styles, state) => {
    return {
      ...styles,
      transition: 'all 125ms ease-in',
      top: state.isFocused || state.hasValue ? '5px' : '40%',
      transform: state.isFocused ? 'translate(0, -50%)' : null,
      fontSize: state.isFocused || state.hasValue ? '12px' : '14px'
    };
  },
  dropdownIndicator: (styles, state) => {
    return {
      ...styles,
      transform: state.isFocused ? 'rotate(180deg)' : ''
    };
  },
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none'
  }),
  menu: (styles, state) => {
    // console.log('menu', state);
    return {
      ...styles,
      '& div:nth-of-type(n+2)': {
        borderTop: `1px solid ${colors().platinum}`
      }
    };
  },
  option: (styles, state) => {
    // console.log('option', state);
    return {
      ...styles,
      backgroundColor: 'white',
      ':active': { backgroundColor: 'white' },
      minHeight: 55,
      display: 'flex',
      fontSize: '14px',
      fontWeight: 600,
      alignItems: 'center'
    };
  },
  valueContainer: (styles, state) => {
    // console.log('valueContainer', state);
    return {
      ...styles,
      marginLeft: '20px',
      flexWrap: 'no-wrap',
      minHeight: '55px'
    };
  },
  multiValue: (styles, { data }) => {
    // console.log('multivalue', styles);
    return {
      ...styles,
      backgroundColor: 'white',
      margin: 0,
      minWidth: '40px',
      '& div': { padding: 0 }
    };
  },
  multiValueLabel: (styles, state) => {
    // console.log('multivalueLabel', styles);
    return {
      ...styles,
      color: colors().darkCharcoal,
      fontStyle: 'normal',
      fontSize: '16px',
      fontWeight: 500,
      paddingTop: '18px !important',
      ':before': {
        content: state.index > 0 ? '","' : '""',
        paddingRight: '4px'
      },
      textOverflow: 'ellipsis'
    };
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    display: 'none'
  })
};
