import produce from 'immer';
import {
    GET_ECM_CREDITI_ACQUISITI, GET_ECM_MAX_CREDITI_ACQUISITIBILI
} from 'redux/actions/ecm';

const initialState = {
    ecmCreditiAcquisiti: null
};

const ecmReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case GET_ECM_CREDITI_ACQUISITI._SUCCESS: {
                draft.ecmCreditiAcquisiti = action.data;
                break;
            }
            case GET_ECM_MAX_CREDITI_ACQUISITIBILI._SUCCESS: {
                draft.ecmMaxCrediti = action.data;
                break;
            }
            default:
                return state;
        }
        return draft;
    });

export default ecmReducer;
