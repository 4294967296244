import styled from 'styled-components/macro';
import { FooterHeight } from 'const';

export const FooterContainer = styled.div`
  border-top: solid 1px ${props => props.theme.colors.lightSilver};
  width: 100%;
  height: ${FooterHeight}px;
  background-color: ${props => props.theme.colors.darkCharcoalHeader};
  display: flex;
  align-items: center;

  @media (min-width: 1440px) {
    & .container {
      max-width: 1440px;
    }
  }

  @media ${props => props.theme.device.mobile} {
   height: fit-content;
  }
`;

export const Icon = styled.span`
  font-size: large;
  opacity: 0.65;
`;

export const Image = styled.img`
  width: 170px;
`;

export const Text = styled.div`
  padding: 0 15px;

  @media ${props => props.theme.device.mobile} {
    margin-bottom: 10px;
  }
`;

export const FooterWrapper = styled.div``;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 22px 0;

  & > div:first-child {
    margin-right: auto;
  }

  @media ${props => props.theme.device.mobile} {
    flex-direction: column;

    & > div:first-child {
      margin: 0 auto;
      margin-bottom: 21px;
    }
  }
`;

export const CopyrightWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.white};
  padding: 13px 0;

  @media ${props => props.theme.device.mobile} {
    flex-wrap: wrap;
    width: 100%;
    flex-direction: column-reverse;
    border: none;
  }
`;

export const LegalLinks = styled.div`
  display: flex;
`;
export const Copyright = styled.div`
  padding-right: 3px;
  color: ${props => props.theme.colors.white};
  text-align: right;
  margin-left: auto;

  @media ${props => props.theme.device.mobile} {
    padding-right: unset;
    margin: 0 auto;
  }
`;

export const Link = styled.a`
  padding-right: 20px;
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  height: fit-content;

  &:hover,
  :active {
    text-decoration: underline;
  }

  &.spacer {
    border-left: 1px solid ${props => props.theme.colors.white};
    padding-left: 20px;

    @media ${props => props.theme.device.mobile} {
      border: none;
    }
  }
`;

export const LogoContainer = styled.div`
  color: ${props => props.theme.colors.white};
  font-size: 26px;
`;
