import {
    takeLatest, put, call,
    // select
} from 'redux-saga/effects';

import {LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN} from 'redux/actions';
import {
    GET_ECM_CREDITI_ACQUISITI,
    GET_ECM_MAX_CREDITI_ACQUISITIBILI,
    GET_SPECIALIZATIONS_PACKAGE_ECM_CREDITS
} from 'redux/actions/ecm';
import ModalErrors from "../../ui/components/Modals/ModalErrors";
import ecm from "api/ecm";
import SpecializationCreditsEcmModal from "../../ui/components/Modals/SpecializationCreditsEcmModal";

function* getEcmCreditiAcquisiti({ userEventCode }) {
    try {
        yield put({ type: LOADER_OPEN });
        const response = yield call(ecm.getEcmCreditiAcquisiti, userEventCode);
        let data = response.data;
        if (data.responseresult && (data.responseresult.statusCode === 200)) {
            yield put({
                type: GET_ECM_CREDITI_ACQUISITI._SUCCESS,
                data: data.credits,
            });
        }
    } catch (err) {
        yield put({
            type: MODAL_OPEN,
            id: ModalErrors.id,
            payload: {
                errorText: 'Errore di caricamento',
            },
        });
        // eslint-disable-next-line no-console
        console.log('sagas > ecm > getEcmCreditiAcquisiti', err);
    } finally {
        yield put({ type: LOADER_CLOSE });
    }
}

function* getEcmCreditiAcquisitiWatch() {
    yield takeLatest(GET_ECM_CREDITI_ACQUISITI._REQUEST, getEcmCreditiAcquisiti);
}

// fornisce una lista di specializzazioni che forniscono crediti ecm per pacchetto
function* getSpecializationsByPackageEcmCreditsWatch() {
    yield takeLatest(GET_SPECIALIZATIONS_PACKAGE_ECM_CREDITS._REQUEST, getSpecializationsByPackageEcmCredits);
}
function* getSpecializationsByPackageEcmCredits({payload}){

    const {ecmProducts, nextStepValue} = payload;
    const productIds = ecmProducts.map(product => product.product_id);

    try {
        yield put({ type: LOADER_OPEN });
        const response = yield call(ecm.getSpecializationsByPackageEcmCredits, { productIds });
        let data = response.data;
        if (response?.status === 200) {
            yield put({
                type: GET_SPECIALIZATIONS_PACKAGE_ECM_CREDITS._SUCCESS,
                data: data.data,
            });
        }
        yield put({ type: MODAL_OPEN, id: SpecializationCreditsEcmModal.id, payload: { title: 'Corsi ECM', descriptionData: data.data, nextStepValue, ecmProducts }});
    } catch (err) {
        yield put({
            type: MODAL_OPEN,
            id: ModalErrors.id,
            payload: {
                errorText: 'Errore di caricamento',
            },
        });
        // eslint-disable-next-line no-console
        console.log('sagas > ecm > getSpecializationsByPackageEcmCredits', err);
    } finally {
        yield put({ type: LOADER_CLOSE });
    }
}

function* getEcmMaxCrediti({productIds}) {
    try {
        yield put({ type: LOADER_OPEN });
        const response = yield call(ecm.getEcmMaxCrediti, productIds);
        let data = response.data;
        if (response?.status === 200) {
            yield put({
                type: GET_ECM_MAX_CREDITI_ACQUISITIBILI._SUCCESS,
                data: data.productcredits,
            });
        }
    } catch (err) {
        yield put({
            type: MODAL_OPEN,
            id: ModalErrors.id,
            payload: {
                errorText: 'Errore di caricamento',
            },
        });
        // eslint-disable-next-line no-console
        console.log('sagas > ecm > getEcmMaxCrediti', err);
    } finally {
        yield put({ type: LOADER_CLOSE });
    }
}

function* getEcmMaxCreditiWatch() {
    yield takeLatest(GET_ECM_MAX_CREDITI_ACQUISITIBILI._REQUEST, getEcmMaxCrediti);
}

export default [
    getEcmCreditiAcquisitiWatch(),
    getSpecializationsByPackageEcmCreditsWatch(),
    getEcmMaxCreditiWatch()
];
