import moment from 'moment';

const dateFormat = (date, type) => {
  switch (type) {
    case 'short':
      return moment(date * 1000).format('DD/MM/YYYY');
    default:
      return moment(date * 1000).format('DD/MM/YYYY - HH:mm:ss');
  }
};

const getTimeByLastUpdate = dateStr => {
  const date = new Date(dateStr);
  const now = new Date();
  const hours = ((now.getTime() - date.getTime()) / 1000 / 3600).toFixed(0);
  if (hours < 1) return `Poco fa`;
  if (hours < 24) return `${hours} ore fa`;
  if (hours < 48) return '1 giorno fa';
  if (hours < 168) return `${Math.floor(hours / 24)} giorni fa`;
  if (hours < 336) return '1 settimana fa';
  if (hours < 672) return `${Math.floor(hours / 24 / 7)} settimane fa`;
  if (hours < 1344) return '1 mese fa';
  if (hours < 8064) return `${Math.floor(hours / 24 / 7)} mesi fa`;
  if (hours >= 8064) return 'più di un anno fa';

  return '-';
};

const getDates = ({ startDate, endDate }) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const startDateMonth = `${start.getDate()} ${start.toLocaleString('it-IT', { month: 'long' })}`;
  const endDateMonth = `${end.getDate()} ${end.toLocaleString('it-IT', { month: 'long' })}`;
  const startHours = `ore ${start.toLocaleString('it-IT', { hour: 'numeric', minute: 'numeric' })}`;
  const endHours = `${end.toLocaleString('it-IT', { hour: 'numeric', minute: 'numeric' })}`;

  return startDateMonth === endDateMonth
   ? `${startDateMonth} - ${startHours} - ${endHours}`
   : `${startDateMonth} - ${endDateMonth}`;
};

const getDatesDay = ({ startDate, endDate }) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const startDateMonth = `${start.getDate()} ${start.toLocaleString('it-IT', { month: 'long' })}`;
  const endDateMonth = `${end.getDate()} ${end.toLocaleString('it-IT', { month: 'long' })}`;

  return startDateMonth === endDateMonth
   ? `${startDateMonth}`
   : `${startDateMonth}`;
};

const getDatesHour = ({ startDate, endDate }) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const startDateMonth = `${start.getDate()} ${start.toLocaleString('it-IT', { month: 'long' })}`;
  const endDateMonth = `${end.getDate()} ${end.toLocaleString('it-IT', { month: 'long' })}`;
  const startHours = `ore ${start.toLocaleString('it-IT', { hour: 'numeric', minute: 'numeric' })}`;
  const endHours = `${end.toLocaleString('it-IT', { hour: 'numeric', minute: 'numeric' })}`;

  return startDateMonth === endDateMonth
   ? `${startHours} - ${endHours}`
   : `${startDateMonth} - ${endDateMonth}`;
};

/**
 * Converts a Date object in a string of format YYYY-MM-DD,
 * accounting for time zone.
 * 
 * @param {Date} date is a date object that will be stringified
 * @returns {string} a string containing a date in the format YYYY-MM-DD
 */
const getIsoDateStringFromDate = (date) => {
  /* get timezone differential in milliseconds */
  const timeZoneOffset = date.getTimezoneOffset() * 60 * 1000;
  /* doubles the original time offset (intended to trick Date.toISOString) */
  const adjustedDate = new Date(date.getTime() - timeZoneOffset);
  /* gets the string from ISO conversion, which will output the date with a single time offset */
  return adjustedDate.toISOString().split('T')[0];
}

/**
 * Converts a Date object in a localized string.
 * 
 * @param {Date} date is a date object that will be stringified
 * @returns {string} a string containing a localized date
 */
const getLocaleStringFromDateTime = (dateTime) => {
  const date = dateTime.toLocaleDateString(
    'it-IT',
    {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }
  );
  const time = dateTime.toLocaleTimeString(
    'it-IT',
    {
      hour: 'numeric',
      minute: 'numeric'
    }
  );
  return `${date} / ${time}`;
}

/**
 * Returns the first and last day of a month given a date input.
 * 
 * @param {Date} date 
 * @returns {object} an object of shape { firstDay: Date, lastDay: Date }
 */
const getFirstLastDayOfMonth = (date) => {
  const d = date ?? new Date();
  const firstDay = new Date(d.getFullYear(), d.getMonth(), 1);
  const lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0);
  return { firstDay, lastDay};
}

export {
  dateFormat,
  getFirstLastDayOfMonth,
  getIsoDateStringFromDate,
  getLocaleStringFromDateTime,
  getDates,
  getTimeByLastUpdate,
  getDatesHour,
  getDatesDay
};
