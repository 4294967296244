import styled, {css} from 'styled-components/macro';
import {PackageHeader} from 'pages/PackageDetails/style';
import {VideoRow} from '../CollectionDetails/style';

export const EventHeader = styled(PackageHeader)`
.labelEvent{
    border-radius: 10px;
    display: inline-block;
    padding: 0 22px;
    color: ${props => props.theme.colors.white};
    span{
        line-height: 27px;
        font-weight: 500;
    }
    &.Virtual{
        background-color: ${props => props.theme.colors.virtualBlue};
    }
    &.Live{
        background-color:${props => props.theme.colors.primary.base};
    }
}
.react-add-to-calendar{
  min-width: 160px;
  height: 35px;
  color:white;
  text-align: center;
  text-transform: uppercase;
  display:inline-flex;
  align-items: center;
  justify-content: center;
  margin: 35px 10px 35px 0;
  position: relative;
  span, a{
    font-family: "ProDisplay",sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: unset;
    line-height: 1;
    text-align: unset;
    text-transform: unset;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 0;
    -webkit-box-orient: vertical;
    white-space: normal;
    color: ${props => props.theme.colors.primary.brandColor1};
  }
  .react-add-to-calendar__dropdown{
    position:absolute;
    top: 100%;
    background: white;
    left: 0;
    right: 0;
    box-shadow: 0 8px 20px 0 rgb(0 0 0 / 8%);
    border-radius: 16px;
    a{
      padding: 5px;
      margin: 3px;
    }
    li{
      border-bottom: 1px solid ${props => props.theme.colors.platinum};
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
`;


export const Title = styled.div`
  min-height: 70px;
  //border-left: 1px solid ${props => props.theme.colors.lightSilver};
  padding: 10px 32px 0 0px;
  color: ${props => props.theme.colors.white};

  @media ${props => props.theme.device.mobile} {
    min-width: calc(100% - 70px);
    padding: 10px 0px;
  }

  span {
    color: ${props => props.theme.colors.white};
  }
`;

export const Subtitle = styled.div`
  padding: 10px 32px 0 0px;
  min-height: 70px;
  //border-left: 1px solid #D6D6D6;

  @media ${props => props.theme.device.tablet} {
    padding: 10px 0px;
  }
`;

export const LockedIcon = styled.div`
  padding: 10px 32px 0 0px;

  @media ${props => props.theme.device.tablet} {
    padding: 10px 0px;
  }

  .locked-icon {
    &:before {
      font-size: 30px;
    }
  }
`;

export const EventTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;

`;

const linkStyles = css`
  &.event-btn {
    min-width: 160px;
    height: 35px;
    color:white;
    text-align: center;
    text-transform: uppercase;
    display:inline-flex;
    align-items: center;
    justify-content: center;
    margin: 35px 10px 35px 0;

    &.map-btn {
      background-color: ${prop => prop.theme.colors.primary.brandColor2};
    }

    &.ask-btn {
      background-color: ${prop => prop.theme.colors.transparent};
      color: ${prop => prop.theme.colors.primary.base};
    }
  }
`;

export const Link = styled.a`
  ${linkStyles}

  cursor: pointer;

  &.no-pointer-events {
    pointer-events: none;
  }
`;

export const FakeLink = styled.div`
  ${linkStyles}

  position: relative;
  cursor: not-allowed;

  &:before {
    content: "";
    z-index: 9;
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
`;

export const EventText = styled.div`
  padding: 0 16px 0 0;

  &.align-right {
    margin-left: auto;
    cursor: pointer;
  }
`;

export const Section = styled.div`
  padding-top: 25px;

  &.gray-bg{
    position: relative;
    padding:60px 0;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(-50vw);
      right: calc(-50vw);
      background: ${props => props.theme.colors.cultured};;
    }
    * {
      z-index: 1;
      position:relative
    }

    .kDYzJS {
        padding-left: 0;
        border:none;
        min-height:unset:
      }

    @media ${props => props.theme.device.tablet} {
      .kDYzJS {


      }
    }

  }

  .link-doc-row {
    padding: 0 66px;
    position: relative;
    justify-content:space-between;

    &:after{
      content: '';
      display:block;
      width: 1px;
      background:  ${props => props.theme.colors.lightSilver};
      position: absolute;
      right:50%;
      left:50%;
      top: 0;
      bottom: 0;

      @media (max-width: 767px) {
        bottom: 0;
        left: 30%;
        right: 30%;
        top: auto;
        width: auto;
        height: 1px;
      }
      @media ${props => props.theme.device.mobile} {
        bottom: 0;
        left: 30%;
        right: 30%;
        top: auto;
        width: auto;
        height: 1px;
      }
    }



    .link-doc-row__links,
    .link-doc-row__docs{
      display:flex;
      flex-direction: column;
      align-items:center;

      @media ${props => props.theme.device.laptop} {

      }

      @media ${props => props.theme.device.tablet} {
        padding-bottom: 30px;
        margin-bottom: 30px;
      }
      @media ${props => props.theme.device.mobile} {
        margin-bottom: 30px;
      }
    }
    .item{
      width:100%;
      display:flex;
      align-items:center;


      .value  {
         width: calc(100% - 51px);

         a {
          max-width:100%;
         }

         .lgLYGP {
            display: block !important;
            white-space: nowrap;
         }
      }

      &:last-child{
        margin-bottom: 0;
      }
      .icon{
        margin-right: 10px;
      }
      .icon-icona-allega{
        font-size: 25px;
      }

      a{
        color: ${props => props.theme.colors.links};
        text-decoration: underline;
        line-height: 24px;
      }
      .icon-download-link{
        text-decoration: none;
      }
      .icon-download{
        color: ${props => props.theme.colors.black};
      }
    }
    .link-doc-row__docs {
     padding:0 24px 0 0;
     max-width:40%;

      .item {
        justify-content:space-between;
        margin-bottom: 22px;
        line-height: 27px;
        .value{
          margin-right: auto;
          a{
            color: ${props => props.theme.colors.darkCharcoal};
            text-decoration: none;
          }
        }
      }
    }

    @media ${props => props.theme.device.tablet} {
       flex-wrap:wrap;
       padding: 0 37px;

       &:after {
           content:none;
       }

       .link-doc-row__docs {
          margin-bottom:33px;
          padding:0;
           &.col-sm-6 {
              width:100%;
              max-width:100%;
           }
            .item {
               margin-bottom:0;
            }

       }
    }

    @media ${props => props.theme.device.mobile} {
       padding: 0 51px;

       .link-doc-row__docs {
          margin-bottom:28px;

       }
    }
  }
`;

export const TeachersList = styled.ul`

  display: flex;
  flex-direction: column;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.08);

  border-radius: 16px;
  li{
    display:flex;
    border-bottom: 1px solid ${props => props.theme.colors.platinum};
    &:first-child{
      padding-top: 10px;
    }
    &:last-child{
      border-bottom: none;
      padding-bottom: 10px;
    }
    .avatar{
      margin: 13px 25px;
      min-width: 44px;
      height: 44px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      background-color: ${props => props.theme.colors.white};
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;

      &.no-cover {
        background-color: ${props => props.theme.colors.primary.brandColor2};
      }

      img{
        position: absolute;
        top: 50%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 100%;
      }
    }
    .teach-info{
      margin-left: 5px;
      display:flex;
      flex-direction: column;
      justify-content: center;
      color: ${props => props.theme.colors.davysGray};

      .description {
        cursor: default;
      }
    }
  }

`;

export const EventCoreRow = styled.div`
  > .row{
    display:block;
    overflow: hidden;

    @media (max-width: 767px) {
      display: flex;
      overflow: none;
    }

  }
  .description-col{
    float: left;

     .kDYzJS {
      min-height:unset;
      padding-left: 0;
      border-left: 0;
    }

    @media ${props => props.theme.device.tablet} {
      width: 100%;
      margin-bottom:22px;
    }
    @media ${props => props.theme.device.mobile} {
      width: 100%;
    }
  }
  .feedback-col{
    float: left;


    .ask-btn {
      min-width: 150px;
      span {
       font-size:12px;
      }

    }

    @media ${props => props.theme.device.tablet} {
      width: 58.333333%;

    }
    @media ${props => props.theme.device.mobile} {
      max-width: 100%;

    }
  }
  .teachers-col{
    float: right;

    @media ${props => props.theme.device.tablet} {
      width: 41.666667%;

    }
    @media ${props => props.theme.device.mobile} {
      max-width: 100%;
    }
  }
  .feedback-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${props => props.theme.colors.platinum};
    &:first-child{
      border-top: 1px solid ${props => props.theme.colors.platinum};
    }
    .event-btn{
      margin: 16px 0;
    }
  }

`;

export const VideoRowAsk = styled(VideoRow)`
   padding-left: 35px;



 @media ${props => props.theme.device.tablet} {
    flex-wrap:wrap;
    row-gap:16px;
    padding:27px 16px 27px 32px;

      .lkacHW {
         width:100%
      }

      .ask-btn {
         margin: 0;
         height:auto;
      }

      button {
        margin-left:auto;
      }
 }

 @media ${props => props.theme.device.mobile} {
   .ask-btn, button {
     margin: 0;
   }
 }
`;