import { all } from 'redux-saga/effects';
import authentication from './authentication';
import subscriptions from './subscriptions';
import profile from './profile';
import teams from './teams';
import signup from './signup';
import billings from './billings';
import configuration from './configuration';
import landingPage from './landingPage';
import premiumPurchase from './premiumPurchase';
import playlist from './playlist';
import bookmark from './social';
import notes from './notes';
import fiveMinutes from './fiveMinutes';
import onBoarding from './onBoarding';
import domain from './domain';
import tracking from './tracking';
import workouts from './workouts';
import ecm from './ecm';

export default function* rootSaga() {
  yield all([
    ...subscriptions,
    ...authentication,
    ...profile,
    ...teams,
    ...billings,
    ...signup,
    ...configuration,
    ...landingPage,
    ...premiumPurchase,
    ...playlist,
    ...bookmark,
    ...notes,
    ...fiveMinutes,
    ...onBoarding,
    ...domain,
    ...tracking,
    ...workouts,
    ...ecm
  ]);
}
