import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  font-family: 'ProDisplay', sans-serif;
`;

export const ModalGlobalContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.black};
  border-radius: 6px;
  pointer-events: auto;
  position: relative;
  user-select: none;

  min-height: ${props => (props.height && props.height === 'auto' ? `${props.height}` : '459px')};
  padding: 26px;
  padding-top: 0;

  height: ${props => (props.height ? `${props.height}` : '90vw')};
  width: ${props => (props.width ? `${props.width}` : '80vw')};
  max-height: 600px;
  max-width: 1024px;
  margin: 0 auto;

  @media ${props => props.theme.device.tablet} {
    width: ${props => props.widthtablet && `${props.widthtablet}px`};
  }

  @media ${props => props.theme.device.mobile} {
    position: fixed;
    height: 100vh;
    max-height: -webkit-fill-available;
    width: 100vw;
    top: 0;
    left: 0;
    border-radius: 0;
    padding: 15px 20px 15px 30px;
  }
`;

export const ModalBody = styled.div`
    color: #333333;
  padding-top: 20px;
  overflow: auto;
  height: calc(100% - 80px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;
export const Header = styled.div`
    border-bottom: 1px solid #8DCCF0;
    height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.div`
  color: #000;
  margin-bottom: 0;
  font-size: 20px;
  width: 372px;
  font-weight: bold;
  flex: auto;
  
`;

export const Icon = styled.span`
  font-size: 30px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
  flex-grow: 0;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    column-gap: 10px;
    max-width: 100%;
    @media (max-width: 640px){
        button{
            padding: 22px 50px;
        }
    }
`

export const ModalWrapper = styled.div`
  width: 100%;
`;

export const DescriptionContent = styled.div`
    width: 100%;
`

export const Row = styled.div`
    margin-bottom: 15px;
`

export const DescriptionText = styled.h1`
    margin-bottom: 20px;
    font-size: 20px;
`