import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import ProText from 'ui/typography/proText';
import ProDisplay from 'ui/typography/proDisplay';
import {
  GET_DOMAINS,
} from 'redux/actions/authentication';

import {
  BootCnt,
  Section2
} from './style';
import { HtmlWrapper,Text } from 'pages/LandingPage/style';

const AccSection2 = ({props
}) => (
  <>
  <BootCnt>
    <Section2 className='container '>
      <div  className={ `row d-flex my-4 ${props?.image_position==='right' ? 'flex-row-reverse'  : ""}`}>
        <div className="col-12 col-lg-6 my-4" data-aos="flip-up">
        <div className="card_custom shadow-lg" id="about_us_img" 
          style={ { backgroundImage: 'url("' +props?.image+ '")'}}>
        </div>
        </div>
        <div className="col-12 col-lg-6 my-4 h-100" data-aos="flip-up">
          <div className="card_custom shadow-lg" id="about_us">
              <div className="row mx-auto">
                  <div className="col-12 col-lg-4 align-items-start d-flex">
                    <ProDisplay
                      className="display-3 bg-transparent text-start about-us-title"
                      configuration={{ fontSize: 64, fontWeight: 700 }}>   
                          <HtmlWrapper 
                            dangerouslySetInnerHTML={{__html:props?.text_boxes[0]?.text_box_large[0]?.large_text}} 
                          />
                      </ProDisplay>
                  </div>
                  <div className="col-12 col-lg-8 align-items-center align-items-lg-end d-flex">
                    <ProDisplay configuration={{ fontSize: 25, lineHeight: 1.5}} className="ms-lg-5 ps-lg-3 py-3">
                      <HtmlWrapper 
                        dangerouslySetInnerHTML={{__html:props?.text_boxes[0]?.text_box_large[0]?.side_text}} 
                      />    
                    </ProDisplay>
                  </div>
                  <div className="col-12 mt-2 pt-lg-2 justify-content-lg-center d-flex">
                  <ProDisplay configuration={{ fontSize: 18, lineHeight: 1.5}}>
                    <HtmlWrapper 
                      dangerouslySetInnerHTML={{__html:props?.text_boxes[0]?.text_box_large[0]?.bottom_text}} 
                    />   
                  </ProDisplay>
                  </div>
              </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6 mt-4">
              <div className="bg card_secondary h-100 align-items-center d-flex shadow-lg text-light" style={{backgroundColor:props?.text_boxes[0]?.text_small_left[0]?.field_background_color}}>
                <ProDisplay configuration={{ fontSize: 18, lineHeight: 1.5, color: 'white', fontWeight: 500}}>
                  <HtmlWrapper 
                    className="text-white"
                    dangerouslySetInnerHTML={{ __html:props?.text_boxes[0]?.text_small_left[0]?.field_richtext}}/>
                </ProDisplay>
              </div>

            </div>
            <div className="col-12 col-lg-6 mt-4">
              <div className="bg card_secondary h-100 align-items-center d-flex shadow-lg text-light" style={{backgroundColor:props?.text_boxes[0]?.text_small_right[0]?.field_background_color}}>
                <ProDisplay configuration={{ fontSize: 18, lineHeight: 1.5, color: 'white', fontWeight: 500,}}>
                <HtmlWrapper
                    className="text-white"
                    dangerouslySetInnerHTML={{ __html:props?.text_boxes[0]?.text_small_right[0]?.field_richtext}}/>
                </ProDisplay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section2>
  </BootCnt>
  </>
);

AccSection2.propTypes = {
  // HOC withMediaQueries
  mediaIsDesktop: PropTypes.bool,
  mediaIsLaptop: PropTypes.bool,

  // HOC (connect, state)
  domains: PropTypes.array,

  // HOC (connect, dispatch)
  AccSection2: PropTypes.func,
};

export default connect(
  state => ({ domains: state.authentication.user.domainList }),
  dispatch => ({
    AccSection1: domain => dispatch({ type: GET_DOMAINS._REQUEST, domain }),
  })
)(withMediaQueries(AccSection2));
