import { css } from 'styled-components';

export const getPaths = (location = window.location) => {
  const { pathname = '' } = location;
  const paths = pathname.split('/');
  const params = {};
  location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    params[key] = decodeURIComponent(decodeURIComponent(value.toString()));
  });

  return {
    pathname,
    paths,
    params,
  };
};

export const preventBrowserBack = () => {
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = () => {
    window.history.go(1);
  };
};

export const dynamicImageImport = (
  file1 = '',
  file2 = '',
  callback = () => ({})
) => {
  const effectFn = ({ default: file = '' } = {}) => {
    callback(file);
    return file;
  };
  // eslint-disable-next-line no-undef
  Modernizr.on('webp', result => {
    if (result) {
      // supported
      import(`ui/assets/img/${file1}`)
        // .then(v => dynamicImportDestructuring(v));
        .then(effectFn);
    } else {
      // not-supported
      import(`ui/assets/img/${file2}`).then(effectFn);
    }
  });
};

export const shadeColor = (color = '#00000', percent) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
};

export const colorOpacity = (color, opacity) => {
  const R = parseInt(color.substring(1, 3), 16);
  const G = parseInt(color.substring(3, 5), 16);
  const B = parseInt(color.substring(5, 7), 16);

  return `rgba(${R},${G},${B},${opacity})`;
};

export const desaturateColor = (color = '#00000', percent) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  // let M = (R + G + B) / 3;
  const M = 200;

  R = parseInt(R - ((R - M) * percent) / 100);
  G = parseInt(G - ((G - M) * percent) / 100);
  B = parseInt(B - ((B - M) * percent) / 100);

  const RR =
    R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

  return `#${RR}${GG}${BB}`;
};

export const detailType = {
  COURSE: 'course',
  RELATED_COURSE: 'learning_object',
  PLAYLIST: 'playlist',
  COLLECTION: 'collection',
  SELECTION: 'selection',
  NEWS: 'news',
  PACKAGE: 'package',
  LIVE_EVENT: 'live_event',
  VIRTUAL: 'VIRTUAL',
  CLASSROOM: 'LIVE',
};

export const customScrollbar = () => css`
    ::-webkit-scrollbar {
      width: 9px;
      height: 9px;
      margin-left: 3px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 2px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${props => props.theme.colors.lightSilver};
      border-radius: 2px;
    }
    scrollbar-width: thin;
    scrollbar-color: ${props =>
      `${props.theme.colors.lightSilver} ${props.theme.colors.ghostWhite}`};
  `;

export const hideScrollbar = () => css`
    ::-webkit-scrollbar {
      display: none;
    }
  `;

//SUBMIT
export const createCartPayloadFromDraft = (order, promoCode = null) => ({
  promoCode,
  items: order[0]?.orderSubscriptions.reduce((acc, val) => {
    const { isPremium, bundleCode, bundleName, calendarChoice, ecmFlag, packageType, productId, productRatePlanId, productRatePlanChargeId, quantity, productType: chargeType, billingPeriod, periodSize, triggerEvents, priceWithoutDiscount: { amountPerSubscription } = {} } = val;
    return [...acc, { isPremium, bundleCode, bundleName, calendarChoice, ecmFlag, packageType, productId, productRatePlanId, productRatePlanChargeId, quantity, chargeType, billingPeriod, periodSize, triggerEvents, isFreePrice: amountPerSubscription < 0.01 }];
  }, []).filter(item => !(!item.productId && item.isFreePrice !== undefined)),
  orderPriceIsFree: parseFloat(order[0]?.totalPrice) < 0.01,
});

//PREVIEW
export const createCartPayloadFromLocalCart = (products = {}) => (Object.keys(products).reduce((acc, key) => {
  console.log('products',products);
  const { is_premium: isPremium, calendar_choice: calendarChoice, flag_ecm: ecmFlag, bundleCode, bundleName, field_productid: productId, field_productrateplanchargesbill: billingPeriod, field_productrateplanchargesid: productRatePlanChargeId, field_productrateplanchargestrig: productRatePlanChargesTriggerEvent, field_productrateplansid: productRatePlanId, field_productrateplanchargestype: chargeType, num: quantity } = products[key];
  if (quantity) {
    const product = {
      productId,
      productRatePlanId,
      productRatePlanChargeId,
      chargeType,
      billingPeriod,
      triggerEvents: [productRatePlanChargesTriggerEvent],
      quantity,
      bundleCode,
      bundleName,
      calendarChoice,
      ecmFlag,
      packageType: isPremium === true || isPremium === 'True' ? 'premium' : 'base',
      isPremium: isPremium
    };
    return [
      ...acc,
      product,
    ];
  }
  return [...acc];
}, []));

export const createCartPayloadFromLocalCartPremium = (product = {}) => {
  const {
    zuora_productid: productId,
    productrateplanchargesbill: billingPeriod,
    productrateplanchargesid: productRatePlanChargeId,
    productrateplanchargestrig: productRatePlanChargesTriggerEvent,
    productrateplansid: productRatePlanId,
    productrateplanchargestype: chargeType,
    quantity
  } = product;
  const item = {
    productId,
    productRatePlanId,
    productRatePlanChargeId,
    chargeType,
    billingPeriod,
    triggerEvents: [productRatePlanChargesTriggerEvent],
    quantity,
  };
  return [
    item,
  ];
};

export const SOURCETYPE = {
  BANK: 'BANK',
  PORTAL: 'PORTAL',
};

export function unescape(string) {
  return new DOMParser()
    .parseFromString(string,'text/html')
    .querySelector('html')
    .textContent;
}
