import {
  addBookmarkMock,
  getBookmarksMock,
  getFollowCountMock,
  getFollowersMock,
  handleFeedbackMock,
  removeBookmarkByIdMock,
  removeBookmarkMock,
  addFollowMock,
  removeFollowMock,
  shouldUseMock
} from './mock';
import {
  apiCall,
  baseUrl,
  catalogPrefix,
  extendUrl,
  socialPrefix
} from './config';

export default {
  getBookmarks: userId => {
    if (shouldUseMock()) {
      return getBookmarksMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}bookmark/getall`,
      'GET'
    );
  },
  addBookmark: payload => {
    if (shouldUseMock()) {
      return addBookmarkMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}bookmark/add`,
      'POST',
      payload
    );
  },
  removeBookmark: (itemId, usrId) => {
    if (shouldUseMock()) {
      return removeBookmarkMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}bookmark/delete/byitemuser?itemId=${itemId}`,
      'DELETE'
    );
  },
  removeBookmarkById: bookId => {
    if (shouldUseMock()) {
      return removeBookmarkByIdMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}bookmark/delete/?id=${bookId}`,
      'DELETE'
    );
  },
  handleFeedback: (itemId, usrId, feedback, reset) => {
    if (shouldUseMock()) {
      return handleFeedbackMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}feedback/${itemId}?userId=${usrId}&feedback=${feedback}&reset=${reset}`,
      'POST',
      {}
    );
  },
  getFollowCount: userId => {
    if (shouldUseMock()) {
      return getFollowCountMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}follow/getcount?userId=${userId}`,
      'GET'
    );
  },
  getFollowers: userId => {
    if (shouldUseMock()) {
      return getFollowersMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}follow/getallfollow?userId=${userId}&items=100`,
      'GET'
    );
  },
  addFollow: body => {
    if (shouldUseMock()) {
      return addFollowMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}follow/add`,
      'POST',
      body
    );
  },
  removeFollow: body => {
    if (shouldUseMock()) {
      return removeFollowMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}follow/delete`,
      'PUT',
      body
    );
  }
};
