import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {Route, Switch, Redirect, useLocation, useHistory} from 'react-router-dom';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';

import routes from 'routes';
import { Numeration } from 'ui/atoms';
import { PurchaseFooter } from 'ui/components';
// import { useAnalytics } from 'providers/analyticsProvider';
import { preventBrowserBack } from 'utils/common';
// import { mapCartItem } from 'utils/analytics';
import {
  SIGNUP_NEXT_STEP,
  SIGNUP_SET_MANAGER_INFO,
  SIGNUP_SET_BUYER_B2C_INFO,
  SIGNUP_STEP_RESET,
  SIGNUP_SET_SUPER_MANAGER_INFO,
} from 'redux/actions/signup';
import { Steps } from 'redux/reducers/signup';
import { CreateCartInterface } from 'interface/signup';

import { Container } from 'ui/gridSystem';
import { UserAccount, Subscriptions, Billings, Confirm } from './steps';
import { FlowHeader, FlowHeaderWrapper, Logo, SignupContainer } from './style';
import ConfirmOTP from './steps/ConfirmOTP';
import ConfirmFromBank from './steps/ConfirmFromBank';
import SpecializationCreditsEcmModal from "../../ui/components/Modals/SpecializationCreditsEcmModal";
import { GET_SPECIALIZATIONS_PACKAGE_ECM_CREDITS} from "../../redux/actions/ecm";

preventBrowserBack();

const initialFormValid = false;

const Signup = ({
  pushUrl,
  cart,
  nextSignupStep,
  getSpecializationsByPackageEcmCredits,
  step,
  setManagerInformation,
  setBuyerB2CInformation,
  headerIsVisible,
  isFromBank,
  isFirstAccess,
  brand = '',
  resetSignupStep,
  createSuperManager,
  sendDataTreatment,
  agreements,
  paymentToken,
}) => {
  const [formValid, setFormValid] = useState(initialFormValid);
  const [paymentType, setPaymentType] = useState('zuora_credit-card');
  const [hasProducts, setHasProducts] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [userType, setUserType] = useState(searchParams.get('userType'));
  const [isStudent, setIsStudent] = useState(searchParams.get('student'));
  // const { ga4IsInitialized, sendGA4Event } = useAnalytics();

  const [showCreditiEcmModal, setShowCreditiEcmModal] = useState(false);

  useEffect(() => {
    if (window.location.hash !== '#') {
      setUserType(userType);
      setIsStudent(isStudent);
      const ecmSelected = searchParams.get('defaultEcm') || '';// set tab ecm open
      if (isStudent) {
        pushUrl(`${window.location.pathname}?userType=${userType}&student=${isStudent}${ecmSelected ? '&defaultEcm=true' : ''}#`);
      } else {
        pushUrl(`${window.location.pathname}?userType=${userType}${ecmSelected ? '&defaultEcm=true' : ''}#`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    setFormValid(initialFormValid);
  }, [step.num]);

  useEffect(() => {
    const checkCart = Object.values(cart.products)
      .some(product => product.num > 0);
    setHasProducts(checkCart);

    // Se l'utente seleziona prodotti ecm, viene mostrato popup che mostra per quali non ha diritto a crediti ecm
    const checkHasEcmProduct = Object.values(cart.products).some(product => (product.flag_ecm === 'True') && (product.num > 0));
    setShowCreditiEcmModal(checkHasEcmProduct);
  }, [cart]);

  const isSubscriptionStep = (stepNum)=>{
    return step.num === 1;
  }

  const nextStep = () => {
    const newStep = Steps[step.num];
    if (newStep) {
      if (step.num === 2) {
        if (isFromBank) {
          createSuperManager();
        } else if (userType !== 'persona_fisica') {
            setManagerInformation();
          } else {
            setBuyerB2CInformation();
          }
      } else {
        const ecmProducts = Object.values(cart.products).filter(product => (product.flag_ecm === 'True') && (product.num > 0));
        if (showCreditiEcmModal && isSubscriptionStep()){ // se da backend arriva parametro per mostrare popup di info crediti ecm (solo se mi trovo in step subscription(1) )
          getSpecializationsByPackageEcmCredits({ ecmProducts, nextStepValue: step.num }); // TODO: specificare productIds
        }else{
          nextSignupStep(step.num); // vado al prossimo step senza aprire il popup
        }
      }
    }
  };

  const updateFormValid = value => {
    setFormValid(value);
  };

  const continueButtonDisabled = !(
    (step.num === 1
      && Object.values(cart.products).reduce((acc, p) => acc + p.num, 0))
    || (step.num === 2 && formValid && (isFromBank || !!userType))
    || (step.num === 3 && formValid)
    || (step.num === 4 && formValid && (!sendDataTreatment || !!userType))
  );

  const redirectHomepage = () => {
    if (step.num >= 2) {
      return null;
    }
    resetSignupStep();
    pushUrl(routes.landingPage.path);
    return true;
  };
  const subscriptionPrice = Object.values(cart?.products || {}).reduce(
    (acc, item) => (
      acc
        + (item.product_type === 'Learning Subscription' && item.price_number
          ? parseFloat(item.price_number.replaceAll(',', '.')) * item.num
          : 0)
    ),
    0
  );

  // const trackingCallback = () => {
  //   if (!ga4IsInitialized) return;
  //   const productValues = Object.values(cart.products);
  //   sendGA4Event({
  //     eventCategory: 'signup',
  //     eventAction: 'begin_checkout',
  //     currency: productValues[0].price_code,
  //     value: cart.total_price,
  //     items: productValues.map((item, index) => mapCartItem(item, index)),
  //   });
  // };

  return (
    <>
      <FlowHeaderWrapper>
        <Container>
          <FlowHeader>
            <Logo
              disabled={step.num >= 2}
              onClick={redirectHomepage}
              img={brand.logo_white}
            />
            {headerIsVisible && (
              <>
                {isFromBank ? (
                  <Numeration currentStep={step.num} totalSteps={2} />
                ) : sendDataTreatment ? (
                  <Numeration currentStep={2} totalSteps={2} />
                ) : location.pathname !== routes.confirmFromBank.path ? (
                  <Numeration
                    currentStep={step.num}
                    totalSteps={Steps.length}
                  />
                ) : null}
              </>
            )}
          </FlowHeader>
        </Container>
      </FlowHeaderWrapper>
      <SignupContainer>
        <Switch>
          <Route
            path={routes.subscription.path}
            exact
            render={() => <Subscriptions cart={cart} />}
          />
          <Route
            path={routes.userAccount.path}
            exact
            render={() => (
              hasProducts
                ? <UserAccount onChange={updateFormValid} />
                : <Redirect to={routes.subscription.path} />
            )}
          />

          <Route
            path={routes.billings.path}
            exact
            render={() => (
              <Billings
                paymentType={paymentType}
                setPaymentType={setPaymentType}
                onChange={updateFormValid}
                isValid={step.num === 4 && formValid}
                paymentToken={paymentToken}
                userType={userType}
              />
            )}
          />

          <Route
            path={routes.confirmOtp.path}
            exact
            render={() => <ConfirmOTP onChange={updateFormValid} userType={userType} />}
          />
          <Route path={routes.confirm.path} exact render={() => <Confirm />} />
          <Route path={routes.confirmFromBank.path} exact render={() => <ConfirmFromBank />} />

          <Redirect to={`${routes.subscription.path}?userType=${userType}`} />
        </Switch>
      </SignupContainer>
      {headerIsVisible && step.num !== 4 && !isFirstAccess && (
        <PurchaseFooter
          price={cart.total_price || '0'}
          subscriptionPrice={subscriptionPrice}
          btnText="Continua"
          btnOnClick={nextStep}
          btnDisabled={continueButtonDisabled}
          cart={cart}
          // trackingCallback={trackingCallback}
        />
      )}
      <SpecializationCreditsEcmModal/>
    </>
  );
};

Signup.propTypes = {
  // HOC (connect, state)
  headerIsVisible: PropTypes.bool.isRequired,
  cart: CreateCartInterface,
  step: PropTypes.shape({
    num: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
  isFirstAccess: PropTypes.bool,
  isFromBank: PropTypes.bool,
  brand: PropTypes.object,
  paymentToken: PropTypes.string,
  sendDataTreatment: PropTypes.bool,
  agreements: PropTypes.object,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  getSpecializationsByPackageEcmCredits: PropTypes.func.isRequired,
  nextSignupStep: PropTypes.func.isRequired,
  setManagerInformation: PropTypes.func.isRequired,
  setBuyerB2CInformation: PropTypes.func.isRequired,
  resetSignupStep: PropTypes.func.isRequired,
  createSuperManager: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const {
      signupFlowStart: signupStart,
      cart,
      step,
      headerIsVisible,
      isFromBank,
      paymentToken,
      agreements,
      userType,
      isStudent
    } = state.signup;
    const { isFirstAccess } = state.landingPage;
    const { brand } = state.domain;
    const {
      user: { sendDataTreatment },
    } = state.authentication;
    return {
      signupStart,
      cart,
      step,
      headerIsVisible,
      isFromBank,
      isFirstAccess,
      brand,
      paymentToken,
      sendDataTreatment,
      agreements,
      userType,
      isStudent
    };
  },
  dispatch => ({
    pushUrl: (url, userType) => {
      const hasUserType = url.includes('?userType=');
      const newUrl = hasUserType ? url : `${url}?userType=${userType}`;
      dispatch(push(newUrl));
    },
    // pushUrl: url => dispatch(push(url)),
    nextSignupStep: (nextStep, data) => dispatch({ type: SIGNUP_NEXT_STEP._REQUEST, nextStep, data }),
    setManagerInformation: () => dispatch({ type: SIGNUP_SET_MANAGER_INFO._REQUEST }),
    setBuyerB2CInformation: (payload) => dispatch({ type: SIGNUP_SET_BUYER_B2C_INFO._REQUEST, payload }),
    resetSignupStep: () => dispatch({ type: SIGNUP_STEP_RESET }),
    createSuperManager: () => dispatch({ type: SIGNUP_SET_SUPER_MANAGER_INFO._REQUEST }),
    getSpecializationsByPackageEcmCredits: payload => dispatch({ type: GET_SPECIALIZATIONS_PACKAGE_ECM_CREDITS._REQUEST, payload })
  })
)(Signup);
