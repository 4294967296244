import {
  shouldUseMock,
  getTeamProgessMock,
  getViewsMock,
  getPositiveMock,
  getNotificationsMock
} from './mock';
import {
  apiCall,
  baseUrl,
  bePrefix,
  extendUrl,
  notificationPrefix,
  socialPrefix
} from './config';

export default {
  getTeamProgess: ({ userId }) => {
    if (shouldUseMock()) {
      return getTeamProgessMock();
    }
    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}teamprogress?managerId=${userId}`,
      'GET'
    );
  },
  getViews: () => {
    if (shouldUseMock()) {
      return getViewsMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}feedback/views`,
      'GET'
    );
  },
  getPositive: () => {
    if (shouldUseMock()) {
      return getPositiveMock();
    }
    return apiCall(
      `${socialPrefix}${baseUrl}${extendUrl}feedback/positive`,
      'GET'
    );
  },
  getNotifications: userId => {
    if (shouldUseMock()) {
      return getNotificationsMock();
    }
    return apiCall(
      `${notificationPrefix}${baseUrl}${extendUrl}getnotifications`,
      'GET'
    );
  }
};
