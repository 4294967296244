import React, {useEffect, useState} from 'react';
import {Content, RowCreditiEcmEditWrapper} from "./style";
import {Button, Checkbox, CustomInput, CustomSelect} from "../../atoms";
import {Col} from "../../gridSystem";
import {ButtonWrapperEdit, IconEdit, IconStatus, WrapperIcon} from "../TableCardDesktop/style";
import {styles} from "../../../utils/multi-select";
import {withMediaQueries} from "../../../hoc/withMediaQueries";
import CustomInputNumber from "../../atoms/CustomInputNumber";

const RowCreditiEcmEdit = (
    {
        prospectId,
        handleUpdateCreditiEcmProspect,
        ecmCreditsNotP,
        ecmRoleOptionsState,
        participationRole,
        ecmMaxCrediti,

        // HOC media queries
        currentViewport,
        viewportTypes,
    }) => {
    const [editCreditiEcmEnabled, setEditCreditiEcmEnabled] = useState(false);
    const [creditiEcmValue, setCreditiEcmValue] = useState(0);
    const [selectedRole, setSelectedRole] = useState();
    useEffect(() => {
        const role = ecmRoleOptionsState?.find(_role => _role.value === participationRole.id);
        role && setSelectedRole(role);
    }, [participationRole, ecmRoleOptionsState]);
    useEffect(()=>{
        setCreditiEcmValue(ecmCreditsNotP);
    },[ecmCreditsNotP])

    const handleSubmit = ()=>{
        handleUpdateCreditiEcmProspect(prospectId, creditiEcmValue, selectedRole, participationRole);
    }

    const handleChangeRole = (roleValue)=>{
        if (roleValue?.label === 'Partecipante'){
            setCreditiEcmValue(null);
        }
        setSelectedRole(roleValue);
    }

    const handleCancel = ()=>{
        const role = ecmRoleOptionsState.find(_role => _role.value === participationRole.id);
        role && setSelectedRole(role);

        setCreditiEcmValue(ecmCreditsNotP);
        setEditCreditiEcmEnabled(false);
    }

    const ConfirmCancelButtons = ()=>{
        return <Col md={4}>
            <ButtonWrapperEdit>
                <Button
                    type="PRIMARY"
                    text="Conferma"
                    height={35}
                    fontSize={16}
                    disabled={selectedRole?.label && (selectedRole?.label !== 'Partecipante') && !creditiEcmValue}
                    onClick={() => {
                        handleSubmit();
                        setEditCreditiEcmEnabled(false);
                    }}
                />
                <Button
                    type="SECONDARY"
                    text="Annulla"
                    height={35}
                    fontSize={16}
                    onClick={handleCancel}
                />
            </ButtonWrapperEdit>
        </Col>
    }

    return (
        <RowCreditiEcmEditWrapper>
            <Col xs={10} md={7}>
                <Content>
                    <CustomInput
                        type="text"
                        value={creditiEcmValue}
                        disabled={!editCreditiEcmEnabled || (selectedRole?.label === 'Partecipante')}
                        placeholder={`Crediti ECM ${ecmMaxCrediti ? '(max: '+ ecmMaxCrediti +')' : ''}`}
                        className={'m-3'}
                        onBlur={()=>{
                            const parsedValue = parseInt(creditiEcmValue);
                            if (parsedValue) {
                                if (parsedValue < 0) {
                                    setCreditiEcmValue(0);
                                } else if (ecmMaxCrediti && (parsedValue > ecmMaxCrediti)) {
                                    setCreditiEcmValue(ecmMaxCrediti);
                                }
                            }else {
                                setCreditiEcmValue(null);
                            }
                        }}
                        onChange={(value) => setCreditiEcmValue(value)}/>
                    <CustomSelect
                        placeholder="Ruolo di partecipazione"
                        options={ecmRoleOptionsState}
                        onChange={handleChangeRole}
                        selected={selectedRole}
                        disabled={!editCreditiEcmEnabled}
                        styles={styles}
                        hideSelectedOptions={true}
                    />
                </Content>
            </Col>
            <Col xs={2} md={5}>
                {!editCreditiEcmEnabled && <WrapperIcon>
                    <IconEdit
                        className="icon-modifica"
                        onClick={() => {
                            setEditCreditiEcmEnabled(true);
                        }}
                    />
                </WrapperIcon>}
                {(viewportTypes[currentViewport] > viewportTypes.mediaIsTablet) ? (editCreditiEcmEnabled && ConfirmCancelButtons(prospectId)) :
                    editCreditiEcmEnabled && <WrapperIcon>
                        <IconStatus
                            className="icon-check mx-2"
                            onClick={() => {
                                handleSubmit();
                                setEditCreditiEcmEnabled(false);
                            }}
                        />
                        <IconStatus
                            className="icon-close-x"
                            onClick={handleCancel}
                        /></WrapperIcon>
                }
            </Col>

        </RowCreditiEcmEditWrapper>
    );
};

export default withMediaQueries(RowCreditiEcmEdit);