import produce from 'immer';
import { CREATE_USER } from 'redux/actions/landingPage';
import {
  ONBOARDING_GET_NOT_CONFIGURED_ASSETS,
  ONBOARDING_GET_ON_BOARDING,
  ONBOARDING_INITIAL_STATE,
  ONBOARDING_PUT_ASSETS_ACTIVE,
  ONBOARDING_RESET_STEP,
  ONBOARDING_SELECT_ITEM,
  ONBOARDING_SET_STEP,
  ONBOARDING_STORE_DATA,
  ONBOARDING_GET_PROFESSIONS,
  stepsOnboarding
} from 'redux/actions/onBoarding';

const initialState = {
  stepOnBoarding: stepsOnboarding.STEP1,
  professions: []
};

const onBoardingReducers = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ONBOARDING_STORE_DATA: {
        const { password, prospectId } = action.payload;
        draft = {
          ...state,
          stepOnBoarding: stepsOnboarding.STEP2,
          toSendPassword: password,
          prospectId
        };
        break;
      }
      case ONBOARDING_RESET_STEP: {
        draft = initialState;
        break;
      }
      case ONBOARDING_SET_STEP: {
        const { payload } = action;
        draft.stepOnBoarding = payload;
        break;
      }
      case CREATE_USER._SUCCESS: {
        draft = {
          stepOnBoarding: stepsOnboarding.STEP3
        };
        break;
      }
      case ONBOARDING_GET_ON_BOARDING._SUCCESS: {
        const { payload } = action;
        draft.needs = payload;
        break;
      }
      case ONBOARDING_GET_NOT_CONFIGURED_ASSETS._SUCCESS: {
        const { payload } = action;
        draft.notConfigured = payload;
        break;
      }
      case ONBOARDING_PUT_ASSETS_ACTIVE._SUCCESS: {
        draft = initialState;
        break;
      }
      case ONBOARDING_SELECT_ITEM._SUCCESS: {
        const { payload } = action;
        draft.selectedNeed = payload;
        break;
      }
      case ONBOARDING_INITIAL_STATE: {
        draft = initialState;
        break;
      }
      case ONBOARDING_GET_PROFESSIONS._SUCCESS: {
        const { data } = action;
        draft.professions = data;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default onBoardingReducers;
