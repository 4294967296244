import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BILLINGS_ZUORA_SIGNATURE_GET } from '../../../../redux/actions/billings';
import { LOADER_CLOSE, LOADER_OPEN } from '../../../../redux/actions';
import { ZuoraContainer, ZuoraPayment } from './style';
import { Checkbox } from '../../../atoms';
import { ZUORA_URL } from '../../../../api/config';

const CreditCardZuora = ({
  callbackSuccess,
  callbackError,
  signatures: zuoraSignatures =  {},
  company,
  getBillingsSignatureZuora,
  loaderOpen,
  loaderClose
}) => {
  const Zuora = window.Z;
  const [agreementChecked, setAgreementChecked] = useState(false);
  useEffect(() => {
    loaderOpen();
    const { CreditCard } = zuoraSignatures;
    if(!CreditCard) getBillingsSignatureZuora();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBillingsSignatureZuora]);
  const default_params = {
    style: 'inline',
    locale: 'it_IT',
    submitEnabled: true,
    url: ZUORA_URL[process.env.REACT_APP_DEPLOY_ENV]
  };

  const [zuora_params, setZuoraParams] = useState({});

  useEffect(() => {
    const {CreditCard : {key, signature, token, tenantid: tenantId, acn_pageid: id} = {}} = zuoraSignatures;
    const {zuoraaccountid: field_accountId} = company;
    setZuoraParams({ ...default_params, id, field_accountId, key, signature, tenantId, token });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zuoraSignatures]);

  const zuoraCallback = ({ success, refId, errorMessage }) => {
    if(success) {
      callbackSuccess && callbackSuccess(refId);
    } else if(callbackError) {
      callbackError(errorMessage);
    }
    getBillingsSignatureZuora();
  };

  const onChangeAgreement = () => {
    const {zuoraaccountid: field_accountId} = company;
    if(!agreementChecked) {
      Zuora.setAgreement('External','Recurring','Visa,Mastercard,AmericanExpress',field_accountId);
    } else {
      Zuora.setAgreement('','','','');
    }
    setAgreementChecked(!agreementChecked);
  };
  
  const loadZuora= () => {
    Zuora.setEventHandler('onloadCallback', () => {
      loaderClose();
      onChangeAgreement();
    });
    Zuora.render(zuora_params, {}, zuoraCallback);
  };

  useEffect(() => {
    const { CreditCard } = zuoraSignatures;
    if(CreditCard && Object.keys(zuora_params).length > 0) {
      loadZuora();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zuora_params]);

  return (
    <ZuoraContainer>
      <ZuoraPayment />
      <Checkbox onChange={onChangeAgreement} label="Salva in modo sicuro questa carta per futuri acquisti" checked={agreementChecked} />
    </ZuoraContainer>
  );
};

CreditCardZuora.propTypes = {
  callbackSuccess: PropTypes.func,
  callbackError: PropTypes.func,

  // HOC (connect, state)
  company: PropTypes.object,
  signatures: PropTypes.object,
  // HOC (connect, dispatch)
  getBillingsSignatureZuora: PropTypes.func,
  loaderOpen: PropTypes.func,
  loaderClose: PropTypes.func
};

export default connect(
  state => ({
    signatures: state.billings.signatures,
    company: state.profile.company
  }),
  dispatch => ({
    getBillingsSignatureZuora: () => dispatch({ type: BILLINGS_ZUORA_SIGNATURE_GET._REQUEST, payment_method: 'CreditCard' }),
    loaderOpen: () => dispatch({ type: LOADER_OPEN }),
    loaderClose: () => dispatch({ type: LOADER_CLOSE })
  })
)(CreditCardZuora);
