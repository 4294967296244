import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container } from 'ui/gridSystem';
import { MODAL_CLOSE } from 'redux/actions';
import { ProfilePicture } from 'ui/components';
import { Button, CustomInput } from 'ui/atoms';
import routes from 'routes';
import { AUTHENTICATION_SIGNOUT } from 'redux/actions/authentication';
import Modal, { stopEvent } from '../Modal';
import { ModalGlobalContainer } from '../Modal.style';
import {
  ContentWrapper,
  Icon,
  InputWrapper,
  ButtonWrapper,
  IconEdit,
  FlexWrapper
} from './style';
import { ModalWrapper } from '../InviteFriendModal/style';

const modalId = 'EditProfileModal';

const newField = () => ({
  value: undefined,
  valid: true
});

const initialForm = {
  firstname: newField(),
  lastname: newField(),
  email: newField(),
  password: newField()
};

const EditProfileModal = ({ onClose, profile, logout }) => {
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    const newState = { ...form };
    newState.firstname.value = profile.firstname;
    newState.lastname.value = profile.lastname;
    newState.email.value = profile.email;
    newState.password.value = 'FakePwd1!';
    setForm(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  // const handleOnChange = (field, newValue) =>
  //   setForm(s => {
  //     const newState = { ...s };
  //     newState[field].value = newValue;
  //     return newState;
  //   });

  // const handleOnBlur = (type, field) => {
  //   setForm(s => {
  //     const newState = { ...s };
  //     newState[field].valid = validator[type](form[field].value);
  //     return newState;
  //   });
  // };

  const onChangePwd = () => {
    logout(`${routes.resetPassword.path}?email=${profile.email}`);
    onClose();
  };

  const buttonDisabled = () => {
    let valid = true;
    Object.values(form).forEach(field => {
      valid = valid && field.valid && !!field.value;
    });
    return !valid;
  };

  const onSave = () => {
    onClose();
  };

  return (
    <Modal id={modalId}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer
            width="640px"
            height="572px"
            onMouseDown={stopEvent}
            onClick={stopEvent}
          >
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={onClose} />
              <ProfilePicture
                profile={profile}
                heightContainer="90px"
                widthContainer="90px"
              />
              <InputWrapper>
                <CustomInput
                  type="text"
                  placeholder="Nome"
                  value={profile.firstname}
                  // onChange={val => handleOnChange('firstname', val)}
                  // onBlur={() => handleOnBlur('string', 'firstname')}
                  // error={!form.firstname.valid}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <CustomInput
                  type="text"
                  placeholder="Cognome"
                  value={profile.lastname}
                  // onChange={val => handleOnChange('lastname', val)}
                  // onBlur={() => handleOnBlur('string', 'lastname')}
                  // error={!form.lastname.valid}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <CustomInput
                  type="text"
                  placeholder="Email"
                  value={profile.email}
                  // onChange={val => handleOnChange('email', val)}
                  // onBlur={() => handleOnBlur('email', 'email')}
                  // error={!form.email.valid}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <FlexWrapper>
                  <CustomInput
                    type="password"
                    placeholder="Password"
                    value="FakePwd1!"
                    // onChange={val => handleOnChange('password', val)}
                    // onBlur={() => handleOnBlur('password', 'password')}
                    // error={!form.password.valid}
                    disabled
                  />
                  <IconEdit className="icon-modifica" onClick={onChangePwd} />
                </FlexWrapper>
              </InputWrapper>
              <ButtonWrapper>
                <Button
                  text="Salva"
                  type="PRIMARY"
                  onClick={onSave}
                  disabled={buttonDisabled()}
                />
              </ButtonWrapper>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

EditProfileModal.propTypes = {
  // HOC (connect, state)
  profile: PropTypes.object,

  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
};

EditProfileModal.id = modalId;

export default connect(
  state => {
    const { user: profile = {} } = state.authentication;
    return { profile };
  },
  dispatch => ({
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    logout: goTo => dispatch({ type: AUTHENTICATION_SIGNOUT._REQUEST, goTo })
  })
)(EditProfileModal);
