import { parseQueryParams } from 'utils/queryParams';
import {
  getRecommendedPlansMock,
  getTeamsInterestMock,
  getMostPopularContentOverViewMock,
  getMostViewedContentOverViewMock,
  shouldUseMock,
  getActiveSubscriptionsMock,
  getCalendarsForPremiumProductMock,
  createProspectMock,
  getTeamsInterestDetailsMock,
  updateEmailSubscriptionMock,
  updateEcmRoleOrCreditsSubscriptionMock,
  associatePaymentMethodMock,
  deleteEmailSubscriptionMock,
  disableEmailSubscriptionMock,
  getProductCatalogMock,
  getCategoriesMock,
  getFilteredCatalogMock,
  putOrderItemUpdateToRenewMock,
  getSubcriptionDraftMock,
  getTotalAmountAndCountSubscriptions,
  getSubscriptionProductNamesMock,
  getEcmRoleMock,
  getXmlListMock,
  generateXmlMock
} from './mock';
import {
  apiCall,
  baseUrl,
  bePrefix,
  calendarprefix,
  extendUrl,
  internalPrefix,
  ecmPrefix
} from './config';

export default {
  getActiveSubscriptions: ({ userId }) => {
    if (shouldUseMock()) {
      return getActiveSubscriptionsMock();
    }
    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}getallprospectgroupedbyemail`,
      'GET'
    );
  },
  getSubscriptionProductNames: ({ ids }) => {
    if (shouldUseMock()) {
      return getSubscriptionProductNamesMock();
    }
    return apiCall(
      `/ecommerce/api/frontend/subscription/productnames?ids=${ids}`,
      'GET'
    );
  },
  getTotalAmountAndCountSubscriptions: () => {
    if (shouldUseMock()) {
      return getTotalAmountAndCountSubscriptions();
    }
    return apiCall(
      '/ecommerce/api/frontend/subscription/totalamountandcount',
      'GET'
    );
  },
  getRecommendedPlans: ({ userId }) => {
    if (shouldUseMock()) {
      return getRecommendedPlansMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}promoted_collections`,
      'GET',
      {},
      { isDrupal: true, isPrivate: true, userId }
    );
  },
  getTeamsInterest: ({ userId }) => {
    if (shouldUseMock()) {
      return getTeamsInterestMock();
    }
    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}reccomendations`,
      'GET'
    );
  },
  getProductCatalog: () => {
    if (shouldUseMock()) {
      return getProductCatalogMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}product_catalog?items_per_page=100`,
      'GET',
      null,
      {
        isDrupal: true
      }
    );
  },
  getTeamsInterestDetails: ({ filters, userId }) => {
    if (shouldUseMock()) {
      return getTeamsInterestDetailsMock(filters.type);
    }
    return apiCall(
      `${baseUrl}${extendUrl}compact_list?type=${filters.type}&ids=${filters.ids}&items_per_page=50`,
      'GET',
      null,
      { isDrupal: true, isPrivate: true, userId }
    );
  },
  getMostPopularContentOverview: ({ filters, userId }) => {
    if (shouldUseMock()) {
      return getMostPopularContentOverViewMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}content-overview${parseQueryParams(filters)}`,
      'GET',
      { userId }
    );
  },
  getMostViewedContentOverview: ({ filters, userId }) => {
    if (shouldUseMock()) {
      return getMostViewedContentOverViewMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}content-overview${parseQueryParams(filters)}`,
      'GET',
      { userId }
    );
  },
  getSubcriptionDraft: ({items = 10, nextPageId}) => {
    if (shouldUseMock()) {
      return getSubcriptionDraftMock();
    }
    return apiCall(
      `/ecommerce/api/frontend/subscription/draft${parseQueryParams({items, nextPageId})}`,
      'GET'
    );
  },
  getCalendarsForPremiumProduct: ({  productId }) => {
    if (shouldUseMock()) {
      return getCalendarsForPremiumProductMock();
    }
    return apiCall(`${calendarprefix}${baseUrl}${extendUrl}booking/check?productId=${productId}`, 'GET');
  },
  createProspect: body => {
    if (shouldUseMock()) {
      return createProspectMock();
    }
    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}createprospect${internalPrefix}`,
      'POST',
      body,
      { encryptBody: true }
    );
  },
  updateEmailSubscription: body => {
    if (shouldUseMock()) {
      updateEmailSubscriptionMock();
    }
    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}secure/prospect/changemail`,
      'POST',
      body,
      { encryptBody: true }
    );
  },
  updateEcmRoleOrCreditsSubscription: body => {
    if (shouldUseMock()) {
      updateEcmRoleOrCreditsSubscriptionMock();
    }
    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}prospect/changeecmroleorcredits`,
      'PUT',
      body,
      { encryptBody: true }
    );
  },
  deleteEmailSubscription: params => {
    if (shouldUseMock()) {
      return deleteEmailSubscriptionMock();
    }
    return apiCall(
      `${bePrefix}${baseUrl}${extendUrl}prospect/delete${parseQueryParams(
        params
      )}`,
      'DELETE'
    );
  },
  disableEmailSubscription: body => {
    if (shouldUseMock()) {
      return disableEmailSubscriptionMock();
    }
    return apiCall(
      '/ecommerce/api/frontend/secure/subscription/cancel',
      'POST',
      body,
      { encryptBody: true }
    );
  },
  associatePaymentMethod: ({ paymentId: payment_method_id, userId }) => {
    if (shouldUseMock()) {
      return associatePaymentMethodMock();
    }
    return apiCall(
      `${baseUrl}current_user/cart/latest/payment_method`,
      'POST',
      { payment_method_id },
      { isDrupal: true, isPrivate: true, userId }
    );
  },
  replacePaymentMethod: ({
    paymentId: payment_method_id,
    userId,
    subscriptionId: subscription_id
  }) => {
    if (shouldUseMock()) {
      return associatePaymentMethodMock();
    }
    return apiCall(
      `${baseUrl}assoc_subscription/payment_method`,
      'POST',
      {
        payment_method_id,
        subscription_id
      },
      { isDrupal: true, isPrivate: true, userId }
    );
  },
  getCategories: () => {
    if (shouldUseMock()) {
      return getCategoriesMock();
    }
    return apiCall(`${baseUrl}${extendUrl}esigenze_formative`, 'GET', null, {
      isDrupal: true
    });
  },
  getFilteredCatalog: filterId => {
    if (shouldUseMock()) {
      return getFilteredCatalogMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}product_catalog?needs=${filterId}&items_per_page=100`,
      'GET',
      null,
      {
        isDrupal: true
      }
    );
  },
  putOrderItemUpdateToRenew: ({ body, userId }) => {
    if (shouldUseMock()) {
      return putOrderItemUpdateToRenewMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}resource/order-item-update-to-renew`,
      'PUT',
      body,
      {
        isDrupal: true,
        isPrivate: true,
        userId
      }
    );
  },
  getEcmRole: () => {
    if (shouldUseMock()) {
      return getEcmRoleMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}content_manager/ecm_role`,
      'GET',
      null,
      {
        isDrupal: true
      }
    );
  },
  getXmlList: ({domainId, userId, organizationId, itemsPerPage, nextPage}) => {
    if (shouldUseMock()) {
      return getXmlListMock();
    }

    let paginationParams = '';
    paginationParams += itemsPerPage ? `&items_per_page=${itemsPerPage}` : '';
    paginationParams += nextPage ? `&next_page=${nextPage}` : '';

    return apiCall(
      `${ecmPrefix}${baseUrl}${extendUrl}public/ecm-events?Domain=${domainId}${paginationParams}`,
      'GET'
    );
  },
  createXml: ({domainId, userId, ecmId}) => {
    if (shouldUseMock()) {
      return generateXmlMock()
    }
    return apiCall(
      `${ecmPrefix}${baseUrl}${extendUrl}public/ecm-events?Domain=${domainId}&ecm_id=${ecmId}`,
      'POST'
    );
  },
};
