import {
    getEcmCreditiAcquisitiMock, getEcmMaxCrediti, getSpecializationsByPackageEcmCredits, shouldUseMock
} from './mock';
import {apiCall, baseUrl, ecmPrefix, extendUrl} from "./config";

export default {
    getEcmCreditiAcquisiti: userId => {
        if (shouldUseMock()) {
            return getEcmCreditiAcquisitiMock();
        }

        return apiCall(
            `${ecmPrefix}${baseUrl}${extendUrl}public/ecm-credits?user_event_id=${userId}`,
            'GET'
        );
    },
    getSpecializationsByPackageEcmCredits: ({productIds}) => {
        if (shouldUseMock()) {
            return getSpecializationsByPackageEcmCredits();
        }

        return apiCall(
            `${baseUrl}${extendUrl}professions${(productIds && (productIds.length > 0)) ? '?productIds='+productIds.join(',') : ''}`,
            'GET',null, {isDrupal: true}
        );
    },
    getEcmMaxCrediti: productIds => {
        if (shouldUseMock()) {
            return getEcmMaxCrediti();
        }

        return apiCall(
            `${ecmPrefix}${baseUrl}${extendUrl}public/ecm-product-credits${(productIds && (productIds.length > 0)) ? '?product_ids='+productIds.join(',') : ''}`,
            'GET'
        );
    }
};
