import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import routes from 'routes';
import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';

import Button from 'ui/atoms/Button';
import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import {Title, ContentWrapper, Icon, ModalGlobalContainer, Header, ModalBody} from './style';

import { ModalWrapper } from '../InviteFriendModal/style';
import {HtmlWrapper} from "../../../../pages/CollectionDetails/style";
import {secsToTime} from "../../../../utils/duration-format";
import {IconPlay} from "../../BookmarksBox/style";


const modalId = 'homepage/InfoModal';

const InfoModal = ({payload, onClose}) => {
    return <Modal id={modalId}>
        <Container>
            <ModalWrapper>
                <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
                    <ContentWrapper>
                        <Header>
                            <Title>
                                {payload.title}
                            </Title>
                            <div className={"info"}>
                                <div className={"duration"}><IconPlay className="icon-play" /><span>{secsToTime(payload.totalDurationSec||0)}</span></div>
                                {payload.numberOfAsset > 1 && <div className={"numberOfAsset"}>{payload.numberOfAsset + ' moduli'}</div>}
                            </div>

                            <Icon className="icon-close-x" onClick={onClose} />
                        </Header>
                        <ModalBody>
                            <HtmlWrapper
                                dangerouslySetInnerHTML={{ __html: payload.description }}/>
                        </ModalBody>
                    </ContentWrapper>
                </ModalGlobalContainer>
            </ModalWrapper>
        </Container>
    </Modal>
};

InfoModal.propTypes = {
    description: PropTypes.string,
    // HOC (connect, dispatch)
    onClose: PropTypes.func.isRequired
};

InfoModal.id = modalId;

export default connect(
    state => ({
        payload: state.modal.payload
    }),
    dispatch => ({
        onClose: () => dispatch({ type: MODAL_CLOSE })
    })
)(InfoModal);
