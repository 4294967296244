import styled from 'styled-components/macro';
import { colorOpacity } from 'utils/common';

export const Background = styled.div`
  background-color: ${props => props.theme.colors.mineShaft};
  z-index: -10;
`;

export const LandingPageContainer = styled.div`
  height: fit-content;
  min-height: 90vh;
  background-color: ${props =>
    (props?.bgColor ? props.bgColor : props.theme.colors.transparent)};
  display: flex;
  flex-direction: ${props => (props.mediaIsLeft ? 'row-reverse' : 'row')};
  align-items: center;
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top right;


  @media ${props => props.theme.device.tablet} {
    height: initial;
    min-height: 800px;
    align-items: flex-end;
  }

  @media ${props => props.theme.device.mobile} {
    height: initial;
    min-height: 90vh;
    background-image: none;
    flex-wrap: wrap;
    align-items: center;
  }
`;

export const Accedi = styled.div`
  cursor: pointer;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.primary.bandColor3};
  border-left: 1px solid ${props => props.theme.colors.white};
  border-right: 1px solid ${props => props.theme.colors.white};
  height: 100%;
  width: 101px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const IconLock = styled.div`
  font-size: 18px;
  color: ${props => props.theme.colors.white};
  margin-bottom: 4px;
`;

export const Label = styled.div`
  padding-bottom: 6px;
  word-break: break-word;
  color: ${props => props.theme.colors.darkCharcoal};
`;

export const TitleWrapper = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  padding-bottom: 14px;
  padding-top: 30px;
  word-break: break-word;

  color: ${props => props.theme.colors.primary.bandColor3};

  @media ${props => props.theme.device.mobile} {
    padding-bottom: 5px;
  }
`;

export const OverTitle = styled.div`
  padding-bottom: 16px;

  color: ${props => props.theme.colors.mineShaft};

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 12px;
  }
  @media ${props => props.theme.device.mobile} {
    padding-bottom: 5px;
  }
`;

export const SubTitle = styled.div`
  padding-bottom: 16px;

  color: ${props => props.theme.colors.primary.base};

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 12px;
  }
  @media ${props => props.theme.device.mobile} {
    padding-bottom: 5px;
  }
`;

export const Text = styled.div`
  padding-bottom: 48px;
  padding-right: 60px;
  color: ${props => props.theme.colors.red};

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 27px;
  }
  @media ${props => props.theme.device.mobile} {
    padding-right: 0;
    padding-bottom: 23px;
  }
`;

export const Buttons = styled.div`
  display: inline-flex;
  @media ${props => props.theme.device.mobile} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const ButtonWrapperStore = styled.div`
  min-width: 170px;
  margin-right: 25px;

  @media ${props => props.theme.device.laptop} {
    min-width: 220px;
    margin-right: 24px;
  }
  @media ${props => props.theme.device.tablet} {
    min-width: 229px;
    margin-right: 15px;
  }
  @media ${props => props.theme.device.mobile} {
    min-width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  min-width: 180px;
  margin-right: 20px;
  @media ${props => props.theme.device.tablet} {
    width: 233px;
    margin-bottom: 15px;
    margin-right: 0;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.mediaIsLeft ? 'row-reverse' : 'row')};
`;

export const Video = styled.div`
  width: 400px;
  height: 100px;
  background-color: yellow;
`;

export const TextBox = styled.div`
  max-width: 50%;
  padding: 50px;
  background-color: ${props =>
    (props?.bgColor ? props.bgColor : props.theme.colors.transparent)};
`;

export const VideoBox = styled.div`
  max-width: 50%;
  width: 50%;
  height: 340px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Body = styled.div`
  width: 100%;
  padding: 25px 0;

  @media ${props => props.theme.device.mobile} {
    order: 2;
  }
`;

export const BodyContainer = styled.div`
  width: 43%;
  height: 100%;

  @media ${props => props.theme.device.mobile} {
    margin-top: 50%;
    width: 100%;
    padding: 100px 30px 0;
  }
  @media ${props => props.theme.device.tablet} {
    width: 100%;
  }
`;

export const ImageWrapper = styled.div`
  height: fit-content;
  width: 100%;
  height: 90vh;
  position: absolute;
  right: ${props => (props.mediaIsLeft ? 'auto' : 0)};
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media ${props => props.theme.device.laptop} {
    width: 53%;
  }
  @media ${props => props.theme.device.tablet} {
    width: 53%;
  }
  @media ${props => props.theme.device.mobile} {
    position: absolute;
    top: 70px;
    margin: 0 auto;
    width: 600px;
    /* width: 100%; */
    /* max-width: 576px; */
    order: 1;
    height: initial;
  }
`;

export const ImageAppWrapper = styled.div``;

export const Image = styled.img`
  position: relative;
  top: 0;
  right: 0;

  max-width: 100%;
  height: auto;
  max-height: 90vh;
  z-index: 0;
`;
export const ImageHp = styled.img`
  width:100%
`;

export const ImageLeft = styled(Image)`
  left: 0;
  right: initial;
`;

export const HeadWrapper = styled.div`
  width: 100%;
  height: 70px;
  background-color: ${props => props.theme.colors.darkCharcoalHeader};
  z-index: 2;
  position: fixed;
  top: 0;
`;

export const Head = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Logo = styled.div`
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  height: 40px;
  cursor: pointer;
  width: auto;
  min-width: 200px;
  max-width: 260px;
  &.no-pointer {
    cursor: default;
  }
`;

export const ContainerWrapper = styled.div`
  background-color: white;
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
`;

export const RowStripe = styled.div`
  position: relative;
  overflow: hidden;
`;

export const BodyContainerRight = styled(BodyContainer)`
  justify-content: flex-end;
`;

export const PreTitle = styled.div`
  padding-bottom: 60px;

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 12px;
  }
  @media ${props => props.theme.device.mobile} {
    padding-bottom: 5px;
  }
`;

export const TitleWrapperRight = styled.div`
  width: 40%;
  @media ${props => props.theme.device.laptop} {
    width: 60%;
  }
  @media ${props => props.theme.device.tablet} {
    width: 64%;
  }
  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const HtmlWrapper = styled.div`
  width: 100%;
  min-height: min-content;
  &.text-white *{
    color:white
  }
  & ul {
    list-style: none;
  }

  & li {
    display: flex;
    margin-bottom: 10px;
  }

  & a:link,
  a:visited {
    color: ${props => props.theme.colors.primary.base};
  }
  & a:hover,
  a:active {
    text-decoration: underline;
  }
`;

export const CtasWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  display: inline-flex;
  justify-content: flex-start;
  button{
    max-width: 100%;
  }
  @media ${props => props.theme.device.tablet} {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const Placeholder = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center center;
`;

export const Layout = styled.div`
  background-image: linear-gradient(
    -45deg,
    rgba(187, 58, 54, 0.8),
    rgba(234, 129, 102, 0.4)
  );
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const VideoTitle = styled.div`
  color: ${props => props.theme.colors.white};
  text-align: center;
  width: 500px;

  @media ${props => props.theme.device.tablet} {
    width: 300px;
  }
`;

export const VideoSubtitle = styled(VideoTitle)`
  margin-bottom: 54px;
`;

export const IconPlay = styled.div`
  font-size: 60px;
  color: ${props => props.theme.colors.white};
  width: 100px;
  height: 100px;
  padding: 20px 25px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  margin: 46px 0;
`;

export const LandingCard = styled.div`
  width: 300px;
  height: 360px;
  background-image: url(${props => props.image});
  background-size: cover;
  transition-delay: 250ms;
`;

export const Overlay = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${props => colorOpacity(props.theme.colors.darkCharcoal, 0.6)};
  padding: 28px;
`;

export const CardTitle = styled.div`
  margin-top: 60%;
  margin-bottom: 10px;

  &.top {
    margin-top: 0;
  }
`;

export const CardBody = styled.div``;

export const CoursesContainer = styled.div`
  overflow: visible;
`;

export const CourseWrapper = styled.div`
  padding: 7px 0;
  border-bottom: 1px solid ${props => props.theme.colors.white};
`;

export const Duration = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const IconPlaySmall = styled.div`
  width: 14px;
  height: 14px;
  line-height: 14px;
  border-radius: 14px;
  font-size: 10px;
  text-align: center;
  margin-right: 4px;
  background-color: ${props => props.theme.colors.white};
  opacity: 0.8;
`;

export const DotsWrapper = styled.div`
  margin-top: 15px;
  display: flex;
`;

export const Dot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 5px;
  margin-right: 3px;
  opacity: 0.8;
  background-color: ${props => props.theme.colors.white};
`;

export const BackgroungImg = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 70vh;
  padding: 44px 100px;
  background-image: url(${props => props.image});
  background-size: cover;
  display: flex;
  justify-content: ${props => (props.tbIsLeft ? 'flex-start' : 'flex-end')};
`;

export const TextBoxImg = styled.div`
  max-width: 380px;
  /* height: fit-content; */
  padding: 40px 58px 40px 30px;
  background-color: ${props => props.theme.colors.white};
  height: 350px;

  @media ${props => props.theme.device.mobile} {
    max-width: 100%;
  }
`;
