import { takeLatest, put, call, select } from 'redux-saga/effects';

import { LOADER_CLOSE, LOADER_OPEN, MODAL_OPEN } from 'redux/actions';
import {
  GET_PATH,
  GET_PLAYLIST,
  GET_COURSE,
  GET_RELATED,
  ADD_SURVEY,
  GET_QUESTIONS,
  ADD_REVIEW,
  SET_RECOMMENDED,
  GET_CATEGORIES_USER,
  GET_KEEP_WATCHING,
  GET_PACKAGES,
  GET_PACKAGE,
  GET_EVENTS,
  GET_EVENT,
  GET_SELECTIONS,
  GET_COLLECTIONS,
  GET_RECOMMENDED_COLLECTIONS,
  GET_ALL_CATEGORIES,
  GET_SKILLROOM,
  GET_CORSI_ECM,
  GET_COURSES_EVENTS,
  GET_EXPERT,
  GET_DR_SIM,
  GET_SPONSORS,
  UPDATE_SPONSORS,
  GET_SKILLROM_PACKAGES,
  GET_CORSI_ECM_PACKAGES,
  GET_CATEGORIES_PILLS,
  GET_CATEGORY_PLAYLIST,
  GET_PACKAGE_ASSET_DESCRIPTION
} from 'redux/actions/playlist';
import { SET_COURSE_FIVE_MINUTE } from 'redux/actions/fiveMinutes';

import { channel } from 'api/config';
import playlist from 'api/playlist';
import onBoarding from 'api/onBoarding';

import ModalErrors from 'ui/components/Modals/ModalErrors';
import InfoModal from 'ui/components/Modals/InfoModal';
import SuccessModal from "../../ui/components/Modals/SuccessModal";

function* getCards({ forceCall = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: usrId } = {} } = yield select(
      state => state.authentication
    );
    const {
      playlists: { yourpathprogress: youroldpath }
    } = yield select(state => state.playlist);
    if (
      !forceCall &&
      (youroldpath.collection.items.length > 0 ||
        youroldpath.selection.items.length > 0)
    ) {
      yield put({ type: GET_PATH._SUCCESS, yourpathprogress: youroldpath });
    } else {
      const {
        data: { yourpathprogress },
        status
      } = yield call(playlist.pathSelection, usrId);
      if (status === 200) {
        yield put({ type: GET_PATH._SUCCESS, yourpathprogress });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getCards', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCardsWatch() {
  yield takeLatest(GET_PATH._REQUEST, getCards);
}

function* getPlaylist({ id, locked = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const {
      data: { response },
      status
    } = yield call(playlist.getPlaylist, { userId, id, locked });
    if (status === 200) {
      yield put({
        type: GET_PLAYLIST._SUCCESS,
        data: response
      });
    };
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getPlaylist', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getPlaylistWatch() {
  yield takeLatest(GET_PLAYLIST._REQUEST, getPlaylist);
}

function* getCourse({ id: itemId, isFiveMinute }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { data } = yield call(playlist.getCourse, { itemId, userId });
    if (isFiveMinute) {
      yield put({
        type: SET_COURSE_FIVE_MINUTE,
        data
      });
    } else {
      yield put({
        type: GET_COURSE._SUCCESS,
        data
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getCourse', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCourseWatch() {
  yield takeLatest(GET_COURSE._REQUEST, getCourse);
}

function* getRelated({ needId, itemId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const {
      data: { itemscompleted, itemsuncompleted, itemsuntracked }
    } = yield call(playlist.getRelated, {
      userId,
      needId,
      itemId
    });
    yield put({
      type: GET_RELATED._SUCCESS,
      data: { itemscompleted, itemsuncompleted, itemsuntracked }
    });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getRelated', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getRelatedWatch() {
  yield takeLatest(GET_RELATED._REQUEST, getRelated);
}

function* getQuestions({ surveyId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data } = yield call(playlist.getQuestions, surveyId);
    yield put({
      type: GET_QUESTIONS._SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message }
    });
    // eslint-disable-next-line no-console
    console.log('sagas > playlist > getQuestions', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getQuestionsWatch() {
  yield takeLatest(GET_QUESTIONS._REQUEST, getQuestions);
}

function* addSurvey({ payload: { body, playlistId, packageId, surveyId, type } }) {
  try {
    yield put({ type: LOADER_OPEN });
    // const { user: { userId } = {} } = yield select(
      //   state => state.authentication
    // );
    // body.userId = userId;
    const { data, status } = yield call(
      playlist.addSurvey,
      playlistId,
      surveyId,
      body
    );
    if (status === 200 || data.resultcode.toString() === '200') {
      yield put({
        type: ADD_SURVEY._SUCCESS,
        data
      });
      if(type !== 'package'){
        yield put({ type: GET_PLAYLIST._REQUEST, id: playlistId });
      } else {
        yield put({ type: GET_PACKAGE._REQUEST, itemId: packageId });
        yield put({
          type: MODAL_OPEN,
          id: SuccessModal.id,
          payload: {
            description:
              'Survey inviata con successo.',
          },
        });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > addSurvey', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* addSurveyWatch() {
  yield takeLatest(ADD_SURVEY._REQUEST, addSurvey);
}

function* addReview({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    // const {
      //   user: { userId: usrId, organizationId: orgId } = {}
    // } = yield select(state => state.authentication);
    // payload.owner = usrId;
    // payload.orgId = orgId;
    const { status } = yield call(playlist.addReview, payload);
    if (status?.toString() === '200') {
      yield put({
        type: ADD_REVIEW._SUCCESS
      });
      yield put({
        type: MODAL_OPEN,
        id: SuccessModal.id,
        payload: {
          description:
              'Recensione inviata con successo',
        },
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > addReview', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* addReviewWatch() {
  yield takeLatest(ADD_REVIEW._REQUEST, addReview);
}

function* setRecommended({ id }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId: usrId } = {} } = yield select(
      state => state.authentication
    );
    const body = {
      //userId: usrId,
      collId: id,
      channel
    };
    const { status } = yield call(playlist.setRecommended, body);
    if (status?.toString() === '200') {
      yield put({
        type: SET_RECOMMENDED._SUCCESS
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > setRecommended', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* setRecommendedWatch() {
  yield takeLatest(SET_RECOMMENDED._REQUEST, setRecommended);
}

function* getCategoriesUser() {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      user: { userId }
    } = yield select(state => state.authentication);
    const { status, data } = yield call(onBoarding.getOnBoarding, { userId });
    if (status === 200 || data.resultcode?.toString() === '200') {
      yield put({
        type: GET_CATEGORIES_USER._SUCCESS,
        data
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getCategories', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCategoriesUserWatch() {
  yield takeLatest(GET_CATEGORIES_USER._REQUEST, getCategoriesUser);
}

function* getKeepWatching() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { data, status } = yield call(playlist.getKeepWatching, {
      userId
    });
    if (status?.toString() === '200') {
      yield put({
        type: GET_KEEP_WATCHING._SUCCESS,
        data
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getKeepWatching', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getKeepWatchingWatch() {
  yield takeLatest(GET_KEEP_WATCHING._REQUEST, getKeepWatching);
}

function* getPackages() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const {
      data: allPackages,
      status
    } = yield call(playlist.getPackages, { userId });
    if (status === 200) {
      yield put({ type: GET_PACKAGES._SUCCESS, allPackages });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getPackages', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getPackagesWatch() {
  yield takeLatest(GET_PACKAGES._REQUEST, getPackages);
}

function* getPackage({ itemId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const {
      data: thisPackage,
      status
    } = yield call(playlist.getPackage, { itemId, userId });
    if (status === 200) {
      yield put({ type: GET_PACKAGE._SUCCESS, package: thisPackage });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlists > getPackage', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getPackageWatch() {
  yield takeLatest(GET_PACKAGE._REQUEST, getPackage);
}

function* getEvents({ startDate, endDate }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const {
      data: events,
      status
    } = yield call(playlist.getEventsByDate, { userId, startDate, endDate });
    if (status === 200) {
      yield put({ type: GET_EVENTS._SUCCESS, events });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getEvents', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getEventsWatch() {
  yield takeLatest(GET_EVENTS._REQUEST, getEvents);
}

function* getEvent({ itemId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const {
      data: event,
      status
    } = yield call(playlist.getEvent, { userId, itemId });
    if (status === 200) {
      yield put({ type: GET_EVENT._SUCCESS, event });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getEvent', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getEventWatch() {
  yield takeLatest(GET_EVENT._REQUEST, getEvent);
}

function* getSelections() {
  try {
    yield put({ type: LOADER_OPEN });
    const { title: appName } = yield select(
      state => state.domain
    );
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { nextPage, previousPage } = yield select(
      state => state.playlist.selections
    );
    if ((nextPage !== previousPage && !!nextPage) || !nextPage) {
    const {
        data: { asset, nextpage },
      status
      } = yield call(playlist.getSelections, { appName, userId, nextPage, itemsPerPage: 8 });
    if (status === 200) {
        yield put({ type: GET_SELECTIONS._SUCCESS, asset, nextPage: nextpage});
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getSelections', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getSelectionsWatch() {
  yield takeLatest(GET_SELECTIONS._REQUEST, getSelections);
}

function* getCollections() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { nextPage, previousPage } = yield select(
      state => state.playlist.collections
    );
    if ((nextPage !== previousPage && !!nextPage) || !nextPage) {
    const {
        data: { asset, nextpage },
      status
      } = yield call(playlist.getCollections, { assetFilter: 'BY_JOB', userId, nextPage, itemsPerPage: 8 });
    if (status === 200) {
        yield put({ type: GET_COLLECTIONS._SUCCESS, assetFilter: 'BY_JOB', asset, nextPage: nextpage });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getCollections', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCollectionsWatch() {
  yield takeLatest(GET_COLLECTIONS._REQUEST, getCollections);
}

function* getRecommendedCollections() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { nextPage, previousPage } = yield select(
      state => state.playlist.recommendedCollections
    );
    if ((nextPage !== previousPage && !!nextPage) || !nextPage) {
    const {
        data: { asset, nextpage },
      status
      } = yield call(playlist.getCollections, { assetFilter: 'CROSS_CONTENT', userId, nextPage, itemsPerPage: 8 });
    if (status === 200) {
        yield put({ type: GET_COLLECTIONS._SUCCESS, assetFilter: 'CROSS_CONTENT', asset, nextPage: nextpage });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getCollections', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getRecommendedCollectionsWatch() {
  yield takeLatest(GET_RECOMMENDED_COLLECTIONS._REQUEST, getRecommendedCollections);
}

function* getCategoriesPromotedItems() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { nextPage, previousPage } = yield select(
      state => state.playlist.evidence
    );
    if ((nextPage !== previousPage && !!nextPage) || !nextPage) {
    const {
        data: { asset, nextpage },
      status
      } = yield call(playlist.getCategoriesPromotedItems, { filterType: 'PROMOTED', filterValue:true, userId, nextPage, itemsPerPage: 8 });
    if (status === 200) {
        yield put({ type: GET_ALL_CATEGORIES._SUCCESS, filterValue: false, asset, nextPage: nextpage });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getCategoriesPromotedItems', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getEvidenceItemsWatch() {
  yield takeLatest(GET_ALL_CATEGORIES._REQUEST, getCategoriesPromotedItems);
}

function* getSkillroomItems() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { nextPage, previousPage } = yield select(
      state => state.playlist.skillroom
    );
    const domainId = yield select(
        state => state.domain.id
    );
    const filterValue = domainId === 3581 ? '239' : '176';
    if ((nextPage !== previousPage && !!nextPage) || !nextPage) {
    const {
        data: { asset, nextpage },
      status
      } = yield call(playlist.getCategoriesPromotedItems, { filterType: 'CAROUSEL', filterValue: filterValue, userId, nextPage, itemsPerPage: 8 });
    if (status === 200) {
        yield put({ type: GET_ALL_CATEGORIES._SUCCESS, filterValue: filterValue, asset, nextPage: nextpage });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getSkillroomItems', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getSkillroomItemsWatch() {
  yield takeLatest(GET_SKILLROOM._REQUEST, getSkillroomItems);
}

function* getCorsiEcmItems() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
        state => state.authentication
    );
    const { nextPage, previousPage } = yield select(
        state => state.playlist.corsi_ecm
    );
    const domainId = yield select(
        state => state.domain.id
    );
    const filterValue = domainId === 3581 ? '511' : '387';
    if ((nextPage !== previousPage && !!nextPage) || !nextPage) {
      const {
        data: { asset, nextpage },
        status
      } = yield call(playlist.getCategoriesPromotedItems, { filterType: 'CAROUSEL', filterValue: filterValue, userId, nextPage, itemsPerPage: 8 });
      if (status === 200) {
        yield put({ type: GET_ALL_CATEGORIES._SUCCESS, filterValue: filterValue, asset, nextPage: nextpage });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getCorsiEcmItems', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCorsiEcmItemsWatch() {
  yield takeLatest(GET_CORSI_ECM._REQUEST, getCorsiEcmItems);
}

function* getCoursesEventsItems() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { nextPage, previousPage } = yield select(
      state => state.playlist.coursesEvents
    );
    const domainId = yield select(
      state => state.domain.id
    );
    const filterValue = domainId === 3581 ? '241' : '177'

    if ((nextPage !== previousPage && !!nextPage) || !nextPage) {
    const {
        data: { asset, nextpage },
      status
      } = yield call(playlist.getCategoriesPromotedItems, { filterType: 'CAROUSEL', filterValue: filterValue, userId, nextPage, itemsPerPage: 8 });
    if (status === 200) {
        yield put({ type: GET_ALL_CATEGORIES._SUCCESS, filterValue: filterValue, asset, nextPage: nextpage });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getCoursesEventsItems', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCoursesEventsItemsWatch() {
  yield takeLatest(GET_COURSES_EVENTS._REQUEST, getCoursesEventsItems);
}

function* getExpertItems() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { nextPage, previousPage } = yield select(
      state => state.playlist.expert
    );
    const domainId = yield select(
      state => state.domain.id
    );
    const filterValue = domainId === 3581 ? '240' : '175';

    if ((nextPage !== previousPage && !!nextPage) || !nextPage) {
    const {
        data: { asset, nextpage },
      status
      } = yield call(playlist.getCategoriesPromotedItems, { filterType: 'CAROUSEL', filterValue: filterValue, userId, nextPage, itemsPerPage: 8 });
    if (status === 200) {
        yield put({ type: GET_ALL_CATEGORIES._SUCCESS, filterValue: filterValue, asset, nextPage: nextpage });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getExpertItems', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getExpertItemsWatch() {
  yield takeLatest(GET_EXPERT._REQUEST, getExpertItems);
}

function* getDrSimItems() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );

    const domainId = yield select(
      state => state.domain.id
    );
    const filterValue = domainId === 3581 ? '242' : '228'

    const { nextPage, previousPage } = yield select(
      state => state.playlist.drSim
    );
    if ((nextPage !== previousPage && !!nextPage) || !nextPage) {
    const {
        data: { asset, nextpage },
      status
      } = yield call(playlist.getCategoriesPromotedItems, { filterType: 'CAROUSEL', filterValue: filterValue, userId, nextPage, itemsPerPage: 8 });
    if (status === 200) {
        yield put({ type: GET_ALL_CATEGORIES._SUCCESS, filterValue: filterValue, asset, nextPage: nextpage });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getDrSimItems', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getDrSimItemsWatch() {
  yield takeLatest(GET_DR_SIM._REQUEST, getDrSimItems);
}

function* getSponsorsList({itemId, ecmEditionCode, ecmEventCode, participationCode}) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const {
      data,
      status
    } = yield call(playlist.getSponsors, { userId, itemId, ecmEditionCode, ecmEventCode, participationCode });
    if (status === 200) {
      yield put({ type: GET_SPONSORS._SUCCESS, data });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getSponsors', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getSponsorsListWatch() {
  yield takeLatest(GET_SPONSORS._REQUEST, getSponsorsList);
}

function* updateSponsors({ payload }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { id: domainId } = yield select(
      state => state.domain
    );
    const { status } = yield call(playlist.updateSponsors, domainId, payload);
    if (status === 200) {
      yield put({
        type: UPDATE_SPONSORS._SUCCESS
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > updateSponsors', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* updateSponsorsWatch() {
  yield takeLatest(UPDATE_SPONSORS._REQUEST, updateSponsors);
}

function* getSkillroomPackages() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const domainId = yield select(
      state => state.domain.id
    );
    const filterValue = domainId === 3581 ? '254' : '295';
    const {
      data: { packagelist },
      status
      } = yield call(playlist.getSkillroomPackages, { userId, filterValue: filterValue });
    if (status === 200) {
      yield put({ type: GET_SKILLROM_PACKAGES._SUCCESS, packageList: packagelist, filterValue: filterValue});
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getSkillroomPackages', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getSkillroomPackagesWatch() {
  yield takeLatest(GET_SKILLROM_PACKAGES._REQUEST, getSkillroomPackages);
}

function* getCorsiEcmPackages() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const domainId = yield select(
      state => state.domain.id
    );
    const filterValue = domainId === 3581 ? '512' : '386';
    const {
      data: { packagelist },
      status
      } = yield call(playlist.getCorsiEcmPackages, { userId, filterValue: filterValue });
    if (status === 200) {
      yield put({ type: GET_CORSI_ECM_PACKAGES._SUCCESS, packageList: packagelist, filterValue: filterValue});
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getCorsiEcmPackages', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCorsiEcmPackagesWatch() {
  yield takeLatest(GET_CORSI_ECM_PACKAGES._REQUEST, getCorsiEcmPackages);
}

function* getCategoriesPills() {
  try {
    yield put({ type: LOADER_OPEN });
    const { status, data } = yield call(playlist.getCategoriesPills);
    if (status === 200 || data.resultcode?.toString() === '200') {
      yield put({
        type: GET_CATEGORIES_PILLS._SUCCESS,
        data
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getCategoriesPills', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCategoriesPillsWatch() {
  yield takeLatest(GET_CATEGORIES_PILLS._REQUEST, getCategoriesPills);
}

function* getCategoryPlaylist({filterId}) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const { nextPage, previousPage } = yield select(
      state => state.playlist.categoryPlaylist
    );
    if ((nextPage !== previousPage && !!nextPage) || !nextPage) {
      const {
        data: {catalog, nextpage},
        status
      } = yield call(playlist.getCategoryPlaylist, { userId, filterId, nextPage, itemsPerPage: 8 });
      if (status === 200) {
        yield put({ type: GET_CATEGORY_PLAYLIST._SUCCESS, catalog, nextPage: nextpage });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getCategory', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCategoryPlaylistWatch() {
  yield takeLatest(GET_CATEGORY_PLAYLIST._REQUEST, getCategoryPlaylist);
}

function* getPackageAssetDescriptionWatch() {
  yield takeLatest(GET_PACKAGE_ASSET_DESCRIPTION._REQUEST, getPackageAssetDescription);
}

function* getPackageAssetDescription({payload}){
  const {title, totalDurationSec, totalDuration, numberOfAsset, itemType, packageId, assetId} = payload;
  try {
    yield put({ type: LOADER_OPEN });

    const { status, data } = yield call(playlist.getPackageAssetsDescription, {itemType, packageId, assetId});
    if (status === 200 || data.resultCode?.toString() === '200') {
      if (data.errorManagement){
        yield put({
          type: MODAL_OPEN,
          id: ModalErrors.id,
          payload: {
            errorText: data.errorManagement
          }
        });
      }else{
        yield put({ type: MODAL_OPEN, id: InfoModal.id, payload: { title: title, totalDurationSec: (totalDurationSec || totalDuration), numberOfAsset:numberOfAsset, description: data.description || ""} });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento'
      }
    }); // eslint-disable-next-line no-console
    console.log('sagas > playlist > getPackageAssetDescription', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

export default [
  getCardsWatch(),
  getPlaylistWatch(),
  getCourseWatch(),
  getRelatedWatch(),
  getQuestionsWatch(),
  addSurveyWatch(),
  addReviewWatch(),
  setRecommendedWatch(),
  getCategoriesUserWatch(),
  getKeepWatchingWatch(),
  getPackagesWatch(),
  getPackageWatch(),
  getEventsWatch(),
  getEventWatch(),
  getSelectionsWatch(),
  getCollectionsWatch(),
  getRecommendedCollectionsWatch(),
  getEvidenceItemsWatch(),
  getCoursesEventsItemsWatch(),
  getSkillroomItemsWatch(),
  getCorsiEcmItemsWatch(),
  getExpertItemsWatch(),
  getDrSimItemsWatch(),
  getSponsorsListWatch(),
  updateSponsorsWatch(),
  getSkillroomPackagesWatch(),
  getCorsiEcmPackagesWatch(),
  getCategoriesPillsWatch(),
  getCategoryPlaylistWatch(),
  getPackageAssetDescriptionWatch()
];
