import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { SubscriptionsInterface } from 'interface/subscriptions';
import {
  Content,
  Labels,
} from 'pages/MySubscriptions/sections/Subscriptions/ActiveSubscriptions/style';
import { Col, Row } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import { connect } from 'react-redux';
import EmptyCard from './EmptyCard';
import {GET_ECM_MAX_CREDITI_ACQUISITIBILI} from "../../../redux/actions/ecm";

export const mappingVariations = variat => ({
  label: !!variat.bundleName ? variat.bundleName : variat.productName || variat.subscriptionNumber,
  value: variat.variationId,
  packageType: variat.packageType,
  productId: variat.productId,
  bundleCode: variat.bundleCode,
  bundleName: variat.bundleName,
  bundleId: variat.bundleId,
  quantity: variat.quantity
});

export const removeDuplicates = (array, key) => [...new Map(array.map(x => [key(x), x])).values()];

const NewSubscriptions = ({
  handleOnChangeEmail,
  getFilteredArray,
  variations,
  subscriptions,
  calendars,
  handleOnChangeVariation,
  handleOnChangeRole,
  handleOnChangeEcmCredits,
  handleOnChangeFinalTestEnabled,
  handleSelectCalendar,
  all_prospect,
  assoc_subscriptions,
  personaFisica,
  getEcmMaxCrediti,
  ecmMaxCrediti
  }) => {
  const [options, setOptions] = useState([]);
  const [optionsBundle, setOptionsBundle] = useState([]);
  const [parsedProspects, setParsedProspects] = useState([]);
  const [subscriptionsGroupedBundle, setSubscriptionsGroupedBundle] = useState([]);
  const [fixedVariations] = useState(variations);
  const [multipleBunde, setIsMultipleBundle] = useState(false);

  useEffect(() => {
    setParsedProspects(
      Object.values(all_prospect || {})
        .reduce((arr, val) => [...arr, ...(val || [])], [])
        .map(pr => ({
          ...pr,
          offerts: pr.offerts.map(off => {
            const sub = assoc_subscriptions?.orders.find(
              s => s.variationId.toString() === off.variationId.toString()
            );
            return {
              variationId: sub?.variationId || '',
              status: off.status,
            };
          }),
        }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_prospect, assoc_subscriptions]);

  useEffect(() => {
    const newOptions = variations.map(mappingVariations);
    //setOptions(removeDuplicates(newOptions, it => it.value));
    setOptions(newOptions);
  }, [variations]);

  useEffect(() => {
    setOptionsBundle(removeDuplicates(options, it => it.bundleId))
  }, [options]);

  useEffect(() => {
    const subscriptionWithBundleCode = getFilteredArray(subscriptions.filter(sub => !!sub.bundleId));
    const subscriptionsGroupedBundles = subscriptionWithBundleCode.reduce((acc, curr) => {
      const { bundleId } = curr;
      const objInAcc = acc.find((o) => o.bundle === bundleId);
      if (objInAcc) objInAcc.data.push(curr);
      else acc.push({ bundle: bundleId, data: [curr] });
      return acc;
    }, []);
    const isMultipleBundle = subscriptionsGroupedBundles.length > 1;
    setIsMultipleBundle(isMultipleBundle);

    setSubscriptionsGroupedBundle(subscriptionsGroupedBundles);
    //const isPopulatedBundle = !subscriptionsGroupedBundles.find(bundle => !bundle.data[0].variations.length);
    const isPopulatedBundle = !subscriptionsGroupedBundles.find(bundle => bundle.data.filter(data => !!data.variations.length).length !== bundle.data[0].quantity) ||
      !subscriptionsGroupedBundles.find(bundle => bundle.data.find(data => !data.variations[0]));

    if (!!isPopulatedBundle || (!isPopulatedBundle && isMultipleBundle)) {
            subscriptionsGroupedBundles.forEach((bundle, b) => {
        bundle.data.forEach((dataItem, d) => {
          if(!dataItem.email || (!!personaFisica && !dataItem.variations.length)){
            bundle.data.forEach(bundeDataEl => {
              if(bundeDataEl.variations.length === 0){
                bundeDataEl.variations.push(variations.find(elVar =>  elVar.productId === bundeDataEl.productId))
              }
            })


            bundle.data.forEach(bundeDataEl => {
              bundeDataEl.variations = null;
              bundeDataEl.variations = [fixedVariations.find(elVar =>  elVar.productId === bundeDataEl.productId &&
                elVar.bundleId === bundeDataEl.bundleId)]
            })
          }
            })
});

        const subBundlesRow = document.querySelectorAll('.sub-element');
        let subIdsList = [];

        subBundlesRow.forEach(row => {
          subIdsList = [
            ...subIdsList,
            row.id
          ]
        })

        subscriptionsGroupedBundles.forEach(subGrpBnd =>{
          //const countMailSet = subGrpBnd.data.filter((obj) => !!obj.email).length;
          //subGrpBnd.data.find(data => !subIdsList.includes(data.id)).email = undefined;

          subGrpBnd.data.forEach(element => {
            if(!subIdsList.includes(element.id)) {
              subGrpBnd.data.find(item => item.id === element.id).email = undefined
            }
          })

          const countMailSet = subGrpBnd.data.filter((obj) => !!obj.email).length;

          if(subGrpBnd.data[0].quantity === countMailSet){
          const mailList = subGrpBnd.data.map(s => s.email).filter(email=> email !== undefined);
          const productIdList = Array.from(new Set(subGrpBnd.data.map(s => s.productId)));
          mailList.forEach(m => {
            productIdList.forEach(p => {
              const i = subGrpBnd.data.findIndex(s => p === s.productId && !s.email && s.email !== m);
              if (i !== -1){
                subGrpBnd.data[i].email = m;
              }
            })
          })
        }
        })

      let subscriptionBundleList = [];
      subscriptionsGroupedBundles.forEach(group => {
        group.data.forEach(el => {
          subscriptionBundleList.push(el)
        })
      })
    }



  }, [subscriptions]);

  useEffect(() => {
    let _filteredArray = getFilteredArray(subscriptions.filter(sub => !sub.bundleId && sub.ecmFlag)).length && getFilteredArray(subscriptions.filter(sub => !sub.bundleId) || []);
    if (_filteredArray){
      let productIds = _filteredArray.map(_subscription => _subscription.productId);
      productIds && (productIds.length > 0) && getEcmMaxCrediti(productIds);
    }
  }, [getEcmMaxCrediti]);

  return (
    <>
      <Content>
        {/*{!!getFilteredArray(subscriptions.filter(sub => !sub.bundleId)).length && <Labels>
          <Row>
            <Col lg={2} md={2}>
              <ProDisplay type="tableLabel">Utenti</ProDisplay>
            </Col>
            <Col lg={2} md={2} className="d-none d-md-block">
              <ProDisplay type="tableLabel">Pacchetti</ProDisplay>
            </Col>
            <Col lg={2} md={2} className="d-none d-md-block">
              <ProDisplay type="tableLabel">Ruoli di partecipazione</ProDisplay>
            </Col>
            <Col lg={2} md={2} className="d-none d-md-block">
              <ProDisplay type="tableLabel">Calendario eventi live</ProDisplay>
            </Col>
            <Col lg={2} md={2} className="d-none d-md-block">
              <ProDisplay type="tableLabel">Crediti ECM</ProDisplay>
            </Col>
            <Col lg={2} md={2} className="d-none d-md-block">
              <ProDisplay type="tableLabel">Rendi partecipante</ProDisplay>
            </Col>
          </Row>
        </Labels>}*/}
        {!!getFilteredArray(subscriptions.filter(sub => !sub.bundleId)).length && getFilteredArray(subscriptions.filter(sub => !sub.bundleId) || []).map((item, index) => (
          <EmptyCard
            key={`subscription-${item.subscriptionId}${index}`}
            allSubscriptions={subscriptions}
            subscription={item}
            ecmMaxCrediti={ecmMaxCrediti?.find(product => product.productId === item.productId)?.productEcmCredits}
            email={item.email}
            handleOnChangeVariation={handleOnChangeVariation}
            handleOnChangeRole={handleOnChangeRole}
            handleOnChangeEmail={handleOnChangeEmail}
            handleOnChangeEcmCredits={handleOnChangeEcmCredits}
            handleOnChangeFinalTestEnabled={handleOnChangeFinalTestEnabled}
            handleSelectCalendar={handleSelectCalendar}
            options={options}
            calendars={calendars}
            parsedProspects={parsedProspects}
            isBundle={false}
          />
        ))}
        {/* {assoc_subscriptions?.nextpagepresent && (
          <LoadMore>
            <ProDisplay type="deleted" configuration={{ fontWeight: 600, fontSize: 16 }} onClick={() => getAssocSubscriptions(true)}>Mostra altro</ProDisplay>
          </LoadMore>
        )} */}
      </Content>

      {/* AGGREGATI */}
      <Content>
       {!!getFilteredArray(subscriptions.filter(sub => !!sub.bundleId)).length  && <Labels className='mt-5'>
          <Row>
            <Col lg={3} md={3}>
              <ProDisplay type="tableLabel">Utenti</ProDisplay>
            </Col>
            <Col lg={3} md={3} className="d-none d-md-block">
              <ProDisplay type="tableLabel">Abbonamento</ProDisplay>
            </Col>
            <Col lg={3} md={3} className="d-none d-md-block">
              <ProDisplay type="tableLabel">Ruoli di partecipazione</ProDisplay>
            </Col>
          </Row>
        </Labels>}
        {!!getFilteredArray(subscriptions.filter(sub => !!sub.bundleId)).length && subscriptionsGroupedBundle.map((bundle, index) => (
          <>
          {[...Array(bundle.data[0].quantity)].map((v, i) =>
            <EmptyCard
              key={`subscription-${bundle.bundleId}${index}`}
              allSubscriptions={subscriptionsGroupedBundle.map(bundle => bundle.data).flat()}
              //allSubscriptions={subscriptions}
              subscription={bundle.data[i]}
              email={bundle.data[i].email}
              handleOnChangeVariation={handleOnChangeVariation}
              handleOnChangeRole={handleOnChangeRole}
              handleOnChangeEmail={handleOnChangeEmail}
              handleSelectCalendar={handleSelectCalendar}
              handleIsBundle={true}
              options={optionsBundle}
              calendars={calendars}
              parsedProspects={parsedProspects}
              isBundle={true}
              isMultipleBundle={multipleBunde}
            />
          )}
        </>
        ))}
      </Content>
    </>
  );
};

NewSubscriptions.propTypes = {
  subscriptions: SubscriptionsInterface,
  handleOnChangeEmail: PropTypes.func,
  setBundleSubs: PropTypes.func,

  getFilteredArray: PropTypes.func,
  handleOnChangeVariation: PropTypes.func,
  handleOnChangeRole: PropTypes.func,
  handleOnChangeEcmCredits: PropTypes.func,
  handleOnChangeFinalTestEnabled: PropTypes.func,
  getEcmMaxCrediti: PropTypes.func.isRequired,

  variations: PropTypes.array,

  //HOC state
  all_prospect: PropTypes.object,
  assoc_subscriptions: PropTypes.object,
  personaFisica: PropTypes.bool,
  ecmMaxCrediti: PropTypes.array
};

export default connect(
  state => {
    return ({
      personaFisica: state.authentication.user.userDomainAttributes?.personaFisica,
        all_prospect: state.subscriptions.all_prospect,
        assoc_subscriptions: state.subscriptions.assoc_subscriptions,
      ecmMaxCrediti: state.ecm.ecmMaxCrediti
    })
  }, dispatch => ({
        getEcmMaxCrediti: productIds => dispatch( { type: GET_ECM_MAX_CREDITI_ACQUISITIBILI._REQUEST, productIds } )
    })
)(NewSubscriptions);
