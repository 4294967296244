import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import ProText from 'ui/typography/proText';
import ProDisplay from 'ui/typography/proDisplay';
import {
  GET_DOMAINS,
} from 'redux/actions/authentication';

import {
  BootCnt,
  Section4
} from './style';
import { HtmlWrapper } from 'pages/LandingPage/style';


const AccSection4 = ({props
}) => (
  <>
    <BootCnt>
      <Section4 className='container'>
        <div  className={ `row d-flex my-4 ${props?.image_position==='left' ? 'flex-row-reverse'  : ""}`}>
            <div className="col-12 col-md-6 my-4" data-aos="flip-up">
              <div className="card_custom shadow-lg">
                  <div className="row">
                    <ProDisplay
                    className={'px-3 title'}
                      configuration={{ fontSize: 64, fontWeight:500, lineHeight:1.2}}>
                        {props?.title}
                      </ProDisplay>
                    <div className='col-12 col-md-8 description'>
                    <ProDisplay
                      configuration={{ fontSize: 16, lineHeight: 1.5 }}>
                        <HtmlWrapper
                          dangerouslySetInnerHTML={{__html: props?.description}}
                        />
                      </ProDisplay>
                      </div>
                  </div>
              </div>
            </div>
            <div className="col-12 col-md-6 my-4" data-aos="flip-up">
              <div className="card_custom shadow-lg" id="how_to_do_img" style={{backgroundImage: 'url("'+ props?.image +'")'}}>
              </div>
          </div>
        </div>
      </Section4>
    </BootCnt>

  </>
);

AccSection4.propTypes = {
  // HOC withMediaQueries
  mediaIsDesktop: PropTypes.bool,
  mediaIsLaptop: PropTypes.bool,

  // HOC (connect, state)
  domains: PropTypes.array,

  // HOC (connect, dispatch)
  AccSection4: PropTypes.func,
};

export default connect(
  state => ({ domains: state.authentication.user.domainList }),
  dispatch => ({
    AccSection1: domain => dispatch({ type: GET_DOMAINS._REQUEST, domain }),
  })
)(withMediaQueries(AccSection4));
