import styled from 'styled-components/macro';

export const Iscriviti = styled.div`
    margin-bottom: 50px;
`

export const Button = styled.button`
  padding: 10px 25px;
  font-size: 25px;
  font-weight: bold;
  background-color: rgb(242, 40, 79);
  color: white;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
`