import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';
import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';
import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { ModalWrapper } from '../InviteFriendModal/style';
import { push } from 'redux-first-history';
import { HtmlWrapper } from 'pages/LandingPage/style';
import {
  Title,
  ContentWrapper,
  Icon,
  ProductInfo,
  TextContainer
} from './style';

const modalId = 'AggregatedProductsModal';

const AggregatedProductsModal = ({
  onClose,
  currentAggregatedProducts
 }) => {

  const handleClose = () => {
    onClose();
  }

  return (
    <Modal id={modalId} noClose={true}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={() => handleClose()} />
              <TextContainer>
                <Title>
                  <ProDisplay type="modalTitle">
                    Pacchetti inclusi
                  </ProDisplay>
                </Title>
                <div className='products-container d-flex justify-content-start flex-column'>
                  {currentAggregatedProducts?.map(product => (
                    <ProductInfo>
                      <ProDisplay className='title' type="label" configuration={{ fontWeight: 450, fontSize: '15', textAlign: 'start'}}>
                        {product.title}
                      </ProDisplay>
                      <ProDisplay className='description' type="label" configuration={{ fontWeight: 300, fontSize: '13', textAlign: 'start' }}>
                        <HtmlWrapper className='d-flex product-info'
                          dangerouslySetInnerHTML={{__html: `${product.body}`}}
                        />
                      </ProDisplay>
                      {product?.child_products?.length > 0 &&
                        product?.child_products.map(child_product => (
                          <>
                            <ProDisplay className='is-child' type="label" configuration={{ fontWeight: 450, fontSize: '15', textAlign: 'start'}}>
                              {child_product.title}
                            </ProDisplay>
                            <ProDisplay className='description is-child-description' type="label" configuration={{ fontWeight: 200, fontSize: '13', textAlign: 'start' }}>
                            <HtmlWrapper className='d-flex product-info'
                              dangerouslySetInnerHTML={{__html: `${child_product.body}`}}
                            />
                            </ProDisplay>
                          </>
                        ))}
                    </ProductInfo>))}
                </div>
              </TextContainer>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  )
};

AggregatedProductsModal.propTypes = {
  onClick: PropTypes.func,
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  pushUrl: PropTypes.func,
  currentAggregatedProducts: PropTypes.array
};

AggregatedProductsModal.id = modalId;
export default connect(
  state => {
    const { currentAggregatedProducts } = state.signup;
    return {
      currentAggregatedProducts
    };
  },

  dispatch => ({
    pushUrl: url =>
    dispatch(push(url)),
    onClose: () => dispatch({ type: MODAL_CLOSE }),
  })
)(AggregatedProductsModal);
