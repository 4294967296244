import styled from "styled-components/macro";

export const RowCreditiEcmEditWrapper = styled.div`
    /*margin: 0 20px;*/
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: auto;
`

export const Content = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > div{
        margin: 10px;
        max-width: 300px;
    }
`