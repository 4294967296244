import {
  getPlaylistMock,
  getPlaylistsMock,
  getCourseMock,
  shouldUseMock,
  getRelatedMock,
  addSurveyMock,
  getQuestionsSurveyMock,
  addReviewMock,
  setRecommendedMock,
  getKeepWatchingMock,
  getPackagesMock,
  getPackageMock,
  getEventsMock,
  getEventMock,
  getSelectionsMock,
  getCollectionsMock,
  getCategoriesPromotedMock,
  getRecommendedCollectionsMock,
  getSponsorsMock,
  updateSponsorsSuccessMock,
  getSkillroomPackagesMock,
  getCorsiEcmPackagesMock,
  categoriesPillsMock,
  categoryPlaylistMock,
  getPackageAssetsDescriptionMock
} from './mock';
import {
  apiCall,
  baseUrl,
  extendUrl,
  catalogPrefix,
  assetPrefix,
  ecmPrefix
} from './config';

export default {
  pathSelection: userId => {
    if (shouldUseMock()) {
      return getPlaylistsMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}pathselection?userId=${userId}`,
      'GET'
    );
  },
  getPlaylist: ({ id, userId, locked }) => {
    if (shouldUseMock()) {
      return getPlaylistMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}catalog/detail?itemId=${id}&locked=${locked}&htmlEnabled=true`,
      'GET'
    );
  },
  getCourse: ({ itemId, userId }) => {
    if (shouldUseMock()) {
      return getCourseMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}catalog/detail/course?itemId=${itemId}&htmlEnabled=true&htmlEnabled=true`,
      'GET'
    );
  },
  getRelated: ({ userId, needId, itemId }) => {
    if (shouldUseMock()) {
      return getRelatedMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}relatedlos?needId=${needId}&excludeLo=${itemId}&htmlEnabled=true`,
      'GET'
    );
  },
  getQuestions: surveyId => {
    if (shouldUseMock()) {
      return getQuestionsSurveyMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}getsurvey/${surveyId}?htmlEnabled=true`,
      'GET'
    );
  },
  addSurvey: (playlistId, surveyId, body) => {
    if (shouldUseMock()) {
      return addSurveyMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}addsurvey/${playlistId}/${surveyId}`,
      'POST',
      body
    );
  },
  addReview: body => {
    if (shouldUseMock()) {
      return addReviewMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}review`,
      'POST',
      body
    );
  },
  setRecommended: body => {
    if (shouldUseMock()) {
      return setRecommendedMock();
    }
    return apiCall(
      `${assetPrefix}${baseUrl}${extendUrl}reccomendation`,
      'POST',
      body
    );
  },
  getKeepWatching: ({ userId }) => {
    if (shouldUseMock()) {
      return getKeepWatchingMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}lo/getallinprogress?htmlEnabled=true`,
      'GET'
    );
  },
  getPackages: ({ userId }) => {
    if (shouldUseMock()) {
      return getPackagesMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}packages`,
      'GET'
    );
  },
  getPackage: ({ itemId, userId }) => {
    if (shouldUseMock()) {
      return getPackageMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}packages/${itemId}?userId=${userId}`,
      'GET'
    );
  },
  getEventsByDate: ({ userId, startDate, endDate }) => {
    if (shouldUseMock()) {
      return getEventsMock({ startDate, endDate });
    }
    return apiCall(
      /* date format: YYYY-MM-DD */
      `${catalogPrefix}${baseUrl}${extendUrl}live_event/planned?start_date=${startDate}&end_date=${endDate}&htmlEnabled=true`,
      'GET'
    );
  },
  getEvent: ({ userId, itemId }) => {
    if (shouldUseMock()) {
      return getEventMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}live_event?itemId=${itemId}&htmlEnabled=true`,
      'GET'
    );
  },
  getSelections: ({ itemsPerPage, nextPage, userId }) => {
    if (shouldUseMock()) {
      return getSelectionsMock();
    }
    let paginationParams = '';
    paginationParams += itemsPerPage ? `&items=${itemsPerPage}` : '';
    paginationParams += nextPage ? `&next_page=${nextPage}` : '';
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}user_selections?${paginationParams}`,
      'GET'
    );
  },
  getCollections: ({
    assetFilter, // ALL, BY_JOB (suggested), CROSS_CONTENT (formazione trasversale)
    itemsPerPage,
    nextPage,
    userId
  }) => {
    if (shouldUseMock()) {
      return getCollectionsMock(assetFilter);
    }
    let paginationParams = '';
    paginationParams += itemsPerPage ? `&items=${itemsPerPage}` : '';
    paginationParams += nextPage ? `&next_page=${nextPage}` : '';
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}user_collections?asset_filter=${assetFilter}${paginationParams}`,
      'GET'
    );
  },
  getCategoriesPromotedItems: ({
    filterType,
    filterValue,
    itemsPerPage,
    nextPage,
    userId
  }) => {
    if (shouldUseMock()) {
      return getCategoriesPromotedMock();
    }
    let paginationParams = '';
    paginationParams += itemsPerPage ? `&items=${itemsPerPage}` : '';
    paginationParams += nextPage ? `&next_page=${nextPage}` : '';
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}assets/filtered?filterType=${filterType}&filterValue=${filterValue}${paginationParams}`,
      'GET'
    );
  },
  getSponsors: ({
    userId,
    itemId,
    ecmEditionCode,
    ecmEventCode,
    participationCode
  }) => {
    if (shouldUseMock()) {
      return getSponsorsMock();
    }
    return apiCall(
      `${ecmPrefix}${baseUrl}${extendUrl}public/ecm-sponsors?ecm_event_code=${ecmEventCode}&participation_code=${participationCode}&edition_code=${ecmEditionCode}`,
      'GET'
    );
  },
  updateSponsors: (domainId, body) => {
    if (shouldUseMock()) {
      return updateSponsorsSuccessMock();
    }
    return apiCall(
      `${ecmPrefix}${baseUrl}${extendUrl}public/ecm-sponsors`,
      'POST',
      body
    );
  },
  getSkillroomPackages: ({userId, filterValue}) => {
    if (shouldUseMock()) {
      return getSkillroomPackagesMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}packages/filtered?filterType=BY_TAG&filterValue=${filterValue}`,
      'GET'
    );
  },
  getCorsiEcmPackages: ({userId, filterValue}) => {
    if (shouldUseMock()) {
      return getCorsiEcmPackagesMock();
    }
    return apiCall(
        `${catalogPrefix}${baseUrl}${extendUrl}packages/filtered?filterType=BY_TAG&filterValue=${filterValue}`,
        'GET'
    );
  },
  getCategoriesPills: () => {
    if (shouldUseMock()) {
      return categoriesPillsMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}needs`,
      'GET'
    );
  },
  getCategoryPlaylist: ({ userId, filterId }) => {
    if (shouldUseMock()) {
      return categoryPlaylistMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}catalog/learning?filterBy=NEED&filterValue=${filterId}`,
      'GET'
    );
  },
  getPackageAssetsDescription: ({ itemType, packageId, assetId })=> {
    if (shouldUseMock()) {
      return getPackageAssetsDescriptionMock();
    }
    let packageIdParam = packageId ? `&packageId=${packageId}` : '';
    let assetIdParam = assetId ? `&assetId=${assetId}` : '';
    return apiCall(
        `${catalogPrefix}${baseUrl}${extendUrl}package_asset/description?itemType=${itemType}${packageIdParam}${assetIdParam}`,
        'GET'
    );
  }
};
