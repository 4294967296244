import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import ProDisplay from 'ui/typography/proDisplay';
import {
  GET_DOMAINS,
} from 'redux/actions/authentication';

import {
  Section1,
  BootCnt
} from './style';
import Modal from "../../../../ui/components/Modals/SubscriptionB2C";
import {HtmlWrapper} from "../../style";




const AccSection1 = ({ props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <BootCnt>
        <Section1>
          <div
            className="row mx-0"
            id="header"
            data-aos="flip-up"
            style={{ backgroundImage: `url("${props?.image}")` }}
          >
            <div id="header-text">
              <ProDisplay
                  configuration={{ fontSize: 40, fontWeight: 400 }}
                  className="first-row"
              >
                <HtmlWrapper
                    dangerouslySetInnerHTML={{__html:props?.text[0]?.first_row}}
                />
              </ProDisplay>
            </div>
          </div>
        </Section1>
        {isModalOpen && <Modal isOpen={isModalOpen} closeModal={closeModal} />}
      </BootCnt>
    </>
  );
};

AccSection1.propTypes = {
  // HOC withMediaQueries
  mediaIsDesktop: PropTypes.bool,
  mediaIsLaptop: PropTypes.bool,

  // HOC (connect, state)
  domains: PropTypes.array,

  // HOC (connect, dispatch)
  AccSection1: PropTypes.func.isRequired,
};

export default connect(
  state => ({ domains: state.authentication.user.domainList }),
  dispatch => ({
    AccSection1: domain => dispatch({ type: GET_DOMAINS._REQUEST, domain }),
  })
)(withMediaQueries(AccSection1));
