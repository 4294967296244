import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'ui/gridSystem';
import ProText from 'ui/typography/proText';
import { withMediaQueries } from 'hoc/withMediaQueries';
import MainContainer from '../../../style';
import { TableWrapper, Load, Link, ButtonContainer, SubTitle, BtnContainer } from './style';
import ProDisplay from 'ui/typography/proDisplay';
import {Button, Tooltip} from 'ui/atoms';
import {
  TableCardContainer
} from 'ui/components/TableCard/style';
import {
  Labels
} from 'pages/MySubscriptions/sections/Subscriptions/ActiveSubscriptions/style';
import { CREATE_XML, GET_XML_LIST, RESET_XML_LIST } from 'redux/actions/subscriptions';
import {Spinner} from 'ui/components';
import { getToken } from 'utils/token';

const DownloadXml = ({mediaIsPhone, xmlList, getXmlList, createXml, nextPage, resetXmlList }) => {
  const [xmlListState, setXmlList] = useState(null);
  const [loadingContent, setLoadingContent] = useState(true);
  const [nextPageState, setNextPage] = useState(null);

  useEffect(() => {
    setXmlList(null);
    resetXmlList();
    setLoadingContent(true);
    getXmlList();
  }, [getXmlList]);

  useEffect(() => {
    setNextPage(nextPage);
  }, [nextPage]);

  useEffect(() => {
    setXmlList(xmlList);
  }, [xmlList]);

  useEffect(() => {
    if(Boolean(xmlListState) && Boolean(xmlList)) {
      setLoadingContent(false);
    }
  }, [xmlListState, xmlList]);

  const reload = () => {
    setXmlList(null);
    resetXmlList();
    setLoadingContent(true);
    getXmlList();
  }

  const generateXml = (ecmId) => {
    createXml(ecmId);
  }

  const downloadXml = (fileUri, fileName) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', fileUri);
    xhr.setRequestHeader('Authorization', getToken().token);
    xhr.onload = function() {
      if (xhr) {
          const url = window.URL.createObjectURL(new Blob([xhr.response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
      }
    }

    xhr.send();
  }

  const IconWithTooltip = ({text, children})=>{
    if (text){
      let formattedDate = (new Date(text)).toLocaleString();
      return <Tooltip text={formattedDate}>
        {children}
      </Tooltip>
    }else{
      return children;
    }
  }

  return (
    <MainContainer noMenu whiteBg>
      <Container>
        <SubTitle>
          <ProText className="mb-5" configuration={{ fontSize: mediaIsPhone ? '16' : '18' }}>
            Download XML
          </ProText>
        </SubTitle>
        {!loadingContent && !!xmlListState?.length && <ButtonContainer>
          <Load className='link-btn mb-4' onClick={() => reload()}>
            <Link href="#">
              <ProText type="link">Ricarica</ProText>
            </Link>
          </Load>
        </ButtonContainer>}
        {!loadingContent && !!xmlListState?.length && <Labels>
          <Row>
            <Col lg={3} md={3}>
              <ProDisplay type="tableLabel">Pacchetto</ProDisplay>
            </Col>
            <Col lg={3} md={3} className="d-none d-md-block">
              <ProDisplay type="tableLabel">Genera XML</ProDisplay>
            </Col>
            <Col lg={3} md={3} className="d-none d-md-block">
              <ProDisplay type="tableLabel">Stato</ProDisplay>
            </Col>
            <Col lg={1} md={1} className="d-none d-md-block">
              <ProDisplay type="tableLabel">Scarica XML</ProDisplay>
            </Col>
            <Col lg={1} md={1} className="d-none d-md-block">
              <ProDisplay type="tableLabel">Scarica Report di gradimento aggregato</ProDisplay>
            </Col>
            <Col lg={1} md={1} className="d-none d-md-block">
              <ProDisplay type="tableLabel">Scarica Report Fruizioni</ProDisplay>
            </Col>
          </Row>
        </Labels>}
        {loadingContent ? <Spinner/> : 
          !!xmlListState?.length ?
          <TableWrapper>
            {xmlListState?.map((item, index) => (<TableCardContainer key={index} grayBg className='mb-4 mb-md-0 table-container'>
              <Row className='position-relative'>
                <Col lg={3} md={3} className="mb-3 mb-sm-5 mb-md-0 col-item">
                  <ProText configuration={{ fontSize: mediaIsPhone ? '16' : '18' }}>
                    {Boolean(item.title) ? item.title : '*titolo*'}
                  </ProText>
                </Col>
                <Col lg={3} md={3} className="mb-3 mb-sm-5 mb-md-0 col-item">
                  <ProText configuration={{ fontSize: '16'}}  className='mobile-label d-md-none'>
                    Genera
                  </ProText>
                  <ProText className='button' configuration={{ fontSize: mediaIsPhone ? '16' : '18' }}>
                    <span className='icon-icona-play' onClick={() => generateXml(item.id)} ></span>
                  </ProText>
                </Col>
                <Col lg={3} md={3} className="mb-3 mb-sm-5 mb-md-0 col-item">
                  <ProText configuration={{ fontSize: '16'}} className='mobile-label-status d-md-none'>
                    Stato:
                  </ProText>
                  <ProText configuration={{ fontSize: mediaIsPhone ? '16' : '18' }}>
                    {item.status}
                  </ProText>
                </Col>
                <Col lg={1} md={1} className='mb-3 mb-sm-5 mb-md-0 col-item'>
                  <ProText configuration={{ fontSize: '16'}} className='mobile-label d-md-none'>
                    Scarica
                  </ProText>
                  <Link className={`icon-download-link button ${!Boolean(item.fileUri) ? 'disabled' : ''}`} onClick={() => downloadXml(item.fileUri, item.fileName)}>
                    <span className='icon-download'></span>
                  </Link>
                </Col>
                <Col lg={1} md={1} className='mb-3 mb-sm-5 mb-md-0 col-item'>
                  <ProText configuration={{ fontSize: '16'}} className='mobile-label d-md-none'>
                    Scarica
                  </ProText>
                  {<IconWithTooltip text={item.fileSurveyDate}>
                    <Link className={`icon-download-link button ${!Boolean(item.fileSurveyUri) ? 'disabled' : ''}`} onClick={() => downloadXml(item.fileSurveyUri, item.fileSurveyName)}>
                      <span className='icon-download'></span>
                    </Link>
                  </IconWithTooltip>}
                </Col>
                <Col lg={1} md={1} className='mb-3 mb-sm-5 mb-md-0 col-item'>
                  <ProText configuration={{ fontSize: '16'}} className='mobile-label d-md-none'>
                    Scarica
                  </ProText>
                  {<IconWithTooltip text={item.fileFruizioniDate}>
                    <Link className={`icon-download-link button ${!Boolean(item.fileFruizioniUri) ? 'disabled' : ''}`} onClick={() => downloadXml(item.fileFruizioniUri, item.fileFruizioniName)}>
                      <span className='icon-download'></span>
                    </Link>
                  </IconWithTooltip>}
                </Col>
              </Row>
            </TableCardContainer>))}
          </TableWrapper> :           
          <ProText className="mb-5" configuration={{ fontSize: mediaIsPhone ? '16' : '18' }}>
            Nessun risultato da mostrare
          </ProText>}
        {Boolean(nextPageState) && !loadingContent && !!xmlList.length && <BtnContainer>
              <Button
                text="Mostra altri"
                type="PRIMARY"
                height={35}
                fontSize={16}
                onClick={() => getXmlList(true)}
              />
          </BtnContainer> }
      </Container>
    </MainContainer>
  );
};

DownloadXml.propTypes = {
  //HOC (connect, state)
  xmlList: PropTypes.array,
  nextPage: PropTypes.string,

  //HOC (connect, dispatch)
  mediaIsPhone: PropTypes.bool.isRequired,
  getXmlList: PropTypes.func.isRequired,
  createXml: PropTypes.func.isRequired,
  resetXmlList: PropTypes.func.isRequired
};

export default connect(
  state => {
    const xmlList = state.subscriptions.xmlList.items;
    const nextPage = state.subscriptions.xmlList.nextPage
    return {
      xmlList,
      nextPage
    };
  },

  dispatch => ({
    getXmlList: (loaderActive) => dispatch({ type: GET_XML_LIST._REQUEST, loaderActive }),
    createXml: (ecmId) => dispatch({ type: CREATE_XML._REQUEST, ecmId }),
    resetXmlList: () => dispatch({ type: RESET_XML_LIST._SUCCESS})
  })
)(withMediaQueries(DownloadXml));
