import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Col } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import { MODAL_OPEN } from 'redux/actions';
import { Checkbox } from 'ui/atoms';
import AgreementsCheckModal from 'ui/components/Modals/AgreementsCheckModal';
import {
  AGREEMENTS_CREATE,
  CHECK_AGREEMENTS_MODAL
} from 'redux/actions/signup';
import { PROFILE_GET_DATA_PRIVACY } from 'redux/actions/profile';
import { useLocation } from 'react-router-dom';
import {
  PrivacyWrapper,
  Icon,
  Link,
  CheckboxWrapper,
  CheckboxWrapperText
} from './style';

const AgreementCheck = ({
  domain,
  openAgreementsCheckModal,
  agreements,
  validator,
  createAgreements,
  isSimpleUser = false,
  isCompleteManager = false,
  getPrivacyData,
  privacyData,
  isLogged,
  sendDataTreatment,
  userDomainAttributes,
  checkUserType = false,
  checkAgreementsModal,
  agreementsModalChecked,
  personaFisicaFruitore
}) => {
  const firstCheck = !isLogged || sendDataTreatment || isCompleteManager;
  const [conditions, setConditions] = useState({});
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [userType, setUserType] = useState(searchParams.get('userType'));
  const [isCreateUser, setIsCreateUser] = useState(false);

  useEffect(() => {
    let userTypeFromParams = searchParams.get('userType');
    const isUserAccountUrl = window.location.href.includes('user-account');
    const isBillings = window.location.href.includes('billings');

    if (isBillings) {
      userTypeFromParams = userDomainAttributes?.personaFisica ? 'persona_fisica' : 'business';
    }

    if (!userType || userType !== 'fruitore_type_acquirente') {
      setUserType(userTypeFromParams);
    }

    if (userTypeFromParams === 'business') {
      setUserType('persona_giuridica');
    }

    if (isUserAccountUrl && userTypeFromParams === 'persona_fisica') {
      setUserType('fruitore_type_acquirente');
    }
  }, [searchParams]);

  useEffect(() => {
    if (!firstCheck) {
      getPrivacyData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.location.href.indexOf('compile-profile') > -1 || window.location.href.indexOf('compile-manager-profile') > -1) {
      setIsCreateUser(true);
      setUserType('persona_fisica');
      // if (personaFisicaFruitore) setUserType('fruitore_type_acquirente');
    }
  }, [personaFisicaFruitore]);

  const formConfirmed = () => {
    let valid = true;
    // const consentIds = domain.consents.consents.map(c => c.id);
    if (agreements?.user_clauses) {
      (Object.keys(agreements.user_clauses) || []).forEach(key => {
        // if ((userType !== 'fruitore_type_acquirente') && (isSimpleUser || !isCreateUser) && consentIds.includes(key)) {
        //   return;
        // }
        // if ((userType === 'fruitore_type_acquirente')) {
        //
        //   valid = valid && (agreements.user_clauses[key]?.valid || !agreements.user_clauses[key]?.mandatory);
        //   return;
        // }
        valid = valid && agreements.user_clauses[key]?.valid;
      });
    } else {
      valid = false;
    }
    return (
      valid &&
      (!checkUserType || userType) &&
      ((!isSimpleUser) || agreementsModalChecked)
    );
  };

  const setFilteredConditions = conds => {
    if (firstCheck) setConditions(conds);
    else {
      const myPrivacyDataCodes = privacyData.map(pd => pd.type);
      const filteredConds = {
        ...conds,
        consents: conds.consents.filter(c => (
          myPrivacyDataCodes.includes(c.code)
        ))
      };
      setConditions(filteredConds);
    }
  };

  const handleConditionModal = conds => {
    setFilteredConditions(conds);
    if (isSimpleUser || userType === 'persona_fisica') checkAgreementsModal();
    openAgreementsCheckModal();
  };

  const handleCreateAgreements = (keepMandatory = false) => {
    const agreementsPre = {};

    if (domain.policy && ((!isSimpleUser || !isCreateUser) || (userType === 'fruitore_type_acquirente'))) {
      const filteredConsents =
      domain.policy.consents
            .filter(c => (
              firstCheck
                ? c.user_type === '' || c.user_type === userType
                : (privacyData.map(pd => pd.type) || []).includes(c?.code)
            ));

      // domain.policy.consents
        // .filter(c => (
          //   firstCheck
            //     ? c.user_type === '' || c.user_type === userType
            //     : (privacyData.map(pd => pd.type) || []).includes(c?.code)
        // ))
      filteredConsents.forEach(c => {
          const profilePrivacy = (privacyData || []).find(
            p => p.type === c.code
          );
          agreementsPre[c.id] = {
            // authorize: !profilePrivacy?.mandatory
              //   ? profilePrivacy?.authorized
              //   : false,
            authorize: false,
            type: c?.code,
            mandatory: c?.mandatory === 'True',
            valid: !(c?.mandatory === 'True')
          };
        });

    }
    if (domain.contract && !isCreateUser) {
      domain.contract.consents.forEach(c => {
        const profilePrivacy = (privacyData || []).find(p => p.type === c.code);
        agreementsPre[c.id] = {
          authorize: !profilePrivacy?.mandatory
            ? profilePrivacy?.authorized
            : false,
          type: c?.code,
          mandatory: c?.mandatory === 'True',
          valid: !(c?.mandatory === 'True')
        };
      });
    }
    if (domain.consents && ((isSimpleUser || isCreateUser) && (userType !== 'fruitore_type_acquirente'))) {
      domain.consents.consents.forEach(c => {
        agreementsPre[c.id] = {
          authorize: false,
          type: c?.code,
          mandatory: c?.mandatory === 'True',
          valid: !(c?.mandatory === 'True')
        };
      });
    }

    createAgreements(agreementsPre, keepMandatory);
  };

  useEffect(() => {
    if (!Object.keys(agreements).length) {
      const keepMandatory = false;
      handleCreateAgreements(keepMandatory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain, createAgreements, isSimpleUser, agreements]);

  useEffect(() => {
    // if (userType) handleCreateAgreements(true);
    // else handleCreateAgreements(false);
    handleCreateAgreements(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  const findConditionToCheckOpenModal = () => {
    // const consentIds = domain.consents.consents.map(c => c.id);
    const notValidAgreementId = Object.keys(agreements.user_clauses || []).find(a => !agreements.user_clauses[a].valid);
    // const notValidAgreementId = Object.keys(agreements.user_clauses || []).find(a => {
    //   if ((isSimpleUser || (!isCreateUser || (userType === 'fruitore_type_acquirente'))) && consentIds.includes(a)) {
    //     return false;
    //   }
    //   return !agreements.user_clauses[a].valid;
    // });

    if (notValidAgreementId) {
      const dp = domain.policy.consents.find(c => c.id === notValidAgreementId);
      const dc = domain.contract.consents.find(c => c.id === notValidAgreementId);

      if (dp && (!isSimpleUser || (!isCreateUser || (userType === 'fruitore_type_acquirente')))) {
        setFilteredConditions(domain.policy);
      } else if (dc && !isCreateUser) {
        setFilteredConditions(domain.contract);
      } else if ((isSimpleUser || isCreateUser) && userType !== 'fruitore_type_acquirente') {
        setFilteredConditions(domain.consents);
      }
      checkAgreementsModal();
      openAgreementsCheckModal();
    } else if (((isSimpleUser || isCreateUser) && (userType !== 'fruitore_type_acquirente')) && !agreementsModalChecked) {
      setFilteredConditions(domain.consents);
      checkAgreementsModal();
      openAgreementsCheckModal();
    }
  };

  return (
    <Col md={9} sm={12} className="mt-4">
      <ProDisplay type="label">
        Prendi visione dell’informativa: per procedere occorre aprire il
        documento.
      </ProDisplay>
      {/*{(!isSimpleUser && (userType !== 'persona_fisica')) && (*/}
        <>
          <PrivacyWrapper
            onClick={() => {
            if (!isCreateUser || (userType === 'fruitore_type_acquirente')) {
              handleConditionModal({ ...domain.policy, isConsents: checkUserType });
              } else {
                handleConditionModal(domain.consents);
              }
            }}
          >
            <ProDisplay type="label">
              <Icon className="icon-note" />{' '}
              <Link>Trattamento dei dati personali</Link>
            </ProDisplay>
          </PrivacyWrapper>
        {!isCreateUser && <PrivacyWrapper
            onClick={() => {
              handleConditionModal(domain.contract);
            }}
          >
            <ProDisplay type="label">
              <Icon className="icon-note" />{' '}
              <Link>Termini e condizioni di utilizzo del servizio</Link>
            </ProDisplay>
        </PrivacyWrapper>}
        </>
            <CheckboxWrapper onClick={findConditionToCheckOpenModal}>
        <Checkbox checked={formConfirmed()} disabled onChange={() => null} />
        <CheckboxWrapperText disabled={!formConfirmed()}>
          <ProDisplay type="label">
            Dichiaro di aver letto attentamente l’informativa disponibile su
            documento elettronico
          </ProDisplay>
        </CheckboxWrapperText>
      </CheckboxWrapper>
      <AgreementsCheckModal
        validator={validator}
        conditions={conditions}
        firstCheck={firstCheck}
      />
    </Col>
  );
};

AgreementCheck.propTypes = {
  validator: PropTypes.func,
  isSimpleUser: PropTypes.bool,
  isCompleteManager: PropTypes.bool,
  checkUserType: PropTypes.bool,
  //HOC (connect, state)
  agreements: PropTypes.object,
  domain: PropTypes.object,
  isLogged: PropTypes.bool,
  sendDataTreatment: PropTypes.bool,
  privacyData: PropTypes.array,
  agreementsModalChecked: PropTypes.bool,
  personaFisicaFruitore: PropTypes.bool,

  //HOC (connect, dispatch)
  openAgreementsCheckModal: PropTypes.func,
  createAgreements: PropTypes.func,
  getPrivacyData: PropTypes.func,
  checkAgreementsModal: PropTypes.func
};

export default connect(
  state => {
    const { agreements, agreementsModalChecked } = state.signup;
    const { isLogged } = state.app.authState;
    const {
      user: { sendDataTreatment, userDomainAttributes }
    } = state.authentication;
    const {
      privacy: { dataPrivacylist: privacyData }
    } = state.profile;
    return {
      agreements,
      domain: state.domain,
      isLogged,
      sendDataTreatment,
      userDomainAttributes,
      privacyData,
      userType: agreements.userType,
      agreementsModalChecked
    };
  },
  dispatch => ({
    openAgreementsCheckModal: () =>
      dispatch({ type: MODAL_OPEN, id: AgreementsCheckModal.id }),
    createAgreements: (agreements, keepMandatory = false) =>
      dispatch({ type: AGREEMENTS_CREATE, agreements, keepMandatory }),
    getPrivacyData: () => dispatch({ type: PROFILE_GET_DATA_PRIVACY._REQUEST }),
    checkAgreementsModal: () => dispatch({ type: CHECK_AGREEMENTS_MODAL })
  })
)(AgreementCheck);
