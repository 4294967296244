import React, { useState } from "react";
import Modal from 'ui/components/Modals/SubscriptionB2C';
import { Iscriviti, Button } from "./style";

const SectionIscriviti = ({btnText, defaultEcm })=>{
    const [isModalOpen, setIsModalOpen] = useState(false);
    return <>
        <Iscriviti style={{textAlign: 'center'}}>
            <Button onClick={()=>{setIsModalOpen(true);}}>{btnText || 'Iscriviti ora'}</Button>
        </Iscriviti>
        {isModalOpen && <Modal isOpen={isModalOpen} defaultEcm={defaultEcm} closeModal={()=>{setIsModalOpen(false)}} />}
    </>
}

export default SectionIscriviti;