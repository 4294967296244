import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .radio-container {
    max-height: 150px;
  }

  .sponsors-items-container {
    &.scroll {
      overflow: scroll;
      max-height: 100px
    }
  }
`;

export const Title = styled.div`
  margin-bottom: 35px;
  max-width: 372px;
  width: 100%;
  text-align: center;
`;

export const CheckboxWrapper = styled.div`

`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -15px;

  @media ${props => props.theme.device.mobile} {
    margin: 0 -10px;
  }
`;

export const BtnContainer = styled.div`
  button {
    padding: 20px 40px;
    margin: 0 15px;

    @media ${props => props.theme.device.mobile} {
      padding: 10px 30px;
      margin: 0 10px;
    }
  }
`;

export const Icon = styled.span`
  position: absolute;
  top: 0;
  font-size: 30px;
  right: 15px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;
