import styled, { css } from 'styled-components/macro';

const ProductSubscriptionContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
`;

const DescriptionContainer = styled.div`
  width: ${props => (props.isCatalog ? '80%' : '50%')};
  padding-right: 20px;
`;
const Question = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${props => props.theme.colors.davysGray};
`;
const TextSection = styled.span`
  margin: 7px 0;
  color: ${props => props.theme.colors.primary.base};
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
    justify-content: center;
  }
`;

const Title = styled.span`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 400;
`;

const IconInfo = styled.span`
  font-size: 30px;
  margin-left: 3px;
  color: ${props => props.theme.colors.primary.base};
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 1.43;
`;

const PriceContainer = styled.div`
  display: flex;
  width: 20%;
  background-color: rgba(62, 107, 126, 0.05);
  margin: -20px 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Price = styled.div`
  margin: auto;
`;

const IVA = styled.div``;

const DetailButton = styled.div`
  margin-top: 15px;

  button {
    padding: 5px;
    height: 100%;
    margin: 0 auto;
    font-size: 20px;
  }
`;

const QuantityContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: -20px;
  width: 30%;
  justify-content: center;
`;

const QuantityLabel = styled.div`
  margin-bottom: 5px;
  font-size: 10px;
  font-weight: 500;
  color: ${props => props.theme.colors.americanSilver};
`;

const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 10px 30px 0 rgba(11, 58, 78, 0.1);
  min-height: 115px;
  position: relative;
  background: white;
  z-index: 0;

  ${props =>
    props.isChild &&
    css`
      background-color: rgba(62, 107, 126, 0.05);
      border-radius: 0;
      margin-top: -6px;
      position: relative;
      z-index: 1;
      margin-bottom: 5px;
      border-top: none;

      &:last-child {
        border-radius: 0 0 6px 6px;
      }

      ${PriceContainer} {
        background-color: rgba(62, 107, 126, 0.1);
      }
    `}
    
  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
    
    ${DescriptionContainer} {
      width: 100%;
      text-align: center;
      padding: 0;
      margin-bottom: 20px;
    }
    
    ${PriceContainer} {
      width: inherit;
      margin: 0 -20px;
      height: 60px;
      margin-bottom: 20px;
    }
    
    ${QuantityContainer} {
      width: 100%;
    }
  }
`;

const Span = styled.span``;

const PriceRow = styled.div`
  display: flex;
  align-items: center;
`;

const VatRow = styled.div``;

const Period = styled.span`
  color: ${props => props.theme.colors.americanSilver};
  font-size: 16px;
  font-weight: 300;
`;

export {
  ProductSubscriptionContainer,
  DescriptionContainer,
  QuantityContainer,
  ProductContainer,
  Title,
  Description,
  QuantityLabel,
  PriceContainer,
  Span,
  IVA,
  Price,
  Question,
  Period,
  PriceRow,
  VatRow,
  IconInfo,
  TextSection,
  DetailButton
};
