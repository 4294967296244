import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { styles } from 'utils/multi-select';

import { Col, Container, Row } from 'ui/gridSystem';

import { Button, CustomInput, CustomSelect } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
import { withMediaQueries } from 'hoc/withMediaQueries';
import validator from 'utils/regex-validator';
import OnBoardingLayout from 'ui/components/OnBoardingLayout';
import routes from 'routes';
import {
  CREATE_USER_ONBOARDING,
  ONBOARDING_GET_PROFESSIONS,
  stepsOnboarding,
} from 'redux/actions/onBoarding';
import AgreementCheck from 'ui/components/AgreementCheck';
import {
  ChangePasswordContainer,
  Title,
  Body,
  ButtonWrapper,
  InputWrapper,
} from './style';

const newField = () => ({
  value: undefined,
  valid: true,
});

const initialForm = {
  firstname: newField(),
  lastname: newField(),
  phoneNumber: newField(),
  fiscalCode: newField(),
  userJob: newField(),
};

const CreateUser = ({
  mediaIsPhone,
  createUser,
  pushUrl,
  stepOnBoarding,
  toSendPassword,
  prospectId,
  agreements,
  agreementsModalChecked,
  professions = [],
  getProfessions
}) => {
  const [form, setForm] = useState(initialForm);
  const [professionsList, setProfessionsList] = useState([]);
  const [specializationList, setSpecializationList] = useState([]);
  const [professionOptions, setProfessionOptions] = useState();
  const [specializationOptions, setSpecializationOptions] = useState();
  const [specializationOptionsToSelect, setSpecializationOptionsToSelect] = useState();
  const [specializationOptionsSelected, setSpecializationOptionsSelected] = useState();
  const [professionOptionsSelected, setProfessionOptionsSelected] = useState();
  const [typeOptionSelected, setTypeOptionSelected] = useState();
  const [specializationError, setSpecializationError] = useState(false);
  const [payloadProfessions, setPayloadProfessions] = useState();

  useEffect(() => {
    getProfessions();
  }, [getProfessions]);

  useEffect(() => {
    const professionsArray = professions.data;
    setProfessionsList(professionsArray);

    let specializations = [];

    if (Boolean(professionsArray)) {
      professionsArray.forEach(profession => {
        profession.children.forEach(children => {
          specializations = [...specializations, children];
          setSpecializationList(specializations);
        })
      });
    }
  }, [professions]);

  useEffect(() => {
    if (Boolean(professionsList && Boolean(specializationList))) {
      let professionOptionsArray = [];

      professionsList.forEach(profession => {
        professionOptionsArray = [
          ...professionOptionsArray,
          {
            label: profession.title,
            value: profession.id
          }
        ];
        setProfessionOptions(professionOptionsArray);
      });

      let specializationsArray = [];

      specializationList.forEach(specialization => {
        specializationsArray = [
          ...specializationsArray,
          {
            label: specialization.title,
            value: specialization.id
          }
        ];
        setSpecializationOptions(specializationsArray);
      });
    }
  }, [professionsList, specializationList]);

  useEffect(() => {
    if(!professionOptionsSelected?.length) {
      setSpecializationError(false);
    }

    const prof = professionsList.filter(function(p) {
      return professionOptionsSelected.find(function(pfSel) {
          return p.id === pfSel.value
      })
    })

    var profPayload = [];
    prof.forEach((w) => {
      const el = {
          professionId: w.id,
          professionCode: w.code,
          professionTitle: w.title,
          discipline: []
        }

        professionsList.forEach((p) => {
            if (p.id === w.id) {
              const disc = !!specializationOptionsSelected ? p.children.filter((c) => {
                  return specializationOptionsSelected?.find((sp) => {
                      return sp.value === c.id
                  })
              }) : [];

              if(!disc.length) {
                setSpecializationError(true);
              } else {
                setSpecializationError(false);
              }
              if (disc) {
                let disciplinePayload = [];
                disc.forEach((d) => {
                    disciplinePayload.push({
                        disciplineId: d.id,
                        disciplineCode: d.code,
                        disciplineTitle: d.title
                    })
                })
                el.discipline = disciplinePayload;
              } 
            }
        })
        profPayload.push(el);
    });

    setPayloadProfessions(profPayload);

  }, [professionOptionsSelected, specializationOptionsSelected]);

  function onChangeProfession(values) {
    setProfessionOptionsSelected(values);

    let professionSelectedIds = [];
    let professionSelectedList = [];
    let professionChildrenAvailable = [];
    let professionChildrenAvailableIds = [];

    values.forEach(value => {
      professionSelectedIds = [
        ...professionSelectedIds, {
          id: value.value
      }]
    });

    professionSelectedList = professionsList.filter(profession => professionSelectedIds.map(item => item.id).includes(profession.id));
    professionChildrenAvailable = professionSelectedList.map(professionSelected => professionSelected.children).flat();
    professionChildrenAvailableIds = professionChildrenAvailable.map(children => children.id);

    const filteredSpecializationOptions = specializationOptions.filter(specializationOption => professionChildrenAvailableIds.includes(specializationOption.value));

    let remappedSpecializationOptions = [];

    filteredSpecializationOptions.forEach(option => {
      const relatedProfession = professionsList.find(profession => profession.children.find(children => children.id === option.value)).title;

      remappedSpecializationOptions = [...remappedSpecializationOptions, {
        label: `${option.label} (${relatedProfession})`,
        value: option.value
      }]
    });

    setSpecializationOptionsToSelect(remappedSpecializationOptions);

    if(!!specializationOptionsSelected) {
      let filteredSpecializationOptionsSelected = specializationOptionsSelected.filter(specializationSelected => professionChildrenAvailableIds.includes(specializationSelected.value));
      setSpecializationOptionsSelected(filteredSpecializationOptionsSelected);
    }
  }

  function onChangeSpecialization(values) {
    setSpecializationOptionsSelected(values);
  }

  function onChangeType(value) {
    setTypeOptionSelected(value);
  }

  const handleOnChange = (field, newValue) =>
    setForm(s => {
      const newState = { ...s };
      newState[field].value = newValue;
      return newState;
    });

  const handleOnBlur = (type, field, callback) => {
    setForm(s => {
      const newState = { ...s };
      newState[field].valid = validator[type](form[field].value);
      return newState;
    });

    if (callback) callback();
  };

  const buttonDisabled =
    (!Object.values(form).reduce((acc, field) => acc && field.value && field.value.length > 0, true) ||
    typeof form.fiscalCode.valid === 'string'
      ? !!form.fiscalCode.valid
      : !form.fiscalCode.valid) ||
    (!Boolean(typeOptionSelected && professionOptionsSelected && specializationOptionsSelected));

  const handleCreateUser = () => {
    const payloadJobInfo = {
      jobType: typeOptionSelected?.value,
      professions: payloadProfessions
    }

    createUser({
      firstName: form.firstname?.value,
      lastName: form.lastname?.value,
      phoneNumber: form.phoneNumber.value,
      fiscalCode: form.fiscalCode.value,
      userJob: form.userJob.value,
      jobInfo: payloadJobInfo,
      passwordUser: toSendPassword,
      prospectId,
      persDataAcceptance: (Object.values(agreements.user_clauses) || []).map(
        clause => ({
          authorize: clause.authorize,
          type: clause.type,
          mandatory: clause.mandatory,
        })
      )
    });
  };

  useEffect(() => {
    if (stepOnBoarding === stepsOnboarding.STEP3) {
      pushUrl(routes.drawProfile.path);
    }
  }, [stepOnBoarding, pushUrl]);

  useEffect(() => {
    if (!prospectId) {
      pushUrl(routes.landingPage.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formConfirmed = () => {
    let valid = true;
    if (agreements?.user_clauses) {
      (Object.keys(agreements.user_clauses) || []).forEach(key => (valid = valid && agreements.user_clauses[key]?.valid));
    } else {
      valid = false;
    }
    return valid && agreementsModalChecked;
  };

  return (
    <OnBoardingLayout step={2}>
      <Container>
        <Row>
          <ChangePasswordContainer>
            <Col lg={6} md={6} sm={12}>
              <Body>
                <Title>
                  <ProDisplay
                    type="title"
                    configuration={mediaIsPhone ? { fontSize: '20' } : null}
                  >
                    Manca poco, inserisci gli ultimi dati
                  </ProDisplay>
                </Title>
                <InputWrapper>
                  <CustomInput
                    type="text"
                    placeholder="Nome *"
                    value={form.firstname.value}
                    onChange={val => handleOnChange('firstname', val)}
                    onBlur={() => handleOnBlur('string', 'firstname')}
                    error={
                      typeof form.firstname.valid === 'string'
                        ? form.firstname.valid
                        : !form.firstname.valid
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <CustomInput
                    type="text"
                    placeholder="Cognome *"
                    value={form.lastname.value}
                    onChange={val => handleOnChange('lastname', val)}
                    onBlur={() => handleOnBlur('string', 'lastname')}
                    error={
                      typeof form.lastname.valid === 'string'
                        ? form.lastname.valid
                        : !form.lastname.valid
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <CustomInput
                    type="text"
                    placeholder="Telefono *"
                    value={form.phoneNumber.value}
                    onChange={val => handleOnChange('phoneNumber', val)}
                    onBlur={() => handleOnBlur('string', 'phoneNumber')}
                    error={
                      typeof form.phoneNumber.valid === 'string'
                        ? form.phoneNumber.valid
                        : !form.phoneNumber.valid
                    }
                  />
                </InputWrapper>
                <InputWrapper>
                  <CustomInput
                    type="text"
                    placeholder="Codice Fiscale *"
                    forceUppercase
                    value={form.fiscalCode.value}
                    onChange={val =>
                      handleOnChange('fiscalCode', val.toUpperCase())
                    }
                    onBlur={() => handleOnBlur('fiscalCode', 'fiscalCode')}
                    error={
                      typeof form.fiscalCode.valid === 'string'
                        ? form.fiscalCode.valid
                        : !form.fiscalCode.valid
                    }
                  />
                </InputWrapper>
                <InputWrapper className='select'>
                  <CustomSelect
                    placeholder="Professione *"
                    options={professionOptions}
                    isMulti={true}
                    hideSelectedOptions={false}
                    closeOnSelect={false}
                    onChange={values => onChangeProfession(values)}
                    styles={styles}
                    selected={professionOptionsSelected}
                    id='profession-select'
                    isSearchType={false}
                    hasError={true}
                  />
                </InputWrapper>
                <InputWrapper className='select'>
                  <CustomSelect
                    placeholder="Specializzazione *"
                    options={specializationOptionsToSelect}
                    isMulti={true}
                    hideSelectedOptions={false}
                    closeOnSelect={false}
                    onChange={values => onChangeSpecialization(values)}
                    styles={styles}
                    selected={specializationOptionsSelected}
                    id='specialization-select'
                    isSearchType={false}
                    hasError={true}
                    isError={specializationError}
                    customErrorMessage={'Selezionare almeno una specializzazione per ogni professione selezionata'}
                  />
                </InputWrapper>
                <InputWrapper className='select'>
                  <CustomSelect
                    placeholder="Tipologia *"
                    options={[
                      {
                        label: 'Privo di occupazione',
                        value: 'P'
                      },
                      {
                        label: 'Dipendente',
                        value: 'D'
                      },
                      {
                        label: 'Libero Professionista',
                        value: 'L'
                      },
                      {
                        label: 'Convenzionato',
                        value: 'C'
                      }
                    ]}
                    hideSelectedOptions={true}
                    onChange={value => onChangeType(value)}
                    styles={styles}
                    selected={typeOptionSelected}
                    isSearchType={false}
                    id='type-select'
                  />
                </InputWrapper>
                <InputWrapper>
                  <CustomInput
                    type="text"
                    placeholder="Ruolo in azienda *"
                    value={form.userJob.value}
                    onChange={val => handleOnChange('userJob', val)}
                    onBlur={() => handleOnBlur('string', 'userJob')}
                    error={
                      typeof form.userJob.valid === 'string'
                        ? form.userJob.valid
                        : !form.userJob.valid
                    }
                  />
                </InputWrapper>
                <Row>
                  <AgreementCheck validator={formConfirmed} isSimpleUser />
                </Row>
                <ButtonWrapper>
                  <Button
                    type={Button.TYPE.PRIMARY}
                    text="Accedi"
                    disabled={buttonDisabled || !formConfirmed() || specializationError}
                    onClick={handleCreateUser}
                  />
                </ButtonWrapper>
              </Body>
            </Col>
          </ChangePasswordContainer>
        </Row>
      </Container>
    </OnBoardingLayout>
  );
};

CreateUser.propTypes = {
  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,

  // HOC (store)
  stepOnBoarding: PropTypes.string,
  toSendPassword: PropTypes.string,
  prospectId: PropTypes.string,
  agreements: PropTypes.object,
  agreementsModalChecked: PropTypes.bool,
  professions: PropTypes.array,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  createUser: PropTypes.func.isRequired,
  getProfessions: PropTypes.func.isRequired
};

export default connect(


    ({
      onBoarding: { stepOnBoarding, toSendPassword, prospectId, professions },
      signup: { agreements, agreementsModalChecked },
    }) => ({
      stepOnBoarding,
      toSendPassword,
      prospectId,
      agreements,
      agreementsModalChecked,
      professions
    }),


  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    createUser: payload => dispatch({ type: CREATE_USER_ONBOARDING._REQUEST, payload }),
    getProfessions: () => dispatch({ type: ONBOARDING_GET_PROFESSIONS._REQUEST })
  })
)(withMediaQueries(CreateUser));
