import {useEffect, useState} from "react";

const useEcmRoleList = (userDomainAttributes, ecmRoleList) => {
    const [ecmRoleListState, setEcmRoleListState] = useState([]);
    const [ecmRoleOptionsState, setEcmRoleOptionsState] = useState();

    useEffect(() => {
        const ecmRoleListFiltered = userDomainAttributes?.managerType?.toLowerCase() === 'base' ? ecmRoleList?.filter(role => !role.only_provisioner) : ecmRoleList;
        setEcmRoleListState(ecmRoleListFiltered);

        let ecmRoleOptions = [];

        if (Boolean(ecmRoleListFiltered)) {
            ecmRoleListFiltered.forEach(roleItem => {
                ecmRoleOptions = [
                    ...ecmRoleOptions,
                    {
                        label: roleItem.title,
                        value: roleItem.id
                    }
                ];
                setEcmRoleOptionsState(ecmRoleOptions);
            });
        }
    }, [ecmRoleList]);

    return [ecmRoleListState, ecmRoleOptionsState];
};

export default useEcmRoleList;