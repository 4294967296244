import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { SubscriptionsFiltersInterface } from 'interface/subscriptions';
import { SET_SUBSCRIPTIONS_FILTER } from 'redux/actions/subscriptions';

import { withMediaQueries } from 'hoc/withMediaQueries';

import { ConfirmModal, DisableSubscriptionModal } from 'ui/components';
import { Container } from 'ui/gridSystem';
import ProDisplay from 'ui/typography/proDisplay';
import OldSubscriptions from 'ui/components/OldSubscriptions';
import PlaylistPremiumModal from 'ui/components/Modals/PlaylistPremiumModal';
import routes from 'routes';
import { push } from 'redux-first-history';
import {
  BILLING_GET_PAYMENT_METHODS,
  BILLING_IS_PREMIUM_PURCHASE,
} from 'redux/actions/billings';
import {
  SIGNUP_NEXT_STEP,
  SIGNUP_SET_HEADER_VISIBLE,
} from 'redux/actions/signup';
import SubscriptionModal from 'ui/components/Modals/SubscriptionModal';
import { CustomInput } from 'ui/atoms';
import MainContainer from '../../../../style';
import {
  ActiveSubscriptionsContainer,
  Head,
  Link,
  Add,
  MenuLabel,
  IconPlus,
  AddWrapper,
  SearchWrapper,
} from './style';

const ActiveSubscriptions = ({
  assoc_subscriptions,
  filters,
  all_prospect,
  pushUrl,
  setUpselling,
  getPaymentMethods,
  setSubscriptionStep,
  setHeaderVisible,
}) => {
  const [search, setSearch] = useState(filters.search);

  const addNewSubscription = async () => {
    // eslint-disable-next-line no-unused-vars
    const resGetPaymentMethods = getPaymentMethods();
    setSubscriptionStep();
    setHeaderVisible();
    setUpselling();
    pushUrl(routes.subscription.path);
  };

  const applySearch = subscription => subscription.email.includes(search);

  const getFilteredArray = array => {
    if (search !== '') {
      return array.filter(applySearch);
    }
    return array;
  };

  const subscriptions = Object.keys(all_prospect || {}).reduce(
    (acc, k) => [...acc, ...all_prospect[k]],
    []
  );
  return (
    <MainContainer noMenu whiteBg>
      <Container className="p-0">
        <ActiveSubscriptionsContainer>
          <Head>
            <AddWrapper>
              <MenuLabel>ACQUISTA CORSI:</MenuLabel>
              <Add onClick={addNewSubscription}>
                <IconPlus className="icon-plus" />
                <Link href="#">
                  <ProDisplay type="link">Aggiungi </ProDisplay>
                </Link>
              </Add>
            </AddWrapper>
            <SearchWrapper className='mt-4'>
              <CustomInput
                type="text"
                placeholder="Cerca"
                className="smallHeight"
                value={search}
                onChange={val => setSearch(val)}
              />
            </SearchWrapper>
          </Head>
          <OldSubscriptions
            subscriptions={subscriptions}
            assocSubscriptions={assoc_subscriptions}
            getFilteredArray={getFilteredArray}
          />
        </ActiveSubscriptionsContainer>
        <ConfirmModal />
        <DisableSubscriptionModal />
        <PlaylistPremiumModal />
        <SubscriptionModal />
      </Container>
    </MainContainer>
  );
};

ActiveSubscriptions.propTypes = {
  // HOC (connect, state)
  filters: SubscriptionsFiltersInterface,
  all_prospect: PropTypes.object.isRequired,
  assoc_subscriptions: PropTypes.object.isRequired,

  //HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  setUpselling: PropTypes.func.isRequired,
  getPaymentMethods: PropTypes.func.isRequired,
  setSubscriptionStep: PropTypes.func.isRequired,
  setHeaderVisible: PropTypes.func,

  // HOC withMediaQueries
};

export default connect(
  state => {
    const { user: { userId, organizationId } = {} } = state.authentication;
    const { filters, all_prospect, assoc_subscriptions } = state.subscriptions;
    return {
      userId,
      organizationId,
      filters,
      all_prospect,
      assoc_subscriptions,
    };
  },
  dispatch => ({
    setSubscriptionFilters: filters =>
      dispatch({ type: SET_SUBSCRIPTIONS_FILTER, filters }),
    pushUrl: url => dispatch(push(url)),
    setUpselling: () =>
      dispatch({ type: BILLING_IS_PREMIUM_PURCHASE, payload: true }),
    getPaymentMethods: () =>
      dispatch({
        type: BILLING_GET_PAYMENT_METHODS._REQUEST,
        skipWallet: true,
      }),
    setSubscriptionStep: () =>
      dispatch({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 0 }),
    setHeaderVisible: () =>
      dispatch({ type: SIGNUP_SET_HEADER_VISIBLE, response: true }),
  })
)(withMediaQueries(ActiveSubscriptions));
