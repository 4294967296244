import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import { useLocation } from 'react-router-dom';

import routes from 'routes';

import { Button, Checkbox, CustomInput } from 'ui/atoms';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import { Footer } from 'ui/components';
import { withMediaQueries } from 'hoc/withMediaQueries';
import validator from 'utils/regex-validator';
import { AUTHENTICATION_SIGNIN } from 'redux/actions/authentication';
import { REMEMBER_ME, FIRST_ACCESS_SET } from 'redux/actions/landingPage';
import { FIRST_PASSWORD_SET } from 'redux/actions/signup';
import { Container } from 'ui/gridSystem';
import { channel } from 'api/config';
import TYPES from 'ui/typography/pro.types'
import { Head, HeadWrapper, Logo } from '../../style';
import {
  CheckboxContainer,
  Text,
  Title,
  Link,
  TitleWrapper,
  Body,
  Image,
  ImageWrapper,
  ButtonWrapper,
  Close,
  CloseWrapper,
  AccessContainer,
  InputsWrapper,
  FooterWrapper, SignUp, Foot,
} from './style';
import Modal from "../../../../ui/components/Modals/SubscriptionB2C";

const Access = ({
  authenticationSignin,
  currentViewport,
  viewportTypes,
  pushUrl,
  rememberMe,
  domain,
  setFirstAccess,
}) => {

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const newField = () => ({
    value: undefined,
    valid: true
  });

  const initialForm = {
    email: newField(),
    password: newField()
  };

  const location = useLocation();
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    const params = location.search.slice(1, location.search.length).split('&');
    if (params[0]?.length) {
      // const newParams = params.map(str => ({
      //   key: str.split('=')[0],
      //   value: str.split('=')[1]
      // }));
      //TODO some check on queryParams
      setFirstAccess();
    }
  }, [location, setFirstAccess]);

  const handleOnChange = (field, newValue) =>
    setForm(s => {
      const newState = { ...s };
      newState[field].value = newValue;
      return newState;
    });

  const handleOnBlur = (type, field) => {
    setForm(s => {
      const newState = { ...s };
      newState[field].valid = validator[type](form[field].value);
      return newState;
    });
  };

  const formValid = (Object.keys(form) || []).map(key =>
    Boolean(form[key].value && form[key].value.length > 0 && form[key].valid)
  );
  const isValid = formValid.filter(bool => bool === false).length <= 0;

  const redirectHomepage = () => {
    pushUrl(routes.landingPage.path);
  };



  const handleAuthentication = () => {
    const params = location.search.slice(1, location.search.length).split('&');
    // let code;
    // if (params[0]?.length) {
    //   code = params.map(str => ({
    //      key: str.split('=')[0],
    //      value: str.split('=')[1]
    //    }));
    // }
      authenticationSignin({
        username: form.email?.value,
        passwordUser: form.password?.value,
        channel
      });

  };
  return (
    <>
      <HeadWrapper>
        <Container>
          <Head>
            <Logo onClick={redirectHomepage} img={domain?.brand?.logo_white} />
            <CloseWrapper onClick={() => pushUrl(routes.landingPage.path)}>
              <Close className="icon-close-x" />
            </CloseWrapper>
          </Head>
        </Container>
      </HeadWrapper>
      <Body>
        <ImageWrapper>
          <Image alt="" src={domain.image_login} />
        </ImageWrapper>
        <AccessContainer>
          <TitleWrapper>
            <Title>
              <ProDisplay
                type={TYPES.DISPLAY.LOGIN_TITLE}
                currentViewport={currentViewport}
              >
                Accedi a Digit'Ed Med
              </ProDisplay>
            </Title>
            <Text>
              <ProText
                type={TYPES.TEXT.LOGIN_SUB_TITLE}
                currentViewport={currentViewport}
              >
                Entra in piattaforma e coltiva il tuo talento
              </ProText>
            </Text>
          </TitleWrapper>
          <InputsWrapper>
            <label htmlFor="email-input" className="sr-only">Email Input</label>
            <CustomInput
              id="email-input"
              type="text"
              placeholder="Indirizzo email"
              onChange={val => handleOnChange('email', val)}
              onBlur={() => handleOnBlur('email', 'email')}
              error={!form.email.valid}
              currentViewport={currentViewport}
            />
            <label htmlFor="password-input" className="sr-only">Password Input</label>
            <CustomInput
              id="password-input"
              type="password"
              placeholder="Password"
              onChange={val => handleOnChange('password', val)}
              error={!form.password.valid}
              currentViewport={currentViewport}
            />
            <CheckboxContainer>
              <Checkbox
                onChange={rememberMe}
                label="Ricordami"
              />
              <Link onClick={() => pushUrl(routes.resetPassword.path)}>
                <ProText
                  type={TYPES.TEXT.LOGIN_PASSWORD_RECOVERY}
                  currentViewport={currentViewport}
                >
                  Recupera password
                </ProText>
              </Link>
            </CheckboxContainer>
          </InputsWrapper>
          <FooterWrapper>
            <ButtonWrapper>
              <Button
                on
                type={Button.TYPE.LOGIN}
                text="Accedi"
                disabled={!isValid}
                submit
                onClick={(e) => {
                  e.preventDefault();
                  handleAuthentication();
                }}
              />
            </ButtonWrapper>
            <Foot>
              <ProText
                  type={TYPES.TEXT.LOGIN_SIGNUP_CTA}
                  currentViewport={currentViewport}
              >
                Non sei ancora registrato?
                <SignUp onClick={openModal}>
                  Iscriviti ora
                </SignUp>
              </ProText>
            </Foot>
            {isModalOpen && <Modal isOpen={isModalOpen} closeModal={closeModal} />}
          </FooterWrapper>
        </AccessContainer>
      </Body>
      <Footer />
    </>
  );
};

Access.propTypes = {
  // HOC withMediaQueries
  currentViewport: PropTypes.string.isRequired,
  viewportTypes: PropTypes.shape({
    IsPhone: PropTypes.oneOf([0]),
    IsTablet: PropTypes.oneOf([1]),
    IsLaptop: PropTypes.oneOf([2]),
    IsDesktop: PropTypes.oneOf([3]),
    IsLargeDesktop: PropTypes.oneOf([4])
  }),

  // HOC (connect, state)
  isFirstAccess: PropTypes.bool,
  domain: PropTypes.object,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func.isRequired,
  authenticationSignin: PropTypes.func.isRequired,
  rememberMe: PropTypes.func.isRequired,
  setFirstAccess: PropTypes.func,
  setStep: PropTypes.func
};

export default connect(
  state => {
    const { isFirstAccess } = state.landingPage;
    return {
      isFirstAccess,
      domain: state.domain
    };
  },
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
    authenticationSignin: payload =>
      dispatch({ type: AUTHENTICATION_SIGNIN._REQUEST, payload }),
    rememberMe: remember => dispatch({ type: REMEMBER_ME, remember }),
    setFirstAccess: () => dispatch({ type: FIRST_ACCESS_SET }),
    setStep: () => dispatch({ type: FIRST_PASSWORD_SET })
  })
)(withMediaQueries(Access));
