import ProDisplay from "ui/typography/proDisplay";
import React from "react";
import {withMediaQueries} from 'hoc/withMediaQueries';
import {ImageBox, PaddingContainer, Title} from "./style";
import SectionIscriviti from "../SectionIscriviti";

const SectionOffertaEcm = ({
    props
}) => {
    return (
        <>
            <PaddingContainer>
                <div className="row-flex">
                    <ImageBox>
                        <div className={'img'} style={{backgroundImage: 'url('+props.image+')'}}/>
                    </ImageBox>
                    <div>
                        <ProDisplay
                            configuration={{ fontSize: 40, fontWeight: 400 }}
                        >
                            <Title>
                                La nostra offerta ECM
                            </Title>
                        </ProDisplay>
                        <ProDisplay
                            configuration={{ fontSize: 25, fontWeight: 400 }}
                        >
                            <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
                        </ProDisplay>
                    </div>
                </div>
                <SectionIscriviti btnText={'Acquista corsi ECM'} defaultEcm={true}/>
            </PaddingContainer>
        </>
    )
}

export default withMediaQueries(SectionOffertaEcm);