import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import ProText from 'ui/typography/proText';
import ProDisplay from 'ui/typography/proDisplay';
import {
  GET_DOMAINS,
} from 'redux/actions/authentication';

import {
  BootCnt,
  Section3
} from './style';
import { HtmlWrapper } from 'pages/LandingPage/style';

const AccSection3 = ({props
}) => (
  <>
    <BootCnt>
      <Section3 className='container'>
        <div className="row my-4">
          <div className="col">
          <div className="card_custom py-5 mx-0 shadow-lg" id="section_3_img" data-aos="flip-up" style={{backgroundImage: 'url("'+props?.image+'")'}}>
                  <div className="col-12 col-md-7">
                    <ProDisplay
                      className="upper display-3 bg-transparent text-start about-us-title"
                        configuration={{ fontSize: 46, fontWeight: 700, }}>
                        <p>  {props?.title} </p> 
                      </ProDisplay>
                  </div>
                  <div className="col-12 col-md-9">
                    <ProDisplay
                      configuration={{ fontSize: 20, lineHeight: 1.5 }}
                      className=" px-4">
                        <HtmlWrapper 
                          dangerouslySetInnerHTML={{__html:props?.description}} 
                        />
                    </ProDisplay>
                  </div>
              </div>
          </div>
        </div>
      </Section3>
    </BootCnt>

  </>
);

AccSection3.propTypes = {
  // HOC withMediaQueries
  mediaIsDesktop: PropTypes.bool,
  mediaIsLaptop: PropTypes.bool,

  // HOC (connect, state)
  domains: PropTypes.array,

  // HOC (connect, dispatch)
  AccSection3: PropTypes.func,
};

export default connect(
  state => ({ domains: state.authentication.user.domainList }),
  dispatch => ({
    AccSection1: domain => dispatch({ type: GET_DOMAINS._REQUEST, domain }),
  })
)(withMediaQueries(AccSection3));
