import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { GET_FIVE_MINUTES } from 'redux/actions/fiveMinutes';
import {
  GET_CATEGORIES_USER,
  GET_COLLECTIONS,
  GET_EVENTS,
  GET_KEEP_WATCHING,
  GET_PACKAGES,
  GET_RECOMMENDED_COLLECTIONS,
  GET_SELECTIONS,
  SET_CATEGORIES_FILTER,
  SET_PLAYLIST_HISTORY,
  SET_SECTION,
  GET_ALL_CATEGORIES,
  GET_COURSES_EVENTS,
  GET_SKILLROOM,
  GET_CORSI_ECM,
  GET_EXPERT,
  GET_DR_SIM,
  RESET_PLAYLIST,
  GET_SKILLROM_PACKAGES,
  GET_CORSI_ECM_PACKAGES,
  GET_CATEGORIES_PILLS,
  GET_CATEGORY_PLAYLIST
} from 'redux/actions/playlist';
import routes from 'routes';
import { detailType } from 'utils/common';
import { getFirstLastDayOfMonth } from 'utils/date-format';
import {
  shouldUseMock,
} from 'api/mock';

/**
 * custom hook to fetch the config objects for the sections of the homepage
 * @param currentViewport the current viewport key
 * @param viewportTypes the map of available viewports
 * @returns a config object with a list of sections to display.
 */
const useSections = (currentViewport, viewportTypes) => {
  // HOC, state
  const categories = useSelector(state => state.playlist.categories);
  const categoriesPills = useSelector(state => state.playlist.categoriesPills);
  const collections = useSelector(state => state.playlist.collections.items);
  const domainSettings = useSelector(state => state.domain.domain_settings)
  const domainTitle = useSelector(state => state.domain.title);
  const domainId = useSelector(state => state.domain.id);
  const events = useSelector(state => state.playlist.events.plannedEvents);
  const fiveMinutes = useSelector(state => state.fiveMinutes.fiveMinutes);
  const keepWatching = useSelector(state => state.playlist.keepWatching);
  const packages = useSelector(state => state.playlist.packages.packageList);
  const recommendedCollections = useSelector(state => state.playlist.recommendedCollections.items);
  const router = useSelector(state => state.router);
  const selections = useSelector(state => state.playlist.selections.items);
  const evidenceItems = useSelector(state => state.playlist.evidence.items);
  const skillroomItems = useSelector(state => state.playlist.skillroom.items);
  const skillroomPackages = useSelector(state => state.playlist.skillroomPackages.items);
  const corsiEcmItems = useSelector(state => state.playlist.corsiEcm.items);
  const corsiEcmPackages = useSelector(state => state.playlist.corsiEcmPackages.items);
  const coursesEventsItems = useSelector(state => state.playlist.coursesEvents.items);
  const expertItems = useSelector(state => state.playlist.expert.items);
  const drSimItems = useSelector(state => state.playlist.drSim.items);
  // HOC, dispatch
  const dispatch = useDispatch();
  const getCategories = useCallback(() => dispatch({ type: GET_CATEGORIES_USER._REQUEST }), [dispatch]);
  const getCategoriesPills = useCallback(() => dispatch({ type: GET_CATEGORIES_PILLS._REQUEST }), [dispatch]);
  const getCategoryPlaylist = useCallback((filterId) => dispatch({ type: GET_CATEGORY_PLAYLIST._REQUEST, filterId }), [dispatch]);
  const getCollections = useCallback(() => dispatch({ type: GET_COLLECTIONS._REQUEST }), [dispatch]);
  const getRecommendedCollections = useCallback(() => dispatch({ type: GET_RECOMMENDED_COLLECTIONS._REQUEST }), [dispatch]);
  const getEvents = useCallback(({ startDate, endDate }) => dispatch({ type: GET_EVENTS._REQUEST, startDate, endDate }), [dispatch]);
  const getFiveMinutes = useCallback(() => dispatch({ type: GET_FIVE_MINUTES._REQUEST, forceReload: true }), [dispatch]);
  const getKeepWatching = useCallback(() => dispatch({ type: GET_KEEP_WATCHING._REQUEST }), [dispatch]);
  const getPackages = useCallback(() =>  dispatch({ type: GET_PACKAGES._REQUEST }), [dispatch]);
  const getSelections = useCallback(() => dispatch({ type: GET_SELECTIONS._REQUEST }), [dispatch]);
  const getEvidenceItems = useCallback(() => dispatch({ type: GET_ALL_CATEGORIES._REQUEST }), [dispatch]);
  const getSkillroomItems = useCallback(() => dispatch({ type: GET_SKILLROOM._REQUEST }), [dispatch]);
  const getSkillroomPackages = useCallback(() => dispatch({ type: GET_SKILLROM_PACKAGES._REQUEST }), [dispatch]);
  const getCorsiEcmPackages = useCallback(() => dispatch({ type: GET_CORSI_ECM_PACKAGES._REQUEST }), [dispatch]);
  const getCorsiEcmItems = useCallback(() => dispatch({ type: GET_CORSI_ECM._REQUEST }), [dispatch]);
  const getCoursesEventsItems = useCallback(() => dispatch({ type: GET_COURSES_EVENTS._REQUEST }), [dispatch]);
  const getDrSimItems = useCallback(() => dispatch({ type: GET_DR_SIM._REQUEST }), [dispatch]);
  const getExpertItems = useCallback(() => dispatch({ type: GET_EXPERT._REQUEST }), [dispatch]);
  const pushUrl = (url) => dispatch(push(url));
  const setFilters = useCallback((filters) => dispatch({ type: SET_CATEGORIES_FILTER, filters }), [dispatch]);
  const setPlaylistHistory = (source) => dispatch({ type: SET_PLAYLIST_HISTORY, source });
  const setSection = (section) => dispatch({ type: SET_SECTION, section });
  const resetPlaylist = useCallback(() => dispatch({ type: RESET_PLAYLIST._SUCCESS }), [dispatch]);
  useEffect(() => {
    switch(domainId) {
      //Prelios
      case 301:
      case 2485:
        {
          getSelections();
          getCollections();
          const { firstDay, lastDay } = getFirstLastDayOfMonth();
          getEvents({
            // startDate: getIsoDateStringFromDate(firstDay),
            // endDate: getIsoDateStringFromDate(lastDay),
            startDate: firstDay.getTime(),
            endDate: lastDay.getTime(),
          });
          getRecommendedCollections();
          getFiveMinutes();
          getKeepWatching();
          getCategories();
          setFilters({ filterId: undefined, name: '' });
        }
        break;
        //Accurate
        case 212:
        case 3581:
          {
            resetPlaylist(); //clear playlists
            getSelections();
            getEvidenceItems();
            getSkillroomPackages();
            //getSkillroomItems();
            getCoursesEventsItems();
            getExpertItems();
            getDrSimItems();
            const { firstDay, lastDay } = getFirstLastDayOfMonth();
            getEvents({
              // startDate: getIsoDateStringFromDate(firstDay),
              // endDate: getIsoDateStringFromDate(lastDay),
              startDate: firstDay.getTime(),
              endDate: lastDay.getTime(),
            });
            getRecommendedCollections();
            getFiveMinutes();
            getCorsiEcmPackages();
            getKeepWatching();
            getCategories();
            setFilters({ filterId: undefined, name: '' });
            getPackages();
            getCategoriesPills();
            getCategories();
          }
          break;
          //S4C
      default:
        {
          getFiveMinutes();
          getPackages();
          const { firstDay, lastDay } = getFirstLastDayOfMonth();
          getEvents({
            // startDate: getIsoDateStringFromDate(firstDay),
            // endDate: getIsoDateStringFromDate(lastDay),
            startDate: firstDay.getTime(),
            endDate: lastDay.getTime(),
          });
          getKeepWatching();
          getCategories();
          setFilters({ filterId: undefined, name: '' });
        }
    }
  }, [
    domainId,
    getCategories,
    getCollections,
    getEvents,
    getFiveMinutes,
    getKeepWatching,
    getPackages,
    getRecommendedCollections,
    getSelections,
    setFilters,
  ]);

  const bannerSection = {
    bannerImage: null,
    bannerText: 'Master English: unlock your language potential!',
    carouselCardClickHandler: null,
    carouselType: null,
    data: null,
    description: null,
    link: null,
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: null,
    showCalendar: null,
    title: null,
    type: 'banner',
    vertical: null,
  };

  const categoriesSection = {
    bannerImage: null,
    bannerLink: null,
    bannerLogo: null,
    bannerText: null,
    carouselCardClickHandler: null,
    carouselType: null,
    data: categoriesPills,
    description: null,
    getData: null,
    grayBg: null,
    link: null,
    pillsClickHandler: (pill) => {
      getCategoryPlaylist(pill)
      /*setFilters({
        filterId: pill.id,
        name: pill.title,
        filteredCollections: pill.items.map(i => ({ ...i, itemId: i.id })),
      });*/
      pushUrl(routes.allPlaylistsCategories.path);
    },
    shouldHide: !(Array.isArray(categoriesPills) && categoriesPills.length > 0),
    showCalendar: null,
    title: null,
    type: 'pills',
    vertical: null,
  };

  const collectionsSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'playlist',
        type: card.type,
      })
    },
    carouselType: 'card',
    data: collections?.slice(0,8),
    description: 'Approfondisci tematiche cross rispetto al tuo ruolo e sviluppa le Soft Skill',
    link: {
      text: 'Vedi tutti',
      to: `#`
    },
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(collections) && collections.length > 0),
    showCalendar: null,
    title: 'Formazione trasversale',
    type: 'carousel',
    vertical: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allPlaylistsCollections.path}`
    },
  };

  const eventsSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: null,
    carouselType: null,
    data: events,
    description: null,
    link: null,
    getData: ({ action, activeStartDate, value, view }) => {
      const { firstDay, lastDay } = getFirstLastDayOfMonth(activeStartDate);
      getEvents({
        // startDate: getIsoDateStringFromDate(firstDay),
        // endDate: getIsoDateStringFromDate(lastDay),
        startDate: firstDay.getTime(),
        endDate: lastDay.getTime(),
      });
    },
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(packages) && packages.some(pkg => pkg.packageType.toLowerCase() === 'premium')),
    showCalendar: (viewportTypes[currentViewport] > viewportTypes.mediaIsTablet),
    title: 'Eventi pianificati',
    type: 'calendar',
    vertical: viewportTypes[currentViewport] < viewportTypes.mediaIsLaptop,
  };

  const keepWatchingSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        type: detailType.COURSE,
        section: 'keepWatching',
      });
    },
    carouselType: detailType.COURSE,
    data: keepWatching,
    description: 'Prosegui nel tuo percorso formativo completando i contenuti già iniziati',
    link: {
      text: 'Vedi tutti',
      to: `${routes.allCourses.path}`
    },
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(keepWatching) && keepWatching.length > 0),
    showCalendar: null,
    title: 'Continua a guardare',
    type: 'carousel',
    vertical: null,
  };

  const newsSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'fiveMinutes',
        type: card.type === detailType.RELATED_COURSE
          ? detailType.COURSE
          : card.type,
        }
      )
    },
    carouselType: 'news',
    data: fiveMinutes,
    description: 'Resta aggiornato con le più importanti novità',
    link: {
      text: 'Vedi tutti',
      to: `${routes.allFiveMinutes.path}`
    },
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(fiveMinutes) && fiveMinutes.length > 0),
    showCalendar: null,
    title: 'News',
    type: 'carousel',
    vertical: null,
  };

  const packagesSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      pushUrl(`${routes.package.path}/${card.packageId}`)
    },
    carouselType: detailType.PACKAGE,
    data: packages,
    description: null,
    link: null,
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(packages) && packages.length > 0),
    showCalendar: null,
    title: 'La tua formazione',
    type: 'carousel',
    vertical: null,
  };

  const recommendedCollectionsSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'playlist',
        type: card.type,
      })
    },
    carouselType: 'card',
    data: recommendedCollections?.slice(0,8),
    description: 'Non accontentarti!\nPerfeziona la tua formazione: scopri nuovi contenuti per irrobustire la tua  conoscenza',
    link: {
      text: 'Vedi tutti',
      to: `#`
    },
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(recommendedCollections) && recommendedCollections.length > 0),
    showCalendar: null,
    title: 'Suggeriti per te',
    type: 'carousel',
    vertical: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allPlaylistsRecommendedCollections.path}`
    },
  };

  const drSimSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'playlist',
        type: card.type,
      })
    },
    carouselType: 'card',
    data: drSimItems?.slice(0,8),
    description: 'Migliora le tue abilità attraverso simulazioni di situazioni cliniche specifiche',
    link: {
      text: 'Vedi tutti',
      to: `#`
    },
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(drSimItems) && drSimItems.length > 0),
    showCalendar: null,
    title: 'Microsimulazioni',
    type: 'carousel',
    vertical: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allPlaylistsDrSim.path}`
    },
  };

  const selectionsSections = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'playlist',
        type: card.type,
      })
    },
    carouselType: 'card',
    data: selections?.slice(0,8),
    description: 'Qui puoi trovare i corsi obbligatori che dovrai svolgere',
    link: {
      text: 'Vedi tutti',
      to: `#`
    },
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(selections) && selections.length > 0),
    showCalendar: null,
    title: 'Formazione obbligatoria',
    type: 'carousel',
    vertical: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allPlaylistsSelections.path}`
    },
  };

  const evidenceSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'playlist',
        type: card.type,
      })
    },
    carouselType: 'card',
    data: evidenceItems?.slice(0,8),
    description: null,
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(evidenceItems) && evidenceItems.length > 0),
    showCalendar: null,
    title: 'In evidenza',
    type: 'carousel',
    vertical: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allPlaylistsPromoted.path}`
    },
  };

  const expertSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'playlist',
        type: card.type,
      })
    },
    carouselType: 'card',
    data: expertItems?.slice(0,8),
    description: `Ascolta i consigli dell'esperto`,
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(expertItems) && expertItems.length > 0),
    showCalendar: null,
    title: "Expert insights",
    type: 'carousel',
    vertical: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allPlaylistsExpert.path}`
    },
  };

  const skillroomSection = {
    /*bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'playlist',
        type: card.type,
      })
    },
    carouselType: 'card',
    data: skillroomItems?.slice(0,8),
    description: 'Esegui correttamente le procedure',
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(skillroomItems) && skillroomItems.length > 0),
    showCalendar: null,
    title: 'Skillroom',
    type: 'carousel',
    vertical: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allPlaylistsSkillroom.path}`
    },*/
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      pushUrl(`${routes.package.path}/${card.packageId}`)
    },
    carouselType: detailType.PACKAGE,
    data: skillroomPackages?.slice().sort((a, b) => a.title.localeCompare(b.title)),
    /*carouselType: detailType.COLLECTION,
    data: skillroomItems,*/
    description: "Approfondisci e perfeziona l'esecuzione delle procedure",
    link: null,
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(skillroomPackages) && skillroomPackages.length > 0),
    //shouldHide: !(Array.isArray(skillroomItems) && skillroomItems.length > 0),
    showCalendar: null,
    title: 'Skillroom',
    type: 'carousel',
    vertical: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allPlaylistsSkillroom.path}`
    }
  };

  const corsiEcmSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      pushUrl(`${routes.package.path}/${card.packageId}`)
    },
    carouselType: detailType.PACKAGE,
    data: corsiEcmPackages,
    description: 'Fruisci dei corsi e ottieni crediti ECM',
    link: null,
    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(corsiEcmPackages) && corsiEcmPackages.length > 0),
    //shouldHide: !(Array.isArray(skillroomItems) && skillroomItems.length > 0),
    showCalendar: null,
    title: 'Corsi ECM',
    type: 'carousel',
    vertical: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allPlaylistsCorsiEcm.path}`
    }
  };

  const coursesEventsSection = {
    bannerImage: null,
    bannerText: null,
    carouselCardClickHandler: (card) => {
      openDetails({
        id: card.itemId,
        section: 'playlist',
        type: card.type,
      })
    },
    carouselType: 'card',
    data: coursesEventsItems?.slice(0,8),
    description: 'Segui i corsi per approfondire le tue conoscenze e restare aggiornato sulle più recenti evidenze scientifiche',

    getData: null,
    grayBg: null,
    pillsClickHandler: null,
    shouldHide: !(Array.isArray(coursesEventsItems) && coursesEventsItems.length > 0),
    showCalendar: null,
    title: 'E-learning courses',
    type: 'carousel',
    vertical: null,
    link: {
      text: 'Vedi tutti',
      to: `${routes.allPlaylistsCourses.path}`
    },
  };

  const preliosSections = [
    { ...selectionsSections, grayBg: true },
    eventsSection,
    collectionsSection,
    bannerSection,
    recommendedCollectionsSection,
    newsSection,
    keepWatchingSection,
    categoriesSection,
    //{ ...packagesSection, grayBg: true },
  ];

  const s4cSections = [
    newsSection,
    { ...packagesSection, grayBg: true },
    { ...eventsSection, grayBg: true },
    { ...keepWatchingSection, grayBg: true },
    { ...categoriesSection },
  ];

  const accurateSections = [
    evidenceSection,
    {...skillroomSection, grayBg: true},
    {...drSimSection, grayBg: true},
    {...expertSection, grayBg: true},
    {...coursesEventsSection, grayBg: true},
    {...corsiEcmSection, grayBg: true},
    { ...eventsSection, grayBg: true },
    { ...keepWatchingSection, grayBg: true },
    newsSection,
    // { ...categoriesSection },
  ];

  const sections = {
    Prelios: preliosSections,
    Skills4Capital: s4cSections,
    Accurate: accurateSections,
  };

  switch(domainId) {
    case 301:
    case 2485:
      return sections['Prelios'];
    case 212:
    case 3581:
      return sections['Accurate'];
    default:
      return sections['Skills4Capital'];
  }

  function openDetails({id, type, section}) {
    setSection(section);
    setPlaylistHistory(router.location.pathname);
    switch(type) {
      case 'LIVE_EVENT':
      case 'VIRTUAL_EVENT':
        pushUrl(`${routes.event.path}/${id}`)
        break;
      case 'COLLECTION':
      case 'SELECTION':
        pushUrl(`${routes.detail.path}${routes.collectionDetails.path}/${id}`);
        break;
      default:
        pushUrl(`${routes.detail.path}/${type}/${id}`);

    }
  };
};

export default useSections;
