import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container } from 'ui/gridSystem';
import Modal, { stopEvent } from 'ui/components/Modals/Modal';
import { ModalGlobalContainer } from 'ui/components/Modals/Modal.style';
import { Button } from 'ui/atoms';
import { MODAL_CLOSE } from 'redux/actions';
import ProDisplay from 'ui/typography/proDisplay';
import { ModalWrapper } from '../InviteFriendModal/style';
import {
  Title,
  ButtonsWrapper,
  ContentWrapper,
  BtnContainer,
  Icon,
  CheckboxWrapper
} from './style';
import { RadioButton, Checkbox} from 'ui/atoms';
import { Radio } from 'ui/components/Modals/AgreementsCheckModal/style';
import { GET_SPONSORS, UPDATE_SPONSORS } from 'redux/actions/playlist';
import { push } from 'redux-first-history';
import {Spinner} from 'ui/components';

const modalId = 'SponsorModal';

const SponsorModal = ({ 
  onClose, 
  getSponsors, 
  updateSponsors, 
  ecmEventCode, 
  participationCode,
  itemId, 
  ecmEditionCode,
  ecmId,
  sponsors, 
  userEventId, 
  pushUrl, 
  previousLocation, 
  router, 
  getPreviousPath,
  playlist
 }) => {

 const [recluted, setRecluted] = useState('');
 const [userEventIdState, setUserEventId] = useState('');
 const [sponsorsList, setSponsorsList] = useState([]);
 const [sponsorsChecked, setSponsorsChecked] = useState([]);
 const [isLoading, setIsLoading] = useState(false);
 const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setSponsorsList(sponsors);
    if(sponsors.length > 0 || recluted === 'not-recluted') {
      setIsLoading(false);
    }
  }, [sponsors]);

  useEffect(() => {
    if (recluted === 'not-recluted')  {
      setDisabled(false);
    } else if (recluted === 'recluted' && Boolean(sponsorsChecked?.length)){
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [sponsorsChecked, recluted]);

  useEffect(() => {
    setUserEventId(userEventId)
  }, [userEventId]);

  const handleSetRecluted = (val) => {
    setRecluted(val);
    if(val !== 'recluted' && Boolean(sponsorsChecked?.length)) {
      setSponsorsChecked([]);
    } else if (val === 'recluted' && !sponsorsList.length) {
      setIsLoading(true);
      getSponsors(itemId, ecmEditionCode, ecmEventCode, participationCode);
    }
  }

  const handleSetSponsorChecked = (item, value) => {
    const sponsorCheckedFiltered = sponsorsChecked.filter(sponsor => sponsor.item !== item);
    let sponsorChecked = [
      ...sponsorCheckedFiltered, 
      {
        item: item,
        checked: value
      }
    ]

    const sponsorCheckedTrue = sponsorChecked.filter(sponsor => Boolean(sponsor.checked));
    setSponsorsChecked(sponsorCheckedTrue);
  }

  const handleSubmit = () => {
    const payload = {
      userEventId: Boolean(userEventIdState) ? userEventIdState : ecmId,
      sponsored: recluted === 'recluted',
      sponsors: Boolean(recluted) ? sponsorsChecked.map(sponsor => sponsor.item) : []
    }

    updateSponsors(payload);
      onClose();
    }

  const handleCloseRedirect = () => {
    onClose();
    pushUrl(
      (previousLocation && previousLocation !== router.location.pathname)
        ? previousLocation
        : getPreviousPath(router, playlist));
  }
  

  return (
    <Modal id={modalId} noClose={true}>
      <Container>
        <ModalWrapper>
          <ModalGlobalContainer onMouseDown={stopEvent} onClick={stopEvent}>
            <ContentWrapper>
              <Icon className="icon-close-x" onClick={() => handleCloseRedirect()} />
              <Title>
                <ProDisplay type="modalTitle">
                  Sei stato reclutato da un'azienda?
                </ProDisplay>
              </Title>
              <Radio className="inline start radio-container">
                  <RadioButton
                    label="Si"
                    value="recluted"
                    name="recluted"
                    checked={recluted === 'recluted'}
                    onChange={val => {
                      handleSetRecluted(val);
                    }}
                  />
                  <RadioButton
                    label="No"
                    value="not-recluted"
                    name="recluted"
                    checked={recluted === 'not-recluted'}
                    onChange={val => {
                      handleSetRecluted(val);
                    }}
                  />
                </Radio>
                {recluted === 'recluted' &&
                  <>
                    <div className='sponsors-wrapper mb-5'>
                      {sponsorsList?.length > 0 && !isLoading && <Title className='mb-4'>
                        <ProDisplay type="modalTitle">
                          Seleziona i tuoi sponsor
                        </ProDisplay>
                      </Title>}
                      <div className={`sponsors-items-container ${sponsorsList?.length > 4 ? 'scroll' : ''}`}>
                      {sponsorsList?.length > 0 && !isLoading &&
                          sponsorsList?.map((item, index) => (
                            <CheckboxWrapper key={`opt-${index}`} className='mb-2'>
                              <Checkbox
                                label={item}
                                onChange={(value) => handleSetSponsorChecked(item, value)}
                                value={item}
                              />
                            </CheckboxWrapper>
                          ))
                        }
                        {!!isLoading && <Spinner/>}
                      </div>
                    </div>
                  </>
                }
              <ButtonsWrapper>
                <BtnContainer>
                  <Button
                    onClick={() => handleCloseRedirect()}
                    type={Button.TYPE.SECONDARY}
                    text="Annulla"
                  />
                </BtnContainer>
                <BtnContainer>
                  <Button
                    onClick={() => handleSubmit()}
                    type={Button.TYPE.PRIMARY}
                    text="Salva"
                    disabled={disabled}
                  />
                </BtnContainer>
              </ButtonsWrapper>
            </ContentWrapper>
          </ModalGlobalContainer>
        </ModalWrapper>
      </Container>
    </Modal>
  );
};

SponsorModal.propTypes = {
  onClick: PropTypes.func,
  // HOC (connect, dispatch)
  onClose: PropTypes.func.isRequired,
  pushUrl: PropTypes.func,
  getSponsors: PropTypes.func.isRequired,
  updateSponsors: PropTypes.func.isRequired,
  shareRequest: PropTypes.object,
  shareComment: PropTypes.func,
  ecmEventCode: PropTypes.string,
  ecmEditionCode: PropTypes.string,
  participationCode: PropTypes.string,
  itemId: PropTypes.string,
  ecmId: PropTypes.string,
  sponsors: PropTypes.array,
  userEventId: PropTypes.string
};

SponsorModal.id = modalId;
export default connect(
  state => {
    const { sponsors, userEventId } = state.playlist
    return {
      sponsors,
      userEventId
    }
  },
  dispatch => ({
    pushUrl: url =>
    dispatch(push(url)),
    onClose: () => dispatch({ type: MODAL_CLOSE }),
    getSponsors: (itemId, ecmEditionCode, ecmEventCode, participationCode) => dispatch({ type: GET_SPONSORS._REQUEST, itemId, ecmEditionCode, ecmEventCode, participationCode }),
    updateSponsors: (payload) => dispatch({ type: UPDATE_SPONSORS._REQUEST, payload })
  })
)(SponsorModal);
