import jsonp from 'jsonp';
import {
  getPaymentGetwaysMock,
  shouldUseMock,
  getPaymentMethodsMock,
  getClientSecretMock,
  getResourceCommerceOrderMock,
  getZuoraSignatureMock, getCommerceOrdersZuoraMock, setDefaultPaymentMethodZuoraMock
} from './mock';
import { apiCall, baseUrl, extendUrl, parseQueryParams } from './config';

export default {
  // Billings
  getPaymentGateways: ({ userId }) => {
    if (shouldUseMock()) {
      return getPaymentGetwaysMock();
    }
    return apiCall(`${baseUrl}${extendUrl}payment-gateways`, 'GET', null, {
      isDrupal: true,
      isPrivate: true,
      userId
    });
  },
  getPaymentMethods: ({ zuoraaccountnumber }) => {
    if (shouldUseMock()) {
      return getPaymentMethodsMock();
    }
    return apiCall(`/ecommerce/api/frontend/account/payment/list/${zuoraaccountnumber}`, 'GET');
  },
  getClientSecret: ({ userId, fromRoute: from_route = 'registration', zuoraAccountNumber, zuoraAccountId }) => {
    if (shouldUseMock()) {
      return getClientSecretMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}payment-provider/stripe/setup-intent`,
      'POST',
      {
        from_route
      },
      { isDrupal: true, isPrivate: true, userId, zuoraAccountNumber, zuoraAccountId }
    );
  },
  getZuoraSignature: ({ payment_method }) => {
    if (shouldUseMock()) {
      return getZuoraSignatureMock();
    }
    return apiCall(
      `${baseUrl}${extendUrl}zuora/signature`,
      'POST',
      {
        payment_method
      },
      { isDrupal: true, isPrivate: true }
    );
  },
  addPaymentMethodZuora: body => {
    if (shouldUseMock()) {
      return setDefaultPaymentMethodZuoraMock();
    }
    return apiCall(
      '/ecommerce/api/frontend/secure/account/payment/add',
      'POST',
      body,
      { encryptBody: true }
    );
  },
  setDefaultPaymentMethodZuora: body => {
    if (shouldUseMock()) {
      return setDefaultPaymentMethodZuoraMock();
    }
    return apiCall(
      '/ecommerce/api/frontend/secure/account/payment/update',
      'POST',
      body,
      { encryptBody: true }
    );
  },
  getResourceCommerceOrder: ({ userId, orderId, state }) => {
    if (shouldUseMock()) {
      return getResourceCommerceOrderMock(orderId, state);
    }

    let params = '?take=1000000';
    if (userId) params = `${params}&euid=${userId}`;
    if (orderId) params = `${params}&id=${orderId}`;
    if (state) params = `${params}&state=${state}`;
    return apiCall(
      `${baseUrl}${extendUrl}resource/commerce-order${params}`,
      'GET',
      {},
      { isDrupal: true, isPrivate: true, userId }
    );
  },
  getCommerceOrdersZuora: ({ zuoraAccountNumber, nextPageOrderDate, nextPageId, items = 10 }) => {
    if (shouldUseMock()) {
      return getCommerceOrdersZuoraMock();
    }
    return apiCall(
      `/ecommerce/api/frontend/payments/${zuoraAccountNumber}${parseQueryParams({nextPageOrderDate, nextPageId, items})}`, 'GET'
    );
  },
  getAtecoCodes: ({ type, search, callback }) => {
    const encodedSearch = window.encodeURI(`{"${type}": "${search}:*"}`);
    jsonp(
      `https://indicepa.gov.it/ipa-dati/api/3/action/datastore_search?resource_id=6f0f7122-3312-47ac-a292-7a647986387e&limit=10&offset=0&fields=Descrizione_ateco%2C%20Codice_ateco%2C%20_id&distinct=true&sort=${type}&include_total=false&plain=false&q=${encodedSearch}`,
      null,
      (err, data) => {
        if (err) {
          console.error(err.message);
        } else if (callback) callback(data?.result?.records || []);
      }
    );
  }
};
