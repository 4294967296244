import styled, { css } from 'styled-components/macro';
import { detailType } from 'utils/common';

const getGridByType = props => {
  let style;
  switch (props.location) {
    case detailType.NEWS:
      style = css`
        grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));

        @media ${props => props.theme.device.desktop} {
          grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
        }

        @media ${props => props.theme.device.laptop} {
          grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
        }

        @media ${props => props.theme.device.tablet} {
          grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
        }

        @media ${props => props.theme.device.mobile} {
            grid-template-columns: repeat(3, 1fr);
            gap: 25px;

            > div { 
              width: 100%;
            }
          }

          @media only screen and (max-width: 470px){
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;

            > div { 
              width: 100%;
            }
          }
      `;
      break;

      case detailType.COURSE:
        style = css`
          grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
          gap: 20px;
  
          > div { 
            width: 100%;
          }
                  
          @media ${props => props.theme.device.laptop} {
            grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
          }
  
          @media ${props => props.theme.device.tablet} {
            grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
          }
  
          @media ${props => props.theme.device.mobile} {
              grid-template-columns: repeat(3, 1fr);
              gap: 25px;
  
              > div { 
                width: 100%;
              }
            }
  
            @media only screen and (max-width: 470px){
              grid-template-columns: repeat(2, 1fr);
              gap: 14px;
  
              > div { 
                width: 100%;
              }
            }
        `;
        break;

      case detailType.PLAYLIST:
        style = css`

          @media ${props => props.theme.device.laptop} {
            grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
          }

          @media ${props => props.theme.device.tablet} {
            grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
          }

          @media ${props => props.theme.device.mobile} {
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;

            > div { 
              width: 100%;
            }
          }

          @media only screen and (max-width: 470px){
            grid-template-columns: repeat(2, 1fr);
            gap: 14px;

            > div { 
              width: 100%;
            }
          }
        `;
        break;

        case detailType.PACKAGE:
          style = css`

            grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  
            @media ${props => props.theme.device.laptop} {
              grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
            }
  
            @media ${props => props.theme.device.tablet} {
              grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
            }
  
            @media ${props => props.theme.device.mobile} {
              grid-template-columns: repeat(2, 1fr);
              gap: 20px;
  
              > div { 
                width: 100%;
              }
            }
  
            @media only screen and (max-width: 470px){
              grid-template-columns: repeat(1, 1fr);
              gap: 14px;
  
              > div { 
                width: 100%;
              }
            }
          `;
          break;

    default:
      break;
  }

  return style;
};

export const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  gap: 30px 0;
  @media ${props => props.theme.device.laptop} {
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }
  @media ${props => props.theme.device.tablet} {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
  @media ${props => props.theme.device.mobile} {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  ${props => getGridByType(props)};
`;

export const ShowMore = styled.div`
  margin: 0 auto 100px;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
`;
