import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_BOOKMARK,
  REMOVE_BOOKMARK
} from "redux/actions/social";

/**
 * CUSTOM HOOK: useBookmarks,
 * (everything is memoized so the utilities can be depended upon)
 * @returns utilities to interact with bookmarks
 */
const useBookmarks = () => {
  // STORE, state
  const bookmarks = useSelector(state => state.social.bookmarks.bookmarklist);
  // STORE, dispatch
  const dispatch = useDispatch();
  const addBookmark = useCallback((payload) => dispatch({ type: ADD_BOOKMARK._REQUEST, payload }), [dispatch]);
  const removeBookmark = useCallback((payload) => dispatch({ type: REMOVE_BOOKMARK._REQUEST, payload }), [dispatch]);

  const handleBookmark = useCallback((data) => {
    const body = {
      itemId: data.itemId,
      type: data.type,
      title: data.title,
      imageUrl: data.cover,
      duration: data.duration ?? 0,
      format: data.format ?? '',
      completed: data.completed,
      coursesCount: data.coursesCount ?? parseInt(data?.progress?.split('/')[1]),
    };

    if (bookmarks.find(b => b.itemId?.toString() === data.itemId?.toString())) {
      removeBookmark({ itemId: body.itemId, itemType: body.type });
    } else addBookmark(body);
  }, [addBookmark, bookmarks, removeBookmark]);

  const isBookmarked = useCallback((item) => (
    bookmarks.find(bm => 
      bm.itemId?.toString() === item?.itemId?.toString()
    )
  ), [bookmarks]);

  return { handleBookmark , isBookmarked };
};

export default useBookmarks;