import styled, { css } from 'styled-components/macro';

export const ProfilePictureContainer = styled.div`
  height: ${props => props.heightContainer};
  width: ${props => props.widthContainer};
  background: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${props => props.src
    ? props.theme.colors.white
    : props.theme.colors.bitterLemon
  };
  border-radius: 100%;
  //margin-right: 33px;
  position: relative;
  //margin-bottom: 21px;
  background-color: ${props => props.theme.colors.primary.brandColor1};

  ${props =>
    !props.src &&
    css`
      &::before {
        font-family: 'ProDisplay', sans-serif;
        font-size: ${props.fontSizeInitials}px;
        font-style: normal;
        font-weight: 500;
        -webkit-letter-spacing: unset;
        -moz-letter-spacing: unset;
        -ms-letter-spacing: unset;
        letter-spacing: unset;
        line-height: 1.86;
        text-align: unset;
        text-transform: unset;

        content: attr(data-initials);
        position: absolute;
        color: ${props.theme.colors.white};
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`;

export const ChangePicture = styled.div`
  background-color: ${props => props.theme.colors.azureDark};
  color: ${props => props.theme.colors.white};
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
`;

export const Icon = styled.label`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
