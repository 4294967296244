import styled from 'styled-components/macro';

export const Head = styled.header`
  height: 90px;
  display: flex;
  align-items: center;
`;

export const IconBack = styled.div`
  width: 35px;
  height: 30px;
  padding-left: 5px;
  font-size: 30px;
  color: ${props => props.theme.colors.primary.base};
  transform: rotate(180deg);
  border-left: 1px solid ${props => props.theme.colors.lightSilver};
  cursor: pointer;
`;

export const Title = styled.h1`
  padding: 10px;
    display: flex;
    flex-direction: column;
  span{
    line-height: 1;
  }
`;

export const Text = styled.span`
  @media ${props => props.theme.device.mobile} {
    padding-left: 0;
  }
`;

export const BtnContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 100px;

  button {
    background: transparent;
    color: #33a7b2;
    padding: 0;
    margin: 0 auto;

    &:hover {
      background-color: transparent ;
    }
  }
`;
