import { createAsyncActionType } from './index';

export const stepsOnboarding = {
  STEP1: 'STEP1',
  STEP2: 'STEP2',
  STEP3: 'STEP3'
};

export const ONBOARDING_RESET_STEP = '@@onBoarding/RESET_STEP';
export const ONBOARDING_SET_STEP = '@@onBoarding/SET_STEP';
export const ONBOARDING_INITIAL_STATE = '@@onBoarding/ONBOARDING_INITIAL_STATE';

export const ONBOARDING_GET_ON_BOARDING = createAsyncActionType(
  'onBoarding',
  'GET_ON_BOARDING'
);

export const ONBOARDING_GET_NOT_CONFIGURED_ASSETS = createAsyncActionType(
  'onBoarding',
  'GET_NOT_CONFIGURED_ASSETS'
);

export const ONBOARDING_PUT_ASSETS_ACTIVE = createAsyncActionType(
  'onBoarding',
  'ASSETS_ACTIVE'
);

export const ONBOARDING_SELECT_ITEM = createAsyncActionType(
  'onBoarding',
  'SELECT_ITEM'
);

export const ONBOARDING_STORE_DATA = '@@onBoarding/STORE_DATA';

export const COMPLETE_MANAGER = createAsyncActionType(
  'onBoarding',
  'COMPLETE_MANAGER'
);

export const CREATE_USER_ONBOARDING = createAsyncActionType(
  'onBoarding',
  'CREATE_USER_ONBOARDING'
);

export const GET_ALL_COL = createAsyncActionType('onBoarding', 'GET_ALL_COL');

export const ONBOARDING_GET_PROFESSIONS = createAsyncActionType(
  'onBoarding',
  'SIGNUP_PROFESSIONS'
);
