import ProDisplay from "ui/typography/proDisplay";
import React, {useState} from "react";
import {withMediaQueries} from 'hoc/withMediaQueries';
import {AccordionItem, AccordionItems, AccordionPanel, EdgeLine, PaddingContainer, Title} from "./style";
import SectionIscriviti from "../SectionIscriviti";

const SectionAccordion = ({
    props
}) => {
    const [openedIndex, setOpenedIndex] = useState(0)

    return (
        <>
            <PaddingContainer>
                <div className="row-accordion">
                    <AccordionItems>
                        <ProDisplay
                            configuration={{ fontSize: 40, fontWeight: 400 }}
                        >
                            <Title>
                                {props.title}
                            </Title>
                        </ProDisplay>
                        {props.panels.map((panel,i) =>
                            <AccordionItem className={(openedIndex === i) ? 'active' : ''}>
                                <h4 onClick={()=>{setOpenedIndex(i)}}>{panel.title}</h4>
                                <p dangerouslySetInnerHTML={{ __html: panel.subtitle }}></p>
                            </AccordionItem>
                        )}
                    </AccordionItems>
                    <AccordionPanel>
                        <p dangerouslySetInnerHTML={{ __html: props.panels[openedIndex].description }}></p>
                        <div className={'img'} style={{backgroundImage: 'url('+props.panels[openedIndex].image+')'}}/>
                    </AccordionPanel>
                </div>
                <SectionIscriviti btnText={'Abbonati a Prime Academy'}/>
                {props.edgeLine && <EdgeLine></EdgeLine>}
            </PaddingContainer>
        </>
    )
}

export default withMediaQueries(SectionAccordion);