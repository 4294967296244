import styled from 'styled-components/macro';

export const ChangePasswordContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.transparent};
  flex-direction: column;
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.tablet} {
    margin-bottom: 100px;
  }
  @media ${props => props.theme.device.mobile} {
    margin-bottom: 25px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 3rem;
  /*height: calc(100vh - 50px - 50px);

  @media ${props => props.theme.device.tablet} {
    height: calc(100vh - 50px - 59px);
  }
  @media ${props => props.theme.device.mobile} {
    height: calc(100vh - 50px - 100px);
  }*/
`;

export const Title = styled.div`
  padding-bottom: 32px;

  color: ${props => props.theme.colors.primary.base};
`;

export const InputWrapper = styled.div`
  padding-bottom: 16px;

  &.select {
    padding-bottom: 32px;
  }
`;

export const ButtonWrapper = styled.div`
  max-width: 177px;
  min-width: 177px;
  padding-top: 32px;
  padding-bottom: 100px;
  margin: 0 auto;

  @media ${props => props.theme.device.tablet} {
    max-width: 233px;
  }
  @media ${props => props.theme.device.mobile} {
    max-width: 100%;
    margin-bottom: 15px;
  }
`;
