import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { POST_STATEMENTS, PUT_ACTIVITY } from 'redux/actions/tracking';
import PropTypes from 'prop-types';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { BRIGHT_COVE_ACCOUNT } from 'api/config';
import ProText from 'ui/typography/proText';
import { VideoPlaceholder, VideoWrapper } from './style';

const VideoPlayer = ({
  videoId,
  itemid,
  body,
  title,
  skipTraking = false,
  videoStatement,
  setSeconds,
  startFrom = '0',
  type,
  image,
  isSelectionType
}) => {
  const [player, setPlayer] = useState();
  const [hasError, setHasError] = useState(false);

  const onStartEnd = (typeStart, endTime = 0) => {
    const bodyVideo = {
      type: typeStart,
      itemId: itemid,
      title,
      description: body,
      duration:
        Math.round(endTime - startFrom) > 0
          ? Math.round(endTime - startFrom)
          : 0,
    };
    videoStatement(bodyVideo);
  };

  const onSkip = time => {
    const payload = {
      itemId: itemid,
      body: { val: time.toString() },
    };
    setSeconds(payload);
  };

  const onSuccess = success => {
    const { player_, controlBar } = success.ref;
    player_.currentTime(startFrom);
    player_.itemId = itemid;
    setPlayer(player_);

    if (type === 'training') {
      controlBar.progressControl.disable();
    }
    let click = 0;

    player_.on('play', () => {
      if (!skipTraking) {
        if (click === 0) {
          onStartEnd('initialized');
          click += 1;
        } else onSkip(player_.currentTime());
      }
    });
    player_.on('pause', () => {
      if (!skipTraking) {
        onSkip(player_.currentTime());
      }
    });
    player_.on('ended', () => {
      if (!skipTraking && player_.currentTime() - startFrom > 0.01) { onStartEnd('completed', player_.currentTime()); }
    });
  };

  const onFailure = () => {
    setHasError(true);
  };

  const onTerminate = (typeTerm, time, itemId) => {
    const payload = {
      type: typeTerm,
      itemId,
      title,
      description: body,
      body: { val: time.toString() },
      duration:
        Math.round(time - startFrom) > 0 ? Math.round(time - startFrom) : 0,
    };

    setSeconds(payload);
    videoStatement(payload);
  };

  const disposePlayer = () => {
    if (player && !player?.isDisposed_) {
      if (
        player?.hasStarted_ &&
        player.cache_?.currentTime < player.cache_?.duration &&
        !skipTraking
      ) {
        onTerminate('terminated', player.currentTime(), player.itemId);
      }

      player.dispose();
    }
  };

  useEffect(() =>
    () => {
      disposePlayer();
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [player]);

  useEffect(() => {
    if (
      player &&
      player?.hasStarted_ &&
      player.cache_?.currentTime < player.cache_?.duration &&
      !skipTraking
    ) {
      onTerminate('terminated', player.cache_?.currentTime, player.itemId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemid]);

  return (
    <VideoWrapper>
      {!hasError || !videoId ? (
          isSelectionType ?
            <ReactPlayerLoader
              accountId={BRIGHT_COVE_ACCOUNT}
              videoId={videoId}
              onSuccess={onSuccess}
              onFailure={onFailure}
              itemId={itemid}
              playerId={'mqN69WU1rf'}
              startFrom={startFrom}
              poster={image}
            /> :
        <ReactPlayerLoader
          accountId={BRIGHT_COVE_ACCOUNT}
          videoId={videoId}
          onSuccess={onSuccess}
          onFailure={onFailure}
          itemId={itemid}
          startFrom={startFrom}
          poster={image}
        />
      ) : (
        <VideoPlaceholder>
          <ProText>Error loading video</ProText>
        </VideoPlaceholder>
      )}
    </VideoWrapper>
  );
};

VideoPlayer.propTypes = {
  videoId: PropTypes.string,
  skipTraking: PropTypes.bool,
  title: PropTypes.string,
  videoStatement: PropTypes.func,
  setSeconds: PropTypes.func,
  startFrom: PropTypes.string,
  body: PropTypes.string,
  itemid: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string,
  isSelectionType: PropTypes.bool
};

export default connect(
    state => {
      const {
        type: playlistType
      } = state.playlist.playlistSelected;
      return {
        isSelectionType: playlistType === 'selection'
      }
    },
  dispatch => ({
    videoStatement: payload =>
      dispatch({ type: POST_STATEMENTS._REQUEST, payload }),
    setSeconds: payload => dispatch({ type: PUT_ACTIVITY._REQUEST, payload }),
  })
)(VideoPlayer);
