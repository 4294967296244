import styled from 'styled-components/macro';

export const TabsContaner = styled.div`
  display: flex;
  margin: 30px 0;
`;

export const Tab = styled.div`
  height: 32px;
  margin: 0 15px;
  padding: 6px 20px;
  border-radius: 16px;
  cursor: pointer;
  font-size:12px;
  display: inline-flex;
  align-items: center;
  color: ${props =>
    (props.active
      ? props.theme.colors.white
      : props.theme.colors.primary.brandColor1)};
  background-color: ${props =>
    (props.active ? props.theme.colors.primary.brandColor1 : props.theme.colors.white)};

border: 1px solid ${props => props.theme.colors.primary.brandColor1};
`;

export const TabContent = styled.div``;

export const PlaceholderList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 1rem;
  background-color: ${props => props.theme.colors.white};
`;
