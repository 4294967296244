import styled from "styled-components/macro";

export const CarouselWrapper = styled.div`
  margin: 0px 0px 46px;
  width: 100%;

  .xrDUb {
      @media ${props => props.theme.device.mobile} {
        width: 100%;
    }

    @media ${props => props.theme.device.tablet} {
        width: 100%;
    }
  }

  &.LIVE_EVENT{
    width: calc(100% + 20px - 556px - 30px);
  }
  .slick-slider .slick-list{
    padding-bottom: 20px;

    .slick-slide > div {
      padding: 0;
    }
  }
`;