import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { push } from 'redux-first-history';
import { connect } from 'react-redux';
import routes from 'routes';


import { withMediaQueries } from 'hoc/withMediaQueries';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import ProgressBar from 'ui/atoms/ProgressBar';
import colors from 'ui/theme/partials/colors';
import { desaturateColor, shadeColor } from 'utils/common';
import ProgressBarCircular from 'ui/atoms/ProgressBarCircular';
import { detailType } from 'utils/common';
import { SET_PLAYLIST_HISTORY } from 'redux/actions/playlist';
import {
  Header,
  HeaderContainer,
  ProfileProgressCardContainer,
  TitleWrapper,
  OverTitle,
  Title,
  CollectionContainer,
  CollectionTitle,
  CollectionInfo,
  IconCollection,
  CollectionContents,
  ProgressBarWrapper,
  InfoWrapper,
  CounterConainer,
  Counter,
  IconWrapper,
  IconArrow
} from './style';

const ProfileProgressCard = ({
  data,
  title,
  themeColor,
  bgColorLighter,
  mediaIsPhone,
  mediaIsTablet,
  isSelection,
  pushUrl,
  setPlaylistHistory,
  router
}) => {
  const getPercentage = (contents = '') => {
    const contentsList = contents.split('/');
    return (contentsList[0] / contentsList[1]) * 100;
  };

  const [showMore, setShowMore] = useState(!(mediaIsPhone || mediaIsTablet));
  useEffect(() => {
    if (!(mediaIsPhone || mediaIsTablet)) setShowMore(true);
    else setShowMore(false);
  }, [mediaIsPhone, mediaIsTablet]);

  return (
    <ProfileProgressCardContainer>
      <Header
        bgColorLighter={bgColorLighter}
        hasContents={data.items.length > 0}
        onClick={() => {
          if (!(mediaIsPhone || mediaIsTablet)) return;
          setShowMore(!showMore);
        }}
      >
        <HeaderContainer>
          <TitleWrapper>
            <OverTitle>
              <ProDisplay
                type="1"
                configuration={{ lineHeight: 1.33, color: 'white' }}
              >
                {data.progress} COMPLETATI
              </ProDisplay>
            </OverTitle>
            <Title>
              <ProDisplay type="progressCardTitle">{title}</ProDisplay>
            </Title>
          </TitleWrapper>
          <ProgressBarCircular
            percentage={parseInt(data.percentage)}
            bgColorLighter={bgColorLighter}
          />
        </HeaderContainer>
        {(mediaIsPhone || mediaIsTablet) && data.items.length > 0 && (
          <IconWrapper>
            <IconArrow
              className={showMore ? 'icon-btn-arrow open' : 'icon-btn-arrow'}
              open={showMore}
            />
          </IconWrapper>
        )}
      </Header>
      {showMore &&
        data.items.length > 0 &&
        data.items.map((collection, index) => (
          <CollectionContainer key={`item-${index}`}>
            <InfoWrapper>
              <CollectionTitle onClick={() => {
                  setPlaylistHistory(router.location.pathname);
                  console.log(router.location.pathname);
                  pushUrl(`${routes.detail.path}/${detailType.PLAYLIST}/${collection.itemId}`);
              }}>
                <ProText
                  type="courseTitle"
                  configuration={{ color: 'moonstone', fontWeight: 500 }}
                >
                  {collection.title}
                </ProText>
              </CollectionTitle>
              <CollectionInfo>
                <IconCollection className="icon-icona-collection-copy-1" />
                <CollectionContents>
                  <ProText
                    type="courseDuration"
                    configuration={{ color: 'nickel' }}
                  >
                    {collection?.progress} Contenuti
                  </ProText>
                </CollectionContents>
                <ProgressBarWrapper>
                  <ProgressBar
                    percentage={getPercentage(collection.progress)}
                    color1={desaturateColor(
                      shadeColor(colors(themeColor).primary.lighter, 40),
                      50
                    )}
                    color2={colors(themeColor).primary.lighter}
                  />
                </ProgressBarWrapper>
              </CollectionInfo>
            </InfoWrapper>
            {isSelection && (
              <CounterConainer>
                <Counter>
                  <ProDisplay type="1">Tempo permanenza</ProDisplay>
                  <ProDisplay type="1">{collection.sessionTime}</ProDisplay>
                </Counter>
                <Counter>
                  <ProDisplay type="1">Tempo totale</ProDisplay>
                  <ProDisplay type="1">{collection.totalDuration}</ProDisplay>
                </Counter>
              </CounterConainer>
            )}
          </CollectionContainer>
        ))}
    </ProfileProgressCardContainer>
  );
};

ProfileProgressCard.propTypes = {
  data: PropTypes.object,
  bgColorLighter: PropTypes.bool,
  title: PropTypes.string,
  isSelection: PropTypes.bool,

  router: PropTypes.object,
  //HOC (connect, state)
  themeColor: PropTypes.string,
  //HOC (connect, dispatch)

  setPlaylistHistory: PropTypes.func,
  pushUrl: PropTypes.func.isRequired,

  // HOC (withMediaQueries)
  mediaIsPhone: PropTypes.bool.isRequired,
  mediaIsTablet: PropTypes.bool.isRequired,
};

export default withMediaQueries(
  connect(
    state => {

      const { router } = state;
      const {
        brand: { color: themeColor }
      } = state.domain;
      return {
        themeColor,
        router
      };
    },
    dispatch => ({
      pushUrl: url => dispatch(push(url)),
      setPlaylistHistory: source => dispatch({ type: SET_PLAYLIST_HISTORY, source })
    })
  )(ProfileProgressCard)
);
