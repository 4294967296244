import { withMediaQueries } from 'hoc/withMediaQueries';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { mappingVariations, removeDuplicates } from '.';
import { TableCardEmpty } from '..';
import { all } from 'redux-saga/effects';

const EmptyCards = ({
  subscription,
  allSubscriptions,
  calendars,
  email = null,
  options,
  parsedProspects,
  handleOnChangeVariation,
  handleSelectCalendar,
  mediaIsPhone,
  handleOnChangeRole,
  handleOnChangeEmail,
  handleOnChangeEcmCredits,
  handleOnChangeFinalTestEnabled,
  isBundle,
  isMultipleBundle,
  ecmMaxCrediti
}) => {
  const [cardOptions, setCardOptions] = useState(options);

  useEffect(() => {
    if (!subscription.variations[0]?.length) {
      const checkedVariations = subscription.variations.map(mappingVariations);

      const checkVariation = subscription.variations.find(va => {
        if (!email) return false;
        const prospect = isBundle ? undefined : parsedProspects.find(p => p.email === email);
        if (!prospect) return false;
        const offert = (prospect.offerts || []).find(
            o => va.variationId === o.variationId && o.status.toLowerCase() !== 'inactive'
        );
        if (!offert) return false;
        return true;
      });

      if (checkVariation)
        handleOnChangeVariation(subscription.id, subscription.variations, []);

      let allOptions = [
        ...options.filter(va => {
          /* check if variation is already assigned to email in a different card */
          if (!email) return true;
          const row = allSubscriptions.find(sub => (
              sub.email?.toLowerCase() === email?.toLowerCase() && sub.variations[0]?.variationId === va.value
          ));
          if (row) return false;
          /* check of variation is already assigned to email in the back end */
          const prospect = isBundle ? undefined : parsedProspects.find(p => p.email === email);
          if (!prospect) return true;
          const offert = (prospect.offerts || []).find(
              o => va.value === o.variationId && o.status.toLowerCase() !== 'inactive'
          );
          if (!offert) return true;
          /* otherwise let the option be selectable */
          return false;
        }),
        ...checkedVariations
      ];


      let subscriptionsPopulated = [];
      subscriptionsPopulated = [
        ...subscriptionsPopulated, subscription
      ]

      const bundleOptions = allOptions.filter(
          opt => !!opt.bundleId && allSubscriptions.filter(sub => sub.bundleId === opt.bundleId && !!sub.email && !!sub.variations.length).length < opt.quantity)

      setCardOptions(
          !!isBundle ?
              Array(bundleOptions[0]).sort((a, b) =>
                  a.label.localeCompare(b.label)
              ) :

              removeDuplicates(allOptions.filter(opt => !opt.bundleId), it => it.value).sort((a, b) =>
                  a.label.localeCompare(b.label)
              )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, options, subscription]);

    return (
      <TableCardEmpty
        isBundle={isBundle}
        isMultipleBundle={isMultipleBundle}
        handleOnChangeEmail={handleOnChangeEmail}
        handleOnChangeEcmCredits={handleOnChangeEcmCredits}
        handleOnChangeFinalTestEnabled={handleOnChangeFinalTestEnabled}
        handleOnChangeVariation={handleOnChangeVariation}
        handleSelectCalendar={handleSelectCalendar}
        handleOnChangeRole={handleOnChangeRole}
        subscription={subscription}
        emailName={email}
        ecmMaxCrediti={ecmMaxCrediti}
        options={removeDuplicates(cardOptions, it => it?.value)}
        calendarDates={
          /* requires single select on variations  */
          subscription?.variations[0]?.productId
          ? calendars[subscription?.variations[0]?.productId]
          : []
        }
      />
    );

};

EmptyCards.propTypes = {
  subscription: PropTypes.object,
  allSubscriptions: PropTypes.array,
  email: PropTypes.string,
  handleOnChangeEmail: PropTypes.func,
  handleOnChangeRole: PropTypes.func,
  handleOnChangeVariation: PropTypes.func,
  mediaIsPhone: PropTypes.bool.isRequired,
  options: PropTypes.array,
  parsedProspects: PropTypes.array,
  isBundle: PropTypes.bool,
  isMultipleBundle: PropTypes.bool,
  ecmMaxCrediti: PropTypes.number,
};

EmptyCards.defaultProps = {
  allSubscriptions: []
}

export default withMediaQueries(EmptyCards);
