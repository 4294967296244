import { createAsyncActionType } from './index';

export const GET_ECM_CREDITI_ACQUISITI = createAsyncActionType(
    'ecm',
    'GET_ECM_CREDITI_ACQUISITI'
);
export const GET_SPECIALIZATIONS_PACKAGE_ECM_CREDITS = createAsyncActionType(
    'ecm',
    'GET_SPECIALIZATIONS_PACKAGE_ECM_CREDITS'
);
export const GET_ECM_MAX_CREDITI_ACQUISITIBILI = createAsyncActionType(
    'ecm',
    'GET_ECM_MAX_CREDITI_ACQUISITIBILI'
);