import { getFiveMinutesMock, getNewsMock, shouldUseMock } from './mock';
import { apiCall, baseUrl, extendUrl, catalogPrefix } from './config';

export default {
  fiveMinutes: userId => {
    if (shouldUseMock()) {
      return getFiveMinutesMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}fiveminutes?userId=${userId}&htmlEnabled=true`,
      'GET'
    );
  },
  getNews: ({ userId, itemId }) => {
    if (shouldUseMock()) {
      return getNewsMock();
    }
    return apiCall(
      `${catalogPrefix}${baseUrl}${extendUrl}catalog/detail/news?itemId=${itemId}&userId=${userId}`,
      'GET'
    );
  }
};
