import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Col, Container, Row } from 'ui/gridSystem';
import { Title } from 'pages/Signup/steps/UserAccount/style';
import { withMediaQueries } from 'hoc/withMediaQueries';
import {
  SIGNUP_NEXT_STEP,
  SIGNUP_UPDATE_PRODUCTS_CART,
} from 'redux/actions/signup';

import ProText from 'ui/typography/proText';
import ProDisplay from 'ui/typography/proDisplay';
import {
  LandingPageInterface,
  ProductVariationInterface,
} from 'interface/signup';
import ProductSubscription from 'ui/components/ProductSubscription';
import { HtmlRaw } from 'ui/components';
import { TitleContainer, SubTitle, NoResult } from './style';
import {useLocation} from "react-router-dom";
import ToggleSwitch from "ui/atoms/ToggleSwitch";
export const removeDuplicates = (array, key) => [...new Map(array.map(x => [key(x), x])).values()];

const Subscriptions = ({
  updateCart,
  mediaIsPhone,
  landingProducts,
  products,
  aggregatedProducts,
  step,
  setStepSubscriptions,
  authentication,
  cart,
}) => {
  const [mappedProds, setMappedProds] = useState([]);
  const [mappedAggregatedProds, setMappedAggregatedProds] = useState([]);
  const location = useLocation();
  const searchParams = useRef(new URLSearchParams(location.search));
  const [isStudent, setIsStudent] = useState(searchParams.current.get('student'));
  const [isVetrinaEcm, setIsVetrinaEcm] = useState(false);

  useEffect(() => {
    if (!searchParams.current.get('student')) {
      setIsStudent(authentication.user.userDomainAttributes?.student.toString());
    }
  }, [searchParams.current, authentication]);

  useEffect(()=>{
    if (searchParams.current.get('defaultEcm') && (isStudent !== 'true')){
      setIsVetrinaEcm(true);
    }
  },[searchParams.current])

  const updateVal = (num, pack) => {
    updateCart({ ...pack, num });
  };

  useEffect(() => {
    const flatProduct = ({ variations, ...props }) =>
      variations.map(v => ({ ...props, ...v }));
    const allProducts = products.reduce(
      (accumulator, currentValue) => [
        ...accumulator,
        ...flatProduct(currentValue),
      ],
      []
    );
    const outArray = [];

    allProducts.forEach(product => {
      if (Array.isArray(product.cards)) {
        const ref = product.cards.find(
          c => c.card_type === 'card_product_variation_relation'
        );
        if (ref) {
          const father = allProducts.find(
            f => f.variation_id.toString() === ref.product_variation_ref
          );
          if (father) {
            const fatherIndex = outArray.findIndex(
              o =>
                o.product_id === father.product_id &&
                o.variation_id === father.variation_id
            );
            if (fatherIndex > -1) {
              outArray[fatherIndex].child_products = [
                ...(outArray[fatherIndex].child_products || []),
                product,
              ];
            } else {
              father.child_products = [product];
              outArray.push(father);
            }
          }
        } else {
          outArray.push(product);
        }
      } else {
        const fatherIndex = outArray.findIndex(
          o =>
            o.product_id === product.product_id &&
            o.variation_id === product.variation_id
        );
        if (!(fatherIndex > -1)) outArray.push(product);
      }
    });
    const filteredByEcmProducts = filterEcm(outArray, isVetrinaEcm);
    setMappedProds(filteredByEcmProducts);
  }, [products, isVetrinaEcm]);

  const filterEcm = (_products, isEcm) => {
    if (_products.length < 1){
      return _products;
    }

    // mappedAggregatedProds
    if (_products[0].products){
      return _products.filter(prod => prod.products.data.find(p=>p.variations.data.find(variation => (isEcm ? (variation.flag_ecm === 'True') : (variation.flag_ecm !== 'True')))))
    }

    // Not aggregated products
    return _products.filter(prod => (isEcm ? (prod.flag_ecm === 'True') : (prod.flag_ecm !== 'True')));
  }

  //AGGREGATI
  useEffect(() => {
    const filteredByEcmProducts = filterEcm(aggregatedProducts, isVetrinaEcm);
    setMappedAggregatedProds(filteredByEcmProducts);
  }, [aggregatedProducts,isVetrinaEcm]);

  //GET AGGREGATED CHILD PRODUCTS
  const getMappedAggregatedProdsChild = (productsAggregatedChild, aggregatedPack) => {
      const flatProduct = ({ variations, ...props }) =>
      variations.map(v => ({ ...props, ...v }));

      const allMappedProduct = productsAggregatedChild.data.map(p => ({
        ...p,
          variations: p.variations.data,
          bundleName: aggregatedPack.title,
          bundleCode: aggregatedPack.field_bundlecode,
          // bundleCode: aggregatedPack.id,
          /*calendarChoice: p.calendar_choice,
          ecmFlag: p.flag_ecm,
          packageType: p.is_premium === true || p.is_premium === 'True' ? 'premium' : 'base',
          isPremium: p.is_premium === true || p.is_premium === 'True'*/
        }))
      const allProducts = allMappedProduct.reduce(
      (accumulator, currentValue) => [
        ...accumulator,
        ...flatProduct(currentValue),
      ],
      []
    );
    const outArray = [];

    allProducts.forEach(product => {
      if (Array.isArray(product.cards)) {
        const ref = product.cards.find(
          c => c.card_type === 'card_product_variation_relation'
        );
        if (ref) {
          const father = allProducts.find(
            f => f.variation_id.toString() === ref.product_variation_ref
          );
          if (father) {
            const fatherIndex = outArray.findIndex(
              o =>
                o.product_id === father.product_id &&
                o.variation_id === father.variation_id
            );
            if (fatherIndex > -1) {
              outArray[fatherIndex].child_products = [
                ...(outArray[fatherIndex].child_products || []),
                product,
              ];
            } else {
              father.child_products = [product];
              outArray.push(father);
            }
          }
        } else {
          outArray.push(product);
        }
      } else {
        const fatherIndex = outArray.findIndex(
          o =>
            o.product_id === product.product_id &&
            o.variation_id === product.variation_id
        );
        if (!(fatherIndex > -1)) outArray.push(product);
      }
    });
    return removeDuplicates(outArray, it => it.variation_id);
  }

  useEffect(() => {
    if (step.num !== 1) {
      setStepSubscriptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Row>
        <Col lg={1} md={12} sm={12}>
          &nbsp;
        </Col>
        <Col lg={10} md={12} sm={12}>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <TitleContainer>
                <Title>
                  <ProDisplay
                    type="modalTitle"
                    configuration={
                      mediaIsPhone
                        ? { letterSpacing: 'normal' }
                        : { fontWeight: 'normal' }
                    }
                  >
                    {landingProducts?.title}
                  </ProDisplay>
                </Title>
                <SubTitle>
                  <ProText type="description">
                    <HtmlRaw html={landingProducts?.subtitle} />
                  </ProText>
                </SubTitle>
              </TitleContainer>
            </Col>
          </Row>
          {(isStudent !== 'true') && <Row style={{marginBottom: '20px'}}>
            <ToggleSwitch active={!isVetrinaEcm} toggleActive={() => setIsVetrinaEcm(oldValue => !oldValue)}/>
          </Row>}
          {mappedAggregatedProds.map(aggregatedPack => (
            <ProductSubscription
              aggregatedTitle={aggregatedPack.title}
              aggregatedSubtitle={aggregatedPack.subtitle}
              isAggregated = { true }
              products = { getMappedAggregatedProdsChild(aggregatedPack.products, aggregatedPack) }
              onChangeQuantity={(num, aggregatedPack) => updateVal(num, aggregatedPack)}
              cart={cart}
            />
          ))}
          {mappedProds.filter(p => !(isStudent == 'true' && p.flag_ecm === 'True')).length < 1 ? <NoResult>Nessun risultato</NoResult> : ''}
          {mappedProds.filter(p => !(isStudent == 'true' && p.flag_ecm === 'True')).map(pack => (
            <ProductSubscription
              isAggregated = { false }
              key={pack.product_id}
              product={pack}
              onChangeQuantity={(num, product) => updateVal(num, product)}
              cart={cart}
            />
          ))}
        </Col>
        <Col lg={1} md={12} sm={12}>
          &nbsp;
        </Col>
      </Row>
    </Container>
  );
};

Subscriptions.propTypes = {
  mediaIsPhone: PropTypes.bool.isRequired,
  cart: PropTypes.object,

  // HOC (connect, state)
  products: ProductVariationInterface,
  aggregatedProducts: PropTypes.array,
  authentication: PropTypes.array,
  landingProducts: LandingPageInterface,
  step: PropTypes.object,
  // HOC (connect, dispatch)
  updateCart: PropTypes.func.isRequired,
  setStepSubscriptions: PropTypes.func.isRequired,
};

export default connect(
  state => {
    const { products, aggregatedProducts, step } = state.signup;
    const { authentication } = state;
    const { landingProducts } = state.landingPage;
    return {
      landingProducts,
      products,
      aggregatedProducts,
      authentication,
      step,
    };
  },
  dispatch => ({
    updateCart: product =>
      dispatch({ type: SIGNUP_UPDATE_PRODUCTS_CART, product }),
    setStepSubscriptions: () =>
      dispatch({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 0 }),
  })
)(withMediaQueries(Subscriptions));
