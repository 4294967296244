import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'ui/gridSystem';
import { Button } from 'ui/atoms/index';
import ProDisplay from 'ui/typography/proDisplay';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { priceFormat } from 'utils/price-format';
import {
  FooterRegistrationContainer,
  Price,
  TotalPrice,
  Foot,
  ButtonWrapper,
  Label,
  PriceWrapper,
  BtnsWrapper,
  SubscriptionPrice,
} from './style';

const PurchaseFooter = ({
  price,
  btnText,
  btnBackText,
  btnOnClick,
  onClickBack,
  btnDisabled,
  isPremium,
  mediaIsPhone,
  subscriptionPrice,
  // trackingCallback = () => {},
}) => (
  <FooterRegistrationContainer id="registration-footer">
    <Container>
      <Foot>
        <PriceWrapper>
          {isPremium ? (
            <Label>
              <ProDisplay type="tableLabel">
                Al tuo abbonamento verrà aggiunto
              </ProDisplay>
            </Label>
          ) : (
            ''
          )}
          {mediaIsPhone ? (
            <ProDisplay
              type="3"
              configuration={{ lineHeight: 1.25, fontSize: '14' }}
            >
              <TotalPrice>
                TOTALE COSTO (IVA esclusa):{' '}
                <Price>
                  <ProDisplay type="totalCost">
                    {Math.trunc(priceFormat(price))}&nbsp;€
                  </ProDisplay>
                </Price>
              </TotalPrice>
            </ProDisplay>
          ) : (
            <ProDisplay type="3" configuration={{ lineHeight: 1.25 }}>
              <TotalPrice>
                TOTALE COSTO (IVA esclusa):{' '}
                <Price>
                  <ProDisplay type="totalCost">
                    {Math.trunc(priceFormat(price))}&nbsp;€
                  </ProDisplay>
                </Price>
              </TotalPrice>
            </ProDisplay>
          )}
        </PriceWrapper>

        <BtnsWrapper>
          {btnBackText && (
            <ButtonWrapper>
              <Button
                text={btnBackText}
                type={Button.TYPE.TERTIARY}
                onClick={onClickBack}
              />
            </ButtonWrapper>
          )}
          {btnText && (
            <ButtonWrapper>
              <Button
                disabled={btnDisabled}
                text={btnText}
                type={Button.TYPE.PRIMARY}
                onClick={() => {
                  // trackingCallback();
                  btnOnClick();
                }}
              />
            </ButtonWrapper>
          )}
        </BtnsWrapper>
      </Foot>
    </Container>
  </FooterRegistrationContainer>
);

PurchaseFooter.propTypes = {
  price: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  btnText: PropTypes.string,
  btnBackText: PropTypes.string,
  btnOnClick: PropTypes.func,
  onClickBack: PropTypes.func,
  btnDisabled: PropTypes.bool,
  isPremium: PropTypes.bool,
  subscriptionPrice: PropTypes.number,
  // trackingCallback: PropTypes.func,

  // HOC withMediaQueries
  mediaIsPhone: PropTypes.bool.isRequired,
};

PurchaseFooter.defaultProps = {
  price: '--,--',
};

export default withMediaQueries(PurchaseFooter);
