import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { Container } from 'ui/gridSystem';
import { Footer, Grid } from 'ui/components';
import { push } from 'redux-first-history';
import ProDisplay from 'ui/typography/proDisplay';
import routes from 'routes';
import { GET_CATEGORY_PLAYLIST, GET_PATH } from 'redux/actions/playlist';
import { detailType } from 'utils/common';
import { Head, IconBack, Title, Text, BtnContainer } from './style';
import MainContainer from '../style';
import { PlaylistTypes } from 'const';
import { Button } from 'ui/atoms';
import {
  GET_COLLECTIONS,
  GET_SELECTIONS,
  GET_RECOMMENDED_COLLECTIONS,
  GET_ALL_CATEGORIES,
  GET_SKILLROOM,
  GET_CORSI_ECM,
  GET_COURSES_EVENTS,
  GET_EXPERT,
  GET_DR_SIM }
from 'redux/actions/playlist';

import {
  shouldUseMock,
} from 'api/mock';

const AllPlaylists = ({
  pushUrl,
  getMyPlaylists,
  collections = [],
  selections,
  playlistSelections,
  playlistCollections,
  playlistRecommendedCollections,
  playlistPromoted,
  playlistSkillroom,
  playlistCorsiEcm,
  playlistExpert,
  playlistCourses,
  playlistDrSim,
  categoriesPills,
  filterId,
  name,
  filteredCollections,
  type,
  selectionsNext,
  collectionsNext,
  recommendedCollectionsNext,
  categoryPlaylistNext,
  playlistCategories,
  promotedNext,
  skillroomNext,
  expertNext,
  coursesNext,
  drSimNext
}) => {
  const [playlists, setPlaylists] = useState([]);
  const [buttonVisible, setbuttonVisible] = useState(false);
  const [categoryPlaylistId, setCategoryPlaylistId] = useState('');

  useEffect(() => {
    if(!categoryPlaylistId && Boolean(categoriesPills)) {
      const categoryPlaylistId = categoriesPills[0]?.needId;
      setCategoryPlaylistId(categoryPlaylistId)
    }
  }, [categoriesPills]);

  const dispatch = useDispatch();
  const getNextSelections = useCallback(() => dispatch({ type: GET_SELECTIONS._REQUEST }), [dispatch]);
  const getNextCollections = useCallback(() => dispatch({ type: GET_COLLECTIONS._REQUEST }), [dispatch]);
  const getNextRecommendedCollections = useCallback(() => dispatch({ type: GET_RECOMMENDED_COLLECTIONS._REQUEST }), [dispatch]);
  const getNextEvidenceItems = useCallback(() => dispatch({ type: GET_ALL_CATEGORIES._REQUEST }), [dispatch]);
  const getNextSkillroomItems = useCallback(() => dispatch({ type: GET_SKILLROOM._REQUEST }), [dispatch]);
  const getNextCorsiEcmItems = useCallback(() => dispatch({ type: GET_CORSI_ECM._REQUEST }), [dispatch]);
  const getNextCoursesEventsItems = useCallback(() => dispatch({ type: GET_COURSES_EVENTS._REQUEST }), [dispatch]);
  const getNextExpertItems = useCallback(() => dispatch({ type: GET_EXPERT._REQUEST }), [dispatch]);
  const getNextDrSimItems= useCallback(() => dispatch({ type: GET_DR_SIM._REQUEST }), [dispatch]);
  const getNextCategoryList = useCallback((categoryPlaylistId) => dispatch({ type: GET_CATEGORY_PLAYLIST._REQUEST, filterId: categoryPlaylistId }), [dispatch]);

  useEffect(() => {
    if (!filterId && !type) {
      // getAllCollections();
      getMyPlaylists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterId]);

  useEffect(() => {
    if (Boolean(type)) {
      switch (type) {
        case PlaylistTypes.SELECTIONS:
          setPlaylists(playlistSelections);
          setbuttonVisible(selectionsNext);
          break;

        case PlaylistTypes.COLLECTIONS:
          setPlaylists(playlistCollections);
          setbuttonVisible(collectionsNext);
          break;

        case PlaylistTypes.RECOMMENDED_COLLECTIONS:
          setPlaylists(playlistRecommendedCollections);
          setbuttonVisible(recommendedCollectionsNext);
          break;

        case PlaylistTypes.PROMOTED:
          setPlaylists(playlistPromoted);
          setbuttonVisible(promotedNext);
          break;

        case PlaylistTypes.SKILLROOM:
          setPlaylists(playlistSkillroom);
          setbuttonVisible(false);
          //setbuttonVisible(skillroomNext);
          break;

          case PlaylistTypes.CORSI_ECM:
          setPlaylists(playlistCorsiEcm);
          setbuttonVisible(false);
          //setbuttonVisible(skillroomNext);
          break;

        case PlaylistTypes.EXPERT:
          setPlaylists(playlistExpert);
          setbuttonVisible(expertNext);
          break;

        case PlaylistTypes.COURSES:
          setPlaylists(playlistCourses);
          setbuttonVisible(coursesNext);
          break;

        case PlaylistTypes.DR_SIM:
          setPlaylists(playlistDrSim);
          setbuttonVisible(drSimNext);
          break;

        case PlaylistTypes.CATEGORIES:
          setPlaylists(playlistCategories);
          setbuttonVisible(categoryPlaylistNext);
          break;

        default:
          break;
      }
    } else {
      if (filterId && filterId > 0) {
        setPlaylists(filteredCollections);
      } else {
        setPlaylists([...collections, ...selections]);
      }
    }
  }, [collections,
      selections,
      filteredCollections,
      filterId,
      playlistSelections,
      playlistCollections,
      playlistRecommendedCollections,
      playlistSkillroom,
      playlistCorsiEcm,
      playlistCourses,
      playlistExpert,
      playlistPromoted,
      playlistDrSim,
      selectionsNext,
      collectionsNext,
      recommendedCollectionsNext,
      promotedNext,
      categoryPlaylistNext,
      type,
      playlistCategories,
      //skillroomNext,
      expertNext,
      coursesNext]);

  function getNextPlaylist(type) {
    switch (type) {
      case PlaylistTypes.SELECTIONS:
        getNextSelections();
        break;

      case PlaylistTypes.COLLECTIONS:
        getNextCollections();
        break;

      case PlaylistTypes.RECOMMENDED_COLLECTIONS:
        getNextRecommendedCollections();
        break;

      case PlaylistTypes.PROMOTED:
        getNextEvidenceItems();
        break;

      /*case PlaylistTypes.SKILLROOM:
        getNextSkillroomItems();
        break;*/

      case PlaylistTypes.EXPERT:
        getNextExpertItems();
        break;

      case PlaylistTypes.COURSES:
        getNextCoursesEventsItems();
        break;

      case PlaylistTypes.DR_SIM:
        getNextDrSimItems();
        break;

      case PlaylistTypes.CATEGORIES:
        getNextCategoryList(categoryPlaylistId);
        break;

      default:
        break;
    }
  }

  function getPageTitle(type) {
    switch (type) {
      case PlaylistTypes.SELECTIONS:
        return {
          title: "Formazione obbligatoria",
          subtitle: "Qui puoi trovare i corsi obbligatori che dovrai svolgere"
        }

      case PlaylistTypes.COLLECTIONS:
        return {
          title: "Formazione trasversale",
          subtitle: "Approfondisci tematiche cross rispetto al tuo ruolo e sviluppa le Soft Skill"
        }

      case PlaylistTypes.RECOMMENDED_COLLECTIONS:
        return {
          title: "Suggeriti per te",
          subtitle: "Non accontentarti! Perfeziona la tua formazione: scopri nuovi contenuti per irrobustire la tua conoscenza"
        }

      case PlaylistTypes.PROMOTED:
        return {
          title: "In evidenza"
        }

      case PlaylistTypes.SKILLROOM:
        return {
          title: "Skillroom",
          subtitle: "Esegui correttamente le procedure"
        }

      case PlaylistTypes.CORSI_ECM:
        return {
          title: "Corsi ECM",
          subtitle: "Fruisci dei corsi e ottieni crediti ECM"
        }

      case PlaylistTypes.EXPERT:
        return {
          title: "Expert insights",
          subtitle: `Ascolta i consigli dell'esperto`
        }

      case PlaylistTypes.COURSES:
        return {
          title: "E-learning courses",
          subtitle: "Segui i corsi per approfondire le tue conoscenze e restare aggiornato sulle più recenti evidenze scientifiche"
        }

      case PlaylistTypes.DR_SIM:
        return {
          title: "Microsimulazioni",
          subtitle: "Migliora le tue abilità attraverso simulazioni di situazioni cliniche specifiche"
        }

      case PlaylistTypes.CATEGORIES:
        const categoryTitle = categoriesPills.find(pill => playlistCategories.find(category => category?.needId === pill?.needId))?.title;

        return {
          title: categoryTitle,
        }

      default:
        break;
    }
  }

  return (
    <>
      <MainContainer>
        <Container>
          <Head>
            <IconBack
              className="icon-arrow-right"
              onClick={() => pushUrl(routes.homepage.path)}
            />
            <Title>
              <ProDisplay type="pageTitle">
                { type ? getPageTitle(type).title : name }
              </ProDisplay>
              <Text>
                <ProDisplay
                  type="tableLabel"
                  configuration={{ color: 'placeholder' }}>
                  { type ? getPageTitle(type)?.subtitle : null }
                </ProDisplay>
              </Text>
            </Title>
          </Head>
          {playlists?.length > 0 ? (
            <>
              <Grid
                elements={playlists}
                location={((type === PlaylistTypes.SKILLROOM) || (type === PlaylistTypes.CORSI_ECM)) ? detailType.PACKAGE : detailType.PLAYLIST}
                //location={detailType.PLAYLIST}
                section="playlist"
                type={type}
              />
            { buttonVisible && <BtnContainer>
              <Button
                text="Mostra altri"
                type="PRIMARY"
                height={35}
                fontSize={16}
                onClick={() => getNextPlaylist(type)}
              />
            </BtnContainer> }
            </>
          ) : (
            <Text>
              <ProDisplay type="1">
                Non ci sono collections associate a questa categoria.
              </ProDisplay>
            </Text>
          )}
        </Container>
      </MainContainer>
      <Footer />
    </>
  );
};

AllPlaylists.propTypes = {
  // HOC (connect, state)
  collections: PropTypes.array,
  selections: PropTypes.array,
  playlistCollections: PropTypes.array,
  playlistSelections: PropTypes.array,
  playlistRecommendedCollections: PropTypes.array,
  playlistPromoted: PropTypes.array,
  playlistSkillroom: PropTypes.array,
  playlistCorsiEcm: PropTypes.array,
  playlistExpert: PropTypes.array,
  playlistCourses: PropTypes.array,
  playlistDrSim: PropTypes.array,
  playlistCategories: PropTypes.array,
  categoriesPills: PropTypes.array,
  filterId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]
  ),
  name: PropTypes.string,
  filteredCollections: PropTypes.array,
  selectionsNext: PropTypes.bool,
  collectionsNext: PropTypes.bool,
  recommendedCollectionsNext: PropTypes.bool,
  promotedNext: PropTypes.bool,
  //skillroomNext: PropTypes.bool,
  expertNext: PropTypes.bool,
  coursesNext: PropTypes.bool,
  drSimNext: PropTypes.bool,
  categoryPlaylistNext: PropTypes.bool,

  // HOC (connect, dispatch)
  pushUrl: PropTypes.func,
  // getAllCollections: PropTypes.func,
  getMyPlaylists: PropTypes.func
};

export default withMediaQueries(
  connect(
    state => {
      const {
        selection: { items: selections },
        collection: { items: collections }
      } = state.playlist.playlists.yourpathprogress;
      // const { allCollections: collections } = state.playlist.playlists;

      const playlistSelections = state.playlist.selections.items;
      const playlistCollections = state.playlist.collections.items;
      const playlistRecommendedCollections = state.playlist.recommendedCollections.items;
      const playlistPromoted = state.playlist.evidence.items;
      const playlistSkillroom = state.playlist.skillroomPackages.items;
      const playlistCorsiEcm = state.playlist.corsiEcmPackages.items;
      //const playlistSkillroom = state.playlist.skillroom.items;
      const playlistExpert = state.playlist.expert.items;
      const playlistCourses = state.playlist.coursesEvents.items;
      const playlistDrSim = state.playlist.drSim.items;
      const playlistCategories = state.playlist.categoryPlaylist.items;
      const categoriesPills = state.playlist.categoriesPills;

      const selectionsNext = Boolean(state.playlist.selections.nextPage);
      const collectionsNext = Boolean(state.playlist.collections.nextPage);
      const recommendedCollectionsNext = Boolean(state.playlist.recommendedCollections.nextPage);
      const promotedNext = Boolean(state.playlist.evidence.nextPage);
      //const skillroomNext = Boolean(state.playlist.skillroom.nextPage);
      const expertNext = Boolean(state.playlist.expert.nextPage);
      const coursesNext = Boolean(state.playlist.coursesEvents.nextPage);
      const drSimNext = Boolean(state.playlist.drSim.nextPage);
      const categoryPlaylistNext = Boolean(state.playlist.categoryPlaylist.nextPage);

      const { filterId, name, filteredCollections } = state.playlist.filters;
      return {
        selections,
        collections,
        filterId,
        name,
        filteredCollections,
        playlistSelections,
        playlistCollections,
        playlistRecommendedCollections,
        playlistCategories,
        selectionsNext,
        collectionsNext,
        recommendedCollectionsNext,
        promotedNext,
        //skillroomNext,
        expertNext,
        coursesNext,
        drSimNext,
        playlistPromoted,
        playlistDrSim,
        playlistSkillroom,
        playlistCorsiEcm,
        playlistExpert,
        playlistCourses,
        categoriesPills,
        categoryPlaylistNext
      };
    },
    dispatch => ({
      pushUrl: url => dispatch(push(url)),
      getMyPlaylists: () =>
        dispatch({
          type: GET_PATH._REQUEST
        })
    })
  )(AllPlaylists)
);
