import routes from 'routes';

const navbarMenu = [
  {
    label: 'Gestione catalogo',
    link: routes.mySubscription.path
  },
  {
    label: 'Profilo',
    link: routes.managerProfile.path
  }
];

export default navbarMenu;
