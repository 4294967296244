import React from 'react';
import PropTypes from 'prop-types';

import ProText from 'ui/typography/proText';
import { Label, Input, CustomRadio, TextWrapper } from './style';

const RadioButton = ({ label, name, value, onChange, checked, disabled, classes }) => (
  <Label className={classes}>
    <TextWrapper style={{ width: '100%' }}>
      <ProText type="4">{label}</ProText>
    </TextWrapper>
    <Input
      type="radio"
      name={name}
      checked={checked}
      value={value}
      onChange={e => onChange(e.target.value)}
      disabled={disabled}
    />
    <CustomRadio />
  </Label>
);

RadioButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string
  ]),
  classes: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
};

export default RadioButton;
