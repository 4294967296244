import styled from "styled-components/macro";

export const Title = styled.h3`
    color: #f2284f;
  font-weight: 600;
  margin-bottom: 100px;
`

export const PaddingContainer = styled.div`
    padding: 80px 0;
  padding-bottom: 0;
  .row-accordion{
      display: flex;
      justify-content: space-between;
      margin-bottom: 200px;
      @media (max-width: 1024px) {
        display: block;
      }
  }
  @media (min-width: 1920px) {
    width: 1728px!important;
    margin: 0 auto;
  }
`

export const EdgeLine = styled.div`
    height: 1px;
  margin: 50px 100px;
  background-color: #000;
  
`

export const AccordionItems = styled.div`
    width: 50%;
  margin-left: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  
  
  @media (min-width: 1296px) {
    max-width: 1296px!important;
  }
  @media (max-width: 1296px) {
    margin-left: 50px;
  }
  @media (max-width: 1024px) {
    width: calc(100% - 50px);
    margin-left: 50px;
  }
  @media (max-width: 598px){
    width: calc(100% - 25px);
    margin-left: 25px;
  }

`

export const AccordionItem = styled.div`
  font-family: 'prodisplay';
  margin-bottom: 20px;
  height: 50px;
  transition: 0.5s height, 0.5s color;
  &.active{
      height: 100px;
      font-weight: bold;
      color: #000;
        h4{
          border-bottom: 2px solid #000;
        }
        p{
          opacity: 1;
        }
    }
    color: #8a8a8a;
  p{
    opacity: 0;
    transition: 0.5s opacity;
  }
    
  h4{
    display: inline-block;
    border-bottom: 2px solid #8a8a8a;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    @media (max-width: 598px){
      font-size: 25px;
    }
  }
  p{
    font-size: 25px;
    font-weight: 400;

    @media (max-width: 598px){
      font-size: 22px;
    }
  }
    &.title{
      
    }
`

export const AccordionPanel = styled.div`
  
    width: 50%;
    height: 400px;
    background-color: #333333;
    position: relative;

    @media (max-width: 1024px) {
      width: 100%;
    }
      
      p{
        color: #fff;
        padding: 50px;
        font-family: 'prodisplay';
        font-weight: 500;
        font-size: 23px;
        * {
          padding: 0;
        }

        @media (max-width: 598px) {
          padding: 25px;
        }
      }
      
      .img{
        position: relative;
        width: 100%;
        padding-bottom: 47%;
        background-size: cover;
        left: -140px;
        display: inline-block;
        transition: 0.3s background-image;
        @media (max-width: 1024px){
          left: 50%;
          transform: translate(-50%);
          width: 80%;
          padding-bottom: 37%;
        }
      }
`