import React,{ useState, useEffect} from "react";
import PropTypes from 'prop-types';
import { withMediaQueries } from 'hoc/withMediaQueries';
import {
  ContentCardUser,
  CustomCarousel,
} from 'ui/components';
import {
  CarouselSettings,
  NewsCarouselSettings,
  CarouselSettingsCourses,
  CarouselSettingsEvents,
  CarouselSettingsPackages
} from "utils/slider-settings";
import { CarouselWrapper } from "./style";
import {connect} from "react-redux";
import {GET_PACKAGE_ASSET_DESCRIPTION} from "../../../../redux/actions/playlist";


const SectionCarousel = ({
  data,
  type,
  onClick,
  // HOC from redux
  domain,
  // HOC, media queries
  currentViewport,
  viewportTypes,
  getPackageAssetDescription,
}) => {
  let settings = CarouselSettings;

  switch (type) {
    case 'news':
      settings = NewsCarouselSettings;
      break;

    case 'course':
      settings = CarouselSettingsCourses;
      break;

    case 'live_event':
      settings = CarouselSettingsEvents;
      break;

    case 'package':
      settings = CarouselSettingsPackages;
      break;

    default:
      break;
  }

  const [showCarousel, setShowCarousel] = useState(false);
  const [carouselData, setCarouselData] = useState("");

  const isProd = ()=>{
      return domain.id === 3581;
  }
   useEffect(() => {
   let first;
   let second;
   let third;
    data = data.filter(function( obj ) {
        if(obj.itemId === (isProd() ? '3739' : '517')){
          first = obj;
      }
        if(obj.itemId === (isProd() ? '3740' : '480')){
            second = obj;
        }
        if(obj.itemId === (isProd() ? '485' : '485')){
            third = obj;
        }
        return obj.itemId !== (isProd() ? '3739' : '517');
    });

    data = data.filter(function( obj ) {
        return obj.itemId !== (isProd() ? '3739' : '480');
    });
    data = data.filter(function( obj ) {
        return obj.itemId !== (isProd() ? '3740' : '485');
    });
    if(third) data.unshift(third);
    if(second) data.unshift(second);
    if(first) data.unshift(first);

    setCarouselData(data);
    setShowCarousel(true);
   }, [data]);

   const openModalInfoHandler = (card, _type)=>{
       getPackageAssetDescription({...card, itemType: (card.packageType ? 'package' : (_type || "").toLowerCase()), packageId: card.packageType ? card.itemId : '', assetId: !card.packageType ? card.itemId : ''});
   }
  return (
    <CarouselWrapper
      className={viewportTypes[currentViewport] >= viewportTypes.mediaIsLaptop ? type : ''}
    >
      <CustomCarousel
        isSDS
        config={settings}
      >
        {showCarousel && carouselData?.length > 0 &&
        carouselData.map(card => (
          <ContentCardUser
            homepage
            openModalInfo={(card, type)=>{openModalInfoHandler(card, type)}}
            key={card.itemId ?? card.packageId}
            type={type === 'card' ? card.type : type}
            data={card}
            onClick={() => onClick(card)}
          />
        ))}
      </CustomCarousel>
    </CarouselWrapper>
  );
};

SectionCarousel.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
    getPackageAssetDescription: PropTypes.func,
  // HOC, media queries
  currentViewport: PropTypes.string.isRequired,
  viewportTypes: PropTypes.shape({
    IsPhone: PropTypes.oneOf([0]),
    IsTablet: PropTypes.oneOf([1]),
    IsLaptop: PropTypes.oneOf([2]),
    IsDesktop: PropTypes.oneOf([3]),
    IsLargeDesktop: PropTypes.oneOf([4])
  }),
};

export default withMediaQueries(
  connect(state => {
    return {
      domain: state.domain
    };
  }, dispatch => ({
        getPackageAssetDescription: payload => dispatch({ type: GET_PACKAGE_ASSET_DESCRIPTION._REQUEST, payload })
    }))
(SectionCarousel));