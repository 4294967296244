import React, { useState, useEffect } from 'react';
import * as CodiceFiscaleUtils from '@marketto/codice-fiscale-utils';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'redux-first-history';

import { Container, Row, Col } from 'ui/gridSystem';
import { SubTitle, Title } from 'pages/Signup/steps/UserAccount/style';
import {
  Button,
  CustomInput,
  InputAutoSuggestion,
  RadioButton,
} from 'ui/atoms';
import {
  PaymentMethod,
  SummaryTable,
  AgreementCheck,
} from 'ui/components';
import { withMediaQueries } from 'hoc/withMediaQueries';
import ProDisplay from 'ui/typography/proDisplay';
import ProText from 'ui/typography/proText';
import {
  SET_SELECTED_GATEWAY,
  BILLING_IS_PREMIUM_PURCHASE,
  BILLINGS_ZUORA_PAYMENT_METHOD_DEFAULT_SET,
  BILLINGS_ZUORA_PAYMENT_METHOD_ADD,
} from 'redux/actions/billings';
import validator from 'utils/regex-validator';
import {
  SIGNUP_UPDATE_BILLING,
  SECTOR_GET,
  SIGNUP_NEXT_STEP,
  SIGNUP_SET_PAYMENT_METHOD_ID,
  SIGNUP_RESET_CART,
  USERTYPE_UPDATE,
  SET_NEED_TO_REOPEN_AGREEMENTS,
  SIGNUP_RETRIEVE_DRAFT_ORDER_ZUORA, SIGNUP_SUBMIT_ORDER_ZUORA, SIGNUP_BUYER_B2C_NEXT_STEP,
} from 'redux/actions/signup';
import states from 'utils/states';
import { MODAL_OPEN } from 'redux/actions';
import { AgreementsContentsInterface } from 'interface/signup';
import SubscriptionModal from 'ui/components/Modals/SubscriptionModal';
import ModalErrors from 'ui/components/Modals/ModalErrors';
import routes from 'routes';
import Amex from 'ui/atoms/IconCreditCard/Amex';
import Visa from 'ui/atoms/IconCreditCard/Visa';
import MasterCard from 'ui/atoms/IconCreditCard/MasterCard';
import billings from 'api/billings';
import { Radio } from 'ui/components/Modals/AgreementsCheckModal/style';
import { PROFILE_DATA_COMPANY_GET, PROFILE_GET_DATA_PRIVACY } from 'redux/actions/profile';
import {
  BillingsContainer,
  Description,
  FormGroup,
  ButtonsContainer,
  BtnCancel,
  BtnConfirm,
  SummaryContainer,
  Text,
  Data,
  Name,
  Card,
  IconCard,
  ChooseType,
} from './style';
import CreditCardZuora from '../../../../ui/components/BillingMethods/CreditCardZuora';
import SEPAZuora from '../../../../ui/components/BillingMethods/SEPAZuora';
import PremiumPurchaseModal from '../../../../ui/components/Modals/PremiumPurchaseModal';

const newField = (types = ['string'], value = undefined, valid = true) => ({
  value,
  valid,
  types,
});

const fields = [
  { name: 'rsociale', types: ['string'] },
  { name: 'piva', types: ['piva'] },
  { name: 'cf_piva', types: ['fiscalCode'] },
  { name: 'phone', types: ['string'] },
  { name: 'country', types: ['string'] },
  { name: 'state', types: ['string'] },
  { name: 'postalcode', types: ['postalCode'] },
  { name: 'city', types: ['string'] },
  { name: 'address', types: ['address'] },
  /*{ name: 'atecoCode', types: ['string'] },
  { name: 'atecoDescription', types: ['string'] },*/
  { name: 'section', types: ['string'] },
  { name: 'sdi', types: ['sdi'] },
];
const initialForm = {};
fields.forEach(f => (initialForm[f.name] = newField(f.types)));
initialForm.country = { ...initialForm.country, value: 'it' };

const options = [{ label: 'Italia', value: 'it' }];

const emptyAteco = {
  code: undefined,
  description: undefined,
};

const Billings = ({
  updateBilling, billing, paymentType, setPaymentType, onChange,
  setSelectedGateway, user, company, sector, getSector,
  nextSignupStep, nextBuyerB2CSignupStep, openModalError, isValid, agreements, sendDataTreatment,
  setPaymentMethodId, setDefaultZuoraPaymentMethod, paymentToken, cart_zuora, pushUrl,
  isPremiumPurchase, payment_methods, closePurchase, addZuoraPaymentMethod,
  resetCart, step, setStepBillings, organizationType, getCompany,
  updateUserType, getPrivacyData, retrieveDraftCartZuora, submitCartZuora,
  setNeedToReopenModal, needToReopenAgreements, all_prospect, assoc_subscriptions, userType, userFirstName, userLastName
}) => {
  const [atecoCodes, setAtecoCodes] = useState([]);
  const [form, setForm] = useState(initialForm);
  const [formBillingValid, setFormBillingValid] = useState(false);
  const [atecoOptions, setAtecoOptions] = useState();
  const [atecoDescOptions, setAtecoDescOptions] = useState();
  const [atecoPair, setAtecoPair] = useState(emptyAteco);
  const [timeoutId, setTimeoutId] = useState();


  /* per calcolo codice fiscale */
  const [gender, setGender] = useState('');
  const [dataNascita, setDataNascita] = useState('');
  const [luogoNascita, setLuogoNascita] = useState('');
  const [errorDataNascita, setErrorDataNascita] = useState('');
  const [errorLuogoNascita, setErrorLuogoNascita] = useState('');
  const [errorCodiceFiscale, setErrorCodiceFiscale] = useState('');

  const {Parser} = CodiceFiscaleUtils;

  const calcCodiceFiscale =(_gender) =>{
    setErrorCodiceFiscale('');
    let regex = /^[a-zA-Z]+$/;
    if (!userLastName || !userFirstName || !regex.test(userLastName) || !regex.test(userFirstName)){
      setErrorCodiceFiscale('Nome o cognome non validi. Verificare che non contengano caratteri non ammessi.');
    }
    if (_gender && dataNascita && luogoNascita){
      const cfEncoded = Parser.encodeCf({
        lastName: userLastName,
        firstName: userFirstName,
        year: parseInt(dataNascita.split('/')[2]),
        month: parseInt(dataNascita.split('/')[1])-1, // zero-based value
        day: parseInt(dataNascita.split('/')[0]),
        gender: _gender,
        place: luogoNascita
      }); //VRNGNY07D68C351V
      handleFormChange('cf_piva',cfEncoded );
      //newState[field].valid = (cfEncoded === newValue);
      //newState[field].valid = true;
      /*handleFormChange('cf_piva', val.toUpperCase())*/
    }
  }

  const isPersonaFisica = ()=>{
    return userType === 'persona_fisica';
  }

  useEffect(()=>{
    const regex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    if (dataNascita.length && !regex.test(dataNascita)){
      setErrorDataNascita('Il formato data non è valido')
    }else{
      setErrorDataNascita('')
    }
  },[dataNascita])

  const getDatiCalcoloCodiceFiscale = ()=>{
    return (
        <>
          <Col sm={12} className="mb-3">
              <ProDisplay>
                Sesso
              </ProDisplay>
            <ChooseType>
              <Radio className="inline start">
                <RadioButton
                    label="M"
                    value="M"
                    name="gender"
                    checked={gender === 'M'}
                    onChange={val => {
                      setGender(val);
                      calcCodiceFiscale(val)
                    }}
                />
                <RadioButton
                    label="F"
                    value="F"
                    name="gender"
                    checked={gender === 'F'}
                    onChange={val => {
                      setGender(val);
                      calcCodiceFiscale(val)
                    }}
                />
              </Radio>
            </ChooseType>
          </Col>
          <Col sm={12} className="mb-3">
            <CustomInput
                type="text"
                placeholder="Data di nascita (GG/MM/AAAA) *"
                value={dataNascita}
                onChange={(val)=>{
                  setDataNascita(val);
                }}
                onBlur={()=>calcCodiceFiscale(gender)}
                error={errorDataNascita}
            />
          </Col>
          <Col sm={12} className="mb-3">
            <CustomInput
                type="text"
                placeholder={"Luogo di nascita o stato estero *"}
                value={luogoNascita}
                onChange={(val)=>{
                  setLuogoNascita(val);
                }}
                onBlur={()=>{
                  /*if ()*/
                  const valid = Parser.placeToCf(luogoNascita);
                  if (!valid) {
                    setErrorLuogoNascita('Luogo di nascita non trovato');
                  }else {
                    setErrorLuogoNascita('');
                    calcCodiceFiscale(gender);
                  }
                }}
                error={errorLuogoNascita}
            />
          </Col>
          {/*<Col md={3} sm={12} className="mb-3">
            {paeseItalia ? <CustomInput
                type="select"
                placeholder="Provincia di nascita *"
                options={states}
                value={provincia}
                onChange={(val)=>{
                  setProvincia(val);
                }}
                searchable={false}
            /> : <Col sm={12} className="mb-3">
              <CustomInput
                  type="text"
                  placeholder="Provincia di nascita *"
                  value={provincia}
                  disabled={true}
                  onChange={(val)=>{
                    setProvincia(val);
                  }}
              />
            </Col>}
          </Col>*/}
          {/*<Col sm={12} className="mb-3">
            <CustomInput
                type="text"
                placeholder="Provincia di nascita *"
                value={provincia}
                disabled={!paeseItalia}
                onChange={(val)=>{
                  setProvincia(val);
                }}
            />
          </Col>*/}
        </>
    )
  }


  useEffect(() => {
    if (!isPremiumPurchase
      && ((all_prospect && Object.keys(all_prospect).length > 0)
        || (assoc_subscriptions && assoc_subscriptions?.orders?.length > 0))) {
      pushUrl(routes.mySubscription.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [all_prospect, assoc_subscriptions]);

  useEffect(() => {
    setAtecoOptions(atecoCodes);
    setAtecoDescOptions(atecoCodes);
  }, [atecoCodes]);

  useEffect(() => {
    if (!isPremiumPurchase && !sendDataTreatment) getPrivacyData();
    if (isPremiumPurchase && !company?.orgfiscalcode) {
      pushUrl(routes.setFiscalCode.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPremiumPurchase, sendDataTreatment, getPrivacyData]);

  useEffect(() => {
    if (Object.keys(user).length && user.organizationId) getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (company?.zuoraaccountid && !cart_zuora?.orderdata?.length) {
      retrieveDraftCartZuora();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  // useEffect(() => {
  //   if (!isPremiumPurchase && !sendDataTreatment) getPrivacyData();
  // }, [isPremiumPurchase, sendDataTreatment, getPrivacyData]);

  const formattedSector = (sector || []).map(({ name: label, id: value }) => ({
    label,
    value,
  }));

  const handleFormChange = (field, newValue) => {
    setForm(s => {
      const newState = { ...s };
      newState[field].value = newValue;
      if(field !== 'cf_piva') {
        const { types } = form[field];
        newState[field].valid = types.reduce((acc, t) => {
          const valid = validator[t](newValue);
          return typeof acc === 'boolean' ? acc || valid : valid;
        }, false);
      }

      return newState;
    });
  };

  useEffect(() => {
    let acceptedTypes = ['fiscalCode'];
    if (organizationType === 'persona_giuridica') acceptedTypes = ['piva'];

    setForm(s => {
      const newState = { ...s };
      newState.cf_piva.value = undefined;
      newState.cf_piva.valid = true;
      newState.cf_piva.types = acceptedTypes;
      return newState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationType]);

  const formConfirmed = () => {
    let valid = true;
    if (agreements?.user_clauses) {
      (Object.keys(agreements.user_clauses) || []).forEach(key => {
        valid = valid && agreements.user_clauses[key]?.valid;
      });
    } else {
      valid = false;
    }
    return valid && (isPremiumPurchase || !!agreements?.userType);
  };

  const handleSubmitPayment = () => {
    if (paymentToken && payment_methods.length > 0) {
      submitCartZuora(paymentToken);
    } else {
      openModalError({
        errorText:
          'Impossibile verificare il pagamento controlla i tuoi dati e riprova',
      });
    }
  };

  const handleSubmitBillingForm = () => {
    const { userId } = user;
    const {
      rsociale: title,
      piva: vatNumber,
      cf_piva: fiscalCode,
      phone: phoneNumber,
      sdi: sdiNumber,
      address: street,
      atecoCode,
      atecoDescription,
      city,
      postalcode: zipCode,
      section: industryId,
      state: district,
      /* customSection:customIndustry */
    } = billing;

    const payload = {
      userId,
      title: (userType === 'persona_fisica') || (userType === 'fruitore_type_acquirente') ? userFirstName + ' ' + userLastName : title,
      vatNumber: (userType === 'persona_fisica') || (userType === 'fruitore_type_acquirente') ? null : vatNumber,
      orgFiscalCode: fiscalCode === 'null' ? null : fiscalCode,
      phoneNumber: userType !== 'persona_giuridica' ? phoneNumber : null,
      sdiNumber: (userType === 'persona_fisica') || (userType === 'fruitore_type_acquirente') ? null : sdiNumber,
      street,
      city,
      district,
      zipCode,
      industryId: (userType === 'persona_fisica') || (userType !== 'fruitore_type_acquirente') ? null : industryId,
      /*atecoCode,
      atecoDescription,*/
      atecoCode: 'N/A',
      atecoDescription: 'N/A',
      // organizationType: userType ?? null,
      organizationType: 'persona_fisica',
    };

    if ((userType !== 'persona_fisica') && (userType !== 'fruitore_type_acquirente')) {
      nextSignupStep(4, payload);
    } else {
      nextBuyerB2CSignupStep(4, payload);
    }
  };

  const excludeFields = ['rsociale', 'piva', 'sdi', 'section'];

  useEffect(() => {
    if (onChange) {
      const formValid = (Object.keys(form) || []).map(key => {
        if (excludeFields.includes(key) && (userType === 'persona_fisica')) return true;
        if ((key === 'phone') && (userType !== 'persona_fisica')) return true;
        return Boolean(
            form[key]?.value?.length > 0 &&
            typeof form[key]?.valid === 'boolean' &&
            form[key]?.valid
        );
      });
      const isValidForm = formValid.filter(bool => bool === false)?.length <= 0;
      setFormBillingValid(isValidForm);
      if (isValidForm) updateBilling(form);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    if (!isPremiumPurchase) {
      getSector();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSelectPayment = (gateway) => {
    setPaymentMethodId(null);
    setSelectedGateway(gateway);
    setPaymentType(gateway);
  };

  const handleClosePurchase = () => {
    closePurchase();
    resetCart();
    pushUrl(routes.mySubscription.path);
  };

  const searchAtecoCodes = (search, type) => {
    if (timeoutId) clearTimeout(timeoutId);
    const currentTimeout = setTimeout(() => {
      if (search && search.length > 1) { billings.getAtecoCodes({ type, search, callback: setAtecoCodes }); }
    }, 500);
    setTimeoutId(currentTimeout);
  };

  useEffect(() => {
    setAtecoOptions(
      atecoCodes.map(ac => ({ value: ac._id, label: ac.Codice_ateco }))
    );
    setAtecoDescOptions(
      atecoCodes.map(ac => ({
        value: ac._id,
        label: ac.Descrizione_ateco.split('-')[1].trim(),
      }))
    );
  }, [atecoCodes]);

  const onChangeAteco = val => {
    if (val) {
      const selectedAteco = atecoCodes.find(ac => ac._id === val.value);
      handleFormChange('atecoCode', selectedAteco.Codice_ateco);
      handleFormChange('atecoDescription', selectedAteco.Descrizione_ateco.split('-')[1].trim());
      setAtecoPair({
        code: { value: selectedAteco._id, label: selectedAteco.Codice_ateco },
        description: {
          value: selectedAteco._id,
          label: selectedAteco.Descrizione_ateco.split('-')[1].trim(),
        },
      });
    } else {
      handleFormChange('atecoCode', undefined);
      handleFormChange('atecoDescription', undefined);
      setAtecoPair(emptyAteco);
      // setAtecoPair({ code: {}, description: {} });
    }
  };

  useEffect(() => {
    if (step.num !== 4) {
      setStepBillings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCallbackErrorZuora = (error) => {
    // const payload = {
    //   errorText: error,
    //// cb: () => {
    ////  resetCart();
    ////   pushUrl(routes.allSubsriptions.path);
    // },
    // };
    // openModalError(payload);
    console.warn('Zuora Error: ', error);
  };

  const handleCallbackSuccessZuora = (payment_method_id) => {
    onChange(true);
    setPaymentMethodId(payment_method_id);
    if (!isPremiumPurchase) {
      setDefaultZuoraPaymentMethod(payment_method_id);
    } else {
      addZuoraPaymentMethod(payment_method_id);
    }
  };

  if (paymentToken) {
    onChange(true);
  }

  const getTitleCard = card_info => {
    const {
      type, creditCardType = '', creditCardExpirationYear, creditCardExpirationMonth,
      bankTransferAccountNumberMask, creditCardMaskNumber, bankTransferAccountName, creditCardHolderName,
    } = card_info;
    const expiration = creditCardExpirationMonth && creditCardExpirationYear ? `${creditCardExpirationMonth}/${creditCardExpirationYear}` : '';
    const name = bankTransferAccountName || creditCardHolderName;
    let card_brand = '';
    let card_icon;
    switch (creditCardType.toLowerCase()) {
      case 'AmericanExpress':
        card_brand = 'American Express';
        card_icon = <Amex />;
        break;
      case 'mastercard':
        card_brand = 'MasterCard';
        card_icon = <MasterCard />;
        break;
      case 'visa':
        card_brand = 'Visa';
        card_icon = <Visa />;
        break;
      default:
        break;
    }
    return (
      <Card>
        <Name>
          {card_icon && <IconCard>{card_icon}</IconCard>}
          {type === 'CreditCard'
            ? `${card_brand} termina con ${creditCardMaskNumber.slice(creditCardMaskNumber.length - 4)}`
            : `IBAN termina con ${bankTransferAccountNumberMask.slice(bankTransferAccountNumberMask.length - 4)}`}
        </Name>
        <Data>
          <ProText configuration={{ fontSize: '12' }}>{name}</ProText>
          {expiration && (
            <ProText configuration={{ fontSize: '12' }}>{expiration}</ProText>
          )}
        </Data>
      </Card>
    );
  };

  return (
    <>
      <Container>
        <Row>
          <BillingsContainer>
            <Col md={10} sm={12}>
              <Title>
                <ProDisplay type="title">Ci siamo quasi!</ProDisplay>
              </Title>
              <SubTitle>
                {(userType === 'persona_fisica') && <ProText type="description">
                  Inserisci il metodo di pagamento che preferisci e i tuoi dati personali
                </ProText>}
                {(userType !== 'persona_fisica') && <ProText type="description">
                  Inserisci il metodo di pagamento che preferisci dati della tua azienda
                </ProText>}
              </SubTitle>
              {!isPremiumPurchase && !company?.zuoraaccountid && (
                <>
                  <SubTitle
                    className={sendDataTreatment ? 'no-margin-bottom' : ''}
                  >
                    <ProText type="section">Dati di fatturazione </ProText>
                    <ProText
                      type="list"
                      configuration={{ color: 'slateGray' }}
                    >
                      {' '}
                      (* campo obbligatorio)
                    </ProText>
                  </SubTitle>
                  {sendDataTreatment && (
                    <ChooseType>
                      <Radio className="inline start">
                        <RadioButton
                          label="Persona giuridica"
                          value="persona_giuridica"
                          name="userType"
                          checked={userType === 'persona_giuridica'}
                          onChange={val => {
                            updateUserType(val);
                            setNeedToReopenModal(true);
                          }}
                        />
                        <RadioButton
                          label="Ditta individuale"
                          value="persona_fisica"
                          name="userType"
                          checked={userType === 'persona_fisica'}
                          onChange={val => {
                            updateUserType(val);
                            setNeedToReopenModal(true);
                          }}
                        />
                      </Radio>
                    </ChooseType>
                  )}
                  <FormGroup>
                    <Row>
                      { userType !== 'persona_fisica' && <Col md={6} sm={12}>
                        <CustomInput
                          type="text"
                          placeholder="Ragione sociale *"
                          value={form.rsociale.value}
                          onChange={val => handleFormChange('rsociale', val)}
                          error={!form.rsociale.valid}
                        />
                      </Col> }
                      { userType !== 'persona_fisica' && <Col md={6} sm={12}>
                        <CustomInput
                          type="select"
                          placeholder="Settore *"
                          options={formattedSector}
                          onChange={val => handleFormChange('section', val)}
                          searchable={false}
                        />
                      </Col> }
                    </Row>
                    <Row>
                      { userType !== 'persona_fisica' && <Col md={6} sm={12}>
                        <CustomInput
                          type="text"
                          placeholder="Partita IVA *"
                          value={form.piva.value}
                          forceUppercase
                          onChange={val =>
                            handleFormChange('piva', val.toUpperCase())
                          }
                          error={
                            typeof form.piva.valid === 'string'
                              ? form.piva.valid
                              : !form.piva.valid
                          }
                        />
                      </Col> }
                      {
                          isPersonaFisica() && getDatiCalcoloCodiceFiscale()
                      }
                      {/* Codice fiscale verrà calcolato in automatico durante inserimento dati anagrafici */}
                      {/* Per persona persona fisica verrà calcolato in automatico. Per persona giuridica si può inserire partita iva */}
                      <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          type="text"
                          disabled={userType === 'persona_fisica'}
                          placeholder="Codice Fiscale *"
                          value={form.cf_piva.value}
                          forceUppercase
                          onChange={val =>
                              handleFormChange('cf_piva', val.toUpperCase())
                          }
                          error={errorCodiceFiscale}
                        />
                      </Col>
                      {(userType === 'persona_fisica') && <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          type="text"
                          placeholder="Numero di Telefono *"
                          value={form.phone.value}
                          onChange={val => handleFormChange('phone', val)}
                        />
                      </Col>}
                    </Row>
                    <Row>
                      <Col md={3} sm={12} className="mb-3">
                        <CustomInput
                          type="select"
                          placeholder="Provincia *"
                          options={states}
                          onChange={val => handleFormChange('state', val)}
                          searchable={false}
                          error={
                            typeof form.state.valid === 'string'
                              ? form.state.valid
                              : !form.state.valid
                          }
                        />
                      </Col>
                      <Col md={3} sm={12} className="mb-3">
                        <InputAutoSuggestion
                          placeholder="CAP *"
                          dataValue={form.postalcode.value}
                          suggestions={[]}
                          onChange={val => handleFormChange('postalcode', val)}
                          error={!form.postalcode.valid}
                          limit={3}
                        />
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <InputAutoSuggestion
                          placeholder="Città *"
                          dataValue={form.city.value}
                          suggestions={[]}
                          onChange={val => handleFormChange('city', val)}
                          error={!form.city.valid}
                          limit={3}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} sm={12} className="mb-3">
                        <CustomInput
                          type="text"
                          placeholder="Indirizzo *"
                          value={form.address.value}
                          onChange={val => handleFormChange('address', val)}
                          error={!form.address.valid}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {/*<Col md={6} sm={12} className="mb-3">
                        <SelectWrapper>
                       <CustomSelect
                            placeholder="Codice ATECO *"
                            isSearchType
                            options={atecoOptions}
                            onInputChange={val =>
                              searchAtecoCodes(val, 'Codice_ateco')
                            }
                            onChange={onChangeAteco}
                            styles={styles}
                            selected={atecoPair.code}
                          />
                        </SelectWrapper>
                      </Col>
                      <Col md={6} sm={12} className="mb-3">
                        <SelectWrapper>
                          <CustomSelect
                            placeholder="Descrizione codice ATECO *"
                            isSearchType
                            options={atecoDescOptions}
                            onInputChange={val =>
                              searchAtecoCodes(val, 'Descrizione_ateco')
                            }
                            onChange={onChangeAteco}
                            styles={styles}
                            selected={atecoPair.description}
                          />
                        </SelectWrapper>
                      </Col>*/}
                    </Row>
                    <Row>
                      { userType !== 'persona_fisica' && <Col md={6} sm={12}>
                        <CustomInput
                          type="text"
                          placeholder="SDI *"
                          value={form.sdi.value}
                          onChange={val => handleFormChange('sdi', val)}
                          error={!form.sdi.valid}
                        />
                      </Col> }
                      <Col md={6} sm={12} className="mb-3">
                        <CustomInput
                          type="select"
                          options={options}
                          placeholder="Nazione"
                          disabled
                          searchable={false}
                          value={options[0]?.value}
                          onChange={val => handleFormChange('country', val)}
                          error={
                            typeof form.country.valid === 'string'
                              ? form.country.valid
                              : !form.country.valid
                          }
                        />
                      </Col>
                    </Row>
                    {sendDataTreatment && (
                      <Row>
                        <Col md={12} sm={12} className="mb-3">
                          <AgreementCheck
                            validator={formConfirmed}
                            checkUserType={sendDataTreatment}
                          />
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col md={12} sm={12} className="mb-3">
                        <BtnConfirm>
                          <Button
                            text="Conferma"
                            type={Button.TYPE.PRIMARY}
                            disabled={!formBillingValid || (sendDataTreatment && !formConfirmed())}
                            onClick={handleSubmitBillingForm}
                          />
                        </BtnConfirm>
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              {payment_methods && payment_methods.length > 0 && (
                <>
                  <Text>
                    <ProText type="4" configuration={{ fontSize: '12' }}>
                      I tuoi metodi di pagamento
                    </ProText>
                  </Text>
                  {payment_methods.map(payment => (
                    <PaymentMethod
                      key={payment.id}
                      title={getTitleCard(payment)}
                      onSelect={() => {
                        setPaymentMethodId(payment.id);
                      }}
                      isOpen={paymentToken === payment.id}
                      isDefault
                    />
                  ))}
                </>
              )}

              {(isPremiumPurchase || (!isPremiumPurchase && company?.zuoraaccountid)) && (
                <>
                  <Text>
                    <ProText type="4" configuration={{ fontSize: '12' }}>
                      Aggiungi un nuovo metodo di pagamento
                    </ProText>
                  </Text>
                  {/****** Payment Method Zuora Credit Card ******/}
                  <PaymentMethod
                    key="zuora_credit-card"
                    title="Carta di credito"
                    isOpen={paymentType === 'zuora_credit-card' && !paymentToken}
                    onSelect={() => handleOnSelectPayment('zuora_credit-card')}
                    isCCZuora
                  >
                    <Description>
                      <ProText type="description" configuration={{ fontWeight: 300 }}>
                        Trasferimento di denaro sicuro tramite il tuo conto bancario.
                      </ProText>
                    </Description>
                    {paymentType === 'zuora_credit-card'
                   && <CreditCardZuora callbackError={(error) => handleCallbackErrorZuora(error)} callbackSuccess={(payment_method_id) => handleCallbackSuccessZuora(payment_method_id)} />}
                  </PaymentMethod>
                  {/****** Payment Method Zuora SEPA  ******/}
                  <PaymentMethod
                    key="zuora_sepa"
                    title="Addebito su conto corrente"
                    isOpen={paymentType === 'zuora_sepa' && !paymentToken}
                    onSelect={() => handleOnSelectPayment('zuora_sepa')}
                    isSEPAZuora
                  >
                    <Description>
                      <ProText type="description" configuration={{ fontWeight: 300 }}>
                        Attiva il pagamento pre-autorizzato in forma automatica sul tuo
                        conto corrente (SDD), il modo più semplice per gestire i tuoi
                        pacchetti
                      </ProText>
                    </Description>
                    {paymentType === 'zuora_sepa'
                    && <SEPAZuora callbackError={(error) => handleCallbackErrorZuora(error)} callbackSuccess={(payment_method_id) => handleCallbackSuccessZuora(payment_method_id)} />}
                  </PaymentMethod>
                  <SummaryContainer>
                    <SummaryTable showDiscountCode />
                  </SummaryContainer>
                  {isPremiumPurchase && (
                    <AgreementCheck
                      validator={formConfirmed}
                      checkUserType={sendDataTreatment}
                    />
                  )}
                  <ButtonsContainer>
                    <Row>
                      <Col md={6} sm={6} className="mb-3">
                        {isPremiumPurchase && (
                          <BtnCancel>
                            <Button
                              text="Annulla"
                              type={Button.TYPE.TERTIARY}
                              onClick={handleClosePurchase}
                            />
                          </BtnCancel>
                        )}
                      </Col>
                      <Col md={6} sm={6} className="mb-3">
                        <BtnConfirm>
                          <Button
                            id="confirmButton"
                            text="Conferma"
                            type={Button.TYPE.PRIMARY}
                            disabled={
                              !isValid
                            || (sendDataTreatment &&
                              (needToReopenAgreements || !formConfirmed()))
                            || (isPremiumPurchase && !formConfirmed())
                            || !paymentToken
                            || !payment_methods
                            }
                            onClick={handleSubmitPayment}
                          />
                        </BtnConfirm>
                      </Col>
                    </Row>
                  </ButtonsContainer>
                </>
              )}
            </Col>
          </BillingsContainer>
        </Row>
      </Container>
      <SubscriptionModal isPremiumPurchase={isPremiumPurchase} />
      <ModalErrors />
      <PremiumPurchaseModal cb={handleClosePurchase} />
    </>
  );
};

Billings.propTypes = {
  setPaymentType: PropTypes.func.isRequired,
  paymentType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  company: PropTypes.object,

  //HOC (connect, dispatch)
  billing: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    state: PropTypes.string,
    postalcode: PropTypes.string,
    address: PropTypes.string,
    rsociale: PropTypes.string,
    piva: PropTypes.string,
    cf_piva: PropTypes.string,
    phone: PropTypes.string,
    sdi: PropTypes.string,
    atecoCode: PropTypes.string,
    atecoDescription: PropTypes.string,
    section: PropTypes.string,
  }),
  account: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
  }),
  setSelectedGateway: PropTypes.func.isRequired,
  updateBilling: PropTypes.func.isRequired,
  nextSignupStep: PropTypes.func.isRequired,
  nextBuyerB2CSignupStep: PropTypes.func.isRequired,
  openModalError: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  sector: PropTypes.array.isRequired,
  agreements: AgreementsContentsInterface,
  sendDataTreatment: PropTypes.bool,
  setPaymentMethodId: PropTypes.func,
  setDefaultZuoraPaymentMethod: PropTypes.func,
  addZuoraPaymentMethod: PropTypes.func,
  getSector: PropTypes.func,
  paymentToken: PropTypes.string,
  cart_zuora: PropTypes.object,
  all_prospect: PropTypes.object,
  assoc_subscriptions: PropTypes.object,
  retrieveDraftCartZuora: PropTypes.func,
  pushUrl: PropTypes.func,
  isPremiumPurchase: PropTypes.bool,
  payment_methods: PropTypes.array,
  closePurchase: PropTypes.func,
  resetCart: PropTypes.func,
  step: PropTypes.object,
  setStepBillings: PropTypes.func.isRequired,
  organizationType: PropTypes.string,
  userType: PropTypes.string,
  updateUserType: PropTypes.func,
  getPrivacyData: PropTypes.func,
  submitCartZuora: PropTypes.func,
  getCompany: PropTypes.func,
  setNeedToReopenModal: PropTypes.func,
  needToReopenAgreements: PropTypes.bool,
};

export default connect(
  state => {
    const { privacy: { organizationType }, company } = state.profile;
    const { user: { sendDataTreatment } } = state.authentication;
    const { isPremiumPurchase, payment_methods } = state.billings;
    const {
      billing, sector, agreements, account, paymentToken,
      cart_zuora, discountCompatible, step, needToReopenAgreements,
    } = state.signup;
    const { all_prospect, assoc_subscriptions } = state.subscriptions;
    const { user } = state.authentication;
    return {
      all_prospect,
      assoc_subscriptions,
      sendDataTreatment,
      billing,
      user,
      company,
      sector,
      agreements,
      account,
      paymentToken,
      cart_zuora,
      isPremiumPurchase,
      payment_methods,
      discountCompatible,
      step,
      organizationType,
      userType: agreements?.userType,
      userFirstName: account?.firstName,
      userLastName: account?.lastName,
      needToReopenAgreements,
    };
  },
  dispatch => ({
    // pushUrl: url => dispatch(push(url)),
    pushUrl: (url, userType) => {
      const hasUserType = url.includes('?userType=');
      const newUrl = hasUserType ? url : `${url}?userType=${userType}`;
      dispatch(push(newUrl));
    },
    setSelectedGateway: gateway => dispatch({ type: SET_SELECTED_GATEWAY, gateway }),
    updateBilling: billing => dispatch({ type: SIGNUP_UPDATE_BILLING, billing }),
    nextSignupStep: (nextStep, data) => dispatch({ type: SIGNUP_NEXT_STEP._REQUEST, nextStep, data }),
    nextBuyerB2CSignupStep: (nextStep, data) => dispatch({ type: SIGNUP_BUYER_B2C_NEXT_STEP._REQUEST, nextStep, data }),
    getSector: () => dispatch({ type: SECTOR_GET._REQUEST }),
    openModalError: payload => dispatch({ type: MODAL_OPEN, id: ModalErrors.id, payload }),
    setPaymentMethodId: payload => dispatch({ type: SIGNUP_SET_PAYMENT_METHOD_ID, payload }),
    setDefaultZuoraPaymentMethod: (payment_method_id, shouldCreate = true) => dispatch({ type: BILLINGS_ZUORA_PAYMENT_METHOD_DEFAULT_SET._REQUEST, payment_method_id, shouldCreate }),
    addZuoraPaymentMethod: payment_method_id => dispatch({ type: BILLINGS_ZUORA_PAYMENT_METHOD_ADD._REQUEST, payment_method_id }),
    retrieveDraftCartZuora: () => dispatch({ type: SIGNUP_RETRIEVE_DRAFT_ORDER_ZUORA._REQUEST }),
    submitCartZuora: (paymentMethodId) => dispatch({ type: SIGNUP_SUBMIT_ORDER_ZUORA._REQUEST, paymentMethodId }),
    closePurchase: () => dispatch({ type: BILLING_IS_PREMIUM_PURCHASE, payload: false }),
    resetCart: () => dispatch({ type: SIGNUP_RESET_CART }),
    setStepBillings: () => dispatch({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 3 }),
    updateUserType: userType => dispatch({ type: USERTYPE_UPDATE, userType }),
    getPrivacyData: () => dispatch({ type: PROFILE_GET_DATA_PRIVACY._REQUEST }),
    getCompany: () => dispatch({ type: PROFILE_DATA_COMPANY_GET._REQUEST }),
    setNeedToReopenModal: payload => dispatch({ type: SET_NEED_TO_REOPEN_AGREEMENTS, payload }),
  })
)(withMediaQueries(Billings));
