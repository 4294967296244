import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Row, Col } from 'ui/gridSystem';
import {
  SelectWrapper,
  SessionsPopup,
  TableCardContainer
} from 'ui/components/TableCard/style';
import {Checkbox, CustomInput, CustomSelect} from 'ui/atoms';
import { withMediaQueries } from 'hoc/withMediaQueries';
import { styles } from 'utils/multi-select';
import validator from 'utils/regex-validator';
import { getLocaleStringFromDateTime } from 'utils/date-format';
import { mappingVariations } from '../NewSubscriptions';
import ProText from 'ui/typography/proText';
import { GET_ECM_ROLE } from 'redux/actions/subscriptions';
import {CheckboxWrapper} from "../Modals/SponsorModal/style";
import useEcmRoleList from "../../../utils/useEcmRoleList";
import {GET_ECM_MAX_CREDITI_ACQUISITIBILI} from "../../../redux/actions/ecm";
import CustomInputNumber from "../../atoms/CustomInputNumber";

const TableCardEmpty = ({
  subscription,
  handleOnChangeEmail,
  emailName,
  options,
  calendarDates,
  handleOnChangeVariation,
  handleSelectCalendar,
  handleOnChangeRole,
  handleOnChangeEcmCredits,
  handleOnChangeFinalTestEnabled,
  getEcmRole,
  ecmRoleList,
  userDomainAttributes,
  isBundle,
  isMultipleBundle,
  ecmMaxCrediti
}) => {
  const [value, setValue] = useState(emailName);
  const [showSessions, setShowSessions] = useState(false);
  const [isBasePkg, setIsBasePkg] = useState(true);
  const [selectedCalendar, setSelectedCalendar] = useState(null);

  const [ecmRoleListState, ecmRoleOptionsState] = useEcmRoleList(userDomainAttributes, ecmRoleList);
  const [selectedRole, setSelectedRole] = useState();
  //const [roleDisabledList, setRoleDisabledList] = useState();
  const [ecmCreditsNotP, setEcmCreditsNotP] = useState(null);
  const [finalTestEnabled, setFinalTestEnabled] = useState(subscription.finalTestEnabled);
  const { id, email, role, variations, error, ecmFlag } = subscription;

  const handleOnChange = newValue => {
    setValue(newValue);
  };

  useEffect(() => {
    getEcmRole();
  }, [getEcmRole]);

  /*useEffect(() => {
    const ecmRoleListFiltered = userDomainAttributes?.managerType?.toLowerCase() === 'base' ? ecmRoleList?.filter(role => !role.only_provisioner) : ecmRoleList;
    setEcmRoleList(ecmRoleListFiltered);

    let ecmRoleOptions = [];

    if (Boolean(ecmRoleListFiltered)) {
      ecmRoleListFiltered.forEach(roleItem => {
        ecmRoleOptions = [
          ...ecmRoleOptions,
          {
            label: roleItem.title,
            value: roleItem.id
          }
        ];
        setEcmRoleOptions(ecmRoleOptions);
      });
    }
  }, [ecmRoleList]);*/

  /*useEffect(() => {
    let roleDisabledArray = [];

    if (Boolean(ecmRoleListState)) {
      ecmRoleListState.forEach((item, index) => {
        roleDisabledArray = [
          ...roleDisabledArray,
          {
            id: `subscription-${index}`,
            disabled: Boolean(subscription.variations[0]?.ecmFlag)
          }
        ];

        setRoleDisabledList(roleDisabledArray);
      });
    }
  }, [variations]);*/

  useEffect(() => {
    if (emailName) {
      setValue(emailName);
    }
  }, [emailName]);

  const handleOnBlur = () => {
    handleOnChangeEmail(id, value);
  };

  const handleSelectRole = (value, id) => {
    const roleObject = ecmRoleListState.find(role => role.id === value.value);

    setSelectedRole(value);

    const roleObjectFormatted = {
      id: roleObject.id,
      name: roleObject.title,
      code: roleObject.code,
      onlyProvisioner: roleObject.only_provisioner
    }
    if (roleObject.code === 'P'){ // se partecipante attivo flag partecipante
      onChangeFinalTestEnabled(id, true, true);
    }else{
      onChangeFinalTestEnabled(id, false, true);
    }

    handleOnChangeRole(id, roleObjectFormatted);
  }

  const formatProductOptionLabel = ({label, bundleName, packageType = 'empty' }) => (
    <div className='premium-container'>
      <div>{!!bundleName ? bundleName : label}</div>
      {/*{packageType.toLowerCase() === 'premium' ?
      <span className='premium'>
        Premium
        <span className='icon-premium'></span>
      </span>
      : ''}*/}
    </div>
  );

  const formatCalendarOptionLabel = ({label, unavailable = false }) => (
    <div className='premium-container'>
      <div>{label}</div>
      {unavailable ? (
        <span className='unavailable'>
          Posti<br />Esauriti
        </span>
      ) : null}
    </div>
  );

  const onChangeEcmCredits = (subscriptionId, ecmCreditsValue)=>{
    setEcmCreditsNotP(ecmCreditsValue);
    handleOnChangeEcmCredits(subscriptionId, ecmCreditsValue);
  }
  const onChangeFinalTestEnabled = (subscriptionId, finalTestEnabledValue, skipUpdateRole) => {
    setFinalTestEnabled(finalTestEnabledValue);
    handleOnChangeFinalTestEnabled(subscriptionId, finalTestEnabledValue);
    if (finalTestEnabledValue){
      onChangeEcmCredits(subscriptionId,null);
      handleOnChangeEcmCredits(subscriptionId, null);

      const ruoloPartecipante = ecmRoleListState.find(role => {
        return role.code === 'P';
      });

      // caso selezione ruolo -> aggiornamento campi ecm e flag test finale
      !skipUpdateRole && handleSelectRole({label: ruoloPartecipante.title, value: ruoloPartecipante.id}, subscriptionId);
    }else if (selectedRole){ // se avevo selezionato partecipante e tolgo il flag, devo svuotare il ruolo
      const ruoloPartecipante = ecmRoleListState.find(role => {
        return role.code === 'P';
      });
      if (ruoloPartecipante.id === selectedRole.value){
        setSelectedRole(null);
        handleOnChangeRole(subscriptionId, null);
      }
    }
  }

  const getSessionDates = (session) => {
    const [startDate, startTime] = getLocaleStringFromDateTime(new Date(session.startDate)).split('/');
    const [endDate, endTime] = getLocaleStringFromDateTime(new Date(session.endDate)).split('/');

    const date = `${startDate}${endDate !== startDate ? ` - ${endDate}` : ''}`;
    const time = `${startTime}${endTime !== startTime ? ` - ${endTime}` : ''}`;

    return `${date}   ${time}`;
  }

  const sessions = (() => (
    /* NOTE, requires product select to be single selection */
    subscription?.variations[0]?.calendarId
      ? calendarDates.find(calendarDate => (
        calendarDate.calendarId === subscription.variations[0].calendarId
      ))?.sessions.sort(function(x, y){
          return new Date(x.startDate) - new Date(y.startDate);
        }) ?? []
      : []
  ))();

  const canSetCreditiEcm = !isBundle && ecmFlag && (userDomainAttributes?.managerType === 'PROVISIONER');

  return (
    <>
      <TableCardContainer grayBg className='mb-4 mb-md-0 sub-element' id={id}>
        <Row className='position-relative'>
          <Col xl={canSetCreditiEcm ? 2 : 3} lg={canSetCreditiEcm ? 4 : 3}>
            <CustomInput
              type="text"
              value={email}
              placeholder="Email utente"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              error={error}
            />
          </Col>
          <Col xl={canSetCreditiEcm ? 2 : 3} lg={canSetCreditiEcm ? 4 : 3} className="mb-3 mb-sm-5">
            <SelectWrapper>
              <CustomSelect
                placeholder={!!isBundle ? isMultipleBundle ? "Bundle" : "Seleziona bundle" : "Seleziona corso"}
                options={options}
                onChange={newVariations =>{
                  setIsBasePkg(newVariations.packageType?.toLowerCase() === 'base');
                  handleOnChangeVariation(id, variations, newVariations)
                  }
                }
                formatOptionLabel={formatProductOptionLabel}
                disabled={!email || (email && !validator.email(email) || isMultipleBundle)}
                selected={variations.map(mappingVariations)}
                styles={styles}
                hideSelectedOptions={true}
                tooltipDescription={!!subscription.variations.length ? subscription.bundleName || subscription.variations[0].productName : undefined}
              />
            </SelectWrapper>
          </Col>
          <Col xl={canSetCreditiEcm ? 2 : 3} lg={canSetCreditiEcm ? 4 : 3} className="mb-3 mb-sm-5">
            <SelectWrapper>
              <CustomSelect
                placeholder="Ruolo di partecipazione"
                options={ecmRoleOptionsState}
                onChange={(value) => handleSelectRole(value, id)}
                disabled={!subscription.variations[0]?.ecmFlag}
                selected={selectedRole}
                styles={styles}
                hideSelectedOptions={true}
              />
            </SelectWrapper>
          </Col>
          {!isBundle && <Col xl={canSetCreditiEcm ? 2 : 3} lg={canSetCreditiEcm ? 4 : 3} className={"mb-3 mb-sm-5"}>
            <SelectWrapper
              onMouseEnter={() => sessions?.length && setShowSessions(true)}
              onMouseLeave={() => setShowSessions(false)}
            >
              <CustomSelect
                placeholder={(
                  <span className='placeholder-icon'>
                    <span className='icon-calendar'>
                    </span>Date evento
                  </span>
                )}
                options={
                  calendarDates
                    .map((calendar) => ({
                      value: calendar.calendarId,
                      label: calendar.title,
                      unavailable: calendar.availableSeats < 1,
                    }))
                }
                formatOptionLabel={formatCalendarOptionLabel}
                onChange={ selectedCalendar => {
                  const actualCalendar = calendarDates.find(calendarDate => (
                      calendarDate.calendarId === selectedCalendar.value
                  ));
                  setSelectedCalendar(selectedCalendar);
                  handleSelectCalendar(
                      actualCalendar,
                      variations.map(mappingVariations),
                      emailName,
                  );
                  }
                }
                selected={selectedCalendar}
                className={isBasePkg ? 'disabled' : ''}
                disabled={(calendarDates?.length === 0 || isBasePkg) || (!email || (email && !validator.email(email))) }
                styles={styles}
                hideSelectedOptions={true}
              />
            </SelectWrapper>
          </Col>}
          {canSetCreditiEcm && <>
            <Col xl={2} lg={4}>
              <CustomInput
                  type="text"
                  value={ecmCreditsNotP}
                  disabled={finalTestEnabled || !Boolean(subscription.ecmFlag)}
                  placeholder={`Crediti ECM ${ecmMaxCrediti ? '(max: '+ ecmMaxCrediti +')' : ''}`}
                  onBlur={()=>{
                    const parsedValue = parseInt(ecmCreditsNotP);
                    if (parsedValue) {
                      if (parsedValue < 0) {
                        setEcmCreditsNotP(0);
                      } else if (ecmMaxCrediti && (parsedValue > ecmMaxCrediti)) {
                        setEcmCreditsNotP(ecmMaxCrediti);
                      }
                    }else {
                      setEcmCreditsNotP(null);
                    }
                  }}
                  onChange={(value) => onChangeEcmCredits(id, value)}/>
            </Col>
            <Col xl={2} lg={4}>
              <CheckboxWrapper key={`opt-finaltest`} className='d-flex align-items-center justify-content-center' style={{marginTop: '15px'}}>
                <Checkbox
                    hideLabelDesktop={false}
                    label={'Rendi partecipante'}
                    onChange={(value) => onChangeFinalTestEnabled(id, value)}
                    checked={finalTestEnabled}
                />
              </CheckboxWrapper>
            </Col>
            </>
          }
          {showSessions ? (
            <SessionsPopup>
              {sessions?.map(session => (
                <>
                {session.liveEventMode === 'virtual' ?
                <ProText
                configuration={{
                  fontSize: 14,
                  fontWeight: 500
                }}
                ><span className='mode'>{session.liveEventMode}</span> | {getSessionDates(session)}</ProText>
                :
                <ProText
                configuration={{
                  fontSize: 14,
                  fontWeight: 500
                }}
                ><span className='mode'>{session.liveEventMode}</span> | {getSessionDates(session)} | {session?.locations[0]?.city}</ProText>
              }
                </>
              ))}
            </SessionsPopup>
          ) : null}
        </Row>
      </TableCardContainer>
    </>
  );
};

TableCardEmpty.propTypes = {
  subscription: PropTypes.object.isRequired,
  handleOnChangeEmail: PropTypes.func.isRequired,
  emailName: PropTypes.string,
  options: PropTypes.array,
  handleOnChangeVariation: PropTypes.func.isRequired,
  calendarDates: PropTypes.array,
  handleSelectCalendar: PropTypes.func,
  handleOnChangeRole: PropTypes.func.isRequired,
  handleOnChangeEcmCredits: PropTypes.func,
  handleOnChangeFinalTestEnabled: PropTypes.func,
  getEcmRole: PropTypes.func.isRequired,
  isBundle: PropTypes.bool,
  isMultipleBundle: PropTypes.bool,
  ecmMaxCrediti: PropTypes.number
};

TableCardEmpty.defaultProps = {
  calendarDates: []
}

export default withMediaQueries(
	connect(
		state => {
        const { userDomainAttributes } = state.authentication.user;

        const ecmRoleList = state.subscriptions.ecmRole;
			return {
        ecmRoleList,
        userDomainAttributes
			};
		},
    dispatch => ({
      getEcmRole: () => dispatch({ type: GET_ECM_ROLE._REQUEST })
    })
	)(TableCardEmpty)
);
