/* eslint-disable no-unused-vars */
import { takeLatest, put, call, select, delay, all } from 'redux-saga/effects';

import { push } from 'redux-first-history';
import {
  AUTH_LOGIN,
  LOADER_CLOSE,
  LOADER_OPEN,
  MODAL_CLOSE,
  MODAL_OPEN,
} from 'redux/actions';

import {
  RECOMMENDED_SUBSCRIPTION_GET,
  TEAMS_INTERESTS_GET,
  CONTENT_OVERVIEW_MOST_POPULAR_GET,
  CONTENT_OVERVIEW_MOST_VIEWED_GET,
  CONTENT_OVERVIEW_SET,
  CREATE_PROSPECT,
  ALL_PROSPECT_GET,
  ASSOC_SUBSCRIPTIONS_GET,
  GO_TO_EDIT_SUBSCRIPTION,
  ECM_ROLE_OR_CREDITS_SUBSCRIPTION_UPDATE,
  EMAIL_SUBSCRIPTION_UPDATE,
  EMAIL_SUBSCRIPTION_DELETE,
  EMAIL_SUBSCRIPTION_DISABLE,
  CATALOG_PRODUCT_GET,
  GET_CATEGORIES,
  GET_FILTERED_CATALOG,
  GET_TOTAL_AMOUNT_ANNUAL_PAYMENT,
  GET_SUBSCRIPTION_PRODUCT_NAMES,
  PREPARE_CREATE_PROSPECT_PREMIUM,
  SET_DRAFT_SUBSCRIPTIONS_LOADED,
  GET_CALENDARS,
  GET_ECM_ROLE,
  GET_XML_LIST,
  CREATE_XML
} from 'redux/actions/subscriptions';

import subscriptions from 'api/subscriptions';
import { calcPagination } from 'utils/pagination';
import routes from 'routes';
import SubscriptionModal from 'ui/components/Modals/SubscriptionModal';
import ModalErrors from 'ui/components/Modals/ModalErrors';
import SuccessModal from 'ui/components/Modals/SuccessModal';
import profile from 'api/profile';
import PremiumPurchaseModal from '../../ui/components/Modals/PremiumPurchaseModal';
import { PROFILE_DATA_COMPANY_SET } from '../actions/profile';
import signup from '../../api/signup';
import { SIGNUP_NEXT_STEP, SIGNUP_SET_DRAFT_ORDER_ZUORA } from '../actions/signup';


function* redirectUser() {
  try {
    const { organizationId, userId, sendDataTreatment } = yield select(state => state.authentication.user);
    const storeCompany = yield select(state => state.profile.company);
    let company = storeCompany || {};
    if (organizationId && Object.keys(company).length <= 0) {
      const data = yield call(profile.getProfileDataCompany, { orgId: organizationId, userId });
      company = { ...data.data };
      yield put({ type: PROFILE_DATA_COMPANY_SET, company });
    }
    if (company.zuoraaccountnumber) {
      const { data } = yield call(signup.retrieveDraftOrderZuora, { id: company.zuoraaccountnumber });
      const { orderdata } = data;
      if (orderdata && orderdata.length) {
        yield put({ type: SIGNUP_SET_DRAFT_ORDER_ZUORA, data });
        yield put({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 3 });
        yield put(push(routes.billings.path));
      } else {
        yield put({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 0 });
        yield put(push(routes.subscription.path));
      }
    } else if (!organizationId && sendDataTreatment) {
      yield put({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 3 });
      yield put(push(routes.billings.path));
    } else {
      yield put({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 0 });
      yield put(push(routes.subscription.path));
    }
  } catch (err) {
    console.log('sagas > subscription > redirectUser', err);
    yield put({ type: SIGNUP_NEXT_STEP._SUCCESS, nextStep: 0 });
    yield put(push(routes.subscription.path));
  }
}

function* assocSubscriptionGet({ loadMore = false, reload = false, toBeRedirect = false, timestamp = 0 }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { assoc_subscriptions: { orders: subscriptionList = [], nextpage: nextPage } = {} } = yield select(state => state.subscriptions);
    if (!subscriptionList.length || reload) {
      let { data: { subscriptions: orders, nextpage, nextpagepresent = {} } = {} } = yield call(subscriptions.getSubcriptionDraft, { items: 10 });
      yield put({ type: ASSOC_SUBSCRIPTIONS_GET._SUCCESS, data: { orders, nextpage, nextpagepresent }, forceReload: true });
      while (nextpagepresent) {
        ({ data: { subscriptions: orders, nextpage, nextpagepresent = {} } = {} } = yield call(subscriptions.getSubcriptionDraft, { items: 10, nextPageId: nextpage.id }));
        yield put({ type: ASSOC_SUBSCRIPTIONS_GET._SUCCESS, data: { orders, nextpage, nextpagepresent }, forceReload: false });
      }
      yield put({ type: GET_TOTAL_AMOUNT_ANNUAL_PAYMENT._REQUEST });
      const { location: { pathname = '' } } = yield select(state => state.router);
      const modalIsOpen = yield select(state => state.modal.open);
      if (toBeRedirect && !orders?.length) {
        yield redirectUser();
      } else if (!modalIsOpen && (pathname.indexOf(routes.signup.path) >= 0 || pathname.indexOf(routes.premiumPurchase.path)) >= 0) {
        yield put(push(routes.mySubscription.path));
      }
    // } else if (subscriptionList.length && loadMore) {
      // const { data: { subscriptions: orders, nextpage, nextpagepresent } = {} } = yield call(subscriptions.getSubcriptionDraft, { nextPageId: nextPage.id }); /* !! nextPage is empty */
      // yield put({ type: ASSOC_SUBSCRIPTIONS_GET._SUCCESS, data: { orders, nextpage, nextpagepresent }, forceReload: false });
    } else {
      yield put({ type: SET_DRAFT_SUBSCRIPTIONS_LOADED });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > assocSubscriptionGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* assocSubscriptionGetWatch() {
  yield takeLatest(ASSOC_SUBSCRIPTIONS_GET._REQUEST, assocSubscriptionGet);
}

function* getCalendars({ productId }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { calendars = [] } = {} } = yield call(subscriptions.getCalendarsForPremiumProduct, { productId });
    calendars.sort(function(x, y){
      return new Date(x.startDate) - new Date(y.startDate);
    })
    yield put({ type: GET_CALENDARS._SUCCESS, productId, calendars });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > getCalendars', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCalendarsWatch() {
  yield takeLatest(GET_CALENDARS._REQUEST, getCalendars);
}

function* getSubscriptionProductNames({ ids = false }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { data: { subscriptions: subscriptionList = [] } = {} } = yield call(subscriptions.getSubscriptionProductNames, { ids });
    yield put({ type: GET_SUBSCRIPTION_PRODUCT_NAMES._SUCCESS, productNames: subscriptionList });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > getSubscriptionProductNames', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getSubscriptionProductNamesWatch() {
  yield takeLatest(GET_SUBSCRIPTION_PRODUCT_NAMES._REQUEST, getSubscriptionProductNames);
}

function* allProspectGet({ reloadAssocProspects = false, timestamp = 0 }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    if (userId) {
      const res = yield call(subscriptions.getActiveSubscriptions, { userId });
      const { data: { response = {} } = {} } = res;
      yield put({ type: ALL_PROSPECT_GET._SUCCESS, response });
      const ids = Object.keys(response || {}).reduce((acc, key) => {
        const prospect = response[key];
        const subscriptionsList = prospect.reduce((subscriptionsAcc, subscription) => ([subscription, ...subscriptionsAcc]), []);
        const offerts = subscriptionsList.reduce((offertsAcc, subscription) => ([...subscription.offerts, ...offertsAcc]), []);
        const subscriptionIds = offerts.reduce((subscriptionIdsAcc, offert) => ([offert.subscriptionId, ...subscriptionIdsAcc]), []);
        return [...subscriptionIds, ...acc];
      }, []);
      //if (ids.length) yield put({ type: GET_SUBSCRIPTION_PRODUCT_NAMES._REQUEST, ids: [...new Set(ids)].toString() });
      yield put({ type: ASSOC_SUBSCRIPTIONS_GET._REQUEST, reload: reloadAssocProspects, toBeRedirect: !(response && Object.keys(response)?.length) });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > allProspectGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* allProspectGetWatch() {
  yield takeLatest(ALL_PROSPECT_GET._REQUEST, allProspectGet);
}

function* recommendedSubscriptionListGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { recommended_subscriptions: { data = [] } = {} } = yield select(
      state => state.subscriptions
    );
    if (data?.length) {
      yield put({
        type: RECOMMENDED_SUBSCRIPTION_GET._SUCCESS,
        data: { data },
      });
    } else {
      const { user: { userId } = {} } = yield select(
        state => state.authentication
      );
      const { data: res = {} } = yield call(subscriptions.getRecommendedPlans, {
        userId,
      });
      yield put({ type: RECOMMENDED_SUBSCRIPTION_GET._SUCCESS, data: res });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > recommendedSubscriptionListGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* recommendedSubscriptionListGetWatch() {
  yield takeLatest(
    RECOMMENDED_SUBSCRIPTION_GET._REQUEST,
    recommendedSubscriptionListGet
  );
}

function* teamInterestDetailsGet(content) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    if (Object.keys(content)?.length) {
      const { data: { data: details } = {} } = yield call(
        subscriptions.getTeamsInterestDetails,
        {
          filters: { type: 'collection', ids: Object.keys(content) },
          userId,
        }
      );
      const teamsInterest = Object.keys(content).reduce((acc, value) => {
        const item = details.find(detail =>
          detail.content_id?.toString() === value.toString()
        );
        const interest = {
          users: content[value],
          ...item,
        };
        acc[value] = interest;
        return acc;
      }, {});
      yield put({ type: TEAMS_INTERESTS_GET._SUCCESS, content: teamsInterest });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > teamInterestDetailsGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* teamsInterestsListGet() {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const res = yield call(subscriptions.getTeamsInterest, { userId });
    const { data: { content, resultcode: code } = {}, status } = res;
    if (code?.toString() === '200' || status?.toString() === '200') {
      yield teamInterestDetailsGet(content);
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > teamsInterestsListGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* teamsInterestsListGetWatch() {
  yield takeLatest(TEAMS_INTERESTS_GET._REQUEST, teamsInterestsListGet);
}

function* getProductCatalog() {
  try {
    yield put({ type: LOADER_OPEN });
    const { catalog } = yield select(state => state.subscriptions);
    if (!catalog || !catalog.length) {
      const res = yield call(subscriptions.getProductCatalog);
      const { data: { data: content } = {} } = res;

      const productVariations = content.reduce((acc, currValue) => {
        const product = (currValue.product_variations.data || []).map(prod => ({
          ...prod,
          product_id: currValue.product_id,
        }));

        return [...acc, ...product];
      }, []);
      yield put({
        type: CATALOG_PRODUCT_GET._SUCCESS,
        content: productVariations,
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > getProductCatalog', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getProductCatalogWatch() {
  yield takeLatest(CATALOG_PRODUCT_GET._REQUEST, getProductCatalog);
}

function* contentMostPopularOverviewGet({
  itemPerPage = 5,
  content_type,
  needLoader,
}) {
  try {
    const { total_items: totalCount, count, from } = yield select(
      state => state.subscriptions[content_type] || {}
    );
    const pagination = calcPagination({ count, from, totalCount }, itemPerPage);

    if (pagination && !pagination.isLastPage) {
      if (needLoader) yield put({ type: LOADER_OPEN });
      const { user: { userId } = {} } = yield select(
        state => state.authentication
      );
      const { data: { data: response } = {} } = yield call(
        subscriptions.getMostPopularContentOverview,
        {
          filters: { ...pagination, content_type },
          userId,
        }
      );
      yield put({
        type: CONTENT_OVERVIEW_SET,
        response: { data: response, content_type },
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > contentOverviewGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* contentMostViewedOverviewGet({
  itemPerPage = 5,
  content_type,
  needLoader,
}) {
  try {
    const { total_items: totalCount, count, from } = yield select(
      state => state.subscriptions[content_type] || {}
    );
    const pagination = calcPagination({ count, from, totalCount }, itemPerPage);

    if (pagination && !pagination.isLastPage) {
      if (needLoader) yield put({ type: LOADER_OPEN });
      const { user: { userId } = {} } = yield select(
        state => state.authentication
      );
      const { data: { data: response } = {} } = yield call(
        subscriptions.getMostViewedContentOverview,
        {
          filters: { ...pagination, content_type },
          userId,
        }
      );
      yield put({
        type: CONTENT_OVERVIEW_SET,
        response: { data: response, content_type },
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > contentOverviewGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* contentOverviewMostViewedGetWatch() {
  yield takeLatest(
    CONTENT_OVERVIEW_MOST_VIEWED_GET,
    contentMostViewedOverviewGet
  );
}

function* contentOverviewMostPopularGetWatch() {
  yield takeLatest(
    CONTENT_OVERVIEW_MOST_POPULAR_GET,
    contentMostPopularOverviewGet
  );
}

function* createProspect({
  payload: { body = [], deletedPersDataAcceptance } = {},
  cb, isPremium = false,
}) {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      user: { userId, organizationId, email: accountEmail } = {},
    } = yield select(state => state.authentication);
    const {
      agreements: { user_clauses: agreements = {} },
    } = yield select(state => state.signup);
    const {
      privacy: { dataPrivacylist: privacy = [] },
    } = yield select(state => state.profile);
    const prospectUsers = [];
    (body || []).forEach(subscription => {
      const prospects = subscription.variations.map(variation => ({
        email: subscription.email,
        organizationId,
        sourceType: subscription.sourceType,
        productId: subscription.productId,
        packageType: subscription.packageType,
        participationRole: subscription.role,
        ecmCreditsNotP: subscription.ecmCreditsNotP,
        ...variation,
      }));
      prospectUsers.push(...prospects);
    });
    const persDataAcceptance = Object.values(agreements).map(clause => ({
      authorize: clause.authorize,
      type: clause.type,
      mandatory: clause.mandatory,
    }));

    const notIncluded = privacy
      ?.filter(p => !persDataAcceptance.map(da => da.type).includes(p.type))
      .map(p => ({
        authorize: p.authorized,
        type: p.type,
        mandatory: p.mandatory,
      }));
    const newPersDataAcceptance = [...persDataAcceptance, ...notIncluded];

    const { data = {} } = yield call(subscriptions.createProspect, {
      prospectUsers,
      userId,
      persDataAcceptance: deletedPersDataAcceptance || newPersDataAcceptance,
    });

    if (data.resultcode?.toString() === '200') {
      yield put({ type: CREATE_PROSPECT._SUCCESS, response: data });
      yield put({ type: ALL_PROSPECT_GET._REQUEST, reloadAssocProspects: true, timestamp: Date.now() });

      const reloadApp = () => {
        if (prospectUsers.find(p => p.email === accountEmail)) {
          window.location.href = routes.allSubsriptions.path;
        } else if (cb) {
          cb();
        }
      };

      if (isPremium) {
        yield put({ type: MODAL_OPEN, id: PremiumPurchaseModal.id, payload: { cb: reloadApp } });
      } else {
        yield put({
          type: MODAL_OPEN,
          id: SubscriptionModal.id,
          payload: { cb: reloadApp },
        });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > createProspect', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* createProspectWatch() {
  yield takeLatest(CREATE_PROSPECT._REQUEST, createProspect);
}

function* prepareCreateProspectPremium({ premiumSubscriptions, premium_subscription_emails }) {
  try {
    yield put({ type: LOADER_OPEN });
    let createProspectPremiumSubscriptionBody = premiumSubscriptions.map(subscription => (
      premium_subscription_emails
        .filter(email1 => email1.variations.find(variation => Number(variation.variationId) === Number(subscription.variationId)))
        .map(email2 => ({ email: email2.email, variations: [subscription] }))
    ));
    if (Array.isArray(createProspectPremiumSubscriptionBody) && createProspectPremiumSubscriptionBody.length > 0 && Array.isArray(createProspectPremiumSubscriptionBody[0])) {
      createProspectPremiumSubscriptionBody = Array.from(createProspectPremiumSubscriptionBody[0]);
    }
    yield put({ type: CREATE_PROSPECT._REQUEST, payload: { body: createProspectPremiumSubscriptionBody }, isPremium: true });
    yield put({ type: PREPARE_CREATE_PROSPECT_PREMIUM._SUCCESS });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > allProspectGet', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* prepareCreateProspectPremiumWatch() {
  yield takeLatest(PREPARE_CREATE_PROSPECT_PREMIUM._REQUEST, prepareCreateProspectPremium);
}

function* goToEditSubscription() {
  try {
    yield put({ type: LOADER_OPEN });
    yield put({ type: AUTH_LOGIN._SUCCESS });
    // console.log('sagas > subscriptions > goToEditSubscription: ASSOC_SUBSCRIPTIONS_GET._REQUEST');
    // yield put({ type: ALL_PROSPECT_GET._REQUEST, reload: true, timestamp: Date.now() })
    yield put(push(routes.mySubscription.path));
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > goToEditSubscription', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* goToEditSubscriptionWatch() {
  yield takeLatest(GO_TO_EDIT_SUBSCRIPTION, goToEditSubscription);
}

function* updateEmailSubscription({ payload: { email, newEmail, prospectId } }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const response = yield call(subscriptions.updateEmailSubscription, {
      userId,
      oldMail: email,
      newMail: newEmail,
      prospectId,
    });
    if (response.status === 200) {
      // yield put({ type: EMAIL_SUBSCRIPTION_UPDATE._REQUEST });
      yield put({ type: ALL_PROSPECT_GET._REQUEST, reloadAssocProspects: true, timestamp: Date.now() });
    }
  } catch (err) {
    let errorText = ''
    switch(err.response?.status) {
      case 409:
        errorText =
          'Questo pacchetto è già stato assegnato all\'utente inserito, per proseguire con l\'assegnazione indica l\'indirizzo e-mail di un altro utente'
        break;
      default:
        errorText = err.message
    }
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText }
    });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* updateEmailSubscriptionWatch() {
  yield takeLatest(EMAIL_SUBSCRIPTION_UPDATE._REQUEST, updateEmailSubscription);
}

function* updateEcmRoleOrCreditsSubscription({ payload: { flagParticipantActive, oldRole, newRole, oldEcmCreditsNotP, newEcmCreditsNotP, prospectId } }) {
  try {
    yield put({ type: LOADER_OPEN });
    const { user: { userId } = {} } = yield select(
      state => state.authentication
    );
    const response = yield call(subscriptions.updateEcmRoleOrCreditsSubscription, {
      userId,
      flagParticipantActive,
      oldRole,
      newRole,
      oldEcmCreditsNotP,
      newEcmCreditsNotP,
      prospectId,
    });
    if (response.status === 200) {
      yield put({ type: ALL_PROSPECT_GET._REQUEST, reloadAssocProspects: true, timestamp: Date.now() });
    }
  } catch (err) {
    let errorText = ''
    switch(err.response?.status) {
      default:
        errorText = err.message
    }
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText }
    });
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* updateEcmRoleOrCreditsSubscriptionWatch() {
  yield takeLatest(ECM_ROLE_OR_CREDITS_SUBSCRIPTION_UPDATE._REQUEST, updateEcmRoleOrCreditsSubscription);
}

function* deleteEmailSubscription({ isFingerLearning }) {
  try {
    yield put({ type: LOADER_OPEN });
    const {
      subscriptionOnEdit: { prospectId, subscriptionId } = {}, productNames = [],
    } = yield select(state => state.subscriptions);
    const { company: { zuoraaccountnumber } = {} } = yield select(state => state.profile);
    const subscriptionName = (productNames || []).filter((product) => product.subscriptionId === subscriptionId);

    const body = {
      accountNumber: zuoraaccountnumber,
      items: [
        {
          subscriptionNumber: subscriptionName[0] ? subscriptionName[0].subscriptionName : '',
          prospectId,
          inactiveReady: true,
          type: subscriptionName[0] ? subscriptionName[0].type : '',
        },
      ],
    };

    // const resDisableEmailSubscription =
    yield call(
      subscriptions.disableEmailSubscription,
      body
    );

    yield put({ type: EMAIL_SUBSCRIPTION_DELETE._SUCCESS });
    console.log('sagas > subscription > deleteEmailSubscription: calls allProspectGet');
    yield put({ type: ALL_PROSPECT_GET._REQUEST, timestamp: Date.now() });
    yield put({ type: MODAL_CLOSE });
    yield delay(500);
    yield put({
      type: MODAL_OPEN,
      id: SuccessModal.id,
      payload: {
        title: isFingerLearning
          ? 'Pacchetto eliminato.'
          : 'Abbonamento eliminato.',
        description: isFingerLearning
          ? 'Il pacchetto selezionato è stato eliminato. La modifica sarà effettiva entro le 24 ore'
          : "L'abbonamento selezionato è stato eliminato. La modifica sarà effettiva entro le 24 ore",
      },
    });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > deleteEmailSubscription', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* deleteEmailSubscriptionWatch() {
  yield takeLatest(EMAIL_SUBSCRIPTION_DELETE._REQUEST, deleteEmailSubscription);
}

function* disableEmailSubscription() {
  try {
    yield put({ type: LOADER_OPEN });
    const { subscriptionOnEdit: { prospectId, subscriptionId } = {}, productNames = [] } = yield select(state => state.subscriptions);
    const { company: { zuoraaccountnumber } = {} } = yield select(state => state.profile);
    const subscriptionName = (productNames || []).filter((product) => product.subscriptionId === subscriptionId);

    const body = {
      accountNumber: zuoraaccountnumber,
      items: [
        {
          subscriptionNumber: subscriptionName[0] ? subscriptionName[0].subscriptionName : '',
          prospectId,
          inactiveReady: false,
          type: subscriptionName[0] ? subscriptionName[0].type : '',
        },
      ],
    };

    // const resDisableEmailSubscription =
    yield call(subscriptions.disableEmailSubscription, body);
    // const { user: { userId } = {} } = yield select(state => state.authentication);

    // const resPutOrderItemUpdateToRenew = yield call(subscriptions.putOrderItemUpdateToRenew,
    //   {
    //     userId,
    //     body: {
    //       order_item_id: subscriptionId,
    //       new_renew_qty: '0'
    //     }
    //   }
    // );

    yield put({ type: EMAIL_SUBSCRIPTION_DISABLE._SUCCESS });
    console.log('sagas > subscription > disableEmailSubscription: calls allProspectGet');
    yield put({ type: ALL_PROSPECT_GET._REQUEST, timestamp: Date.now() });
    yield put({ type: MODAL_CLOSE });
    yield delay(500);
    yield put({
      type: MODAL_OPEN,
      id: SuccessModal.id,
      payload: {
        title: 'Abbonamento disattivato',
        description:
          "Il rinnovo automatico per l'abbonamento selezionato è stato disattivato. La modifica sarà effettiva entro 24 ore",
      },
    });
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > disableEmailSubscription', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* disableEmailSubscriptionWatch() {
  yield takeLatest(
    EMAIL_SUBSCRIPTION_DISABLE._REQUEST,
    disableEmailSubscription
  );
}

function* getCategories() {
  try {
    yield put({ type: LOADER_OPEN });

    const { status, data } = yield call(subscriptions.getCategories);
    if (status?.toString() === '200') {
      yield put({
        type: GET_CATEGORIES._SUCCESS,
        data: Object.values(data),
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento',
      },
    }); // eslint-disable-next-line no-console
    console.log('sagas > subscription > getCategories', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getCategoriesWatch() {
  yield takeLatest(GET_CATEGORIES._REQUEST, getCategories);
}

function* getTotalAmmountAnnualPayment() {
  try {
    yield put({ type: LOADER_OPEN });

    const { status, data: { totalamount: totalAmountAnnualPayment, items: totalItemsToAssociate } } = yield call(subscriptions.getTotalAmountAndCountSubscriptions);
    if (status?.toString() === '200') {
      yield put({
        type: GET_TOTAL_AMOUNT_ANNUAL_PAYMENT._SUCCESS,
        totalAmountAnnualPayment: parseFloat(totalAmountAnnualPayment),
        totalItemsToAssociate,
      });
    }
  } catch (err) {
    console.log('sagas > subscription > getTotalAmmountAnnualPayment', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getTotalAmmountAnnualPaymentWatch() {
  yield takeLatest(GET_TOTAL_AMOUNT_ANNUAL_PAYMENT._REQUEST, getTotalAmmountAnnualPayment);
}

function* getFilteredCatalog({ filterId }) {
  try {
    yield put({ type: LOADER_OPEN });
    if (filterId.toString() === '0') {
      const { catalog } = yield select(state => state.subscriptions);
      yield put({
        type: GET_FILTERED_CATALOG._SUCCESS,
        content: catalog,
      });
    } else {
      const { data: { data: content } = {} } = yield call(
        subscriptions.getFilteredCatalog,
        filterId
      );
      if (content) {
        const productVariations = content?.reduce((acc, currValue) => {
          const product = (currValue?.product_variations?.data || []).map(
            prod => ({
              ...prod,
              product_id: currValue.product_id,
            })
          );

          return [...acc, ...product];
        }, []);
        yield put({
          type: GET_FILTERED_CATALOG._SUCCESS,
          content: productVariations,
        });
      } else {
        yield put({
          type: GET_FILTERED_CATALOG._SUCCESS,
          content: [],
        });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: { errorText: err?.message },
    });
    // eslint-disable-next-line no-console
    console.log('sagas > subscription > getFilteredCatalog', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getFilteredCatalogWatch() {
  yield takeLatest(GET_FILTERED_CATALOG._REQUEST, getFilteredCatalog);
}

function* getEcmRole() {
  try {
    yield put({ type: LOADER_OPEN });

    const { data, status } = yield call(subscriptions.getEcmRole);

    if (status?.toString() === '200') {
      yield put({
        type: GET_ECM_ROLE._SUCCESS,
        ecmRoleList: data,
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento',
      },
    }); // eslint-disable-next-line no-console
    console.log('sagas > subscription > getEcmRole', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* getEcmRoleWatch() {
  yield takeLatest(GET_ECM_ROLE._REQUEST, getEcmRole);
}

function* getXmlList({loaderActive}) {
  try {
    if(loaderActive) {
      yield put({ type: LOADER_OPEN });
    }
    const { organizationId, userId } = yield select(state => state.authentication.user);
    const { id: domainId } = yield select(state => state.domain);

    const { nextPage, previousPage } = yield select(
      state => state.subscriptions.xmlList
    );

    if ((nextPage !== previousPage && !!nextPage) || !nextPage) {
      const { 
        data: {events, pageinfo}, 
        status 
      } = yield call(subscriptions.getXmlList, { domainId, userId, organizationId, itemsPerPage: 5, nextPage });

      if (status === 200) {
        yield put({
          type: GET_XML_LIST._SUCCESS,
          events,
          nextPage: pageinfo?.nextPageToken
        });
      }
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento',
      },
    }); // eslint-disable-next-line no-console
    console.log('sagas > subscription > getXmlList', err);
  } finally {
    if(loaderActive) {
      yield put({ type: LOADER_CLOSE });
    }
  }
}

function* getXmlListWatch() {
  yield takeLatest(GET_XML_LIST._REQUEST, getXmlList);
}

function* createXml({ecmId}) {
  try {
    yield put({ type: LOADER_OPEN });

    const { userId } = yield select(state => state.authentication.user);
    const { id: domainId } = yield select(state => state.domain);

    const { status } = yield call(subscriptions.createXml, { domainId, userId, ecmId });

    if (status === 200) {
      yield put({
        type: CREATE_XML._SUCCESS
      });

      yield put({
        type: MODAL_OPEN,
        id: SuccessModal.id,
        payload: {
          description:
            'XML generato con successo, ricarica la tabella per poterlo scaricare',
        }
      });
    }
  } catch (err) {
    yield put({
      type: MODAL_OPEN,
      id: ModalErrors.id,
      payload: {
        errorText: 'Errore di caricamento',
      },
    }); // eslint-disable-next-line no-console
    console.log('sagas > subscription > createXml', err);
  } finally {
    yield put({ type: LOADER_CLOSE });
  }
}

function* createXmlWatch() {
  yield takeLatest(CREATE_XML._REQUEST, createXml);
}

export default [
  //activeSubscriptionListGetWatch(),
  getTotalAmmountAnnualPaymentWatch(),
  assocSubscriptionGetWatch(),
  getCalendarsWatch(),
  recommendedSubscriptionListGetWatch(),
  teamsInterestsListGetWatch(),
  contentOverviewMostViewedGetWatch(),
  contentOverviewMostPopularGetWatch(),
  createProspectWatch(),
  allProspectGetWatch(),
  goToEditSubscriptionWatch(),
  updateEmailSubscriptionWatch(),
  deleteEmailSubscriptionWatch(),
  disableEmailSubscriptionWatch(),
  getProductCatalogWatch(),
  getCategoriesWatch(),
  getFilteredCatalogWatch(),
  getSubscriptionProductNamesWatch(),
  prepareCreateProspectPremiumWatch(),
  getEcmRoleWatch(),
  getXmlListWatch(),
  createXmlWatch(),
  updateEcmRoleOrCreditsSubscriptionWatch()
];
