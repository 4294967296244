import React from "react";
import PropTypes from 'prop-types';
import ProText from "ui/typography/proText";
import { Section } from 'ui/components/Homepage/Section/style';
import {
  Banner,
  BannerLogo,
  BannerText,
} from "./style";

const SectionBanner = ({
  bannerImage,
  bannerLink,
  bannerLogo,
  bannerText,
}) => {
  return (
    <Section>
      <a href={bannerLink} target="_blank" rel="noopener noreferrer">
        <Banner bg={bannerImage} height={330}>
          <BannerText>
            <ProText
              configuration={{
                fontSize: 56,
                fontWeight: 700,
                lineHeight: '1.2'
              }}
              ellipsis={2}
            >
              {bannerText}
            </ProText>
          </BannerText>
          <BannerLogo src={bannerLogo} alt='banner-logo' />
        </Banner>
      </a>
    </Section>
  );
};

SectionBanner.propTypes = {
  bannerImage: PropTypes.string,
  bannerText: PropTypes.string,
};

export default SectionBanner;