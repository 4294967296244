import styled from 'styled-components/macro';
import { NavBarHeight } from 'const';

const NavBarContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${NavBarHeight}px;
  background-color: ${props => props.theme.colors.darkCharcoalHeader};
  top: 0;
  left: 0;
  z-index: 50;
  position:fixed;
  right: 0;
  top: 0;
  left: 0;

  & > div.container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media ${props => props.theme.device.tablet} {
    &.open {
      position: fixed;
    }
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media ${props => props.theme.device.mobile} {
    justify-content: space-between;
    & > div:last-child {
      margin-left: 10px;
    }
  }
`;

const LogoContainer = styled.div`
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  height: 35px;
  cursor: pointer;
  width: auto;
  min-width: 180px;
  max-width: 260px;
  /* margin-right: 50px; */

  @media ${props => props.theme.device.tablet} {
    margin-right: 0;
  }

  @media ${props => props.theme.device.mobile} {
    min-width: 160px;
    max-width: 160px;
  }
`;

export const IconArrow = styled.div`
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: ${props => props.theme.colors.white};
  transform: rotate(90deg);
  cursor: pointer;
  transform: ${props => (props.open ? 'rotate(-90deg)' : 'rotate(-270deg)')};
  transition: all 250ms;
  margin-right: auto;
  &.open {
    transform: rotate(-90deg);
  }
`;

export const DomainsContainer = styled.div`
  position: absolute;
  top: calc(${NavBarHeight}px - 8px);
  left: 0;
  min-height: 100vh;
  width: 100%;
  z-index: 1;
`;

export const Domains = styled.div`
  border-radius: 4px;
  box-shadow: 0 10px 50px 0 rgba(50, 50, 50, 0.2);
  border: solid 1px ${props => props.theme.colors.lightSilver};
  background-color: ${props => props.theme.colors.white}fff;
  width: fit-content;
  position: relative;
  top: 0;
  right: 20px;

  & div:nth-child(2) {
    border-top: 1px solid ${props => props.theme.colors.lightSilver};
  }
`;

export const DomainWrapper = styled.div`
  height: 67px;
  width: 292px;
  background-image: url(${props => props.logo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  cursor: pointer;
`;

const LinkMenu = styled.div`
  /* width: ${props => `calc(${props.stringLength}px * 6)`}; */
  width: auto;
  color: ${props => props.theme.colors.white};
  text-align: center;
  cursor: pointer;

  &:hover,
  &.active {
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  @media ${props => props.theme.device.tablet} {
    color: ${props => props.theme.colors.black};
    width: 100%;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  /* width: 100%; */
  height: 100%;
  align-items: center;
  justify-content: ${props => (props.userMode ? 'flex-end' : 'space-between')};
  padding-right: 25px;

  border-right: ${props =>
    (props.isManager && props.isUser
      ? `1px solid ${props.theme.colors.white}`
      : 'none')};

  @media ${props => props.theme.device.tablet} {
    padding-right: 0;
    display: none;
    align-items: flex-start;
    background: ${props => props.theme.colors.white};
    flex-direction: column;
    height: calc(100vh - ${NavBarHeight}px);
    left: 0;
    top: ${NavBarHeight}px;
    width: 100%;
    padding: 16px 35px 0;
    margin-right: auto;
    z-index: 2;

    &.open {
      display: flex;
      position: fixed;
      justify-content: flex-start;
      align-items: center;
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  & > div:first-child {
    margin-left: 0;
  }

  @media ${props => props.theme.device.tablet} {
    border-bottom: 1px solid ${props => props.theme.colors.ghostWhite};
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    order: 1;
    width: 100%;
  }
`;

const IconContainer = styled.div`
  margin-left: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;

  @media ${props => props.theme.device.tablet} {
    margin-left: -15px;
    width: 100%;
    height: 40px;
    & div:first-child {
      min-width: 60px;
      text-align: left;
    }

    &.user {
      margin-left: -3px;
      height: 60px;
    }
  }
`;

const Icon = styled.div`
  font-size: 30px;
  color: ${props => props.theme.mixin.rgba(props.theme.colors.white)};

  @media ${props => props.theme.device.tablet} {
    color: ${props => props.theme.colors.primary.base};
  }
`;

const BoxIcon = styled.div`
  border-left: 1px solid ${props => props.theme.colors.lightSilver};
  border-right: 1px solid ${props => props.theme.colors.lightSilver};
  height: 60px;
  width: 187px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & span {
    font-size: 28px;
    color: ${props =>
    props.theme.mixin.rgba(props.theme.colors.azureDark, 0.65)};
    padding: 0 10px;
  }

  @media ${props => props.theme.device.tablet} {
    border-left: none;
    border-right: none;
    justify-content: left;
    margin-right: auto;

    & span {
      padding: 0;
      padding-right: 10px;
    }
  }
`;

const Text = styled.div`
  font-size: 14px;
  margin-left: 5px;
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    margin-right: 20px;
  }

  @media ${props => props.theme.device.tablet} {
    padding: 0;
    flex-direction: column;
    order: 3;
    align-items: flex-start;
    width: 100%;
    & > div {
      border-bottom: 1px solid ${props => props.theme.colors.ghostWhite};
      margin: 0;
      padding: 15px 0;
      display: flex;
      align-items: center;
    }
  }
`;

const SwitchButton = styled.div`
  cursor: pointer;
  color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.transparent};
  height: 46px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 25px;

  @media ${props => props.theme.device.tablet} {
    border: 0;
    color: ${props => props.theme.colors.black};
    order: 2;
    margin-left: 0;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  &.fixed-height {
    height: 60px;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.ghostWhite};
  }
`;

const IconMenu = styled.div`
  font-size: 30px;
  color: ${props => props.theme.colors.white};
  min-width: 30px;
  text-align: left;
  cursor: pointer;

  @media ${props => props.theme.device.tablet} {
    min-width: 60px;
    color: ${props => props.theme.colors.primary.base};
  }
`;

const IconBookmark = styled.div`
  font-size: 30px;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  min-width: 30px;
  text-align: left;

  @media ${props => props.theme.device.tablet} {
    min-width: 60px;
    color: ${props => props.theme.colors.primary.base};
  }
`;
const IconSend = styled.a`
  font-size: 30px;
  color: ${props => props.theme.colors.white};
  display: block;
  cursor: pointer;
  margin-top: 2px;
  min-width: 30px;
  text-align: left;

  @media ${props => props.theme.device.tablet} {
    min-width: 60px;
    color: ${props => props.theme.colors.primary.base};
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 390px;
  border-bottom: solid 1px ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.white};
  margin-right: 40px;
  justify-content: space-between;
  position: relative;
  input {
    border: none;
    background: ${props => props.theme.colors.transparent};
    color: white;
    font-style: italic;
  }

  @media ${props => props.theme.device.laptop} {
    width: 215px;
  }

  @media ${props => props.theme.device.tablet} {
    display: none;
    border: none;
    border-left: 1px solid ${props => props.theme.colors.brightGray};
    border-radius: 0;
    width: 50px;
    height: ${NavBarHeight}px;
    padding-left: 19px;
  }
`;

const SearchBar = styled.input`
  width: 420px;
  height: 40px;
  padding: 5px 17px 5px 0;
  border: none;
  background-color: ${props => props.theme.colors.primary.base};
  color: ${props => props.theme.colors.white};
  cursor: pointer;

  @media ${props => props.theme.device.tablet} {
    display: none;
  }

  :focus {
    outline: none;
  }

  ::-webkit-input-placeholder {
    color: ${props => props.theme.colors.white};
    font-style: italic;
  }
  ::-moz-placeholder {
    color: ${props => props.theme.colors.white};
    font-style: italic;
  }
  :-ms-input-placeholder {
    color: ${props => props.theme.colors.white};
    font-style: italic;
  }
  :-moz-placeholder {
    color: ${props => props.theme.colors.white};
    font-style: italic;
  }
`;

const IconSearch = styled.div`
  cursor: pointer;
  color: ${props => props.theme.colors.white};
  font-size: 30px;

  @media ${props => props.theme.device.tablet} {
    background-color: ${props => props.theme.colors.primary.base};
  }
`;

const ProPicWrapper = styled.div`
  cursor: pointer;

  & > div {
    box-shadow: 0 0 2px 0px ${props => props.theme.colors.white}fff;

    @media ${props => props.theme.device.tablet} {
      margin-right: 10px;
    }
  }

  @media ${props => props.theme.device.tablet} {
    height: 105px;
    width: 100%;
    padding: 20px 36px;
    border-bottom: 1px solid ${props => props.theme.colors.filterColor};
    display: flex;
    align-items: center;
  }
`;

const SearchLoader = styled.div`
.loader,
.loader:after {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}
.loader {
  margin: 0px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 3px solid rgba(255, 255, 255, 0.2);
  border-right: 3px solid rgba(255, 255, 255, 0.2);
  border-bottom: 3px solid rgba(255, 255, 255, 0.2);
  border-left: 3px solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

`;
const ResultSearch = styled.div`
  position: absolute;
  top: 40px;
  width: 100%;
  color: black;
  z-index: 2;
  background-color: ${props => props.theme.colors.secondaryWhite};
`;
const ResultOption = styled.div`
  padding: 1rem 2rem;
  border-bottom: 1px solid ${props => props.theme.colors.brightGray2};
  cursor: pointer;
  span {
    font-style: italic;
  }
  > span{
      display: flex!important;
      justify-content: space-between;
      padding-right: 3px;
      align-items: center;
       span{
        text-align: right;
        font-size: 10px;
        color: ${props => props.theme.colors.primary.base};
       }
      }
  .viewAll{
    color: ${props => props.theme.colors.primary.base};
  }
`;

export {
  NavBarContainer,
  LogoContainer,
  LinkMenu,
  MenuWrapper,
  HeaderContainer,
  IconWrapper,
  IconContainer,
  Icon,
  BoxIcon,
  Text,
  Links,
  SwitchButton,
  IconMenu,
  IconBookmark,
  IconSend,
  SearchContainer,
  SearchBar,
  IconSearch,
  ProPicWrapper,
  ResultSearch,
  ResultOption,
  SearchLoader
};
