/* eslint-disable no-sequences */
import axios from 'axios';
import { cleanToken, getToken, StorageName } from 'utils/token';
import { aesEncrypt } from 'utils/crypt';
import routes from 'routes';

export const isLocalHost = window.location.hostname === 'localhost';

const ENV_BASE_URL = {
  // DEV: 'https://s4b2dev.ispfdevelop.com',
  DEV: 'https://s4b2sit.ispfdevelop.com',
  SIT: 'https://sit.digitedmed.it/',
  PRE_PROD: 'https://sit.digitedmed.it',
  PROD: 'https://www.digitedmed.it',
  DEFAULT: 'https://www.digitedmed.it',
};

const ENV_BASE_API_URL = {
  DEV: 'https://s4b2apidev.ispfdevelop.com',
  SIT: 'https://s4b2api.ispfdevelop.com',
  PRE_PROD: 'https://s4b2sitcms.ispfdevelop.com',
  PROD: 'https://api.skills4business.it',
  DEFAULT: 'https://api.skills4business.it',
};

export const ZUORA_URL = {
  DEV: 'https://sandbox.eu.zuora.com/apps/PublicHostedPageLite.do',
  SIT: 'https://sandbox.eu.zuora.com/apps/PublicHostedPageLite.do',
  PRE_PROD: 'https://sandbox.eu.zuora.com/apps/PublicHostedPageLite.do',
  PROD: 'https://eu.zuora.com/apps/PublicHostedPageLite.do',
  DEFAULT: 'https://sandbox.eu.zuora.com/apps/PublicHostedPageLite.do',
};

export const BRIGHT_COVE_ACCOUNT = '5660549830001';

export const WEB_SOURCE_BASEURL = {
  LOCAL: 'https://los4b2sit.ispfdevelop.com/',
  DEV: 'https://los4b2sit.ispfdevelop.com/',
  SIT: 'https://los4b2sit.ispfdevelop.com/',
  PRE_PROD: 'https://los4b2sit.ispfdevelop.com/',
  PROD: 'https://los4b2sit.ispfdevelop.com/',
};

export const apiHost =
  ENV_BASE_API_URL[process.env.REACT_APP_DEPLOY_ENV] || ENV_BASE_API_URL.DEFAULT;

const ENV_X_API_KEY = {
  DEV: 'JI3DK4YBOBzwBDkHtatZ8bjfu4LZnlf6qud7pS7c',
  SIT: 'g1KSFg7H1A9QzGhSASviP8EmXqAsqqGt9iERQo2p',
  PRE_PROD: null,
  PROD: 'jWfdyaIOLf8aCLvYd1HAS1iOb2Ddh3LaaktgDpVK',

  DEFAULT: 'jWfdyaIOLf8aCLvYd1HAS1iOb2Ddh3LaaktgDpVK',
};

export const xApiKey =
  ENV_X_API_KEY[process.env.REACT_APP_DEPLOY_ENV] || ENV_X_API_KEY.DEFAULT;

const proxyUrl = {
  private: '/proxy/private/api/v1/private_proxy',
  public: '/proxy/api/v1/public_proxy',
};

export const ENCRIPT_KEY = {
  LOCAL: 'gJ4OtUtzet3a+lxsyKH/lA==',
  DEV: 'gJ4OtUtzet3a+lxsyKH/lA==',
  SIT: 'gJ4OtUtzet3a+lxsyKH/lA==',
  PROD: 'gJ4OtUtzet3a+lxsyKH/lA==',
};

export const authPrefix = '/auth';
export const bePrefix = '/asset';
export const socialPrefix = '/social';
export const catalogPrefix = '/catalog';
export const trackingPrefix = '/tracking';
export const assetPrefix = '/asset';
export const notificationPrefix = '/notification';
export const calendarprefix = '/calendar';
export const baseUrl = '/api/';
export const extendUrl = 'v1/';
export const ssoPrefix = 'sso/';
export const internalPrefix =
  process.env.REACT_APP_DEPLOY_ENV === 'DEV' ? '/internal' : '';
export const clientId = 's4bweb';
export const appId = 's4bapp';
export const channel = 'web';
export const ecmPrefix = '/ecm';

const checkForLocalhostRules = (url = '') => {
  if (isLocalHost) {
    const baseurl = `http://${window.location.hostname}`;
    const port = ':2222';

    return `${baseurl}${port}${url}`;
  }

  return `${apiHost}${url}`;
};

export const axiosApi = axios.create({
  withCredentials: true,
});

export const parseQueryParams = (filters = {}) => {
  const queryParts = [];
  Object.keys(filters).forEach((key) => {
    if ((typeof filters[key] !== 'boolean' && typeof filters[key] !== 'number') && (!filters[key] || (typeof filters[key] !== 'boolean' && typeof filters[key] !== 'number' && !filters[key].length))) {
      return;
    }
    queryParts.push(`${key}=${encodeURIComponent(filters[key])}`);
  });

  return `?${queryParts.join('&')}`;
};


axiosApi.interceptors.request.use(req => {
  const { token } = getToken() || {};
  if (token) {
    req.headers.authorization = token;
  }
  if (
    req?.url?.includes('public') ||
    (req.data &&
      (req?.data?.body?.withXApiKey ||
        req?.data?.withXApiKey ||
        (typeof req?.data?.body === 'string' &&
          req?.data?.body?.includes('withXApiKey')) ||
        (typeof req?.data === 'string' && req?.data?.includes('withXApiKey'))))
  ) {
    req.headers['x-api-key'] = xApiKey;
  }
  req.headers['Cache-Control'] = 'no-cache';

  return req;
});

axiosApi.interceptors.response.use(
  res => ({
    ...res,
    // eslint-disable-next-line
    data: Object.keys(res.data).reduce(
      (c, k) => ((c[k.toLowerCase()] = res.data[k]), c),
      {}
    ),
  }),
  err => {
    //console.log({ ...err });
    if (err?.response?.data?.message === 'Session is expired') {
      cleanToken(StorageName.Auth);
      window.location.href = routes.sessionExpired.path;
    }
    return Promise.reject(err);
  }
);

const checkUrlForProxy = (url = '', isDrupal = false, isPrivate = false) =>
  checkForLocalhostRules(
    !isDrupal ? url : isPrivate ? proxyUrl.private : proxyUrl.public
  );

const checkBodyForProxy = (
  body,
  isDrupal = false,
  endpoint = '',
  method = 'POST',
  userId,
  withXApiKey = false,
  encryptBody = false
) => {
  const bodyToBeEncrypted = process.env.REACT_APP_DEPLOY_ENV === 'DEV' ? false : encryptBody;
  return !isDrupal
    ? bodyToBeEncrypted /* && !(process.env.REACT_APP_DEPLOY_ENV === 'SIT') */
      ? { body: aesEncrypt(body), withXApiKey: withXApiKey || undefined }
      : { ...(body || {}), withXApiKey: withXApiKey || undefined }
    : {
      body: body ? JSON.stringify(body) : '',
      method,
      endpoint,
      userId,
      domainUrl: ENV_BASE_URL[process.env.REACT_APP_DEPLOY_ENV] || ENV_BASE_URL.DEFAULT
    };
};

const checkMethod = (method, isDrupal = false) =>
  (isDrupal ? 'POST' : method).toLowerCase();

export function apiCall(
  url,
  method,
  body = null,
  {
    isDrupal = false,
    isPrivate = false,
    userId,
    withXApiKey = false,
    encryptBody = false,
  } = {}
) {
  return axiosApi[checkMethod(method, isDrupal)](
    checkUrlForProxy(url, isDrupal, isPrivate),
    checkBodyForProxy(
      body,
      isDrupal,
      url,
      method,
      userId,
      withXApiKey,
      encryptBody
    )
  );
}

export const setCallHeader = (name, value) => {
  axiosApi.defaults.headers.common[name] = value;
};

export const deleteCallHeader = name => {
  delete axiosApi.defaults.headers.common[name];
};
