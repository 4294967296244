import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withMediaQueries } from 'hoc/withMediaQueries';
import ProText from 'ui/typography/proText';
import ProDisplay from 'ui/typography/proDisplay';
import {
  GET_DOMAINS,
} from 'redux/actions/authentication';

import {
  BootCnt,
  Section5
} from './style';
import { HtmlWrapper } from 'pages/LandingPage/style';
import Modal from "../../../../ui/components/Modals/SubscriptionB2C";

const AccSection5 = ({props
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
  <>
  <BootCnt>
    <Section5 className='container'>
      <div  className={ `row d-flex my-4 ${props?.image_position==='right'? 'flex-row-reverse'  : ""}`}>
        <div className="col-12 col-md-6 col-lg-6 my-4" data-aos="flip-up">
            <div className="card_custom h-100 shadow-lg" id="discover_img" style={{backgroundImage: 'url("' +props?.image+ '")'}}>
                <div id="content_5">

                <ProDisplay
                  className="fw-bold fs-4 spanTitle"
                  configuration={{ fontSize: 18, lineHeight: 1.5}}>
                    {new DOMParser().parseFromString(props?.overlay_text[0]?.first_row, "text/html").documentElement.textContent}
                </ProDisplay>
                <ProDisplay className="fw-bold fs-4 divTitle"
                    configuration={{ fontSize: 18, lineHeight: 1.5}}>
                      {new DOMParser().parseFromString(props?.overlay_text[0]?.second_row, "text/html").documentElement.textContent}
                </ProDisplay>
                <ProDisplay configuration={{ fontSize: 18, lineHeight: 1.5}}>
                      <HtmlWrapper
                        dangerouslySetInnerHTML={{__html:props?.overlay_text[0]?.third_row}}
                      />
                </ProDisplay>
                </div>
            </div>
        </div>
        <div className="col-12 col-md-6 col-lg-6 my-4" data-aos="flip-up">
            <div className='card_custom h-100 shadow-lg'>
                <div className="col-12 col-md-8 py-5 px-0">
                  <ProDisplay
                    className={'upper py-5 fw-semibold'}
                    configuration={{ fontSize: 56, lineHeight: 1.2, fontWeigh: 700 }}>
                      <HtmlWrapper
                        dangerouslySetInnerHTML={{__html: props?.text_box}}
                      />
                  </ProDisplay>
                </div>
                <div id="subscribe-button">
                    <button className="btn" onClick={openModal}>Iscriviti ora</button>
                </div>
            </div>
        </div>
      </div>
    </Section5>
      <Modal isOpen={isModalOpen} closeModal={closeModal} />
    </BootCnt>
  </>
);
};

AccSection5.propTypes = {
  // HOC withMediaQueries
  mediaIsDesktop: PropTypes.bool,
  mediaIsLaptop: PropTypes.bool,

  // HOC (connect, state)
  domains: PropTypes.array,

  // HOC (connect, dispatch)
  AccSection5: PropTypes.func,
};

export default connect(
  state => ({ domains: state.authentication.user.domainList }),
  dispatch => ({
    AccSection1: domain => dispatch({ type: GET_DOMAINS._REQUEST, domain }),
  })
)(withMediaQueries(AccSection5));
