import React from "react";
import { connect } from 'react-redux';
import { push } from 'redux-first-history';
import PropTypes from 'prop-types';
import Calendar from 'react-calendar';
import routes from 'routes';
import { detailType } from 'utils/common';
import SectionCarousel from 'ui/components/Homepage/SectionCarousel';
import ProText from "ui/typography/proText";
import { CardsContainer } from "ui/components/Homepage/Section/style";
import { HalfWidth, NoEvents } from "./style";

const SectionCalendar = ({
  events,
  getEvents,
  showCalendar,
  vertical,
  // HOC, dispatch
  pushUrl,
}) => (
  <CardsContainer vertical={vertical}>
    {showCalendar ? (
      <Calendar
      defaultValue={new Date()}
      defaultView={"month"}
      formatShortWeekday={(locale, date) => date.toLocaleDateString(locale, { weekday: "narrow" })}
      locale='it-IT'
      maxDetail='month'
      minDetail='month'
      next2Label={null}
      onActiveStartDateChange={getEvents}
      // onClickDay={(value, event) => {
      //   getEvents({
      //     // startDate: getIsoDateStringFromDate(value),
      //     // endDate: getIsoDateStringFromDate(value),
      //     startDate: firstDay.getTime(),
      //     endDate: lastDay.getTime(),
      //   });
      // }}
      prev2Label={null}
      tileDisabled={({activeStartDate, date, view }) => (
        date.getDay() > 0 && date.getDay() < 6 &&
        date.getMonth() === activeStartDate.getMonth()
          ? date < new Date().setHours(0, 0, 0, 0)
          : true
      )}
      tileClassName={({ activeStartDate, date, view }) => {
          const hasClassroom = events
            .filter(evt => evt.session?.sessionType === detailType.CLASSROOM)
            .find(evt => {
              const startDay = new Date(evt.session?.startDate).setHours(0, 0, 0, 0)
              const endDay = new Date(evt.session?.endDate).setHours(23, 59, 59, 999);
              return startDay <= date.getTime() && date.getTime() <= endDay
            });
          const hasVirtual = events
            .filter(evt => evt.session?.sessionType === detailType.VIRTUAL)
            .find(evt => {
              const startDay = new Date(evt.session?.startDate).setHours(0, 0, 0, 0)
              const endDay = new Date(evt.session?.endDate).setHours(23, 59, 59, 999);
              return startDay <= date.getTime() && date.getTime() <= endDay
          });
        return `${hasClassroom ? 'event-date-live' : ''} ${hasVirtual ? 'event-date-virtual' : ''}`;
      }}
      // tileContent={({ activeStartDate, date, view }) =>
      //   view === 'month' && date.getDay() === 0 ? <p>It's Sunday!</p> : null
      // }
    />
    ) : null}
    {events?.length > 0 ? (
      <HalfWidth>
        <SectionCarousel
          data={events}
          type={detailType.LIVE_EVENT}
          onClick={(card) =>
            pushUrl(`${routes.event.path}/${card.itemId}`)
          }
        />
      </HalfWidth>
    ) : (
      <NoEvents>
        <ProText>Nessun evento live previsto nel mese selezionato.</ProText>
      </NoEvents>
    )}
  </CardsContainer>
);

SectionCalendar.propTypes = {
  showCalendar: PropTypes.bool,
  vertical: PropTypes.bool,
  // HOC (connect, state)
  events: PropTypes.array,
  // HOC (connect, dispatch)
  getEvents: PropTypes.func,
  pushUrl: PropTypes.func,
};

export default connect(
  state => ({}),
  dispatch => ({
    pushUrl: url => dispatch(push(url)),
  })
)(SectionCalendar);