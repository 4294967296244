import styled, { css } from 'styled-components/macro';

const SelectContainer = styled.div`
  position: relative;
  width: 100%;

  font-family: 'Arial';
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: black;

  @media ${props => props.theme.device.mobile} {
    font-size: 12px;
  }
  .disabled{
    .placeholder-icon{
      [class*='icon']{
        color: ${props => props.theme.colors.oldSilver};
      }
    }
  }
  div[class*='-placeholder'] {
    /* font-size: 14px; */
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${props => props.theme.colors.placeholder};
    /* margin-left: 20px; */
    display: block !important;
  }

  div[class*='-menu'] {
    font-weight: normal;
    margin-top: 0;
    z-index: 200000;
  }

  div[class*='-indicatorContainer'] {
    transform: rotate(90deg) !important;
    position: relative;
    color: ${props => props.theme.colors.primary.base};

    /* padding: 0; */

    /* &:first-child {
      padding-right: 0;
    } */

    &:after {
      content: '';
      display: block;
      height: 70%;
      position: absolute;
      width: 1px;
      top: 15%;
    }
  }

  div[class*='ValueContainer'] {
    overflow: hidden;
    height: inherit;
    /* min-height: 36px; */
    padding: 0;
    font-size: 13px;
    font-weight: normal;
    color: black;
  }

  div[class*='singleValue'] {
    color: ${props => props.theme.colors.black};
    width: 100%;
    top:36px;
    font-size: 16px;
  }

  div[class*='control'] {
    > div:first-child {
      overflow: hidden;
      height: inherit;
    }
  }

  ${props =>
    props.readonly &&
    css`
      pointer-events: none;
      div[class*='control'] {
        border: none;
        background-color: transparent;
      }

      div[class*='ValueContainer'] {
        padding: 0;
      }

      div[class*='IndicatorsContainer'] {
        display: none;
      }
    `}

  ${({ isSearchType }) =>
    isSearchType &&
    css`
      div[class*='control'] {
        cursor: auto;
      }
      div[class*='-indicatorContainer'] {
        cursor: pointer;
      }
      span ~ div[class*='-indicatorContainer'] {
        display: none;
      }
    `}

  ${({ isError }) =>
    isError &&
    css`
      div[class*='-control'] {
        border-color: ${props => props.theme.colors.redError};
      }
  ` }

    .premium-container{
      display:flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      > div {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .premium{
        font-size: 12px;
        color: ${props => props.theme.colors.specialGray};
        display: inline-flex;
        align-items: center;
        .icon-premium{
          color: ${props => props.theme.colors.specialGray};
          display: inline-block;
          margin-left: 3px;
          font-size: 16px;
        }
      }
      .unavailable {
        color: ${props => props.theme.colors.specialGray};
      }
    }
    .placeholder-icon{
      display: flex;
      align-items: center;
      [class*='icon']{
        color: ${props => props.theme.colors.redCrayola};
        padding-right: 5px;
        font-size: 20px;
        margin-top: -4px;
      }
    }

    .icon-btn-arrow {
      &.open {
        transform: rotate(180deg);
      }

      &.close {
        transform: rotate(360deg);
      }
    }

    .error-message {
      color: ${props => props.theme.colors.redError};
      margin-top: 8px;
    }
`;

const TooltipContainer = styled.div` 
  span {
    position: absolute;
    top: 49%;
    right: 19px;
  }
`
const Icon = styled.div`
  font-size: 30px;
`;

const IconInfo = styled.div`
  margin-left: 5px;
  width: 15px;
  height: 15px;
  font-size: 15px;
  background-color: hsl(0,0%,95%);
  color: ${props => props.theme.colors.primary.darker};
  border-radius: 100%;
`;

export { SelectContainer, Icon, TooltipContainer, IconInfo };