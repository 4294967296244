import React from 'react';
import PropTypes from 'prop-types';
import { Container, IconSearch, InputSearch } from './style';

const handleMobileSearch = mobile => {
  if (mobile) {
    const searchContainer = document.querySelector('.search-container .mobile');
    if (searchContainer.classList.contains('open')) {
      searchContainer.classList.remove('open');
    } else {
      searchContainer.classList.add('open');
    }
  }
};

const SearchBar = ({
  enterKeyDisabled = false,
  placeholder,
  onChange,
  onSubmit,
  mobile,
  hidden,
  hideIcon = false,
  value
}) => {

  function onKeyDown(keyEvent) {
    if (enterKeyDisabled) {
      if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
        keyEvent.preventDefault();
      }
    }
  }

return (
  <Container mobile={mobile}>
    <form onSubmit={e => {
      e.preventDefault()
      e.stopPropagation()
      onSubmit(e)
    }}>
      {hidden && (
        <InputSearch
          onKeyDown={e => onKeyDown(e)}
          placeholder={placeholder}
          onChange={e => onChange(e.target.value)}
          value={value}
        />
      )}
      {!hideIcon && (
        <IconSearch
          className="icon-ricerca"
          onClick={() => handleMobileSearch(mobile)}
        />
      )}
    </form>
  </Container>
  )
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  mobile: PropTypes.bool,
  hidden: PropTypes.bool,
  hideIcon: PropTypes.bool,
  value: PropTypes.string,
  enterKeyDisabled: PropTypes.bool
};

export default SearchBar;
