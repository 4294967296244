import styled, { css } from 'styled-components/macro';

export const Btn = styled.button`
  padding: 10px 25px;
  font-size: 25px;
  font-weight: bold;
  background-color: rgb(242, 40, 79);
  color: white;
  border-radius: 8px;
  border: 0;
  cursor: pointer;

  ${({ mobile }) => mobile && css`
    padding: 5px 10px;
    font-size: 18px;
  `}
`
export default Btn;