import styled from 'styled-components/macro';

export const ToggleSwitchContainer = styled.div`
  width: 300px;
  border-radius: 8px;
  background-color: #f6f7f8;
  margin: 0 auto;
  font-family: 'ProDisplay', sans-serif;
  font-weight: bold;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  .slider{
    background-color: #dd4354;
    width: 50%;
    height: 100%;
    position: absolute;
    border-radius: 8px;
    left: 0;
    top: 0;
    transition: 0.3s left;
    &.right{
      left: 50%;
    }
  }
  .slider-full-width{
    background-color: #dd4354;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 8px;
    left: 0;
    top: 0;
    transition: 0.3s left;
  }
  span{
    padding: 10px;
    color: #929293;
    width: 150px;
    text-align: center;
    cursor: pointer;
    transition: 0.3s color;
    position: relative;
    &.active{
      color: #fff;
    }
  }
`;