import produce from 'immer';
import { BILLING_GET_RESOURCE_COMMERCE_ORDER_DETAIL } from 'redux/actions/billings';

import {
  SIGNUP_NEXT_STEP,
  AGREEMENTS_GET,
  SIGNUP_UPDATE_ACCOUNT,
  SIGNUP_UPDATE_BILLING,
  SIGNUP_UPDATE_PRODUCTS_CART,
  AGREEMENTS_UPDATE,
  SIGNUP_PRODUCTS,
  SIGNUP_CREATE_CART,
  SIGNUP_SET_HEADER_VISIBLE,
  SECTOR_GET,
  SIGNUP_GET_LATEST_CART,
  SIGNUP_PRODUCT_DETAILS,
  NEW_USER_UPDATE_PRODUCTS_CART,
  SIGNUP_UPDATE_CART,
  SIGNUP_SET_DISCOUNT,
  SIGNUP_FIRST_STEP,
  FLOW_TYPE_SET,
  FIRST_PASSWORD_SET,
  SIGNUP_SET_OTPCODE,
  SIGNUP_STEP_RESET,
  SIGNUP_SET_PAYMENT_METHOD_ID,
  REMOVE_PROMO,
  SET_COMPATIBILITY,
  SIGNUP_EMAIL_SET,
  SIGNUP_RESET_CART,
  USERTYPE_UPDATE,
  AGREEMENTS_CREATE,
  CHECK_AGREEMENTS_MODAL,
  SIGNUP_ORDER_PREVIEW_ORDER_ZUORA,
  SIGNUP_RETRIEVE_DRAFT_ORDER_ZUORA,
  SIGNUP_SET_DRAFT_ORDER_ZUORA,
  SIGNUP_BUYER_B2C_NEXT_STEP,
  SET_CURRENT_AGGREGATED_PRODUCTS
} from 'redux/actions/signup';
import routes from 'routes';
import { priceFormat } from 'utils/price-format';

const getNewStep = (num, name, path) => ({ num, name, path });
export const Steps = [
  getNewStep(1, 'subscriptions', routes.subscription.path),
  getNewStep(2, 'account', routes.userAccount.path),
  getNewStep(3, 'confimOtp', routes.confirmOtp.path),
  getNewStep(4, 'billings', routes.billings.path)
];

const initialState = {
  signupFlowStart: false,
  step: Steps[0],
  cart: {
    products: {},
    total_price: undefined
  },
  cart_zuora: {},
  account: {
    name: null,
    surname: null,
    email: null,
    pwd: null
  },
  billing: {},
  agreements: {},
  landingPage: {},
  products: [],
  currentAggregatedProducts: [],
  aggregatedProducts: [],
  sector: [],
  headerIsVisible: true,
  discounts: {},
  promoCodes: [],
  discountCompatible: true,
  isFromBank: false,
  agreementsModalChecked: false
};

const calcUpdatedCart = (assoc_subscriptions = []) => {
  let total = 0;
  (assoc_subscriptions || []).forEach(
    ({ field_product_variation_ref: variation = [{}] } = {}) => {
      const { price = 0 } = variation[0] || {};
      total += Number(price);
    }
  );
  return priceFormat(total);
};

const signupReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case SIGNUP_SET_DISCOUNT._SUCCESS: {
        draft.discounts[action.code] = action.data;
        draft.promoCodes.push(action.code);
        break;
      }
      case REMOVE_PROMO._SUCCESS: {
        delete draft.discounts[action.code];
        draft.promoCodes = state.promoCodes.filter(p => p !== action.code);
        break;
      }
      case SET_COMPATIBILITY: {
        draft.discountCompatible = action.isCompatible;
        break;
      }

      case SIGNUP_NEXT_STEP._SUCCESS || SIGNUP_BUYER_B2C_NEXT_STEP._SUCCESS: {
        const { nextStep } = action;

        draft.step = Steps[nextStep];
        break;
      }

      case NEW_USER_UPDATE_PRODUCTS_CART: {
        const { products = {} } = action;
        draft.cart = {
          ...state.cart,
          products
        };
        break;
      }

      case SIGNUP_UPDATE_PRODUCTS_CART: {
        const { product = {} } = action;

        const newProducts = { ...state.cart.products };
        let newTotalAmount = 0;

        if (product.bundleCode) {
          const existingProductKey = `${product.sku}-${product.bundleCode}`;
          newProducts[existingProductKey] = product;
        } else {
          newProducts[product.sku] = product;
        }
        Object.keys(newProducts).map(
            p => (newTotalAmount +=
              Number(
                (newProducts[p].price_number || '0')
                  .toString()
                  .replace(',', '.')
              ) * Number(newProducts[p].num))
        );

        draft.cart = {
          ...state.cart,
          products: newProducts,
          total_price: newTotalAmount.toFixed(2) || undefined,
        };
        break;
      }

      case SIGNUP_CREATE_CART._SUCCESS:
      case SIGNUP_GET_LATEST_CART._SUCCESS: {
        const products = Object.keys(action.cart.products).reduce(
          (acc, item) => {
            return {
              ...acc,
              [item]: {
                ...(state.cart.products[item] || {}),
                ...action.cart.products[item]
              }
            };
          },
          {}
        );

        draft.cart = {
          ...state.cart,
          ...action.cart,
          products
        };
        break;
      }

      case SIGNUP_UPDATE_CART: {
        const { data: assoc_subscriptions = [] } = action;

        let total_price;
        if ((assoc_subscriptions || []).length) {
          total_price = calcUpdatedCart(assoc_subscriptions);
        }
        draft.cart = {
          ...state.cart,
          total_price
        };
        break;
      }

      case SIGNUP_SET_DRAFT_ORDER_ZUORA:
      case SIGNUP_RETRIEVE_DRAFT_ORDER_ZUORA._SUCCESS:
      case SIGNUP_ORDER_PREVIEW_ORDER_ZUORA._SUCCESS: {
        const { data: {promocode, orderdata} } = action;
        draft.cart_zuora = {
          promocode,
          orderdata
        };
        break;
      }

      case SIGNUP_UPDATE_ACCOUNT: {
        const { account } = action;

        const newAccount = {};
        (Object.keys(account) || []).map(
          a =>
            (newAccount[a] =
              a.indexOf('confirmpwd') < 0 ? account[a].value : undefined)
        );

        draft.account = {
          ...state.account,
          ...newAccount
        };
        break;
      }

      case SIGNUP_EMAIL_SET: {
        draft.account = {
          ...state.account,
          ...action.account
        };
        break;
      }

      case SIGNUP_UPDATE_BILLING: {
        const { billing } = action;

        const newBilling = {};
        (Object.keys(billing) || []).map(
          b => (newBilling[b] = billing[b].value)
        );

        draft.billing = {
          ...state.billing,
          ...newBilling
        };
        break;
      }

      case AGREEMENTS_GET._SUCCESS: {
        const { agreements } = action;
        draft.agreements = agreements;
        break;
      }

      case AGREEMENTS_CREATE: {
        const { agreements } = action;
        const oldAgreements = state.agreements?.user_clauses || {};

        const newAgreements = {};
        (Object.keys(agreements) || []).map(
          b => (newAgreements[b] = agreements[b])
        );

        if (action.keepMandatory) {
          (Object.keys(oldAgreements) || []).forEach(k => {
            if (oldAgreements[k].mandatory) newAgreements[k] = oldAgreements[k];
          });
        }

        draft.agreements.user_clauses = {
          ...newAgreements
        };
        draft.agreements.acceptedClauses = Object.keys(newAgreements);
        break;
      }

      case AGREEMENTS_UPDATE: {
        const { agreements } = action;

        const newAgreements = {};
        (Object.keys(agreements) || []).map(
          b => (newAgreements[b] = agreements[b])
        );

        draft.agreements.user_clauses = {
          ...state.agreements.user_clauses,
          ...newAgreements
        };
        break;
      }

      case USERTYPE_UPDATE: {
        const { userType } = action;
        draft.agreements.userType = userType;
        break;
      }
      case CHECK_AGREEMENTS_MODAL: {
        draft.agreementsModalChecked = true;
        break;
      }

      case SIGNUP_PRODUCTS: {
        const { products, aggregatedProducts } = action;

        draft.products = [...state.products, ...products];

        if (Boolean(aggregatedProducts)) {
          draft.aggregatedProducts = [...state.aggregatedProducts, ...aggregatedProducts];
        }

        break;
      }

      case SIGNUP_PRODUCT_DETAILS: {
        const {
          product_details: { id, payment_card, period_card } = {}
        } = action;
        draft.products = (state.products || []).map(product => {
          if (id === product.product_id) {
            return {
              ...product,
              payment_card,
              period_card
            };
          }
          return product;
        });
        break;
      }

      case SIGNUP_SET_HEADER_VISIBLE: {
        const { response } = action;

        draft.headerIsVisible = response;
        break;
      }

      case SECTOR_GET._SUCCESS: {
        const { data = {} } = action;
        const sectorArr = [];
        (Object.keys(data) || []).forEach(sec => sectorArr.push(data[sec]));
        draft.sector = sectorArr;
        break;
      }

      case SIGNUP_FIRST_STEP._SUCCESS: {
        draft = initialState;
        break;
      }

      case FLOW_TYPE_SET: {
        draft.isFromBank = true;
        break;
      }

      case FIRST_PASSWORD_SET: {
        draft.signupFlowStart = true;
        // eslint-disable-next-line no-unused-vars
        const [_, step1] = Steps;
        draft.step = step1;
        break;
      }
      case SIGNUP_SET_OTPCODE: {
        const { payload, isToConfirmUser } = action;
        draft.otpcode = payload;
        draft.isToConfirmUser = isToConfirmUser;
        break;
      }
      case SIGNUP_STEP_RESET: {
        const [step0] = Steps;
        draft.step = step0;
        draft.cart = initialState.cart;
        break;
      }
      case SIGNUP_SET_PAYMENT_METHOD_ID: {
        draft.paymentToken = action.payload;
        break;
      }
      case BILLING_GET_RESOURCE_COMMERCE_ORDER_DETAIL._SUCCESS: {
        draft.cart = { ...state.cart, ...action.payload };
        break;
      }
      case SIGNUP_RESET_CART: {
        draft.cart = initialState.cart;
        draft.cart_zuora = initialState.cart_zuora;
        draft.paymentToken = null;
        draft.discounts = initialState.discounts;
        draft.promoCodes = initialState.promoCodes;
        draft.discountCompatible = initialState.discountCompatible;
        break;
      }

      case SET_CURRENT_AGGREGATED_PRODUCTS._SUCCESS: {
        const { products } = action;
        draft.currentAggregatedProducts = products;
        break;
      }

      default:
        return state;
    }
    return draft;
  });

export default signupReducer;
