import styled from 'styled-components/macro';

export const ProfileProgressCardContainer = styled.div`
  width: 50%;
  padding: 0 20px;
  margin-bottom: 30px;
  @media ${props => props.theme.device.tablet} {
    width: 100%;
  }

  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const Header = styled.div`
  min-width: 400px;
  height: 107px;
  padding: 15px 30px 23px 13px;
  margin-bottom: 10px;
  border-radius: 12px;
  background-color: ${props =>
    props.bgColorLighter
      ? props.theme.colors.primary.lighter
      : props.theme.colors.primary.base};

  @media ${props => props.theme.device.tablet} {
    padding-bottom: 8px;
    height: 125px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: ${props => (props.hasContents ? 'pointer' : 'auto')};
  }

  @media ${props => props.theme.device.mobile} {
    min-width: unset;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: fit-content;
`;

export const TitleWrapper = styled.div`
  width: calc(100% - 80px);
`;
export const OverTitle = styled.div``;
export const Title = styled.div`
  width: 100%;
`;

export const Resume = styled.div`
  width: 58px;
  height: 58px;
  border-radius: 100%;
  margin: 10px 0 2px;
  padding: 0 26px 26px 0;
  transform: rotate(-270deg);
  background-image: linear-gradient(to bottom, ${props => props.theme.colors.orangeCrayola}, ${props => props.theme.colors.vividGamboge});
`;

export const CollectionContainer = styled.div`
  height: fit-content;
  min-height: 70px;
  padding: 17px 0;
  border-bottom: 1px solid ${props => props.theme.colors.antiFlashWhite};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media ${props => props.theme.device.mobile} {
    flex-direction: column;
  }
`;

export const InfoWrapper = styled.div`
  margin-bottom: 10px;
  width: 40%;
  min-width: fit-content;
  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const CollectionTitle = styled.div`
  margin-bottom: 5px;
  cursor:pointer;
  * {
  color: ${props => props.theme.colors.primary.base};
  }
  }
`;
export const CollectionInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const IconCollection = styled.div`
  width: 14px;
  height: 14px;
  font-size: 14px;
  margin-right: 4px;
  color: ${props => props.theme.colors.americanSilver};
`;
export const CollectionContents = styled.div`
  width: 120px;
  margin-right: 4px;
`;

export const ProgressBarWrapper = styled.div`
  width: 80px;
`;

export const CounterConainer = styled.div`
  width: 55%;
  min-width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${props => props.theme.device.laptop} {
    width: 100%;
  }
  @media ${props => props.theme.device.tablet} {
    width: 60%;
  }
  @media ${props => props.theme.device.mobile} {
    width: 100%;
  }
`;

export const Counter = styled.div`
  width: 45%;
  min-width: fit-content;
  min-height: 100%;
  & > span {
    display: block;
    padding-bottom: 9px;
    @media ${props => props.theme.device.laptop} {
      padding-top: 6px;
      padding-bottom: 0;
    }
    @media ${props => props.theme.device.tablet} {
      padding-top: 0;
      padding-bottom: 9px;
    }
    @media ${props => props.theme.device.mobile} {
      padding-top: 6px;
      padding-bottom: 0;
    }
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const IconArrow = styled.div`
  width: 30px;
  height: 30px;
  font-size: 30px;
  color: ${props => props.theme.colors.white};
  transform: rotate(90deg);
  cursor: pointer;
  transform: ${props => (props.open ? 'rotate(-90deg)' : 'rotate(-270deg)')};
  transition: all 250ms;
  &.open {
    transform: rotate(-90deg);
  }
`;
