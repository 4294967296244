import styled from 'styled-components/macro';

export const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .product-info {
    p {
      margin-top: 5px;
    }
  }

  .products-container {
    max-height: 300px;
    overflow-y: auto;

    @media ${props => props.theme.device.mobile} {
      max-height: 100vw;
    }

    @media ${props => props.theme.device.tablet} {
      max-height: 100vw;
    }
  }

  .is-child {
    color: ${props => props.theme.colors.primary.base};
    padding-left: 20px;
    margin-top: 8px;
  }

  .title {
      position: relative;
      padding-left: 15px;

      &:before {
        content: '';
        position: absolute;
        bottom: 50%;
        width: 6px;
        left: 0px;
        height: 6px;
        background-color: ${props => props.theme.colors.primary.base};
        border-radius: 100%;
      }
    }

    .description {
      padding-left: 15px;

      &.is-child-description {
        padding-left: 20px;
      }
    }
`;

export const TextContainer = styled.div`

`;

export const Title = styled.div`
  margin-bottom: 35px;
  max-width: 372px;
  width: 100%;
  text-align: center;
`;

export const ProductInfo = styled.div`
  margin-bottom: 35px;
  max-width: 372px;
  width: 100%;
  text-align: center;
`;

export const Icon = styled.span`
  position: absolute;
  top: 0;
  font-size: 30px;
  right: 15px;
  cursor: pointer;
  color: ${props => props.theme.colors.primary.base};
`;
